import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Header from "../../components/header/header";
import { getPaymentAction } from "../../actions/payment";
import { SUBSCRIPTION_MODULE_TYPE, SUBSCRIPTION_MODULE_TYPE_NAME, SUPPORTED_PAYMENT_METHOD, SUPPORTED_PAYMENT_METHOD_NAME } from "../../constants";
import CurrencyFormat from "../../components/currencyFormat/currencyFormat";
import TimeFormat from "../../components/timeFormat/timeFormat";
import PaymentDetails from "../../components/Payment/PaymentDetails";
import PaymentActionDetails from "../../components/Payment/PaymentActionDetails";
import CryptoCurrency from "../../components/currency/cryptoCurrency";
import ReactPaginate from "react-paginate";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Form } from "react-bootstrap";
import Currency from "../../components/currency/currency";
import PaymentApprovedActionDetails from "../../components/Payment/PaymentApprovedActionDetails";

export default function Payment() {
  const dispatch = useDispatch();
  const { control } = useForm();
  const { payments, count } = useSelector((state) => state.payment);
  const [page, setPage] = useState(1);
  const [activePage, setActivePage] = useState(0);
  // const [perPage, setPerPage] = useState(20);
  const loadRef = useRef(true);
  const [addModalShow, setAddModal] = useState(false);
  const [paymentDetail, setPaymentDetail] = useState(null);
  const limit = useWatch({ control, name: "limit" });

  const [addActionModalShow, setActionAddModal] = useState(false);
  const [addActionApprovedModalShow, setActionApprovedAddModal] = useState(false);
  const [actionDetails, setActionDetails] = useState(null);
  useEffect(() => {
    setPaginate();
  }, [page, limit]);

  const setPaginate = () => {
    dispatch(getPaymentAction(page, limit));
  }

  const handlePaymentDetail = (item) => {
    setPaymentDetail(item)
    setAddModal(true)
  }

  const paymentAction = (item) => {
    setActionDetails(item)
    setActionAddModal(true)
  }

  const paymentApprovedAction = (item) => {
    setActionDetails(item)
    setActionApprovedAddModal(true)
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setActivePage(selectedPage);
    setPage(selectedPage + 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <>
      <Header title="Requests Logs" />
      <div className="container-fluid py-4">
        <div className="row">
          <div className={`col-lg-12 col-xl-12 col-md-12 mb-lg-0 mb-4`}>
            <div className="card">
              <>
                <div className="card-header pb-0 px-3">
                  <div className="row">
                    <div className="col-md-6">
                      <h6 className="font-weight-bolder payment-text mb-0">
                        Payments
                      </h6>
                    </div>
                    {/* <div className="col-md-6 d-flex justify-content-end align-items-center">
                      <div className="btn-group dropup">
                        <Dropdown>
                          <Dropdown.Toggle className="btn payment-text-invoice">
                            <h6 className="payment-text-invoice mb-1  text-sm padding-left-lastest ">
                              Latest
                            </h6>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">
                              Action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              Another action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                              Something else
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div> */}
                  </div>
                </div>

                <div className="table-responsive">
                  <div className="card-body table-store payments p-3">
                    {/* <h6 className="text-uppercase text-body text-xs font-weight-bolder today-text-profile">Today</h6> */}
                    <div className="row mt-3 font-weight-bold text-dark">
                      <div className="col-lg-2 col-xl-2 col-2">Email</div>
                      <div className="col-lg-1 col-xl-1 col-1">Company</div>
                      <div className="col-lg-2 col-xl-2 col-2">Item</div>
                      <div className="col-lg-1 col-xl-1 col-1">Quantity</div>
                      <div className="col-lg-2 col-xl-2 col-2">Amount</div>
                      <div className="col-lg-1 col-xl-1 col-1">Payment method</div>
                      <div className="col-lg-2 col-xl-2 col-2">Payment status</div>
                      {/* <div className="col-lg-1 col-xl-1 col-1">Approved status</div> */}
                      <div className="col-lg-1 col-xl-1 col-1">Action</div>
                    </div>
                    {payments && payments.length > 0 ? (
                      payments.map((item, index) => (
                        <div key={index} className="row mt-3">
                          <div className="col-lg-2 col-xl-2  col-2">
                            <div className="d-flex flex-column">
                              <h6 className="profile-text text-sm">
                                {item?.user?.first_name + ' ' + item?.user?.last_name} <br />
                                {item?.user?.email} <br />
                                {item?.user?.personalAccountNumber}
                              </h6>
                              <span className="text-xs">
                                <TimeFormat time={item.createdAt} />
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-1 col-xl-1  col-1">
                            <div className="d-flex flex-column">
                              <h6 className="profile-text text-sm">
                                {item?.company?.companyName}
                              </h6>
                            </div>
                          </div>
                          <div className="col-lg-2 col-xl-2 col-2">
                            <div className=" align-items-center">
                              <h6 className="profile-text text-sm">
                                {SUBSCRIPTION_MODULE_TYPE_NAME[item?.subscriptionModuleType]} {item?.subscriptionPlan?.name ? `|| ${item?.subscriptionPlan?.name}` : ''}
                              </h6>
                            </div>
                          </div>
                          <div className="col-lg-1 col-xl-1 col-1">
                            <div className="d-flex align-items-center">
                              <h6 className="profile-text text-sm text-align-center">
                                {item?.quantity?.value || 1}
                              </h6>
                            </div>
                          </div>
                          <div className="col-lg-2 col-xl-2 col-2">
                            <div className="align-items-center">
                              <h6 className="profile-text text-sm text-align-center">
                                {/* {item.paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO ? (
                                  <>
                                    <CryptoCurrency currency={item.currency} />&nbsp;
                                    <CurrencyFormat currency={item.currency} value={item.amount / 100} decimal={5} />
                                  </>
                                ) : ( */}
                                  <>
                                    <Currency currency={item.currency} />
                                    <CurrencyFormat currency={item.currency} value={item.amount / 100} decimal={2} />
                                  </>
                                {/* )} */}
                              </h6>
                            </div>
                          </div>
                          <div className="col-lg-1 col-xl-1  col-1">
                            <div className="d-flex align-items-center">
                              <h6 className={`profile-text text-sm Overflow specialPopup ${item.paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER || item.paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO ? '' : 'displayBlock'}`}
                                onClick={() => handlePaymentDetail(item)}>
                                {SUPPORTED_PAYMENT_METHOD_NAME[item.paymentMethodSelection] || "-"}
                              </h6>
                            </div>
                          </div>
                          <div className="col-lg-2 col-xl-2  col-2">
                            <div className="d-flex align-items-center">
                              <h6 className="profile-text text-sm Statusbtn">
                                {item?.payment_status?.toUpperCase() || "-"}
                              </h6>
                            </div>
                          </div>
                          {/* <div className="col-lg-1 col-xl-1  col-1">
                            <div className="d-flex align-items-center">
                              <h6 className="profile-text text-sm Statusbtn">
                                {item?.approved_status === true ? "APPROVED" : "UNAPPROVED"}
                              </h6>
                            </div>
                          </div> */}
                          <div className="col-lg-1 col-xl-1  col-1 d-flex">
                            {/* {item?.approved_status !== true && ( */}
                            <>
                              {/* {(item.paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.BANK_TRANSFER || item.paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO || (item.paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CARD && item.status === "open")) && ( */}
                              <div className="edit" onClick={() => paymentAction(item)}><i className="fa-solid fa-pen"></i></div>
                              {/* // )} */}
                              {/* {item?.payment_status?.toUpperCase() === 'PAID' && (
                                  <>
                                    &nbsp;
                                    <div className="edit" onClick={() => paymentApprovedAction(item)}><i className="fa-solid fa-check"></i></div>
                                  </>
                                )} */}
                            </>
                            {/* )} */}

                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="d-flex pt-3">
                        <div className="d-flex flex-column justify-content-center ">
                          <h6 className="mb-0 text-sm table-heading text-center">
                            No Payments
                          </h6>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="tableBottom tableBottompadding">
                  <div className="filterButton">
                    <div className="dropdownButton">
                      <Controller
                        name="limit"
                        control={control}
                        render={({ field }) => (
                          <Form.Select
                            {...field}
                            className="select-border"
                            placeholder="10"
                          >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </Form.Select>
                        )}
                      />
                    </div>
                  </div>
                  <div>

                    <ReactPaginate
                      previousLabel={"Prev"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={count}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination m-0"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      forcePage={activePage}
                    />

                  </div>
                </div>

              </>

            </div>
          </div>
        </div>
      </div>



      <PaymentDetails
        item={paymentDetail}
        addModalShow={addModalShow}
        addModalClose={() => setAddModal(false)} />

      <PaymentActionDetails
        item={actionDetails}
        addActionModalShow={addActionModalShow}
        addActionModalClose={() => setActionAddModal(false)} />

      <PaymentApprovedActionDetails
        item={actionDetails}
        addActionModalShow={addActionApprovedModalShow}
        addActionModalClose={() => setActionApprovedAddModal(false)} />
    </>
  );
}
