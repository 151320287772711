import axionIns from "../axios";

const getReferralInvites = async (type) => {
  return axionIns.get(`admin/invite_referral/?type=${type}`).then((response) => {
    return response.data;
  });
};

const addIndividualReferral = async (data) => {
  return axionIns
    .post(`admin/invite_referral`, JSON.stringify(data))
    .then((response) => {
      return response.data;
    });
};

const removeIndividualInvite = async (id) => {
  return axionIns.delete(`admin/invite_referral/${id}`).then((response) => {
    return response.data;
  });
};

const exportOject = {
  getReferralInvites,
  addIndividualReferral,
  removeIndividualInvite,
};

export default exportOject;
