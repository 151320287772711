import subscriptionService from "../services/subscription.service";
import {
  GET_SUBSCRIPTION_BY_ID,
  GET_SUBSCRIPTION_LIST,
  SET_FAILED_MESSAGE,
  SET_SUCCESS_MESSAGE,
  START_LOADER,
  STOP_LOADER,
} from "./types";
import { startLoaderAction, stopLoaderAction } from "./updateReducerValue";

export const getSubscriptionListAction = () => (dispatch) => {
  dispatch({ type: START_LOADER });
  return subscriptionService.getSubscription().then((resp) => {
    if (resp.status) {
      dispatch({ type: GET_SUBSCRIPTION_LIST, payload: resp.data });
    } else {
      dispatch({ type: GET_SUBSCRIPTION_LIST, payload: {} });
    }
    dispatch({ type: STOP_LOADER });
  });
};

export const getSubscriptionPlanList =
  (page, perPage, isActive, search) => (dispatch) => {
    dispatch({ type: START_LOADER });
    return subscriptionService
      .getSubscriptionPlan(page, perPage, isActive, search)
      .then((resp) => {
        if (resp.status) {
          dispatch({
            type: GET_SUBSCRIPTION_LIST,
            payload: resp.data,
            count: resp.count,
          });
        } else {
          dispatch({ type: GET_SUBSCRIPTION_LIST, payload: {} });
        }
        dispatch({ type: STOP_LOADER });
      });
  };

export const getPlanById = (id) => (dispatch) => {
  dispatch({ type: START_LOADER });
  return subscriptionService.getPlanById(id).then((resp) => {
    if (resp.status) {
      dispatch({ type: GET_SUBSCRIPTION_BY_ID, payload: resp.data });
    } else {
      dispatch({ type: GET_SUBSCRIPTION_BY_ID, payload: {} });
    }
    dispatch({ type: STOP_LOADER });
  });
};

export const addSubscriptionAction = (payload) => (dispatch) => {
  dispatch(startLoaderAction())
  return subscriptionService.addSubscription(payload).then((resp) => {
    dispatch(stopLoaderAction())
    if (resp.status) {
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.message });
      return resp;
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
    return Promise.resolve();
  });
};

export const editSubscriptionAction = (payload, id) => (dispatch) => {
  dispatch(startLoaderAction())
  return subscriptionService
    .editSubscription(payload, id)
    .then(async (resp) => {
      dispatch(stopLoaderAction())
      if (resp.status) {
        await dispatch(getSubscriptionPlanList(1, 20, "", ""));
        dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
        return resp;
      } else {
        dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
      }
      return Promise.resolve();
    });
};
export const updateSubscriptionAction = (payload) => (dispatch) => {
  return subscriptionService.updateSubscription(payload).then((resp) => {
    dispatch({ type: STOP_LOADER });
    if (resp.status) {
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
      dispatch(getSubscriptionListAction());
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
    return Promise.resolve();
  });
};
