import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import {
  CUSTOM_SELECT_STYLES,
  SELECT_BOX_THEME,
  coloroptions,
  colourStyles,
  notificationAction,
  statsuOption,
  statusStyles,
} from "../../constants";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addNotificationAction,
  editNotificationAction,
  getNotificationByIdAction,
} from "../../actions/notificationBanner";

export default function AddNotificationBanner() {
  const { register, handleSubmit, control, setValue, watch } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notification } = useSelector((state) => state.notificationBanner);
  const [bannerValue, setBannerValue] = useState();
  const [notificationArray, setNotification] = useState();
  const { state } = useLocation();
  const id = state?.id;
  useEffect(() => {
    const subscription = watch((value) => setBannerValue(value));
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (id) {
      dispatch(getNotificationByIdAction(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id) {
      setValue("label", notification.label);
      setValue("message", notification.message);
      setValue("color", {
        title: notification.color ? "Solid Yellow" : "Solid Red",
        value: notification.color ? 'var(--primary-color)' : "red",
      });
      setValue("isActive", {
        title: notification.isActive ? "Published" : "Not Published",
        value: notification.isActive ? "published" : "notPublished",
      });
      setValue("callToAction", {
        label: notification?.callToAction?.label,
        value: notification?.callToAction?.value,
      });
    }
  }, [setValue, notification, id]);

  useEffect(() => {
    var array = [];
    for (const item in notificationAction) {
      array.push(notificationAction[item]);
    }
    setNotification(array);
  }, []);

  const onSubmit = (data) => {
    var allData = {
      label: data.label,
      message: data.message,
      isActive: data.isActive.sendValue,
      color: data.color.sendValue,
      callToAction: data.callToAction,
    };
    if (id) {
      dispatch(editNotificationAction(allData, id));
    } else {
      dispatch(addNotificationAction(allData));
    }
    navigate(-1);
  };
  return (
    <>
      <Header title="Notification Manager" />
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 mb-2">
            <div className="card notification addbanner h-100">
              <div
                className={`card-body ${
                  bannerValue &&
                  (bannerValue?.message !== "" ||
                    bannerValue?.callToAction !== null)
                    ? ""
                    : "pt-3"
                }`}
              >
                {bannerValue &&
                  (bannerValue?.message !== "" ||
                    bannerValue?.callToAction !== null) && (
                    <div className="bannerHolder border-0">
                      <div className="addedbanner">
                        <p className="header">
                          Here is the final look of your banner :
                        </p>
                        <div
                          className={`bannerPreview ${
                            bannerValue?.color?.value === "red"
                              ? ""
                              : "successBannrer"
                          }`}
                        >
                          <p>{bannerValue?.message}</p>
                          
                          {bannerValue?.callToAction?.label && (
                            <button
                              className={`btn save-btn bannerButton ${
                                bannerValue?.color?.value === "red"
                                  ? ""
                                  : "successButton"
                              }`}
                            >
                              {bannerValue?.callToAction?.value ===
                                notificationAction?.NOTI_MYINFO_VERIFICATION?.value ? (
                                <img src="assets/img/myInfoRetry.svg" alt="myInfo" />
                              ) : (
                                bannerValue?.callToAction?.label
                              )}
                            </button>
                          )}

                          <button type="button" className="bannerClose">
                            <img src="assets/img/close-banner.svg" alt="" />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                <div className="addBannerDetails">
                  <div className="detailHolder">
                    <h5>{id ? "Edit banner" : "Create new banner"}</h5>

                    <form>
                      <div className="form-group bannerInputs">
                        <div className="row">
                          <div className="col-lg-9 fieldPadding">
                            <p className="input-text heading">Banner label</p>
                            <input
                              maxLength={50}
                              autoComplete="off"
                              type="text"
                              className="form-control"
                              placeholder="Type banner name here"
                              {...register("label", { required: true })}
                            />
                            <span className="alertMessage">
                              This is the title of the banner it will not be displayed.
                            </span>
                          </div>
                          <div className="col-md-3 fieldPadding">
                            <p className="input-text heading">Message status</p>

                            <div className="selectStatus">
                              <Controller
                                control={control}
                                name="isActive"
                                rules={{ required: true }}
                                defaultValue={null}
                                render={({ field }) => (
                                  <Select
                                    className="select-border"
                                    {...field}
                                    options={statsuOption}
                                    styles={statusStyles}
                                    getOptionLabel={(option) => option.title}
                                    getOptionValue={(option) =>
                                      option.sendValue
                                    }
                                    placeholder="Choose a Status"
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                  />
                                )}
                              />
                            </div>
                            <span className="alertMessage">
                              Set the message status.
                            </span>
                          </div>

                          <div className="col-lg-9 fieldPadding">
                            <p className="input-text heading">
                              Notification message
                            </p>
                            <input
                              autoComplete="off"
                              type="text"
                              className="form-control"
                              placeholder="Type notification message here"
                              {...register("message", { required: true })}
                            />
                            <span className="alertMessage">
                              Write the message  you want your user to see and take an action.
                            </span>
                          </div>
                          <div className="col-md-3 fieldPadding">
                            <p className="input-text heading">Banner color</p>

                            <div className="selectStatus">
                              <Controller
                                name="color"
                                control={control}
                                rules={{ required: true }}
                                defaultValue={null}
                                render={({ field }) => (
                                  <Select
                                    className="select-border"
                                    {...field}
                                    options={coloroptions}
                                    // theme={SELECT_BOX_THEME}
                                    styles={colourStyles}
                                    getOptionLabel={(option) => option.title}
                                    getOptionValue={(option) =>
                                      option.sendValue
                                    }
                                    placeholder="Choose a color"
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                  />
                                )}
                              />
                            </div>
                            <span className="alertMessage">
                              Choose the preferred banner color.
                            </span>
                          </div>

                          <div className="col-md-6 fieldPadding">
                            <p className="input-text heading">
                              Recommended call to action
                            </p>

                            <div className="selectStatus">
                              <Controller
                                name="callToAction"
                                control={control}
                                rules={{ required: true }}
                                defaultValue={null}
                                render={({ field }) => (
                                  <Select
                                    className="select-border"
                                    {...field}
                                    theme={SELECT_BOX_THEME}
                                    styles={CUSTOM_SELECT_STYLES}
                                    options={notificationArray}
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.value}
                                    placeholder="Choose a Status"
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                  />
                                )}
                              />
                            </div>
                            <span className="alertMessage">
                              Choose the necessary call to action.
                            </span>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="addPlanPage previewFooter">
                  <div className="buttonSection">
                    <Link to={-1} type="button" className="btn save-btn cancel">
                      Cancel
                    </Link>
                    <button
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                      className="btn agree-button save-btn"
                    >
                      Publish banner
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
