import React, { useEffect, useState } from "react";
import { Accordion, Dropdown, DropdownButton } from "react-bootstrap";
import Select from "react-select";
import {
  backendUrl,
  CURRENCY_NAME,
  CUSTOM_SELECT_STYLES,
  PRODUCT_GOLD_TYPE,
  SELECT_BOX_THEME,
  SUBSCRIPTION_PLAN_TYPE,
  SUPPORTED_PAYMENT_METHOD_LIST,
} from "../../constants";
import { useLocation } from "react-router";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";

export default function PlanProfile({
  register,
  errors,
  Controller,
  control,
  planTypeWatch,
  uploadFiles,
  fileName,
}) {
  const location = useLocation();
  const uniqueId = location.state?.id || null;

  return (
    <form>
      <div className="planProfileCard subscriptionPlanAddition">
        <h5>Plan profile</h5>
        <div className="form-group form-margin">
          <div className="row">
            <div className="col-lg-4">
              <div className="addPhotoCard">
                <p>Display Image</p>
                <div className="imageHolder">
                  <div className="imageBack">
                    <label className="uploadBtn">
                      <input onChange={uploadFiles} type="file" hidden />
                      <img src="assets/img/uploadImage.svg" alt="" />
                    </label>
                    <label className="imagePlace">
                      <input onChange={uploadFiles} type="file" hidden />
                      <div
                        // withPlaceholder class for placeholder
                        className={`image ${
                          fileName && fileName.filename ? "" : "withPlaceholder"
                        }`}
                        style={{
                          backgroundImage:
                            fileName && fileName.filename
                              ? `url(${backendUrl + fileName.filename})`
                              : "url(assets/img/planPlaceholder.png)",
                        }}></div>
                    </label>
                  </div>
                </div>
                <div className="imageDesc">
                  <p>
                    Set the product thumbnail image. Only *.png, *.jpg and
                    *.jpeg image files are accepted
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {uniqueId && (
              <div className="col-lg-12 fieldPadding">
                <p className="input-text heading">Product/Plan ID</p>
                <CopyToClipboard text={uniqueId}>
                  <div
                    onClick={($event) => {
                      toast.success("Copied!");
                      $event.stopPropagation();
                      $event.preventDefault();
                    }}
                    className="descProductId">
                    <p>
                      {uniqueId} <img src="assets/img/copyText.svg" alt="" />
                    </p>
                  </div>
                </CopyToClipboard>
              </div>
            )}

            <div className="col-lg-4 fieldPadding">
              <p className="input-text heading">Plan type* </p>
              <Controller
                name="planType"
                control={control}
                rules={{ required: true }}
                // defaultValue={null}
                render={({ field }) => (
                  <Select
                    theme={SELECT_BOX_THEME}
                    styles={CUSTOM_SELECT_STYLES}
                    className="select-border"
                    {...field}
                    options={SUBSCRIPTION_PLAN_TYPE}
                    isClearable={true}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.value}
                    placeholder="Choose plan type ..."
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                )}
              />
              <span className="alertMessage">A plan type is required.</span>
            </div>
            <div className={`col-lg-5 fieldPadding`}>
              <p className="input-text heading">Plan name* </p>
              <input
                maxLength={50}
                autoComplete="off"
                type="text"
                className="form-control"
                placeholder="Currency holding plan"
                {...register("planName", { required: true })}
              />
              <span className="alertMessage">
                A product name is required and recommended to be unique.
              </span>
            </div>
            <div className={`col-lg-3 fieldPadding`}>
              <p className="input-text heading">Stock* </p>
              <input
                maxLength={50}
                autoComplete="off"
                type="text"
                className="form-control"
                placeholder="Currency holding plan"
                {...register("stockKey", { required: false })}
              />
            </div>
            <div
              className={`col-lg-${
                parseInt(planTypeWatch?.value) === 2 ? 5 : 8
              } fieldPadding`}>
              <p className="input-text heading">Small description* </p>
              <input
                maxLength={50}
                autoComplete="off"
                type="text"
                className="form-control"
                placeholder="Currency holding plan"
                {...register("smallDescription", { required: true })}
              />
              <span className="alertMessage">
                A Small description is required and recommended to be unique.
              </span>
            </div>
            {parseInt(planTypeWatch?.value) === 2 && (
              <div className="col-lg-3 fieldPadding">
                <p className="input-text heading">Choose type* </p>
                <Controller
                  name="goldProductType"
                  control={control}
                  rules={{ required: true }}
                  // defaultValue={null}
                  render={({ field }) => (
                    <Select
                      theme={SELECT_BOX_THEME}
                      styles={CUSTOM_SELECT_STYLES}
                      className="select-border"
                      {...field}
                      options={PRODUCT_GOLD_TYPE}
                      isClearable={true}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.value}
                      placeholder="Choose type ..."
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  )}
                />
                <span className="alertMessage">
                  Type is required for product.
                </span>
              </div>
            )}
            <div className="col-lg-3 fieldPadding">
              <p className="input-text heading">Currency* </p>
              <Controller
                name="currency"
                control={control}
                rules={{ required: true }}
                // defaultValue={null}
                render={({ field }) => (
                  <Select
                    theme={SELECT_BOX_THEME}
                    styles={CUSTOM_SELECT_STYLES}
                    className="select-border"
                    {...field}
                    options={CURRENCY_NAME}
                    isClearable={true}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    placeholder="Choose currency ..."
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                )}
              />
              <span className="alertMessage">Currency is required.</span>
            </div>
            <div className="col-lg-3 fieldPadding">
              <p className="input-text heading">Price* </p>
              <input
                autoComplete="off"
                type="text"
                className="form-control"
                placeholder="Price/Amount"
                {...register("price", { required: true })}
              />
              <span className="alertMessage">Price/Amount is required.</span>
            </div>
            {/* <div className="col-lg-4 fieldPadding">
              <p className="input-text heading">Stripe Price Id </p>
              <input
                autoComplete="off"
                type="text"
                className="form-control"
                placeholder="Stripe Price Id"
                {...register("stripePriceId", { required: false })}
              />
              <span className="alertMessage">Please enter stripe price Id.</span>
            </div> */}
            <div className="col-lg-6 fieldPadding">
              <p className="input-text heading">Supported payment method* </p>

              <Controller
                name="supportedPaymentMethod"
                control={control}
                rules={{ required: true }}
                // defaultValue={null}
                render={({ field }) => (
                  <Select
                    isMulti
                    theme={SELECT_BOX_THEME}
                    styles={CUSTOM_SELECT_STYLES}
                    className="select-border"
                    {...field}
                    options={SUPPORTED_PAYMENT_METHOD_LIST}
                    isClearable={true}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.value}
                    placeholder="Choose payment method ..."
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                )}
              />
              <span className="alertMessage">
                Supported payment method is required.
              </span>
            </div>
            <div className="col-lg-12 fieldPadding">
              <p className="input-text heading">Short description </p>
              <input
                maxLength={300}
                autoComplete="off"
                type="text"
                className="form-control"
                placeholder="Short description"
                {...register("shortDescription", { required: false })}
              />
            </div>
            <div className="col-lg-12 fieldPadding">
              <p className="input-text heading">Target audience* </p>
              <Controller
                name="audience"
                control={control}
                render={({ field }) => (
                  <ReactQuill className="editor" theme="snow" {...field} />
                )}
              />
              {/* <textarea
                autoComplete="off"
                type="text"
                className="form-control"
                placeholder="Description"
                rows={4}
                {...register("audience", { required: true })}
              /> */}
              {/* <span className="alertMessage">
                Brief description of your target demographic (e.g. For
                individuals seeking wealth accumulation...)
              </span> */}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
