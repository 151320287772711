import moment from "moment";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { approveActivityAction } from "../../actions/activityLogs";
import Deletemodel from "../../pages/dashboard/Deletemodel";
import Confirmmodel from "./Confirmmodel";
import TimeFormat from "../timeFormat/timeFormat";

export default function Editpromotermodel({
  addModalShow,
  addModalClose,
  item,
}) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [confirmshow, setConfirmShow] = useState(false);

  const handleSubmit = async (flag) => {
    setConfirmShow(false);
    var payload = {
      type: item.pageType,
      userId: item.user._id,
      id: item.id,
      flag: flag,
      itemType: item.type,
    };
    await dispatch(approveActivityAction(payload));
    addModalClose();
  };

  return (
    <>
      {item && item.user && item.user._id && (
        <Modal
          show={addModalShow}
          onHide={addModalClose}
          className="moddal-width companyEdit-modal ps-0 add-modal"
        >
          <div className="modal-header pt-2">
            <button
              type="button"
              className="btn-close text-dark close-button"
              onClick={addModalClose}
            >
              &nbsp;
              {/* <span aria-hidden="true">×</span> */}
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xl-3">
                <h4 className="edit-title">Request</h4>
                <span className="text-xs">
                  <TimeFormat time={item.createdAt} />
                </span>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xl-4">
                <h6>Request Type</h6>
                <h4>{item.description}</h4>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 col-xl-5">
                <h6>Company name</h6>
                <h4>{item?.company?.companyName}</h4>
              </div>
            </div>
            <div className="row paddingtopbtm">
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xl-4">
                <h6>Requested by</h6>
                <div>
                  {item.user
                    ? item.user.first_name + " " + item.user.last_name
                    : "-"}
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xl-4">
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-8">
                    <h6>Company head email</h6>
                    <div>{item.user ? item.user.email : "-"}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row paddingtopbtm">
              <div className="col-md-12">
                <h6>Request Details</h6>
              </div>
            </div>

            {item?.type === "remove_promoter" && item?.promoters?.email ? (
              <div className="row paddingtopbtm">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xl-12 addressbox">
                  <h6>Promoter</h6>
                  <div className="textclr Overflow">
                    {item?.promoters?.first_name +
                      " " +
                      item?.promoters?.last_name}{" "}
                    ({item?.promoters?.email})
                  </div>
                </div>
              </div>
            ) : item?.type === "remove_invite" && item?.invite?.email ? (
              <div className="row paddingtopbtm">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xl-12 addressbox">
                  <h6>
                    {item?.invite.type === 1
                      ? "Promoter"
                      : item?.invite.type === 2
                      ? "Shareholder"
                      : "Director"}
                  </h6>
                  <div className="textclr Overflow">
                    {item?.invite.type === 1
                      ? item?.invite?.first_name + " " + item?.invite?.last_name
                      : item?.invite?.name}{" "}
                    ({item?.invite?.email})
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="row paddingtopbtm">
              <div className="col-md-5">
                <button
                  type="submit"
                  className="btn save-btn savebtn"
                  onClick={addModalClose}
                >
                  BACK
                </button>
              </div>
              {item.status === 1 ? (
                <>
                  <div className="col-md-3">
                    <button
                      type="submit"
                      className="btn save-btn deny"
                      onClick={() => setShow(true)}
                    >
                      DENY
                    </button>
                  </div>
                  <Deletemodel
                    message="Are you sure you want to deny?"
                    addModalShow={show}
                    addModalClose={() => setShow(false)}
                    confirmModal={handleSubmit}
                  />

                  <div className="col-md-4">
                    <button
                      type="submit"
                      className="btn save-btn allow"
                      onClick={() => setConfirmShow(true)}
                    >
                      ALLOW AMMENDMENT
                    </button>
                  </div>
                  <Confirmmodel
                    message="Are you sure you want to Allow this ammendment?"
                    addModalShow={confirmshow}
                    addModalClose={() => setConfirmShow(false)}
                    confirmModal={handleSubmit}
                  />
                </>
              ) : (
                <div className="col-md-7 save-btn ">
                  <h6 className="alignright">
                    {item.status === 2
                      ? "Verified"
                      : item.status === 3
                      ? "Denied"
                      : "-"}
                  </h6>
                  <div className="text-xs alignright">
                    <TimeFormat time={item.updatedAt}/>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
