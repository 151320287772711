import activitylogsService from "../services/activityLogs.service";
import { GET_ACTIVITY_LOGS, ACTIVITY_FETCH_RESET, SET_SUCCESS_MESSAGE, START_LOADER, STOP_LOADER, SET_FAILED_MESSAGE } from "./types";

export const getActivityLogsAction = (page, perPage,pageStatus) => (dispatch) => {
  dispatch({ type: START_LOADER });
  return activitylogsService.getActivityLogs(page, perPage, pageStatus).then((resp) => {
    if (resp.status) {
      dispatch({ type: GET_ACTIVITY_LOGS, payload: resp.data, page: page, count: resp.count });
    } else {
      dispatch({ type: GET_ACTIVITY_LOGS, payload: [], page: page, count: 0 });
    }
    dispatch({ type: STOP_LOADER });
  });
};

export const approveActivityAction = (payload) => (dispatch) => {
  dispatch({ type: START_LOADER });
  return activitylogsService.approveActivity(payload).then((resp) => {
    dispatch({ type: ACTIVITY_FETCH_RESET, payload: true });
    dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
    dispatch({ type: STOP_LOADER });
  });
};