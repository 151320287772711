import { GET_DOCUMENTS } from "../actions/types";
const initialState = {
  documents: [],
  count: 0
};
export default function (state = initialState, action) {
  const { type, payload , page ,count } = action;
  switch (type) {
    case GET_DOCUMENTS:
      return {
        ...state,
        documents: payload,
        count: count
      };
      
    default:
      return state;
  }
}
