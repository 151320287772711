import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Dashboard from "../pages/dashboard/dashboard";
import Files from "../pages/files/files";
import Payment from "../pages/payment";
import Subscription from "../pages/subscription";
import Profile from "../pages/profile";
import SignIn from "../pages/signin/";
import SignUp from "../pages/signup/signUp";
import Task from "../pages/task";
import { RoutesPaths } from "./constant";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import PlanList from "../pages/subscription/planList";
import AddPlan from "../pages/subscription/addPlan";
import ReviewPlanDetail from "../pages/subscription/reviewPlanDetail";
import BusinessDetail from "../pages/businessDetail/BusinessDetail";
import NotificationManager from "../pages/notificationManager/NotificationManager";
import AddNotificationBanner from "../pages/notificationManager/AddNotificationBanner";
import MessageDetail from "../pages/chatPage/messageDetail";
import SuggestedProfiles from "../pages/suggestedProfiles/SuggestedProfiles";
import RequestedLogs from "../pages/requested-logs/requested-logs";
import ObjectiveManager from "../pages/objective/objectiveManager";
import AddObjective from "../pages/objective/AddObjective";
import Settings from "../pages/settings";
import Invites from "../pages/invites/invites";
import UserManager from "../pages/user/userManager";
import Banner from "../pages/banner/Banner";
import AddBannerManager from "../pages/banner/AddBannerManager";

export default function RouteComponent() {
  return (
    <>
      <Routes>
        <Route exact path={RoutesPaths.SIGNIN} element={<PublicRoute><SignIn /></PublicRoute>} />
        <Route exact path={RoutesPaths.SIGNUP} element={<PublicRoute><SignUp /></PublicRoute>} />
        <Route exact path={RoutesPaths.DASHBOARD} element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route exact path={RoutesPaths.FILE} element={<PrivateRoute><Files /></PrivateRoute>} />
        <Route exact path={RoutesPaths.INVITE} element={<PrivateRoute><RequestedLogs /></PrivateRoute>} />
        <Route exact path={RoutesPaths.TASK} element={<PrivateRoute><Task /></PrivateRoute>} />
        <Route exact path={RoutesPaths.PROFILE} element={<PrivateRoute><Profile /></PrivateRoute>} />
        <Route exact path={RoutesPaths.SETTINGS} element={<PrivateRoute><Settings /></PrivateRoute>} />
        <Route exact path={RoutesPaths.PAYMENT} element={<PrivateRoute><Payment /></PrivateRoute>} />
        <Route exact path={RoutesPaths.SUBSCRIPTION} element={<PrivateRoute><Subscription /></PrivateRoute>} />
        <Route exact path={RoutesPaths.PLANLIST} element={<PrivateRoute><PlanList /></PrivateRoute>} />
        <Route exact path={RoutesPaths.ADDPLAN} element={<PrivateRoute><AddPlan /></PrivateRoute>} />
        <Route exact path={RoutesPaths.REVIEWDETAIL} element={<PrivateRoute><ReviewPlanDetail /></PrivateRoute>} />
        <Route exact path={RoutesPaths.BUSINESSDETAIL} element={<PrivateRoute><BusinessDetail /></PrivateRoute>} />
        <Route exact path={RoutesPaths.NOTIFICATIONMANAGER} element={<PrivateRoute><NotificationManager /></PrivateRoute>} />
        <Route exact path={RoutesPaths.ADDNOTIFICATIONBANNER} element={<PrivateRoute><AddNotificationBanner /></PrivateRoute>} />
        <Route exact path={RoutesPaths.MESSAGE_DETAIL} element={<PrivateRoute><MessageDetail /></PrivateRoute>} />
        <Route exact path={RoutesPaths.BOOK_OF_BUSINESS} element={<PrivateRoute><SuggestedProfiles /></PrivateRoute>} />
        <Route exact path={RoutesPaths.OBJECTIVE} element={<PrivateRoute><ObjectiveManager /></PrivateRoute>} />
        <Route exact path={RoutesPaths.ADDOBJECTIVE} element={<PrivateRoute><AddObjective /></PrivateRoute>} />
        <Route exact path={RoutesPaths.REFERRAL_INVITES} element={<PrivateRoute><Invites /></PrivateRoute>} />
        <Route exact path={RoutesPaths.USER_MANAGER} element={<PrivateRoute><UserManager /></PrivateRoute>} />
        <Route exact path={RoutesPaths.BANNER} element={<PrivateRoute><Banner /></PrivateRoute>} />
        <Route exact path={RoutesPaths.ADDBANNER} element={<PrivateRoute><AddBannerManager /></PrivateRoute>} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
}
