import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { SUBSCRIPTION_MODULE_TYPE_NAME, SUPPORTED_PAYMENT_METHOD, backendUrl, country } from "../../constants";
import TimeFormat from "../timeFormat/timeFormat";
import Currency from "../currency/currency";
import CurrencyFormat from "../currencyFormat/currencyFormat";
import CryptoCurrency from "../currency/cryptoCurrency";

export default function PaymentDetails({ item, addModalShow, addModalClose }) {
  return (
    <>
      <Modal
        show={addModalShow}
        onHide={addModalClose}
        className="moddal-width companyEdit-modal ps-0 add-modal"
      >
        <div className="modal-header pt-2">
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={addModalClose}
          >
            &nbsp;
            {/* <span aria-hidden="true">×</span> */}
          </button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xl-3">
              <h4 className="edit-title">Request</h4>
              <span className="text-xs"><TimeFormat time={item?.createdAt} /></span>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xl-4">
              <h6>Company</h6>
              <h4>{item?.company?.companyName}</h4>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 col-xl-5">
              <h6>Payment status</h6>
              <h4>{item?.payment_status?.toUpperCase()}</h4>
            </div>
          </div>

          <div className="row paddingtopbtm">
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xl-3">
              <h6>Payment by</h6>
              <div>{item?.user?.first_name} {item?.user?.last_name}</div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xl-4">
              <div className="row">
                <h6>Item</h6>
                <div>{SUBSCRIPTION_MODULE_TYPE_NAME[item?.subscriptionModuleType]} {item?.subscriptionPlan?.name ? `|| ${item?.subscriptionPlan?.name}` : ''}</div>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 col-xl-5 ">
              <div className="row">
                <h6>Company head email</h6>
                <div>{item?.user?.email}</div>
              </div>
            </div>
          </div>

          <div className="row paddingtopbtm">
            <div className="col-md-12">
              <h6>Details</h6>
            </div>
          </div>

          <div className="row ">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xl-12 addressbox">
              <div className="row paddingtopbtm">
                <div className="col-md-4">
                  <h6>Sending institution</h6>
                  <div className="textclr">{item?.sendingInstruction}</div>
                </div>
                <div className="col-md-4">
                  <h6>Amount</h6>
                  <div className="textclr">
                    {/* {item?.paymentMethodSelection === SUPPORTED_PAYMENT_METHOD.PAY_WITH_CRYPTO ? (
                      <>
                        <CryptoCurrency currency={item.currency} />&nbsp;
                        <CurrencyFormat currency={item.currency} value={item.amount / 100} decimal={5}/>
                      </>
                    ) : ( */}
                      <>
                        <Currency currency={item?.currency} />
                        <CurrencyFormat currency={item?.currency} value={item?.amount / 100} decimal={2} />
                      </>
                    {/* )} */}
                  </div>
                </div>
                <div className="col-md-4">
                  <h6>Account number</h6>
                  <div className="textclr">{item?.number}</div>
                </div>
              </div>

              <div className="row paddingtopbtm">
                <div className="col-md-4">
                  <h6>Account nick name</h6>
                  <div className="textclr">{item?.nickName}</div>
                </div>
                <div className="col-md-4">
                  <h6>Save bank info</h6>
                  <div className="textclr">{item?.saveInfo ? "Yes" : "No"}</div>
                </div>
                <div className="col-md-4">
                  <h6>Quantity</h6>
                  <div className="textclr">{item?.quantity?.value ?? 1}</div>
                </div>
              </div>
            </div>

            <div className="row paddingtopbtm">
              <div className="col-md-5">
                <button type="submit" className="btn save-btn savebtn" onClick={addModalClose}>
                  Back
                </button>
              </div>
              <div className="col-md-7 save-btn ">
                <h6 className="alignright">{item?.payment_status?.toUpperCase()}</h6>
                <div className="text-xs alignright">
                  <TimeFormat value={item?.updatedAt} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
