import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  TWO_FACTOR_LOGIN_SUCCESS,
  START_LOADER,
  STOP_LOADER,
  SET_AUTH_USER_INFO,
  TOGGLE_MOBILE_MENU,
  TOGGLE_MENU_SIDEBAR
} from "../actions/types";
const user = localStorage.getItem("kimbocorp") ? JSON.parse(localStorage.getItem("kimbocorp")) : null;
const token = localStorage.getItem("token") ? localStorage.getItem("token") : null;
const initialState = {
  isLoggedIn: user ? true : false,
  user: user,
  token: token,
  twoFactorStorage: null,
  loader: false,
  sidebarMobile: false,
  showMenuSidebar: false,
}

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        token: payload.user.token,
        twoFactorStorage: null
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        token: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        token: null,
      };
    case TWO_FACTOR_LOGIN_SUCCESS:
      return {
        ...state,
        twoFactorStorage: payload,
      };
    case START_LOADER:
      return {
        ...state,
        loader: true,
      };
    case STOP_LOADER:
      return {
        ...state,
        loader: false,
      };
    case SET_AUTH_USER_INFO:
      return {
        ...state,
        user: payload,
      };
    case TOGGLE_MOBILE_MENU:
      return {
        ...state,
        sidebarMobile: !state.sidebarMobile,
      };

    case TOGGLE_MENU_SIDEBAR:
      return {
        ...state,
        showMenuSidebar: payload,
      };
    default:
      return state;
  }
}
