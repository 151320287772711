import React, { useState, useEffect } from "react";
import Header from "../../components/header/header";
import TaskCards from "./taskCards";
import { useDispatch, useSelector } from "react-redux";
import { getTaskList } from "../../actions/tasks";
import TaskLoadMore from "./TaskLoadMore";
import { TASK_STATUS_ENUM } from "../../constants";

export default function Task() {
  const dispatch = useDispatch();
  const { taskBacklog, taskInProgress, taskComplete } = useSelector((state) => state.task);

  const [recordBackLog, setRecordBackLog] = useState(3);
  const [recordComplete, setRecordComplete] = useState(3);
  const [recordInProgress, setRecordInProgress] = useState(3);

  const [taskModalShow, setTaskModalShow] = useState(false);
  const [editItem, setEditItem] = useState(null);

  useEffect(() => {
    dispatch(getTaskList());
  }, [dispatch])

  const taskModalClose = () => {
    setTaskModalShow(false);
    setEditItem(null)
  };

  const handleTaskModalShow = (identify) => {
    setTaskModalShow(true)
    if (identify === null) {
      setEditItem(null)
    }
  };

  const onLoad = (type) => {
    if (type === TASK_STATUS_ENUM.BACKLOG) {
      setRecordBackLog(recordBackLog + 2)
    } else if (type === TASK_STATUS_ENUM.INPROGRESS) {
      setRecordInProgress(recordInProgress + 2)
    } else if (type === TASK_STATUS_ENUM.COMPLETE) {
      setRecordComplete(recordComplete + 2)
    }
  };

  const editItemTrigger = (item) => {
    setEditItem(item)
    handleTaskModalShow(true)
  }
  return (
    <>
      <Header title="Tasks" />
      <div className="container-fluid py-4">
        <div className="row mb-4"></div>
        <div className="row">
          <div className="col-lg-4 col-xs-12 col-md-6 col-border">
            <h5 className="mb-3">Backlog</h5>
            <div className="row">
              {taskBacklog && taskBacklog.length > 0 ? taskBacklog.slice(0, recordBackLog).map((item, index) => (
                <div className="col-12 mt-3" key={index}>
                  <div className="card">
                    <TaskCards item={item} editItem={editItemTrigger} />
                  </div>
                </div>
              )) : (
                <div className="row">
                  <div className="col-12 mt-3">
                    <div
                      className="card right-bottom cursor-pointer"
                    >
                      <p>
                        No backlog task
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <TaskLoadMore task={taskBacklog} onHandleLoad={() => onLoad(TASK_STATUS_ENUM.BACKLOG)} record={recordBackLog} />

          </div>
          <div className="col-lg-4 col-xs-12 col-md-6 col-border mt-4 mt-lg-0">
            <h5 className="mb-3">In progress </h5>
            <div className="row">
              {taskInProgress && taskInProgress.length > 0 ? taskInProgress.slice(0, recordInProgress).map((item, index) => (
                <div className="col-12 mt-3" key={index}>
                  <div className="card">
                    <TaskCards item={item} editItem={editItemTrigger} />
                  </div>
                </div>
              )) : (
                <div className="row">
                  <div className="col-12 mt-3">
                    <div
                      className="card right-bottom cursor-pointer"
                    >
                      <p>
                        No task in-progress
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <TaskLoadMore task={taskInProgress} onHandleLoad={() => onLoad(TASK_STATUS_ENUM.INPROGRESS)} record={recordInProgress} />
          </div>
          <div className="col-lg-4 col-xs-12 col-md-6  mt-4 mt-lg-0">
            {/* right-card-color */}
            <h5 className="mb-3">Complete</h5>
            <div className="row">
              {taskComplete && taskComplete.length > 0 ? taskComplete.slice(0, recordComplete).map((item, index) => (
                <div className="col-12 mt-3" key={index}>
                  <div className="card right-card">
                    <TaskCards item={item} />
                  </div>
                </div>
              )) : (
                <div className="col-12 mt-3">
                  <div className="card right-bottom cursor-pointer">
                    <p>
                      No completed task
                    </p>
                  </div>
                </div>
              )}
            </div>
            <TaskLoadMore task={taskComplete} onHandleLoad={() => onLoad(TASK_STATUS_ENUM.COMPLETE)} record={recordComplete} />
          </div>
        </div>
      </div>
      {/* <TaskModal
        taskModalShow={taskModalShow}
        taskModalClose={taskModalClose}
        editItem={editItem}
      /> */}
    </>
  );
}
