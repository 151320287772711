import axionIns from "../axios";

const updatePassword = async (payload) => {
  return axionIns.post("user/update-password", JSON.stringify(payload)).then((response) => {return response.data});
};

const getProfile = async () => {
  return axionIns.get("user/me").then((response) => {return response.data});
};

const updateProfile = async (payload) => {
  return axionIns.put("user",  JSON.stringify(payload)).then((response) => {return response.data});
};

const getActivity = async () => {
  return axionIns.get("user/activity").then((response) => {return response.data});
};

const loginAdminAsuser = async (payload) => {
  return axionIns.post("/admin/logadminasuser", JSON.stringify(payload)).then((response) => {return response.data});
};

const getAllUserList = async () => {
  return axionIns.get("/admin/alluserlist").then((response) => {return response.data});
};


const getUserListing = async (page, perPage) => {
  return axionIns.get("admin/userlist?page=" + page + "&perPage=" + perPage).then((response) => {
    return response.data;
  });
};

export default {
  updatePassword,
  getProfile,
  updateProfile,
  getActivity,
  loginAdminAsuser,
  getAllUserList,
  getUserListing,
};
