import React from "react";
export default function TaskLoadMore({ onHandleLoad, task, record }) {
  return (
    <>
    {task.length>record && (
      <div className="row">
        <div className="col-12 mt-3">
          <div className="card right-bottom cursor-pointer">
            <p onClick={ onHandleLoad }>
              Load more ({task && task.length>record ? (task.length - record) : 0 })
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-plus"
                viewBox="0 0 16 16"
              >
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
              </svg>
            </p>
          </div>
        </div>
      </div>
      )}
    </>
  );
}
