import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { logout, toggleMobileMenu } from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import Confirm from "../confirm/confrm";
import { Link, useLocation } from "react-router-dom";
import { backendUrl } from "../../constants";
import { RoutesPaths } from "../../Router/constant";

export default function Header(props) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [confirmModal, setConfirmModal] = useState(false);
  const { showMenuSidebar } = useSelector((state) => state.auth);
  const location = useLocation();
  
  const logoutClick = () => {
    setConfirmModal(true);
  };

  const logoutHide = (value) => {
    if (value) {
      dispatch(logout());
    }
    setConfirmModal(false);
  };

  const mobileMenuOpen = () => {
    dispatch(toggleMobileMenu())
  }

  return (
    <>
      <nav
        className="navbar navbar-main navbar-expand-lg px-0 shadow-none mx-3 nav-back navbar-media"
        id="navbarBlur"
        navbar-scroll="true"
      >
        <div className={`container-fluid py-1 px-3 media-content ${location.pathname === RoutesPaths.MESSAGE_DETAIL ? 'chat-section' : ''}`}>
          <nav aria-label="breadcrumb">
            {/* <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
              <li className="breadcrumb-item text-sm">
                <span className="opacity-5 text-dark">Pages</span>
              </li>
              <li
                className="breadcrumb-item text-sm text-dark active"
                aria-current="page"
              >
                {props.title}
              </li>
            </ol> */}
            <h5 className="font-weight-bolder mb-0 phil-text">
              InProved admin
            </h5>
          </nav>
          <div
            className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
            id="navbar"
          >
            <div className="ms-md-auto pe-md-3 d-flex align-items-center"></div>
            <ul className="navbar-nav  justify-content-end">
              <li className="nav-item d-flex align-items-center">
                <Dropdown className="mb-0">
                  <Dropdown.Toggle className="dropdown-icon">
                    <img
                      alt=""
                      id="dropdown-button-dark-example1"
                      src={user && user.profilePic ? backendUrl + user.profilePic : "../assets/img/Shape.png"}
                      className={user && user.profilePic ? "userImage" : "avatar avatar-sm"}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="header-dropdown">
                    <Link
                      className="drop-item-inner dropdown-item"
                      to={RoutesPaths.PROFILE}
                    >
                      My Account
                    </Link>
                    <Link
                      className="drop-item-inner dropdown-item"
                      to={RoutesPaths.SETTINGS}
                    >
                      Settings
                    </Link>
                    <Dropdown.Divider className="dropDivider" />
                    <Dropdown.Item
                      href="#"
                      onClick={logoutClick}
                      className="drop-divider-inner"
                    >
                      Log out <img src="assets/img/logout.svg" alt="" />{" "}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              {showMenuSidebar && (
                <li className="nav-item d-xl-none ps-3 d-flex align-items-center media-content-li">
                  <span className="nav-link text-body p-0" id="iconNavbarSidenav">
                    <div className="sidenav-toggler-inner" onClick={mobileMenuOpen}>
                      <i className="sidenav-toggler-line"></i>
                      <i className="sidenav-toggler-line"></i>
                      <i className="sidenav-toggler-line"></i>
                    </div>
                  </span>
                </li>
              )}
              {/* <li className="nav-item px-3 d-flex align-items-center">
                <span className="nav-link text-body p-0">
                  <i className="fa fa-cog fixed-plugin-button-nav cursor-pointer"></i>
                </span>
              </li>
              <li className="nav-item dropdown pe-2 d-flex align-items-center position-relative">
                <span className="notify-icon">
                  <b>4</b>
                </span>
                <span
                  className="nav-link text-body p-0"
                  id="dropdownMenuButton"
                >
                  <i className="fa fa-bell cursor-pointer"></i>
                </span>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
      <Confirm show={confirmModal} onHide={logoutHide} />
    </>
  );
}
