import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useFieldArray } from "react-hook-form";
import {
  BOB_SERVICES,
  I_POP_OVER,
  POSITIONS,
  POTENTIAL_PURPOSES,
  POTENTIAL_PURPOSE_COMPANIES,
  POTENTIAL_PURPOSE_PRODUCT_SERVICES,
  POTENTIAL_PURPOSE_PROFILES,
  PROFILE_STATUS,
  SELECT_BOX_THEME,
  country
} from "../../constants";
import Select from "react-select";
import SuccessModal from "./SuccessModal";
import { useState } from "react";
import {
  addBusinessProfile,
  editBusinessProfile,
} from "../../actions/bookOfBusiness";
import { useDispatch } from "react-redux";
import InfoModalStatic from "../../components/infoModal/infoModalStatic";

export const getPotentialPurpose = (company) => {
  var position = null;
  var service = null;
  if (company) {
    if ('bookOfBusinessGroupId' in company) {
      position = company?.bookOfBusinessGroupId?.position || null
      service = company?.bookOfBusinessGroupId?.service || null
    } else {
      position = Array.isArray(company?.bookOfBusinessGroup) ? company?.bookOfBusinessGroup[0]?.position : null || null
      service = Array.isArray(company?.bookOfBusinessGroup) ? company?.bookOfBusinessGroup[0]?.service : null || null
    }

    if (position && service) {
      var positionList = BOB_SERVICES.PROFILES === service ? POTENTIAL_PURPOSE_PROFILES : BOB_SERVICES.PRODUCT_AND_SERVICES === service ? POTENTIAL_PURPOSE_PRODUCT_SERVICES : BOB_SERVICES.COMPANIES === service ? POTENTIAL_PURPOSE_COMPANIES : []
      return positionList.filter(x => x.value === position)[0]?.name || null
    }
  } else {
    return ""
  }
}


export default function ProfileForm({
  formOpen,
  formClose,
  companyDetail,
  bobSelected,
  isEdit,
  setIsEdit,
  limit,
  setStatus,
  status,
  setPage,
  setActivePage,
  bookOfBusiness
}) {

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
    reset,
    watch,
  } = useForm({ mode: "onChange" });
  const dispatch = useDispatch();
  const [successOpen, setSuccessOpen] = useState();
  const [infoModalStaicShow, setInfoModalStaticShow] = useState(false);
  const [infoModalStaicShowType, setInfoModalStaticShowType] = useState(null);
  const {
    fields: emailFields,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: "email",
  });

  const {
    fields: mobileFields,
    append: appendMobile,
    remove: removeMobile,
  } = useFieldArray(
    {
      control,
      required: true,
      name: "number",
    },
    {
      control,
      required: true,
      name: "areaCode",
    }
  );

  const handleclose = () => {
    reset();
    setIsEdit(false);
    formClose();
  };

  useEffect(() => {
    // var designation = POSITIONS.filter(
    //   (x) =>
    //     x?.value ===
    //     parseInt(
    //       companyDetail?.position || companyDetail?.defaultDesignationRole || 1
    //     )
    // )[0];
    // setValue("designation", designation);
    if (!isEdit) {
      return;
    }
    if (isEdit && bobSelected) {
      setValue("first_name", bobSelected?.first_name);
      setValue("last_name", bobSelected?.last_name);
      setValue("email", bobSelected?.email);
      bobSelected?.email?.forEach((email, index) => {
        setValue(`email[${index}]`, email);
      });
      setValue("number", bobSelected?.mobileNumber);
      bobSelected?.mobileNumber?.forEach((number, index) => {
        setValue(`areaCode[${index}]`, number?.areaCode);
        setValue(`number[${index}]`, number?.number);
      });
      setValue("companyName", bobSelected?.company?.companyName);
      setValue(
        "position",
        POSITIONS.filter(
          (x) => x.value === parseInt(bobSelected?.position)
        )[0]?.name
      );
      setValue("industry", bobSelected?.industry);
      setValue("linkdin", bobSelected?.linkedInUrl);
      setValue("country", bobSelected?.country);
      setValue("companyProfile", bobSelected?.companyProfile);
      setValue("positionProfile", bobSelected?.positionProfile);

      setValue("serviceProductName", bobSelected?.serviceProductName);
      setValue("description", bobSelected?.description);
      setValue("landingPageUrl", bobSelected?.landingPageUrl);
      setValue("companyType", bobSelected?.companyType);
      setValue("potentialPurpose", bobSelected?.potentialPurpose);
      if (companyDetail?.bookOfBusinessGroupId?.isDefault) {
        setValue("customPotentialPurposeInput", POTENTIAL_PURPOSES.find(x=>x.value === bobSelected?.customPotentialPurposeInput))
      };

    }
  }, [setValue, isEdit, bobSelected, formOpen]);

  useEffect(() => {
    if (emailFields.length === 0) {
      appendEmail("");
    }
    if (mobileFields.length === 0) {
      appendMobile("");
    }
  }, [emailFields, mobileFields, appendEmail, appendMobile]);

  const onSubmit = async (data) => {
    if (data) {
      const result = data?.number?.map((number, index) => ({
        areaCode: data?.areaCode[index],
        number: number,
      }));
      let payload = {
        first_name: data?.first_name || null,
        last_name: data?.last_name || null,
        pEmail: data?.email,
        mobileNumber: result,
        companyProfile: data?.companyProfile,
        positionProfile: data?.positionProfile || null,
        country: data?.country,
        industry: data.industry,
        linkedInUrl: data.linkdin || null,
        position: (!companyDetail?.bookOfBusinessGroup[0]?.isDefault ? parseInt(data?.designation?.value) : null) || null,
        customPotentialPurposeInput: companyDetail?.bookOfBusinessGroup[0]?.isDefault ? data.customPotentialPurposeInput?.value : null,
        status: status,

        serviceProductName: data.serviceProductName || null,
        description: data.description || null,
        landingPageUrl: data.landingPageUrl || null,
        companyType: data.companyType || null,

      };

      var resp = null;
      if (isEdit) {
        resp = await dispatch(
          editBusinessProfile(
            payload,
            companyDetail?._id,
            bobSelected?.id
          )
        );
      } else {
        resp = await dispatch(
          addBusinessProfile(payload, companyDetail?._id)
        );
      }
      reset();
      formClose();
      setPage(1);
      setActivePage(0);
      if (resp && resp.status) {
        setSuccessOpen(resp.details);
        setStatus(status);
      }
    }
  };

  const isFieldEmpty = (index) => {
    const areaCodeValue = watch(`areaCode.${index}`);
    const numberValue = watch(`number.${index}`);
    return areaCodeValue && numberValue;
  };

  const handleClickInfo = (type) => {
    setInfoModalStaticShow(true);
    setInfoModalStaticShowType(type);
  };



  return (
    <>
      <Modal
        show={formOpen}
        onHide={handleclose}
        className="moddal-primary importModal ps-0"
      >
        <Modal.Body>
          <div className="header">
            <div>
              <h4>{isEdit ? "Edit" : "Add"} {status === PROFILE_STATUS.SUGGESTED_PROFILES ? `Suggested 
                ${(companyDetail?.bookOfBusinessGroup[0]?.service === BOB_SERVICES.PROFILES) ? (
                  "profile"
                ) : (companyDetail?.bookOfBusinessGroup[0]?.service === BOB_SERVICES.COMPANIES) ? (
                  "company"
                ) : (companyDetail?.bookOfBusinessGroup[0]?.service === BOB_SERVICES.PRODUCT_AND_SERVICES) ? (
                  "Service or Product"
                ) : ("")}` : status === PROFILE_STATUS.WARM_PROFILES ? `Warm ${(companyDetail?.bookOfBusinessGroup[0]?.service === BOB_SERVICES.PROFILES) ? (
                  "profile"
                ) : (companyDetail?.bookOfBusinessGroup[0]?.service === BOB_SERVICES.COMPANIES) ? (
                  "company"
                ) : (companyDetail?.bookOfBusinessGroup[0]?.service === BOB_SERVICES.PRODUCT_AND_SERVICES) ? (
                  "Service or Product"
                ) : ("")}` : status === PROFILE_STATUS.MEETING_REQUESTS ? "Meeting Request" : ""}</h4>
              <p>
                Account name:{" "}
                <span>{companyDetail?.companyName || "-"}</span>{" "}
                Suggested by:{" "}
                <span>
                  {companyDetail?.user[0]?.first_name} {companyDetail?.user[0]?.last_name}
                </span>{" "}
                Book of business:{" "}
                <span>
                  {companyDetail?.bookOfBusinessGroup?.length > 0 ? (companyDetail?.bookOfBusinessGroup[0]?.name || "-") : "-"}
                </span>
              </p>
            </div>
            <i onClick={handleclose} className="fa-solid fa-xmark"></i>
          </div>
          <div className="modalBody">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row mb-3">
                {companyDetail?.bookOfBusinessGroup[0]?.service === BOB_SERVICES.PROFILES ? (
                  <>
                    <div className="col-md-6">
                      <label className="labelForm" htmlFor="">
                        First name *
                      </label>
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="Insert first name"
                        {...register("first_name", { required: true })}
                      />
                      {errors.first_name?.type === "required" && (
                        <span className="errorMessage">First name is required</span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label className="labelForm" htmlFor="">
                        Last name *
                      </label>
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="Insert last name"
                        {...register("last_name", { required: true })}
                      />
                      {errors.last_name?.type === "required" && (
                        <span className="errorMessage">Last name is required</span>
                      )}
                    </div>
                  </>
                ) : companyDetail?.bookOfBusinessGroup[0]?.service === BOB_SERVICES.COMPANIES ? (
                  <>
                    <div className="col-md-6">
                      <label className="labelForm" htmlFor="">
                        Company name *
                      </label>
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="Company name"
                        {...register("companyProfile", { required: true })}
                      />
                      {errors.companyProfile?.type === "required" && (
                        <span className="errorMessage">Company name is required</span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label className="labelForm" htmlFor="">
                        Company type *
                      </label>
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="Company type"
                        {...register("companyType", { required: true })}
                      />
                      {errors.companyType?.type === "required" && (
                        <span className="errorMessage">Company type is required</span>
                      )}
                    </div>
                  </>
                ) : companyDetail?.bookOfBusinessGroup[0]?.service === BOB_SERVICES.PRODUCT_AND_SERVICES ? (
                  <>
                    <div className="col-md-12">
                      <label className="labelForm" htmlFor="">
                        Service or product name *
                      </label>
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="Service or product name"
                        {...register("serviceProductName", { required: true })}
                      />
                      {errors.serviceProductName?.type === "required" && (
                        <span className="errorMessage">Service or product name is required</span>
                      )}
                    </div>
                  </>
                ) : ("")}
                <div className="col-md-6">
                  {emailFields.map((field, index) => (
                    <div
                      key={field.id}
                      className="col-lg-12 fieldPadding"
                      style={{ position: "relative" }}
                    >
                      <label className="labelForm" htmlFor="">
                        Email address {index > 0 ? index + 1 : ""}*
                      </label>
                      <div className="removeBtnContainer">
                        {index > 0 && (
                          <div
                            onClick={() => removeEmail(index)}
                            className="removeBtn"
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </div>
                        )}
                        <input
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          placeholder="Insert email address"
                          {...register(`email.${index}`, {
                            required: "Email is required",
                            pattern: {
                              value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                              message: "Invalid email format",
                            },
                            validate: {
                              isUnique: (value) => {
                                // Check if the email is unique
                                const emails = getValues("email");
                                return (
                                  emails.findIndex((item) => item === value) ===
                                  index ||
                                  "Email already exists, please use another email"
                                );
                              },
                            },
                          })}
                        />
                      </div>
                      {errors?.email?.[index] && (
                        <span className="errorMessage">
                          {errors.email?.[index].message}
                        </span>
                      )}
                    </div>
                  ))}
                  <div className="col-lg-6">
                    <div
                      onClick={() => appendEmail("")}
                      className="addMoreFeature"
                    >
                      <img src="assets/img/plusBtn.svg" alt="" />
                      Add email address
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  {mobileFields.map((field, index) => (
                    <div className="row" key={field.id}>
                      <div
                        className="col-lg-4 fieldPadding"
                        style={{ position: "relative" }}
                      >
                        <label className="labelForm" htmlFor="">
                          Country code {index > 0 ? index + 1 : ""}
                        </label>

                        <Controller
                          name={`areaCode.${index}`}
                          control={control}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <Select
                              className="select-border"
                              theme={SELECT_BOX_THEME}
                              value={field.value}
                              onChange={(value) => field.onChange(value)}
                              options={country}
                              isClearable={true}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  whiteSpace: "nowrap",
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  fontSize: "12px"
                                })
                              }}
                              getOptionLabel={(option) =>
                                `${option.dial_code} (${option.name})`
                              }
                              getOptionValue={(option) => option.dial_code}
                              placeholder="Country code"
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }}
                            />
                          )}
                        />
                      </div>
                      <div
                        className="col-lg-8 fieldPadding"
                        style={{ position: "relative" }}
                      >
                        <label className="labelForm" htmlFor="">
                          Mobile number {index > 0 ? index + 1 : ""}
                        </label>
                        <div className="removeBtnContainer">

                          {index > 0 && (
                            <div
                              onClick={() => removeMobile(index)}
                              className="removeBtn"
                            >
                              <i className="fa-solid fa-xmark"></i>
                            </div>
                          )}
                          <input
                            autoComplete="off"
                            type="number"
                            className="form-control"
                            placeholder="Mobile number"
                            {...register(`number.${index}`, {
                              required: false,
                              validate: {
                                isUnique: (value) => {
                                  // Check if the email is unique
                                  const number = getValues("number");
                                  return (
                                    number.findIndex((item) => item === value) ===
                                    index ||
                                    "Mobile number already exists, please use another number"
                                  );
                                },
                              },
                            })}
                          />
                        </div>
                      </div>
                      {errors?.number?.[index] && (
                        <span className="errorMessage">
                          {errors.number?.[index].message}
                        </span>
                      )}
                      {errors?.areaCode?.[index] && (
                        <span className="errorMessage">
                          {errors.areaCode?.[index].message}
                        </span>
                      )}
                    </div>
                  ))}
                  <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-lg-6">
                      <div
                        onClick={() =>
                          isFieldEmpty(mobileFields.length - 1) &&
                          appendMobile("")
                        }
                        className="addMoreFeature"
                      >
                        <img src="assets/img/plusBtn.svg" alt="" />
                        Add mobile number
                      </div>
                    </div>
                  </div>
                </div>

                {companyDetail?.bookOfBusinessGroup[0]?.service === BOB_SERVICES.PROFILES && (
                  <>
                    <div className="col-md-6">
                      <label className="labelForm" htmlFor="">
                        Company
                      </label>
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="Insert Company Profile"
                        {...register("companyProfile", { required: false })}
                      />
                      {errors.companyProfile?.type === "required" && (
                        <span className="errorMessage">
                          Company profile name is required
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label className="labelForm" htmlFor="">
                        Position
                      </label>
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="Insert position"
                        {...register("positionProfile", { required: false })}
                      />
                      {errors.positionProfile?.type === "required" && (
                        <span className="errorMessage">
                          Position profile is required
                        </span>
                      )}
                    </div>
                  </>
                )}



                <div className="col-md-6">
                  <label className="labelForm" htmlFor="">
                    Country
                  </label>
                  <Controller
                    name="country"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Select
                        className="select-border"
                        theme={SELECT_BOX_THEME}
                        value={field.value}
                        onChange={(value) => field.onChange(value)}
                        options={country}
                        isClearable={true}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        placeholder="Select Country"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    )}
                  />
                  {errors.country?.type === "required" && (
                    <span className="errorMessage">Country is required</span>
                  )}
                </div>

                {companyDetail?.bookOfBusinessGroup[0]?.service === BOB_SERVICES.PROFILES && (
                  <div className="col-md-6">
                    <label className="labelForm" htmlFor="">
                      LinkedIn profile URL*
                    </label>
                    <input
                      maxLength={200}
                      autoComplete="off"
                      type="text"
                      className="form-control"
                      placeholder="Insert linkedIn profile URL"
                      {...register("linkdin", {
                        required: "Linkedin profile URL is required",
                        pattern: {
                          value: /^https:\/\/www\.linkedin\.com+/,
                          message: "Please enter a valid linkedin URL",
                          // https://www.linkedin.com/ this is required
                        },
                      })}
                    // defaultValue="https://www.linkedin.com"
                    />
                    {errors.linkdin && (
                      <span className="errorMessage">
                        {errors.linkdin.message}
                      </span>
                    )}
                  </div>
                )}

                {(companyDetail?.bookOfBusinessGroup[0]?.service === BOB_SERVICES.COMPANIES || companyDetail?.bookOfBusinessGroup[0]?.service === BOB_SERVICES.PRODUCT_AND_SERVICES) && (
                  <>
                    <div className="col-md-6">
                      <label className="labelForm" htmlFor="">
                        Landing page URL
                      </label>
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="Landing page URL"
                        {...register("landingPageUrl", false)}
                      />
                    </div>
                  </>
                )}

                <div className="col-md-6">
                  <label className="labelForm" htmlFor="">
                    Industry
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Industry"
                    {...register("industry", {
                      required: false,
                    })}
                  />
                  {/* <Controller
                    name="industry"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        isMulti
                        className="select-border"
                        theme={SELECT_BOX_THEME}
                        value={field.value}
                        onChange={(value) => field.onChange(value)}
                        options={industry}
                        isClearable={true}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        placeholder="Select Industry"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    )}
                  /> */}
                  {errors.industry?.type === "required" && (
                    <span className="errorMessage">Industry is required</span>
                  )}
                </div>

                <div className="col-md-6">
                  <label className="labelForm" htmlFor="">
                    Potential purpose{" "}
                    <img
                      onClick={() => handleClickInfo(I_POP_OVER.POTENTIAL_ROLE)}
                      src="assets/img/info-icon.svg"
                      className="cursor-pointer"
                      alt=""
                    />
                  </label>
                  {companyDetail?.bookOfBusinessGroup[0]?.isDefault ? (
                    <>
                      {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Potential purpose"
                        maxLength={50}
                        {...register("customPotentialPurposeInput", {
                          required: true,
                        })}
                      /> */}

                      <Controller
                        name="customPotentialPurposeInput"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            className="select-border"
                            theme={SELECT_BOX_THEME}
                            value={field.value}
                            onChange={(value) => field.onChange(value)}
                            options={POTENTIAL_PURPOSES}
                            isClearable={true}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.value}
                            placeholder={`${companyDetail?.isDefault ? "Enter a potential reason why you like to introduce this profile to other users" : "Enter a potential reason why you would like to meet this profile"}`}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                          />
                        )}
                      />
                      {errors.customPotentialPurposeInput?.type === "required" && (
                        <span className="errorMessage">Potential purpose is required</span>
                      )}
                    </>
                  ) : (

                    <div className="form-control role-border">
                      <p className="role">{getPotentialPurpose(companyDetail)}</p>

                      {/* getValues("designation")?.name */}
                    </div>
                  )}
                </div>

                {(companyDetail?.bookOfBusinessGroup[0]?.service === BOB_SERVICES.COMPANIES || companyDetail?.bookOfBusinessGroup[0]?.service === BOB_SERVICES.PRODUCT_AND_SERVICES) && (
                  <>
                    <div className="col-md-6">
                      <label className="labelForm" htmlFor="">
                        One sentence summary
                      </label>
                      <textarea
                        maxLength={50}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="One sentence summary"
                        {...register("description", false)}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="note">
                <p className="desc">
                  <span>IMPORTANT NOTE:</span> Lorem ipsum velit officia
                  consequat duis enim velit mollit.
                </p>
              </div>

              <div className="importBtn">
                <button
                  type="submit"
                  // disabled={!isValid}
                  className="customAddButton removePadding"
                >
                  {isEdit ? "Edit " : "Add "}
                  {(companyDetail?.bookOfBusinessGroup[0]?.service === BOB_SERVICES.PROFILES) ? (
                    "profile"
                  ) : (companyDetail?.bookOfBusinessGroup[0]?.service === BOB_SERVICES.COMPANIES) ? (
                    "company"
                  ) : (companyDetail?.bookOfBusinessGroup[0]?.service === BOB_SERVICES.PRODUCT_AND_SERVICES) ? (
                    "Service or Product"
                  ) : ("")}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <SuccessModal
        successOpen={successOpen}
        successClose={() => setSuccessOpen(false)}
        isEdit={isEdit}
        reset={reset}
        status={status}
      />
      <InfoModalStatic
        infoModalStaticShow={infoModalStaicShow}
        infoModalStaticClose={() => setInfoModalStaticShow(false)}
        infoModalStaicShowType={infoModalStaicShowType}
      />
    </>
  );
}
