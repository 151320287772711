import settingsService from "../services/settings.service";
import { GET_SETTINGS, SET_FAILED_MESSAGE, SET_SUCCESS_MESSAGE, START_LOADER, STOP_LOADER } from "./types";

export const getSettingsAction = (payload) => async (dispatch) => {
  return settingsService.getSettings(payload).then((resp) => {
    if (resp.status) {
      dispatch({ type: GET_SETTINGS, payload: resp.data, });
    }
  });
};


export const updateSettingsAction = (payload) => async (dispatch) => {
  dispatch({ type: START_LOADER });
  return settingsService.updateSettings(payload).then((resp) => {
    if (resp.status) {
      dispatch(getSettingsAction());
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data,});
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
    dispatch({ type: STOP_LOADER });
  });
};