import axionIns from "../axios";

const getBanner = (page, perPage, isActive) => {
  return axionIns
    .get(
      "admin/banner?page=" +
        page +
        "&perPage=" +
        perPage +
        "&isActive=" +
        isActive
    )
    .then((response) => {
      return response.data;
    });
};

const getBannerById = (id) => {
  return axionIns.get("admin/banner/" + id).then((response) => {
    return response.data;
  });
};

const addBanner = (payload) => {
  return axionIns.post("admin/banner", payload).then((response) => {
    return response.data;
  });
};

const editBanner = (id, payload) => {
  return axionIns.put("admin/banner/" + id, payload).then((response) => {
    return response.data;
  });
};

const deleteBanner = (id) => {
  return axionIns.delete("admin/banner/" + id).then((response) => {
    return response.data;
  });
};

const exportOject = {
  getBanner,
  deleteBanner,
  addBanner,
  editBanner,
  getBannerById,
};

export default exportOject;
