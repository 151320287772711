import React from "react";
import { Modal } from "react-bootstrap";
import "../../App.css";

export default function Deletemodel({ message, addModalShow, addModalClose, confirmModal }) {
  return (
    <>
      <Modal
        show={addModalShow}
        onHide={addModalClose}
        className="companyEdit-modal confim-modal  add-modal"
      >
        <div className="modal-header pt-2">
          <button
            type="button"
            className="btn-close text-dark close-button btn-closemodel"
            onClick={addModalClose}
          >
            &nbsp;
            {/* <span aria-hidden="true">×</span> */}
          </button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-12 deletetitle">
              <h4 className="edit-title">{message}</h4>
            </div>
 
            <div className="row paddingtopbtm">
            <div className="col-md-3"></div>
              <div className="col-md-3 cancelbox">
                <button type="submit" className="btn save-btn cancel" onClick={addModalClose}>
                  Cancel
                </button>
              </div>
              <div className="col-md-3">
                <button type="submit" className="btn save-btn confirm" onClick={() => confirmModal(false)}>
                  Deny
                </button>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
