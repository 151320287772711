import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import files from "./files";
import user from "./user";
import task from "./task";

import dashboard from "./dashboard";
import activityLogs from "./activityLogs";
import payment from "./payment";
import banner from "./banner";
import subscription from "./subscription";
import notificationBanner from "./notificationBanner";
import objective from "./objectiveReducer";
import businessInfo from "./businessInfo";
import chat from "./chat";

import convoReducer, { ReduxConversation } from "./convoReducer";
import sidReducer from "./currentConvoReducer";
import messageReducer, { ChatMessagesState } from "./messageListReducer";
import loadingReducer from "./loadingReducer";
import participantReducer, { ParticipantsType } from "./participantsReducer";
import unreadMessagesReducer, { UnreadMessagesState, } from "./unreadMessagesReducer";
import attachmentsReducer, { AttachmentsState } from "./attachmentsReducer";
import typingDataReducer, { TypingDataState } from "./typingDataReducer";
import lastReadIndexReducer from "./lastReadIndexReducer";
import bookOfBusiness from "./bookOfBusiness"
import settings from "./settings"
import referralInvites from "./referralInvites"

export default combineReducers({
  auth,
  message,
  user,
  task,
  dashboard,
  activityLogs,
  files,
  payment,
  banner,
  subscription,
  notificationBanner,
  businessInfo,
  chat,
  objective,
  bookOfBusiness,
  settings,
  referralInvites,

  convos: convoReducer,
  sid: sidReducer,
  lastReadIndex: lastReadIndexReducer,
  messages: messageReducer,
  loadingStatus: loadingReducer,
  participants: participantReducer,
  unreadMessages: unreadMessagesReducer,
  attachments: attachmentsReducer,
  typingData: typingDataReducer,
});
