import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Select from "react-select";
import {
  CUSTOM_SELECT_STYLES,
  SELECT_BOX_THEME,
  backendUrl,
  statusStyles,
} from "../../constants";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  addObjectiveAction,
  editObjectiveAction,
  getObjectiveByIdAction,
} from "../../actions/objective";
import { getSubscriptionPlanList } from "../../actions/subscription";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { simpleUploadAction } from "../../actions/files";
import toast from "react-hot-toast";
import { RoutesPaths } from "../../Router/constant";
import { getBusinessGroupAction } from "../../actions/bookOfBusiness";

export default function AddObjective() {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    resetField,
    watch,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { objective } = useSelector((state) => state.objective);
  const { subscription } = useSelector((state) => state.subscription);
  const { businessGroup } = useSelector((state) => state.bookOfBusiness);
  const [fileName, setFileName] = useState();

  const [bannerValue, setBannerValue] = useState();
  const { state } = useLocation();
  const id = state?.id;

  const {
    fields: shareholdersEmailFields,
    append: shareholdersEmailAppend,
    remove: shareholdersEmailRemove,
  } = useFieldArray({
    control,
    name: "defaultShareholders",
    fields: [
      { name: "email", key: "email" },
      { name: "first_name", key: "first_name" },
      { name: "last_name", key: "last_name" },
    ],
  });
  const {
    fields: directorsEmailFields,
    append: directorsEmailAppend,
    remove: directorsEmailRemove,
  } = useFieldArray({
    control,
    name: "defaultDirectors",
    fields: [
      { name: "email", key: "email" },
      { name: "first_name", key: "first_name" },
      { name: "last_name", key: "last_name" },
    ],
  });
  const {
    fields: promotersEmailFields,
    append: promotersEmailAppend,
    remove: promotersEmailRemove,
  } = useFieldArray({
    control,
    name: "defaultPromoters",
    fields: [
      { name: "email", key: "email" },
      { name: "first_name", key: "first_name" },
      { name: "last_name", key: "last_name" },
    ],
  });

  const isFieldEmpty = (index) => {
    const email = watch(`defaultShareholders.${index}.email`);
    const first_name = watch(`defaultShareholders.${index}.first_name`);
    const last_name = watch(`defaultShareholders.${index}.last_name`);
    return email && first_name && last_name;
  };

  const isDirectorFieldEmpty = (index) => {
    const email = watch(`defaultDirectors.${index}.email`);
    const first_name = watch(`defaultDirectors.${index}.first_name`);
    const last_name = watch(`defaultDirectors.${index}.last_name`);
    return email && first_name && last_name;
  };

  const isPromoterFieldEmpty = (index) => {
    const email = watch(`defaultPromoters.${index}.email`);
    const first_name = watch(`defaultPromoters.${index}.first_name`);
    const last_name = watch(`defaultPromoters.${index}.last_name`);
    return email && first_name && last_name;
  };

  useEffect(() => {
    if (shareholdersEmailFields.length === 0) {
      shareholdersEmailAppend("");
    }
    if (directorsEmailFields.length === 0) {
      directorsEmailAppend("");
    }
    if (promotersEmailFields.length === 0) {
      promotersEmailAppend("");
    }
  }, [
    directorsEmailFields,
    promotersEmailFields,
    shareholdersEmailFields,
    promotersEmailAppend,
    directorsEmailAppend,
    shareholdersEmailAppend,
  ]);

  // useEffect(() => {
  //   const subscription = watch((value) => setBannerValue(value));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  useEffect(() => {
    if (id) {
      dispatch(getObjectiveByIdAction(id));
    }
    dispatch(getBusinessGroupAction("", "getall"));
    dispatch(getSubscriptionPlanList(1, 10000, true, ""));
  }, [id, dispatch]);

  useEffect(() => {
    if (id) {
      resetField("defaultDirectors");
      resetField("defaultShareholders");
      resetField("defaultPromoters");
      setFileName(objective.image);
      setValue("shortName", objective.shortName);
      setValue("title", objective.title);
      setValue("description", objective.description);
      setValue("isActive", {
        title: objective.isActive ? "Active" : "Non Active",
        value: objective.isActive ? "published" : "notPublished",
      });
      shareholdersEmailAppend(objective?.defaultShareholders);
      directorsEmailAppend(objective?.defaultDirectors);
      promotersEmailAppend(objective?.defaultPromoters);

      if (objective.subscriptionPlan && objective.subscriptionPlan) {
        setValue(
          "subscriptionPlan",
          subscription.find((x) => x.id === objective.subscriptionPlan)
        );
      }
      if (objective.bookOfBusinessGroup && objective.bookOfBusinessGroup) {
        setValue(
          "bookOfBusinessGroup",
          businessGroup.find((x) => x.id === objective.bookOfBusinessGroup)
        );
      }
    }
  }, [setValue, objective, id]);

  const onSubmit = (data) => {
    if (fileName) {
      var allData = {
        shortName: data.shortName,
        image: fileName,
        title: data.title,
        description: data.description,
        subscriptionPlan: data?.subscriptionPlan?.id || null,
        bookOfBusinessGroup: data?.bookOfBusinessGroup?.id || null,
        isActive: data.isActive.sendValue,
        defaultShareholders: data?.defaultShareholders || [],
        defaultDirectors: data?.defaultDirectors || [],
        defaultPromoters: data?.defaultPromoters || [],
      };

      if (id) {
        dispatch(editObjectiveAction(allData, id));
      } else {
        dispatch(addObjectiveAction(allData));
      }
      navigate(-1);
    } else {
      toast.error("Please select image as it is required");
    }
  };

  const uploadFiles = (event) => {
    const file = event.target.files[0];
    uploadDispatchFile(file);
    event.target.value = null;
  };

  const isImageFileValid = (fileExten) => {
    switch (fileExten) {
      case "jpg":
      case "jpeg":
      case "png":
        return true;
      default:
        return false;
    }
  };

  const uploadDispatchFile = (file) => {
    var maxfileUploadSize = 1024 * 1024 * 100;
    if (file.size < maxfileUploadSize) {
      const formData = new FormData();
      var fileExten = file.name.split(".").slice(-1)[0];
      if (isImageFileValid(fileExten)) {
        formData.append("file", file);
        dispatch(simpleUploadAction(formData)).then((resp) => {
          setFileName(resp);
        });
      } else {
        toast.error("Only *.png, *.jpg and *.jpeg image files are accepted");
      }
    } else {
      toast.error("Maximum file upload size is 50mb.");
    }
  };

  const onCancel = () => {
    reset();
    setFileName({});
    if (id) {
      navigate(RoutesPaths.OBJECTIVE);
    }
  };

  return (
    <>
      <Header title="Objective" />
      <div className="container-fluid py-4 addPlanPage">
        <div className="row">
          <div className="col-lg-3 col-xl-3 col-md-3 col-sm-12 mb-2">
            <div className="addPhotoCard">
              <p>Display image</p>
              <div className="imageHolder">
                <div className="imageBack">
                  <label className="uploadBtn">
                    <input onChange={uploadFiles} type="file" hidden />
                    <img src="assets/img/uploadImage.svg" alt="" />
                  </label>
                  <label className="imagePlace">
                    <input onChange={uploadFiles} type="file" hidden />
                    <div
                      // withPlaceholder class for placeholder
                      className={`image ${
                        fileName && fileName.filename ? "" : "withPlaceholder"
                      }`}
                      style={{
                        backgroundImage:
                          fileName && fileName.filename
                            ? `url(${backendUrl + fileName.filename})`
                            : "url(assets/img/planPlaceholder.png)",
                      }}
                    ></div>
                  </label>
                </div>
              </div>
              <div className="imageDesc">
                <p>
                  Set the product thumbnail image. Only *.png, *.jpg and *.jpeg
                  image files are accepted
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-xl-8 col-md-8 col-sm-12 mb-2">
            <div className="planSegments">
              <div className="segment">
                <p>{id ? "Edit objective" : "Create new objective"}</p>
              </div>
            </div>
            <form className="addQuillEditor">
              <div className="planProfileCard">
                <div className="form-group form-margin">
                  <div className="row">
                    <div className="col-lg-3 fieldPadding">
                      <p className="input-text heading">Short name*</p>
                      <input
                        maxLength={10}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="Enter objective short name"
                        {...register("shortName", { required: true })}
                      />
                      <span className="alertMessage">
                        This is the short name of an objective.
                      </span>
                    </div>
                    <div className="col-lg-6 fieldPadding">
                      <p className="input-text heading">Title*</p>
                      <input
                        maxLength={100}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="Enter objective title here"
                        {...register("title", { required: true })}
                      />
                      <span className="alertMessage">
                        This is the title of an objective.
                      </span>
                    </div>
                    <div className="col-md-3 fieldPadding">
                      <p className="input-text heading">Objective status</p>

                      <div className="selectStatus">
                        <Controller
                          control={control}
                          name="isActive"
                          rules={{ required: true }}
                          defaultValue={null}
                          render={({ field }) => (
                            <Select
                              className="select-border"
                              {...field}
                              options={[
                                {
                                  title: "Active",
                                  sendValue: true,
                                  value: "published",
                                },
                                {
                                  title: "Non Active",
                                  sendValue: false,
                                  value: "notPublished",
                                },
                              ]}
                              styles={statusStyles}
                              getOptionLabel={(option) => option.title}
                              getOptionValue={(option) => option.sendValue}
                              placeholder="Choose a Status"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          )}
                        />
                      </div>
                      <span className="alertMessage">
                        Set the objective status.
                      </span>
                    </div>
                    <div className="col-lg-12 fieldPadding">
                      <p className="input-text heading">
                        Link book of business
                      </p>

                      <Controller
                        name="bookOfBusinessGroup"
                        control={control}
                        // defaultValue={null}
                        render={({ field }) => (
                          <Select
                            theme={SELECT_BOX_THEME}
                            styles={CUSTOM_SELECT_STYLES}
                            className="select-border"
                            {...field}
                            options={businessGroup}
                            isClearable={true}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            placeholder="Choose book of business"
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="col-lg-12 fieldPadding">
                      <p className="input-text heading">
                        Link subscripiton plan
                      </p>

                      <Controller
                        name="subscriptionPlan"
                        control={control}
                        // defaultValue={null}
                        render={({ field }) => (
                          <Select
                            theme={SELECT_BOX_THEME}
                            styles={CUSTOM_SELECT_STYLES}
                            className="select-border"
                            {...field}
                            options={subscription}
                            isClearable={true}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            placeholder="Choose subscription plan"
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="col-lg-12 fieldPadding">
                      <div className="row">
                        <div className="col-lg-12">
                          <p className="input-text heading">Shareholder</p>

                          {shareholdersEmailFields &&
                            shareholdersEmailFields.map((field, index) => (
                              <div className="row" key={field.id}>
                                <div className="col-lg-4 fieldPadding">
                                  <p className="input-text heading">
                                    Shareholder email {index + 1}
                                  </p>
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    className="form-control"
                                    placeholder={`Enter shareholder ${
                                      index + 1
                                    } email`}
                                    {...register(
                                      `defaultShareholders.${index}.email`,
                                      {
                                        required: {
                                          value: false,
                                          message: "Email is required",
                                        },
                                        pattern: {
                                          value:
                                            /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                          message:
                                            "Email address must be a valid address",
                                        },
                                        validate: {
                                          isUnique: (value) => {
                                            // Check if the email is unique
                                            const emails =
                                              getValues(`defaultShareholders`);
                                            return (
                                              emails?.findIndex(
                                                (item) => item.email === value
                                              ) === index ||
                                              "Email already exists, please use another email"
                                            );
                                          },
                                        },
                                      }
                                    )}
                                  />
                                  {errors &&
                                    errors.defaultShareholders?.[index]
                                      ?.email && (
                                      <span className="errorMessage">
                                        {
                                          errors?.defaultShareholders?.[index]
                                            ?.email?.message
                                        }
                                      </span>
                                    )}
                                </div>

                                <div className="col-lg-4 fieldPadding">
                                  <p className="input-text heading">
                                    Shareholder first name {index + 1}
                                  </p>
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    className="form-control"
                                    placeholder={`Enter shareholder ${
                                      index + 1
                                    } first name`}
                                    {...register(
                                      `defaultShareholders.${index}.first_name`,
                                      {
                                        required: {
                                          value: false,
                                          message: "First name is required",
                                        },
                                      }
                                    )}
                                  />

                                  {errors &&
                                    errors.defaultShareholders?.[index]
                                      ?.first_name && (
                                      <span className="errorMessage">
                                        {
                                          errors?.defaultShareholders?.[index]
                                            ?.first_name?.message
                                        }
                                      </span>
                                    )}
                                </div>

                                <div className="col-lg-4 fieldPadding">
                                  <p className="input-text heading">
                                    Shareholder last name {index + 1}
                                  </p>
                                  {shareholdersEmailFields.length > 1 && (
                                    <div
                                      onClick={() =>
                                        shareholdersEmailRemove(index)
                                      }
                                      className="removeBtn"
                                    >
                                      <i
                                        className="fa fa-times-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  )}
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    className="form-control"
                                    placeholder={`Enter shareholder ${
                                      index + 1
                                    } last name`}
                                    {...register(
                                      `defaultShareholders.${index}.last_name`,
                                      {
                                        required: {
                                          value: false,
                                          message: "Last name is required",
                                        },
                                      }
                                    )}
                                  />

                                  {errors &&
                                    errors.defaultShareholders?.[index]
                                      ?.last_name && (
                                      <span className="errorMessage">
                                        {
                                          errors?.defaultShareholders?.[index]
                                            ?.last_name?.message
                                        }
                                      </span>
                                    )}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                          <div
                            onClick={() =>
                              !errors?.defaultShareholders &&
                              isFieldEmpty(
                                shareholdersEmailFields.length - 1
                              ) &&
                              shareholdersEmailAppend({
                                email: "",
                                first_name: "",
                                last_name: "",
                              })
                            }
                            className="addMoreFeature"
                          >
                            <img src="assets/img/plusBtn.svg" alt="" />
                            Add shareholder
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 fieldPadding">
                      <p className="input-text heading">Director</p>

                      {directorsEmailFields &&
                        directorsEmailFields.map((field, index) => (
                          <div className="row" key={field.id}>
                            <div className="col-lg-4 fieldPadding">
                              <p className="input-text heading">
                                Director email {index + 1}
                              </p>
                              <input
                                autoComplete="off"
                                type="text"
                                className="form-control"
                                placeholder={`Enter director ${
                                  index + 1
                                } email`}
                                {...register(
                                  `defaultDirectors.${index}.email`,
                                  {
                                    required: {
                                      value: false,
                                      message: "Email is required",
                                    },
                                    pattern: {
                                      value:
                                        /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                      message:
                                        "Email address must be a valid address",
                                    },
                                    validate: {
                                      isUnique: (value) => {
                                        // Check if the email is unique
                                        const emails =
                                          getValues(`defaultDirectors`);
                                        return (
                                          emails?.findIndex(
                                            (item) => item.email === value
                                          ) === index ||
                                          "Email already exists, please use another email"
                                        );
                                      },
                                    },
                                  }
                                )}
                              />
                              {errors &&
                                errors.defaultDirectors?.[index]?.email && (
                                  <span className="errorMessage">
                                    {
                                      errors?.defaultDirectors?.[index]?.email
                                        ?.message
                                    }
                                  </span>
                                )}
                            </div>

                            <div className="col-lg-4 fieldPadding">
                              <p className="input-text heading">
                                Director first name {index + 1}
                              </p>
                              <input
                                autoComplete="off"
                                type="text"
                                className="form-control"
                                placeholder={`Enter director ${
                                  index + 1
                                } first name`}
                                {...register(
                                  `defaultDirectors.${index}.first_name`,
                                  {
                                    required: {
                                      value: false,
                                      message: "First name is required",
                                    },
                                  }
                                )}
                              />

                              {errors &&
                                errors.defaultDirectors?.[index]
                                  ?.first_name && (
                                  <span className="errorMessage">
                                    {
                                      errors?.defaultDirectors?.[index]
                                        ?.first_name?.message
                                    }
                                  </span>
                                )}
                            </div>

                            <div className="col-lg-4 fieldPadding">
                              <p className="input-text heading">
                                Director last name {index + 1}
                              </p>
                              {directorsEmailFields.length > 1 && (
                                <div
                                  onClick={() => directorsEmailRemove(index)}
                                  className="removeBtn"
                                >
                                  <i
                                    className="fa fa-times-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              )}
                              <input
                                autoComplete="off"
                                type="text"
                                className="form-control"
                                placeholder={`Enter director ${
                                  index + 1
                                } last name`}
                                {...register(
                                  `defaultDirectors.${index}.last_name`,
                                  {
                                    required: {
                                      value: false,
                                      message: "Last name is required",
                                    },
                                  }
                                )}
                              />

                              {errors &&
                                errors.defaultDirectors?.[index]?.last_name && (
                                  <span className="errorMessage">
                                    {
                                      errors?.defaultDirectors?.[index]
                                        ?.last_name?.message
                                    }
                                  </span>
                                )}
                            </div>
                          </div>
                        ))}

                      <div className="row">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                          <div
                            onClick={() =>
                              !errors?.defaultDirectors &&
                              isDirectorFieldEmpty(
                                directorsEmailFields.length - 1
                              ) &&
                              directorsEmailAppend({
                                email: "",
                                first_name: "",
                                last_name: "",
                              })
                            }
                            className="addMoreFeature"
                          >
                            <img src="assets/img/plusBtn.svg" alt="" />
                            Add director
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 fieldPadding">
                      <p className="input-text heading">Promoter</p>

                      {promotersEmailFields &&
                        promotersEmailFields.map((field, index) => (
                          <div className="row" key={field.id}>
                            <div className="col-lg-4 fieldPadding">
                              <p className="input-text heading">
                                Promoter email {index + 1}
                              </p>
                              <input
                                autoComplete="off"
                                type="text"
                                className="form-control"
                                placeholder={`Enter promoter ${
                                  index + 1
                                } email`}
                                {...register(
                                  `defaultPromoters.${index}.email`,
                                  {
                                    required: {
                                      value: false,
                                      message: "Email is required",
                                    },
                                    pattern: {
                                      value:
                                        /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                      message:
                                        "Email address must be a valid address",
                                    },
                                    validate: {
                                      isUnique: (value) => {
                                        // Check if the email is unique
                                        const emails =
                                          getValues(`defaultPromoters`);
                                        return (
                                          emails?.findIndex(
                                            (item) => item.email === value
                                          ) === index ||
                                          "Email already exists, please use another email"
                                        );
                                      },
                                    },
                                  }
                                )}
                              />
                              {errors &&
                                errors.defaultPromoters?.[index]?.email && (
                                  <span className="errorMessage">
                                    {
                                      errors?.defaultPromoters?.[index]?.email
                                        ?.message
                                    }
                                  </span>
                                )}
                            </div>

                            <div className="col-lg-4 fieldPadding">
                              <p className="input-text heading">
                                Promoter first name {index + 1}
                              </p>
                              <input
                                autoComplete="off"
                                type="text"
                                className="form-control"
                                placeholder={`Enter promoter ${
                                  index + 1
                                } first name`}
                                {...register(
                                  `defaultPromoters.${index}.first_name`,
                                  {
                                    required: {
                                      value: false,
                                      message: "First name is required",
                                    },
                                  }
                                )}
                              />

                              {errors &&
                                errors.defaultPromoters?.[index]
                                  ?.first_name && (
                                  <span className="errorMessage">
                                    {
                                      errors?.defaultPromoters?.[index]
                                        ?.first_name?.message
                                    }
                                  </span>
                                )}
                            </div>

                            <div className="col-lg-4 fieldPadding">
                              <p className="input-text heading">
                                Promoter Last Name {index + 1}
                              </p>
                              {promotersEmailFields.length > 1 && (
                                <div
                                  onClick={() => promotersEmailRemove(index)}
                                  className="removeBtn"
                                >
                                  <i
                                    className="fa fa-times-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              )}
                              <input
                                autoComplete="off"
                                type="text"
                                className="form-control"
                                placeholder={`Enter promoter ${
                                  index + 1
                                } last name`}
                                {...register(
                                  `defaultPromoters.${index}.last_name`,
                                  {
                                    required: {
                                      value: false,
                                      message: "Last name is required",
                                    },
                                  }
                                )}
                              />

                              {errors &&
                                errors.defaultPromoters?.[index]?.last_name && (
                                  <span className="errorMessage">
                                    {
                                      errors?.defaultPromoters?.[index]
                                        ?.last_name?.message
                                    }
                                  </span>
                                )}
                            </div>
                          </div>
                        ))}

                      <div className="row">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                          <div
                            onClick={() =>
                              !errors?.defaultPromoters &&
                              isPromoterFieldEmpty(
                                promotersEmailFields.length - 1
                              ) &&
                              promotersEmailAppend({
                                email: "",
                                first_name: "",
                                last_name: "",
                              })
                            }
                            className="addMoreFeature"
                          >
                            <img src="assets/img/plusBtn.svg" alt="" />
                            Add promoter
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 fieldPadding">
                      <p className="input-text heading">
                        Objective description
                      </p>
                      <Controller
                        name="description"
                        control={control}
                        render={({ field }) => (
                          <ReactQuill
                            className="editor"
                            theme="snow"
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="buttonSection">
                <button
                  onClick={onCancel}
                  type="button"
                  className="btn save-btn cancel"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  className="btn agree-button save-btn"
                >
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
