import { GET_SETTINGS } from "../actions/types";
const initialState = {
  goldBidMapping: {},
  appVersion: null
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_SETTINGS:
      return {
        ...state,
        goldBidMapping: payload?.goldBidMapping || {},
        appVersion: payload?.appVersion || null
      };

    default:
      return state;
  }
}
