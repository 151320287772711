import toast from "react-hot-toast";
import { CLEAR_MESSAGE, SET_FAILED_MESSAGE, SET_SUCCESS_MESSAGE } from "../actions/types";
const initialState = {};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_SUCCESS_MESSAGE:
      toast.success(payload);
      return { message: payload };
    case SET_FAILED_MESSAGE:
      toast.error(payload);
      return { message: payload };
    case CLEAR_MESSAGE:
      return { message: "" };
    default:
      return state;
  }
}
