import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/header/header";
import { RoutesPaths } from "../../Router/constant";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { getObjectiveAction } from "../../actions/objective";
import TimeFormat from "../../components/timeFormat/timeFormat";

export default function ObjectiveManager() {
  const [page, setPage] = useState(1);
  const [activePage, setActivePage] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { objectives, count } = useSelector((state) => state.objective);

  useEffect(() => {
    dispatch(getObjectiveAction(page, 20));
  }, [page, dispatch]);

  const onEdit = (value) => {
    navigate(RoutesPaths.ADDOBJECTIVE, {
      state: {
        id: value.id,
      },
    });
  };
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setActivePage(selectedPage);
    setPage(selectedPage + 1);
  };

  return (
    <>
      <Header title="Notification Manager" />
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 mb-2">
            <div className="card planList notification h-100">
              <div className="card-header">
                <div className="searchbar">
                  <h6 className="font-weight-bolder payment-text mb-0">Objectives</h6>
                </div>
                <div className="filter">
                  <div className="addButton">
                    <img src="assets/img/addIcon.svg" alt="" />
                    <Link
                      to={RoutesPaths.ADDOBJECTIVE}
                      className="customAddButton"
                    >
                      Add new objective
                    </Link>
                  </div>
                </div>
              </div>
              <div className="card-body detail">
                <table className="businessTable">
                  <thead>
                    <tr>
                      <td>Short name</td>
                      <td>Title</td>
                      <td>Date added</td>
                      <td>Status</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {objectives.length > 0 ? (
                      objectives?.map((ele, eleIndex) => (
                        <tr key={eleIndex}>
                          <td>
                            <h5>{ele.shortName || "-"}</h5>
                          </td>
                          <td className="pointer">
                            <h5>{ele.title || "-"}</h5>
                          </td>
                          <td><TimeFormat time={ele.createdAt} /></td>
                          <td>
                            {ele.isActive ? "Active" : "Non-Active"}
                          </td>
                          <td>
                            <div className="">
                              <button
                                onClick={() => onEdit(ele)}
                                className="btn save-btn editButton"
                              >
                                Edit objective
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="d-flex pt-3">
                        <td className="d-flex flex-column justify-content-center">
                          <h6 className="mb-0 text-sm table-heading text-center">
                            No objectives found
                          </h6>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {count > 1 && (
                <div className="pagination-section">
                  <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={count}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    forcePage={activePage}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
