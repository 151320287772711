import React, { useEffect, useState } from "react";
import { getChatUserImage, getChatUserName } from "./userList";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { backendUrl } from "../../constants";
import { Accordion } from "react-bootstrap";
import { downloadMedia, getName } from "./userMessages";
import ManagePeople from "../../components/message/ManagePeople";
import toast from "react-hot-toast";
import { changeChannelNameAction, chatMediaGetAction } from "../../actions/chat";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ChatImagePreviewer from "../../components/ChatImagePreviewer/ChatImagePreviewer";


export const noData = (data) => {
  return (
    <div className="commingSoon">
      <div>
        <img src="assets/img/commingSoon.svg" alt="" />
        <p>{data}</p>
      </div>
    </div>
  )
}

export default function UserProfile({ setShowProfile, selectedRoomId, conversation, client }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  // const { conversationMedia } = useSelector((state) => state.chat);
  const { conversations, chatMedia, chatMediaDocs, loadingChatMediaFile } = useSelector((state) => state.chat);


  const sid = useSelector((state) => state.sid);
  const loadingStatus = useSelector((state) => state.loadingStatus);
  const participants =
    useSelector((state) => state.participants)[sid] ?? [];
  // const messages = useSelector((state) => state.messages[sid] ?? []);
  // const conversationMedia = messages.filter(x => x.attachedMedia.length > 0)


  const typingData =
    useSelector((state) => state.typingData)[sid] ?? [];
  const lastReadIndex = useSelector((state) => state.lastReadIndex);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [messageModal, setMessageModal] = useState(false);
  const [setNewChat, setNewChatTrigger] = useState(false);
  const [editConvoTrigger, setEditConvoTrigger] = useState(false);
  const [editConvo, setEditConvo] = useState("");
  const [previewFileTabChange, setPreviewFileTabChange] = useState(null);


  useEffect(() => {
    const changedChannelTrigger = async () => {
      if (sid) {
        dispatch(chatMediaGetAction(sid))
      }
    }
    changedChannelTrigger()
  }, [sid, dispatch])

  const sidebarList = [
    // {
    //   id: 1,
    //   img: "assets/img/paymentIcon.svg",
    //   name: "Signature",
    // },
    {
      id: 2,
      img: "assets/img/addUser.svg",
      name: "Chat Members",
    },
    // {
    //   id: 3,
    //   img: "assets/img/addUser.svg",
    //   name: "Payments",
    // },
    {
      id: 4,
      img: "assets/img/viewFile.svg",
      name: "View Files",
    },
    // {
    //   id: 5,
    //   img: "assets/img/addNewTask.svg",
    //   name: "Add New Task",
    // },
  ];


  const showFilteredAddPeople = (item, index) => {
    const filteredData = selectedRoomId && selectedRoomId?.member.length && selectedRoomId?.member.filter(x => x.user.email === item.email);
    if (filteredData?.length > 0) return;
    return (
      <div
        key={index}
        className="col-lg-12 inner-section"
      >
        <div
          className="profile-img"
          style={{
            backgroundImage: `url(${item &&
              item?.attributes?.image
              ? backendUrl + item?.attributes?.image
              : "assets/img/Frame1.svg"
              })`,
          }}
        ></div>
        <div className="profile-name">
          {item?.attributes?.name}
        </div>
      </div>
    )
  }

  const handleUpdateConvoName = async () => {
    if (editConvo.length > 3) {
      await dispatch(changeChannelNameAction({ channelSid: sid, friendlyName: editConvo }))
      setEditConvo("")
      setEditConvoTrigger(false)
    } else {
      toast.error("Group name should be more than 3 characters")
    }
  }

  console.log("conversation", conversation)

  return (
    <>
      {sid && conversation && client && (
        <>
          <div className="profile-header">
            <button
              type="button"
              className="btn-close text-dark close-button"
              onClick={() => setShowProfile(false)}
            ></button>
            <div
              style={{
                backgroundImage: `url(${getChatUserImage(
                  participants,
                  user
                )})`,
              }}
              className="profile_photo"
            ></div>
            <div className="userInfo">
              {participants.length > 1 && (editConvoTrigger ? (
                <h5>
                  <input className="editConvoInput" type="text" name="" onChange={(e) => setEditConvo(e.target.value)} value={editConvo} /> &nbsp;
                  <span onClick={handleUpdateConvoName} className="editConvo"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;&nbsp;
                  <span onClick={() => setEditConvoTrigger(false)} className="editConvo"><i className="fa fa-close" aria-hidden="true"></i></span>
                </h5>
              ) : (
                <>
                  <h5><div dangerouslySetInnerHTML={{ __html: conversation?.friendlyName || getChatUserName(participants, user) }}></div><span onClick={() => { setEditConvoTrigger(true); setEditConvo(conversation?.friendlyName || getChatUserName(participants, user)) }} className="editConvo"><i className="fa fa-pencil-square" aria-hidden="true"></i></span></h5>
                </>
              ))}
              {/* <h5> {getChatUserName(participants, user)}</h5> */}
              <p className="time">
                {moment().format("h:mm") + " GMT" + moment().format("Z")}&nbsp;
              </p>
            </div>
          </div>

          <div className="profile-body">
            <ul>
              <>
                <Accordion>
                  {sidebarList.map((item, index) => (
                    <Accordion.Item
                      className={activeAccordion === index ? 'listHolder active' : 'listHolder'}
                      key={index}
                      eventKey={index}
                    >
                      <Accordion.Header onClick={() => setActiveAccordion(activeAccordion === index ? null : index)}>
                        <li>
                          <img src={item.img} className="icon" alt="" />
                          <p>{item.name}</p>
                          <div className="arrowIcon rotateArrow">
                            <img src="assets/img/profileArrow.svg" alt="" />
                          </div>
                        </li>
                      </Accordion.Header>
                      <Accordion.Body>
                        {(item.id === 2) ? (
                          <>
                            <h5 className="partitipantMemberCountHead">Chat Members ({participants.length})</h5>
                            <div className={`row ${participants && participants.length > 0 ? 'conversation-section customScroll' : ''}`}>
                              {participants &&
                                participants.length > 0 ? (
                                participants.map((item, index) => (
                                  showFilteredAddPeople(item, index)
                                ))
                              ) : (
                                noData("No people to add...")
                              )}
                            </div>
                            {!conversation?.attributes?.isMasterAdmin && (
                              <button onClick={() => setMessageModal(true)} type="button" className="btn card-btn add-morepeople-bottom-btn mb-0">Manage people</button>
                            )}
                          </>
                        ) : (item.id === 4) ? (
                          loadingChatMediaFile ? noData("Loading please wait ...") : (
                            <Tabs onSelect={(index) => setPreviewFileTabChange(index)}>
                              <TabList>
                                <Tab>Images</Tab>
                                <Tab>Documents</Tab>
                              </TabList>
                              <TabPanel>
                                <ChatImagePreviewer type="image" chatMedia={chatMedia} dispatch={dispatch} previewFileTabChange={previewFileTabChange} />
                              </TabPanel>
                              <TabPanel>
                                <ChatImagePreviewer type="other" chatMedia={chatMediaDocs} dispatch={dispatch} previewFileTabChange={previewFileTabChange} />
                              </TabPanel>
                            </Tabs>

                          )
                        ) : (
                          <div className="commingSoon">
                            <div>
                              <img src="assets/img/commingSoon.svg" alt="" />
                              <p>COMING SOON...</p>
                            </div>
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </>
              <li className="flex-li">
                <div className="row">
                  <div className="col-12">
                    <button onClick={() => [setMessageModal(true), setNewChatTrigger(true)]} type="button" className="btn card-btn add-morepeople-bottom-btn mb-0">Start new chat</button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <ManagePeople
            currentConvoSid={setNewChat ? null : sid}
            setNewChat={setNewChat}
            participants={setNewChat ? [] : participants}
            messageModal={messageModal}
            addModalClose={() => [setMessageModal(false), setNewChatTrigger(false)]}
          />
        </>
      )}

    </>
  );
}
