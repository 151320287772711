import { GET_OBJECTIVE, GET_OBJECTIVE_BY_ID } from "../actions/types";
const initialState = {
  objectives: [],
  count: 0,
  objective: {}
};
export default function (state = initialState, action) {
  const { type, payload, count } = action;
  switch (type) {
    case GET_OBJECTIVE:
      return {
        ...state,
        objectives: payload,
        count: count,
      };
    case GET_OBJECTIVE_BY_ID:
      return {
        ...state,
        objective: payload,
      };
    default:
      return state;
  }
}
