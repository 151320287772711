import bannerService from "../services/banner.service";
import {
  GET_BANNER_BY_ID,
  GET_BANNERS,
  SET_FAILED_MESSAGE,
  SET_SUCCESS_MESSAGE,
  START_LOADER,
  STOP_LOADER,
} from "./types";

export const getBannerAction = (page, perPage, isActive) => (dispatch) => {
  dispatch({ type: START_LOADER });
  return bannerService.getBanner(page, perPage, isActive).then((resp) => {
    if (resp.status) {
      dispatch({
        type: GET_BANNERS,
        payload: resp.data,
        count: resp.count,
      });
    } else {
      dispatch({ type: GET_BANNERS, payload: [] });
    }
    dispatch({ type: STOP_LOADER });
  });
};

export const getBannerActionById = (bannerId) => (dispatch) => {
  dispatch({ type: START_LOADER });
  return bannerService.getBannerById(bannerId).then((resp) => {
    if (resp.status) {
      dispatch({
        type: GET_BANNER_BY_ID,
        payload: resp.data,
      });
    } else {
      dispatch({ type: GET_BANNER_BY_ID, payload: {} });
    }
    dispatch({ type: STOP_LOADER });
  });
};

export const addBannerAction = (payload) => (dispatch) => {
  dispatch({ type: START_LOADER });
  return bannerService.addBanner(payload).then((resp) => {
    if (resp.status) {
      dispatch({
        type: SET_SUCCESS_MESSAGE,
        payload: resp.data,
      });
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
    dispatch({ type: STOP_LOADER });
  });
};

export const editBannerAction = (id, payload) => (dispatch) => {
  dispatch({ type: START_LOADER });
  return bannerService.editBanner(id, payload).then((resp) => {
    if (resp.status) {
      dispatch({
        type: SET_SUCCESS_MESSAGE,
        payload: resp.data,
      });
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
    dispatch({ type: STOP_LOADER });
  });
};

export const deleteBannerAction = (id) => (dispatch) => {
  dispatch({ type: START_LOADER });
  return bannerService.deleteBanner(id).then((resp) => {
    dispatch(getBannerAction());
    dispatch({ type: STOP_LOADER });
  });
};
