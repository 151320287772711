import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { ADMIN_USER, backendUrl, CUSTOM_SELECT_STYLES, HOLDERS, SELECT_BOX_THEME } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  startLoaderAction,
  stopLoaderAction,
} from "../../actions/updateReducerValue";
import { addNewChannelAction, addParticipantAction, removeParticipantAction } from "../../actions/chat";
import { getAllUserListAction } from "../../actions/user";
import toast from "react-hot-toast";
import { RoutesPaths } from "../../Router/constant";
import { useNavigate } from "react-router";

export default function ManagePeople({
  messageModal,
  addModalClose,
  participants,
  currentConvoSid,
  setNewChat
}) {
  const {
    register,
    control,
    getValues,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allUserList } = useSelector((state) => state.user);
  const { channelList } = useSelector((state) => state.chat);
  const [chatShowUserList, SetChatShowUserList] = useState([])
  const [chatUserList, setChatUserList] = useState([])
  const [removedUser, setRemovedUser] = useState([]);
  const [isShowChatBtn, setisShowChatBtn] = useState(false);
  const groupNameWatch = watch('channel_name')

  useEffect(() => {
    const setHoldersValue = () => {
      if (allUserList.length > 0) {
        SetChatShowUserList(allUserList)
      }
    }
    setHoldersValue()
  }, [allUserList])

  const onSubmit = async (data) => {
    try {
      let members = [];
      chatUserList.forEach((element) => {
        var email = element.email
        if (element.identity) {
          email = element.identity
        }
        if (!members.includes(email)) {
          members.push(email);
        }
      });
      if (currentConvoSid) {
        dispatch(startLoaderAction())

        if (removedUser.length > 0) {
          await removeParticipantAction({ "channel": currentConvoSid, "member": removedUser })
        }

        if (members.length > 0) {
          await addParticipantAction({ "channel": currentConvoSid, "member": members })
        }
        dispatch(stopLoaderAction())
        addModalCloseFunc()
      } else {
        if (members.length > 0 && data?.channel_name) {
          dispatch(startLoaderAction())
          const payloadMemberList = allUserList.filter(item => members.includes(item.email));
          await dispatch(addNewChannelAction({ friendlyName: data?.channel_name, member: payloadMemberList }))
          reset();
          addModalCloseFunc()
          dispatch(stopLoaderAction())
        }else{
          toast.error("Something went wrong, please try again later.")
        }
      }
    } catch (e) {
      dispatch(stopLoaderAction());
    }
  };

  const removeChatUser = (index, removedUserEmail) => {
    const newList = [...chatUserList];
    newList.splice(index, 1);
    setChatUserList(newList);
    if (removedUserEmail) {
      const prepareRemovedUser = removedUser;
      if (!prepareRemovedUser.includes(removedUserEmail)) {
        setRemovedUser([...removedUser, removedUserEmail])
      }
    }
  };

  useEffect(() => {
    const innserFunction = () => {
      dispatch(getAllUserListAction())
      const condition = setNewChat ? 0 : 1
      const verifyCondition = setNewChat  ? groupNameWatch?.length > 0 ? true : false : true
      setisShowChatBtn(chatUserList.length > condition && verifyCondition);
    }
    innserFunction()
  }, [setNewChat, groupNameWatch, chatUserList, dispatch]);

  useEffect(() => {
    if (messageModal) {
      const effectUpdate = () => {
        if (!setNewChat) {
          setChatUserList([...chatUserList, ...participants]);
        }
      }
      effectUpdate()
    }
  }, [messageModal]);


  const onChange = (obj) => {
    const exists = chatUserList.find((user) => (user.email === obj.email) || (user.identity === obj.email));
    if (exists) return;
    setChatUserList([...chatUserList, obj]);
  };


  const addModalCloseFunc = () => {
    SetChatShowUserList([])
    setChatUserList([])
    setRemovedUser([])
    addModalClose();
  }

  const showRemove = (identity) => {
    let returnVal = true;
    if (ADMIN_USER.includes(identity)) {
      returnVal = false
    }
    return returnVal
  }

  return (
    <>
      <Modal
        show={messageModal}
        onHide={addModalCloseFunc}
        className="custom-modal add-new-member"
        id="addModalMessage"
      >
        <div className="modal-header p-0">
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={addModalCloseFunc}
          ></button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xl-12">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-12">
                    <h5 className="edit-title">
                      Add/Remove chat members
                    </h5>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group message-section">
                      <Controller
                        render={() => (
                          <Select
                            theme={SELECT_BOX_THEME}
                            styles={CUSTOM_SELECT_STYLES}
                            options={chatShowUserList}
                            getOptionLabel={(option) => `${option.first_name} ${option.last_name} (${option.email})`}
                            getOptionValue={(option) => option.email}
                            value=""
                            onChange={onChange}
                            placeholder="Invite people to join the conversation..."
                          />
                        )}
                        name="select"
                        control={control}
                      />
                    </div>
                  </div>

                  {setNewChat && (
                    <div className="col-lg-12 mt-3">
                      <p className="input-text heading">Group name</p>
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="Enter group name"
                        {...register("channel_name", {
                          required: true,
                        })}
                      />
                      {errors.channel_name?.type === "required" && (
                        <span className="errorMessage">
                          Channel name is required
                        </span>
                      )}
                    </div>
                  )}

                  <div className="col-lg-12 mt-4 conversation-text-margin">
                    <p className="conversation-text mb-3">
                      Have a conversation with:
                    </p>
                    <div className="row conversation-section" id="style-1 customScroll">
                      {chatUserList &&
                        chatUserList.length > 0 &&
                        chatUserList.map((item, index) => (
                          <div
                            key={index}
                            className="col-lg-12 inner-section"
                          >
                            {item.identity ? (
                              <div
                                className="profile-img"
                                style={{
                                  backgroundImage: `url(${item &&
                                    item.attributes &&
                                    item.attributes.image
                                    ? backendUrl + item.attributes.image
                                    : "assets/img/Frame1.svg"
                                    })`,
                                }}
                              ></div>
                            ) : (
                              <div
                                className="profile-img"
                                style={{
                                  backgroundImage: `url(${item &&
                                    item.profilePic &&
                                    item.profilePic.filename
                                    ? backendUrl + item.profilePic.filename
                                    : "assets/img/Frame1.svg"
                                    })`,
                                }}
                              ></div>
                            )}
                            <div className="profile-name">
                              {item.identity ? (
                                item.attributes.name || item.identity
                              ) : (
                                ('position' in item) ? item.name : item.first_name + " " + item.last_name
                              )}
                              {showRemove(item.identity) && (
                                <img
                                  src="assets/img/Close-circle.svg"
                                  alt=""
                                  className="cursor-pointer"
                                  onClick={() => removeChatUser(index, item.identity ? item.identity : null)}
                                />
                              )}
                            </div>
                            {/* {!item.identity && (
                              <>
                                {!item.isEditApproved && !item.isAppoint && (
                                  <span>Amendement in progress</span>
                                )}
                                {!item.isDeleteApproved && (
                                  <span>Removal in progress</span>
                                )}
                                {!item.isApproved ||
                                  (item.isAppoint && item.isDeleteApproved && (
                                    <span>Acceptance in progress</span>
                                  ))}
                              </>
                            )} */}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <p className="import-note">Important Note: <span className="will-need-text">Please be aware that newly added members can still access previous chat messages. </span></p>
                <div className="modal-footer modal-bottom-action justify-content-center">
                  {isShowChatBtn && (
                    <button type="submit" className="btn agree-button">
                      Save changes
                    </button>
                  )}
                  {!isShowChatBtn && (
                    <button
                      type="button"
                      disabled
                      className="btn disable-agree-button"
                    >
                      Save changes
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
