import { USER_PROFILE, SET_USER_PROFILE, GET_USER_ACTIVITY, SET_ADMIN_AS_USER, SET_ALL_USER_LIST, SET_USER_LISTING } from "../actions/types";
import { SCORE_LIST } from "../constants";
const initialState = {
  user: {},
  activity: [],
  adminAsuser: null,
  allUserList: [],
  userListLink: null,
  userListCount: null,
  userList: [],
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_PROFILE:
      let score = 0;
      if(payload && payload._id){
        for (const [key, value] of Object.entries(SCORE_LIST)) {
          if(key in payload && payload[key]){
            score = score+value
          }
        }
      }
      return {
        ...state,
        user: {...payload, currentScore: score},
      };
      
    case SET_USER_PROFILE:
      const newArray = {...state.user}; //making a new array
      newArray[payload.fieldName] = payload.value
      return {
        ...state,
        user: newArray,
      };

    case GET_USER_ACTIVITY:
      return {
        ...state,
        activity: payload,
      };

    case SET_ADMIN_AS_USER:
      return {
        ...state,
        adminAsuser: payload,
      };
      
    case SET_ALL_USER_LIST:
      return {
        ...state,
        allUserList: payload,
      };

    case SET_USER_LISTING:
      return {
        ...state,
        userListLink: payload?.status ? payload?.linkUrl : null,
        userListCount: payload?.status ? payload?.count : 0,
        userList: payload?.status ? payload?.data : [],
      };

    default:
      return state;
  }
}
