import { GET_REFERRAL_LIST, LOAD_REFERRAL_SKELETON } from "../actions/types";
const initialState = {
  referralInvites: [],
  loadReferral: false,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_REFERRAL_LIST:
      return {
        ...state,
        referralInvites: payload,
        loadReferral: false
      };
    case LOAD_REFERRAL_SKELETON:
      return {
        ...state,
        loadReferral: payload,
      };
    default:
      return state;
  }
}
