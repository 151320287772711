import { CURRENCY } from "../../constants";

export default function Currency({ currency }) {
  return (
    <>
      {currency === CURRENCY.INR ? (
        "₹ "
      ) : currency === CURRENCY.SGD ? (
        "S$ "
      ) : currency === CURRENCY.PKR ? (
        "Rp "
      ) : currency === CURRENCY.LKR ? (
        "Rs "
      ) : currency === CURRENCY.BDT ? (
        "৳ "
      ) : currency === CURRENCY.USD ? (
        "US$ "
      ) : currency === CURRENCY.CNY ? (
        "CN¥ "
      ) : (
        "- "
      )}
      {/* &nbsp; */}
    </>
  );
}
