import { ACTIVITY_FETCH_RESET, GET_ACTIVITY_LOGS } from "../actions/types";
const initialState = {
  activityLogs: [],
  count: 0,
  resetLogs: false,
};
export default function (state = initialState, action) {
  const { type, payload, count } = action;
  switch (type) {
    case GET_ACTIVITY_LOGS:
      return {
        ...state,
        activityLogs: payload,
        resetLogs: false,
        count: count
      };
    case ACTIVITY_FETCH_RESET:
      return {
        ...state,
        resetLogs: payload,
      };
    default:
      return state;
  }
}
