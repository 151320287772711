import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getDocumentAction } from "../../actions/documents";
import AddFile from "./AddFile";
import { getBusinessAction } from "../../actions/businessInfo";
import { Dropdown, DropdownButton } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import TimeFormat from "../../components/timeFormat"

export default function UploadFile() {
  const dispatch = useDispatch();
  const { count, documents } = useSelector((state) => state.files);
  const { businessInfo } = useSelector((state) => state.businessInfo);

  const [page, setPage] = useState(1);
  const [openFile, setOpenFile] = useState(false);
  const [companyDetail, setCompanyDetail] = useState("");
  const [activePage, setActivePage] = useState(0);
  useEffect(() => {
    dispatch(getDocumentAction(page, 20, companyDetail?._id));
  }, [dispatch, companyDetail, page]);

  // const setPaginate = () => {
  //   setPage(page + 1);
  //   dispatch(getDocumentAction(page, perPage));
  // };

  const getFileName = (fileName) => {
    var splitText = fileName.split("/");
    return splitText[splitText.length - 1];
  };

  const getFileExt = (fileName) => {
    var splitText = fileName.split(".");
    return splitText[splitText.length - 1];
  };

  useEffect(() => {
    dispatch(getBusinessAction(1, 150, "", "", "", "", "", "", "", "", ""));
  }, [dispatch]);

  const sortByCompany = (data) => {
    setCompanyDetail(data);
    setPage(1);
    setActivePage(0);
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setActivePage(selectedPage);
    setPage(selectedPage + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div className="card-header pb-0">
        <div className="row">
          <div className="col-lg-12 col-12 AddFileSection">
            <h5 className="font-weight-bolder payment-text">Documents</h5>
            <div className="filter">
              <div className="dropdownButton">
                <img src="assets/img/downArrow.svg" alt="" />
                <DropdownButton
                  id="dropdown-basic-button"
                  title={
                    companyDetail === ""
                      ? "Search by company name"
                      : companyDetail.companyName
                  }
                >
                  {businessInfo.map((item, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => sortByCompany(item)}
                    >
                      {item.companyName}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>

              <button
                className="customAddButton removePadding"
                onClick={() => setOpenFile(true)}
              >
                Add files
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body px-0 pb-2 pt-2">
        <div className="table-responsive">
          <table className="table align-items-center mb-0 table-sharholder">
            <thead>
              <tr>
                <th className="text-sm table-heading">
                  File name and view link
                </th>
                <th className="text-sm table-heading">Company name</th>
                <th className="text-sm table-heading">File type</th>
                <th className="text-sm table-heading">Uploaded by</th>
                <th className="text-sm table-heading">Date uploaded</th>
                <th className="text-sm table-heading">Download</th>
              </tr>
            </thead>
            <tbody className="text-row-color">
              {documents &&
                documents.map((list, index) => (
                  <tr key={index}>
                    <td className="file-name-row">
                      <div className="d-flex px-2 py-1">
                        <div className="d-flex flex-column justify-content-center">
                          <a href={list.name} target="_blank" rel="noreferrer">
                            <h6 className="mb-0 text-sm file-text-table">
                              {list.originalName
                                ? list.originalName
                                : list.name}
                            </h6>
                          </a>
                        </div>
                      </div>
                    </td>
                    <td className="text-sm table-heading date-padding">
                      {list.company?.companyName}
                    </td>
                    <td className="text-sm table-heading date-padding">
                      {list.name && getFileExt(list.name)}
                    </td>
                    <td className="text-sm table-heading date-padding">
                      {list.user?.first_name +" " + list.user?.last_name}
                    </td>
                    <td className="text-sm table-heading date-padding">
                      {list.createdAt && (
                        <TimeFormat time={list.createdAt}/>
                        // moment(list.createdAt).format("DD/MM/yyyy")
                      )}
                    </td>
                    <td className="align-middle text-center text-sm linkText">
                      <a href={list.name} target="_blank" rel="noreferrer">
                        {/* <button
                      className="btn btn-link payment-text text-sm mb-0 px-0
                        ms-4"
                    > */}
                        <p>
                          <i
                            className="fas fa-file text-lg me-1"
                            aria-hidden="true"
                          ></i>
                          {list.name && getFileExt(list.name)}
                        </p>
                      </a>
                    </td>
                  </tr>
                ))}
              {documents && documents.length === 0 && (
                <tr colSpan="3">
                  <td className="file-name-row">
                    <div className="d-flex px-2 py-1">
                      <div className="d-flex flex-column justify-content-center">
                        <h6 className="mb-0 text-sm file-text-table">
                          No documents
                        </h6>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {count > 1 && (
        <div className="pagination-section">
          <ReactPaginate
            previousLabel={"Prev"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={count}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={activePage}
          />
        </div>
      )}
      <AddFile openFile={openFile} closeFile={() => setOpenFile(false)} />
    </>
  );
}
