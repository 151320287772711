import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import "./App.css";
import Sidebar from "./components/sidebar/sidebar";
import RouteComponent from "./Router/router";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getProfileAction } from "./actions/user";
import { useEffect } from "react";
import Loader from "./components/loader";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { RoutesPaths } from "./Router/constant";
import { IS_LIVE } from "./constants";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { isLoggedIn, loader } = useSelector((state) => state.auth);
  const conversations = useSelector((state) => state.convos);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getProfileAction());
    }
  }, [isLoggedIn]);

  return (
    <>
      {/* {!IS_LIVE && (
        <div className="envonmentIdentify">
          <marquee>
            This is a staging environment.
          </marquee>
        </div>
      )} */}
      {isLoggedIn ? <Sidebar /> : ""}
      {(loader ||
        (location.pathname === RoutesPaths.MESSAGE_DETAIL &&
          conversations.length === 0)) && <Loader />}
      <Toaster
        position="top-right"
        containerStyle={{ zIndex: 123465789 }}
        toastOptions={{
          className: "",
          duration: 5000,
          success: {
            iconTheme: {
              primary: "var(--primary-color)",
            },
          },
        }}
      />
      <ScrollToTop />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <RouteComponent></RouteComponent>
      </main>
    </>
  );
}

export default App;
