import React from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import ImportFile from "./ImportFile";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessAction, resetBusinessList } from "../../actions/businessInfo";
import { useEffect } from "react";
import {
  BOB_SERVICES,
  PROFILE_ACTION,
  PROFILE_STATUS,
  SELECT_BOX_THEME,
  styleSelect,
} from "../../constants";
import { Controller } from "react-hook-form";
import { toast } from "react-hot-toast";
import ProfileForm, { getPotentialPurpose } from "./ProfileForm";
import ContactInfo from "./ContactInfo";
import { updateBusinessProfileAction } from "../../actions/bookOfBusiness";
import Select from "react-select";
import TimeFormat from "../../components/timeFormat/timeFormat";
import ConfirmMeeting from "./ConfirmMeeting";
import debounce from 'lodash/debounce';

export default function ProfileDetail({
  activePage,
  count,
  handlePageClick,
  businessProfiles,
  getValues,
  control,
  limit,
  setStatus,
  profileTab,
  setPage,
  setActivePage,
  bookOfBusiness,
  bookOfBusinessResponseService
}) {
  const dispatch = useDispatch();
  const { businessInfo } = useSelector((state) => state.businessInfo);
  const [isEdit, setIsEdit] = useState(false);
  const [importOpen, setImportOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);
  const [bobSelected, setBobSelected] = useState(null);
  const [meetingOpen, setMeetingOpen] = useState(false);
  const [meetingDetailItem, setMeetingDetailsItem] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [customCompanyDetails, setCustomCompanyDetails] = useState({
    bookOfBusinessGroup: [],
    user: []
  });


  // useEffect(() => {
  //   // dispatch(getBusinessAction(1, 100));
  // }, [dispatch]);

  const handleImport = (value) => {
    if (getValues("companyName")) {
      if (value === "import") {
        setImportOpen(true);
      } else {
        setFormOpen(true);
      }
    } else {
      toast.error("Select company name");
    }
  };

  const handleForm = async (value, detail) => {
    if (value === "edit") {
      setBobSelected(detail);
      const customCompanyDetails = {
        bookOfBusinessGroup: [detail?.bookOfBusinessGroupId],
        ...detail?.company,
        user: [detail?.user]
      }
      setCustomCompanyDetails(customCompanyDetails)
      setIsEdit(true);
      setFormOpen(true);
    } else {
      handleImport("add");
      setIsEdit(false);
    }
  };

  const handleClone = async (item) => {
    const { status, action } = item;
    if (
      status === PROFILE_STATUS.SUGGESTED_PROFILES ||
      status === PROFILE_STATUS.WARM_PROFILES
    ) {
      var curerntAction = PROFILE_ACTION.ACCEPT;
      if (curerntAction && curerntAction !== action) {
        await dispatch(
          updateBusinessProfileAction(
            item.company._id,
            item.id,
            {
              action: PROFILE_ACTION.ACCEPT,
            },
          )
        );
      }
    }
  };

  const handleContact = (value) => {
    setContactOpen(value);
  };

  const handleConnect = async (item) => {
    const { action } = item;
    var curerntAction = null;

    switch (action) {
      case PROFILE_ACTION.REQUESTED:
        curerntAction = PROFILE_ACTION.REJECTED;
        break;
      case PROFILE_ACTION.SCHEDULED:
        curerntAction = PROFILE_ACTION.REJECTED;
        break;
      case PROFILE_ACTION.CONFIRM:
        curerntAction = PROFILE_ACTION.REJECTED;
        break;
      default:
        break;
    }

    if (curerntAction && curerntAction !== action) {
      await dispatch(
        updateBusinessProfileAction(
          item.company._id,
          item.id,
          {
            action: curerntAction,
          },
        )
      );
    }
  };

  const currentStatus = (item) => {
    const { status, action, eventDetails, company } = item;
    const { isDefault } = company;
    const makePublicCondition = ["Connect", "Schedule a meeting", "Confirm meeting"]
    var actionButton = null;
    switch (status) {
      case PROFILE_STATUS.SUGGESTED_PROFILES:
        actionButton =
          action === PROFILE_ACTION.CONNECT
            ? "Connect"
            : action === PROFILE_ACTION.REQUESTED
              ? "Requested"
              : action === PROFILE_ACTION.REJECTED
                ? "Rejected"
                : action === PROFILE_ACTION.ACCEPT
                  ? "Accepted"
                  : "";
        break;
      case PROFILE_STATUS.WARM_PROFILES:
        actionButton =
          action === PROFILE_ACTION.SCHEDULED
            ? "Schedule a meeting"
            : action === PROFILE_ACTION.REQUESTED
              ? "Requested"
              : action === PROFILE_ACTION.REJECTED
                ? "Rejected"
                : action === PROFILE_ACTION.ACCEPT
                  ? "Accepted"
                  : "";
        break;
      case PROFILE_STATUS.MEETING_REQUESTS:
        actionButton =
          action === PROFILE_ACTION.CONFIRM && eventDetails
            ? "Confirm meeting"
            : action === PROFILE_ACTION.CONFIRM && !eventDetails
              ? "Add meeting details"
              : action === PROFILE_ACTION.RESCHEDULED
                ? "Rescheduled"
                : action === PROFILE_ACTION.RESPOND
                  ? "Meeting confirmed"
                  : action === PROFILE_ACTION.REJECTED
                    ? "Rejected"
                    : action === PROFILE_ACTION.ACCEPT
                      ? "Accepted"
                      : "";
        break;
      default:
        break;
    }
    if (actionButton) {
      return (
        <p className="role">
          {isDefault ? (
            makePublicCondition.includes(actionButton) ? 'Make Public' : 'Public'
          ) : (
            actionButton
          )}
        </p>
      );
    } else {
      return "";
    }
  };

  const actionToBeTaken = (item) => {
    const { status, action } = item;
    var actionButton = null;
    switch (status) {
      case PROFILE_STATUS.SUGGESTED_PROFILES:
        actionButton = action === PROFILE_ACTION.REQUESTED ? "Reject" : null;
        break;
      case PROFILE_STATUS.WARM_PROFILES:
        actionButton = action === PROFILE_ACTION.REQUESTED ? "Reject" : null;
        break;
      case PROFILE_STATUS.MEETING_REQUESTS:
        actionButton = action === PROFILE_ACTION.REQUESTED ? "Reject" : null;
        break;
      default:
        break;
    }

    return (
      <>
        {showEditButton(item) && (
          <div
            className="edit"
            onClick={() => handleForm("edit", item)}
          >
            <i className="fa-solid fa-pen"></i>
          </div>
        )}
        {showCloneButton(item) && (
          <div className="edit fill-primary" onClick={() => handleClone(item)}>
            <i className="fa-solid fa-check"></i>
          </div>
        )}

        {showMeetingDetailsButton(item) && (
          <div className="edit fill-primary" onClick={() => handleSetMeetingDetails(item)}>
            <i className="fa-regular fa-handshake"></i>
          </div>
        )}

        {actionButton && (
          <div className="edit btn-c-danger" onClick={() => handleConnect(item)}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        )}
      </>
    )
  };

  const showEditButton = (item) => {
    const acccepShowEdit = [
      PROFILE_ACTION.REQUESTED,
      PROFILE_ACTION.CONNECT,
      PROFILE_ACTION.SCHEDULED,
      PROFILE_ACTION.CONFIRM,
    ];
    const acccepShowEditStatus = [
      PROFILE_STATUS.SUGGESTED_PROFILES,
      PROFILE_STATUS.WARM_PROFILES,
      PROFILE_STATUS.MEETING_REQUESTS,
    ];
    return acccepShowEditStatus.includes(item.status) &&
      acccepShowEdit.includes(item.action)
      ? true
      : false;
  };

  const showCloneButton = (item) => {
    const acccepShowClone = [PROFILE_ACTION.REQUESTED];
    const acceptShowCloneStatus = [
      PROFILE_STATUS.SUGGESTED_PROFILES,
      PROFILE_STATUS.WARM_PROFILES,
    ];
    return acceptShowCloneStatus.includes(item.status) &&
      acccepShowClone.includes(item.action)
      ? true
      : false;
  };

  const formatOptionLabel = ({ companyName, createdAt, user }) => {
    return (
      <div className="menuController">
        <p className="name">{companyName}</p>
        <p><TimeFormat time={createdAt} /></p>
        <p>{user[0]?.first_name + " " + user[0]?.last_name}</p>
      </div>
    );
  };


  const handleChange = (inputValue) => {
    setSearchValue(inputValue);
  };

  useEffect(() => {
    const debouncedSearch = debounce((value) => {
      if (value) {
        dispatch(getBusinessAction(1, 100, value));
      }
      else {
        dispatch(resetBusinessList())
      }
    }, 1000);
    debouncedSearch(searchValue);
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchValue, dispatch]);

  const showMeetingDetailsButton = (item) => {
    const acccepShowMeetingRequest = [PROFILE_ACTION.CONFIRM, PROFILE_ACTION.RESCHEDULED, PROFILE_ACTION.RESPOND];
    const acceptShowMeetingRequestStatus = [PROFILE_STATUS.MEETING_REQUESTS];
    return acceptShowMeetingRequestStatus.includes(item.status) &&
      acccepShowMeetingRequest.includes(item.action)
      ? true
      : false;
  };

  const handleSetMeetingDetails = (item) => {
    setMeetingOpen(true)
    setMeetingDetailsItem(item)
  }

  return (
    <>
      <div className="profileFilterHeader">
        <div className="searchbar">
          <Controller
            name="companyName"
            control={control}
            render={({ field }) => (
              <Select
                className="select-border"
                theme={SELECT_BOX_THEME}
                {...field}
                styles={styleSelect}
                isClearable={true}
                isSearchable={true}
                options={businessInfo}
                getOptionLabel={(option) => option?.companyName}
                formatOptionLabel={formatOptionLabel}
                onInputChange={handleChange}
                getOptionValue={(option) => option._id}
                placeholder="Search business name"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
              />
            )}
          />

          <img src="assets/img/searchIcon.svg" alt="" />
        </div>
        <div className="filterButtons">
          <button
            type="button"
            onClick={() => handleImport("import")}
            className="customImportButton"
          >
            <img src="assets/img/downloadIcon.svg" alt="" />
            Import
          </button>
          <button
            onClick={() => handleForm("add", null)}
            type="button"
            className="customAddButton removePadding"
          >
            Add profile
          </button>
        </div>
      </div>

      <div className="profileCardBody">
        <div className="tableResponsive">
          <table className="profileTable">
            {businessProfiles?.data?.length > 0 && (
              <thead>
                <tr>
                  <th>{bookOfBusinessResponseService === BOB_SERVICES.PROFILES ? "Full Name" : BOB_SERVICES.PRODUCT_AND_SERVICES === bookOfBusinessResponseService ? "Product or Service Name" : BOB_SERVICES.COMPANIES === bookOfBusinessResponseService ? "Company name and type" : ""}</th>
                  <th>Sugggested by</th>
                  {/* <th>{bookOfBusinessResponseService === BOB_SERVICES.PROFILES ? "From Company" : BOB_SERVICES.PRODUCT_AND_SERVICES === bookOfBusinessResponseService ? "From Company" : BOB_SERVICES.COMPANIES === bookOfBusinessResponseService ? "One sentence summary" : ""}</th> */}
                  <th>Potential {bookOfBusinessResponseService === BOB_SERVICES.PROFILES ? "Role" : [BOB_SERVICES.COMPANIES, BOB_SERVICES.PRODUCT_AND_SERVICES].includes(bookOfBusinessResponseService) ? "Purpose" : ""}</th>
                  <th>Industry</th>
                  <th>Contact Info.</th>
                  <th>{bookOfBusinessResponseService === BOB_SERVICES.PROFILES ? "LinkedIn Profile URL" : [BOB_SERVICES.COMPANIES, BOB_SERVICES.PRODUCT_AND_SERVICES].includes(bookOfBusinessResponseService) ? "Landing page URL" : ""}</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
            )}
            <tbody>
              {businessProfiles?.data?.length > 0 ? (
                businessProfiles?.data?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <p>
                        {BOB_SERVICES.PROFILES === bookOfBusinessResponseService ? (item?.first_name ? item?.first_name : "") + " " + (item?.last_name ? item?.last_name : "") : [BOB_SERVICES.COMPANIES, BOB_SERVICES.PRODUCT_AND_SERVICES].includes(bookOfBusinessResponseService) ? (BOB_SERVICES.COMPANIES === bookOfBusinessResponseService ? item.companyProfile : item.serviceProductName) + " " + (BOB_SERVICES.COMPANIES === bookOfBusinessResponseService ? item.companyType : "") : ""}
                      </p>
                      <p className="detail">{item.country?.name}</p>
                    </td>
                    <td>
                      <p>{item?.user?.first_name ? item?.user?.first_name : ""} {item?.user?.last_name ? item?.user?.last_name : ""}</p>
                      {/* <p>{[BOB_SERVICES.PROFILES, BOB_SERVICES.PRODUCT_AND_SERVICES].includes(bookOfBusinessResponseService) ? item.companyProfile : BOB_SERVICES.PRODUCT_AND_SERVICES === bookOfBusinessResponseService ? "From Company / Simple description" : BOB_SERVICES.COMPANIES === bookOfBusinessResponseService ? item.description : ""}</p>
                      <p className="detail">
                        {item.description}
                      </p> */}
                    </td>
                    <td>
                      <p className="role">
                        {item?.bookOfBusinessGroupId?.isDefault ? (
                          item?.customPotentialPurposeInput
                        ) : (
                          getPotentialPurpose(item)
                        )}
                      </p>
                    </td>
                    <td>
                      <p className="role">{item.industry}</p>
                    </td>
                    <td>
                      <button
                        onClick={() => handleContact(item)}
                        className="contact"
                        type="button"
                      >
                        Contact Info
                      </button>
                    </td>
                    <td>
                      <p>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={bookOfBusinessResponseService === BOB_SERVICES.PROFILES ? item.linkedInUrl : [BOB_SERVICES.COMPANIES, BOB_SERVICES.PRODUCT_AND_SERVICES].includes(bookOfBusinessResponseService) ? item.landingPageUrl : ""}
                        >
                          {bookOfBusinessResponseService === BOB_SERVICES.PROFILES ? item.linkedInUrl : [BOB_SERVICES.COMPANIES, BOB_SERVICES.PRODUCT_AND_SERVICES].includes(bookOfBusinessResponseService) ? item.landingPageUrl : ""}
                        </a>
                      </p>
                    </td>
                    <td>{currentStatus(item)}</td>
                    <td>
                      {actionToBeTaken(item)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="d-flex pt-3">
                  <td className="d-flex flex-column justify-content-center">
                    <h6 className="table-heading text-nowrap">
                      No record found
                    </h6>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {count > 0 && (
          <div className="tableBottom">
            <div className="filterButton">
              <div className="dropdownButton">
                <Controller
                  name="limit"
                  control={control}
                  render={({ field }) => (
                    <Form.Select
                      {...field}
                      className="select-border"
                      placeholder="10"
                    >
                      <option value={25}>25</option>
                      <option value={100}>100</option>
                      <option value={500}>500</option>
                      <option value={1000}>1000</option>
                    </Form.Select>
                  )}
                />
              </div>
            </div>
            <div>
              <ReactPaginate
                previousLabel={<i className="fas fa-chevron-left"></i>} // Font Awesome icon for previous
                nextLabel={<i className="fas fa-chevron-right"></i>} // Font Awesome icon for next
                pageCount={count}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination customPagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                forcePage={activePage}
              />
            </div>
          </div>
        )}
      </div>

      <ImportFile
        bookOfBusiness={bookOfBusiness}
        importOpen={importOpen}
        importClose={() => setImportOpen(false)}
        companyDetail={getValues("companyName")}
        status={profileTab}
        limit={limit}
      />

      <ProfileForm
        formOpen={formOpen}
        formClose={() => setFormOpen(false)}
        // companyDetail={getValues("companyName")}
        companyDetail={isEdit ? (getValues("companyName") && Object.keys(getValues("companyName")).length > 0 ? getValues("companyName") : customCompanyDetails) : getValues("companyName")}
        bobSelected={bobSelected}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        limit={limit}
        setStatus={setStatus}
        setPage={setPage}
        setActivePage={setActivePage}
        status={profileTab}
        bookOfBusiness={bookOfBusiness}
      />

      <ContactInfo
        contactOpen={contactOpen}
        contactClose={() => setContactOpen(false)}
        bookOfBusinessResponseService={bookOfBusinessResponseService}
      />

      <ConfirmMeeting
        meetingOpen={meetingOpen}
        meetingClose={() => setMeetingOpen(false)}
        handleConnect={handleConnect}
        item={meetingDetailItem}
      />
    </>
  );
}
