import axionIns from "../axios";

const getObjective = async (page, perPage) => {
  var url = "admin/objective?page=" + page + "&perPage=" + perPage;
  return axionIns.get(url).then((response) => {
    return response.data;
  });
};
const addObjective = async (payload) => {
  return axionIns
    .post("admin/objective", JSON.stringify(payload)).then((response) => {
      return response.data;
    });
};
const editObjective = async (payload, id) => {
  return axionIns
    .put(`admin/objective/${id}`, JSON.stringify(payload)).then((response) => {
      return response.data;
    });
};
const getObjectiveById = async (id) => {
  return axionIns.get(`admin/objective/${id}`).then((response) => {
    return response.data;
  });
};
let exportObj = {
  getObjective,
  addObjective,
  editObjective,
  getObjectiveById
};

export default exportObj;
