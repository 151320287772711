export const backendUrl = process.env.REACT_APP_BACKEND_URL
export const IS_LOCAL = (process.env.REACT_APP_IS_LOCAL === 'true');
export const IS_LIVE = (process.env.REACT_APP_IS_LIVE === 'true');
export const TZ = 'Asia/Singapore';
export const country = [
  {
    name: "Afghanistan",
    dial_code: "+93",
    code: "AF",
  },
  {
    name: "Aland Islands",
    dial_code: "+358",
    code: "AX",
  },
  {
    name: "Albania",
    dial_code: "+355",
    code: "AL",
  },
  {
    name: "Algeria",
    dial_code: "+213",
    code: "DZ",
  },
  {
    name: "AmericanSamoa",
    dial_code: "+1684",
    code: "AS",
  },
  {
    name: "Andorra",
    dial_code: "+376",
    code: "AD",
  },
  {
    name: "Angola",
    dial_code: "+244",
    code: "AO",
  },
  {
    name: "Anguilla",
    dial_code: "+1264",
    code: "AI",
  },
  {
    name: "Antarctica",
    dial_code: "+672",
    code: "AQ",
  },
  {
    name: "Antigua and Barbuda",
    dial_code: "+1268",
    code: "AG",
  },
  {
    name: "Argentina",
    dial_code: "+54",
    code: "AR",
  },
  {
    name: "Armenia",
    dial_code: "+374",
    code: "AM",
  },
  {
    name: "Aruba",
    dial_code: "+297",
    code: "AW",
  },
  {
    name: "Australia",
    dial_code: "+61",
    code: "AU",
  },
  {
    name: "Austria",
    dial_code: "+43",
    code: "AT",
  },
  {
    name: "Azerbaijan",
    dial_code: "+994",
    code: "AZ",
  },
  {
    name: "Bahamas",
    dial_code: "+1242",
    code: "BS",
  },
  {
    name: "Bahrain",
    dial_code: "+973",
    code: "BH",
  },
  {
    name: "Bangladesh",
    dial_code: "+880",
    code: "BD",
  },
  {
    name: "Barbados",
    dial_code: "+1246",
    code: "BB",
  },
  {
    name: "Belarus",
    dial_code: "+375",
    code: "BY",
  },
  {
    name: "Belgium",
    dial_code: "+32",
    code: "BE",
  },
  {
    name: "Belize",
    dial_code: "+501",
    code: "BZ",
  },
  {
    name: "Benin",
    dial_code: "+229",
    code: "BJ",
  },
  {
    name: "Bermuda",
    dial_code: "+1441",
    code: "BM",
  },
  {
    name: "Bhutan",
    dial_code: "+975",
    code: "BT",
  },
  {
    name: "Bolivia, Plurinational State of",
    dial_code: "+591",
    code: "BO",
  },
  {
    name: "Bosnia and Herzegovina",
    dial_code: "+387",
    code: "BA",
  },
  {
    name: "Botswana",
    dial_code: "+267",
    code: "BW",
  },
  {
    name: "Brazil",
    dial_code: "+55",
    code: "BR",
  },
  {
    name: "British Indian Ocean Territory",
    dial_code: "+246",
    code: "IO",
  },
  {
    name: "Brunei Darussalam",
    dial_code: "+673",
    code: "BN",
  },
  {
    name: "Bulgaria",
    dial_code: "+359",
    code: "BG",
  },
  {
    name: "Burkina Faso",
    dial_code: "+226",
    code: "BF",
  },
  {
    name: "Burundi",
    dial_code: "+257",
    code: "BI",
  },
  {
    name: "Cambodia",
    dial_code: "+855",
    code: "KH",
  },
  {
    name: "Cameroon",
    dial_code: "+237",
    code: "CM",
  },
  {
    name: "Canada",
    dial_code: "+1",
    code: "CA",
  },
  {
    name: "Cape Verde",
    dial_code: "+238",
    code: "CV",
  },
  {
    name: "Cayman Islands",
    dial_code: "+ 345",
    code: "KY",
  },
  {
    name: "Central African Republic",
    dial_code: "+236",
    code: "CF",
  },
  {
    name: "Chad",
    dial_code: "+235",
    code: "TD",
  },
  {
    name: "Chile",
    dial_code: "+56",
    code: "CL",
  },
  {
    name: "China",
    dial_code: "+86",
    code: "CN",
  },
  {
    name: "Christmas Island",
    dial_code: "+61",
    code: "CX",
  },
  {
    name: "Cocos (Keeling) Islands",
    dial_code: "+61",
    code: "CC",
  },
  {
    name: "Colombia",
    dial_code: "+57",
    code: "CO",
  },
  {
    name: "Comoros",
    dial_code: "+269",
    code: "KM",
  },
  {
    name: "Congo",
    dial_code: "+242",
    code: "CG",
  },
  {
    name: "Congo, The Democratic Republic of the Congo",
    dial_code: "+243",
    code: "CD",
  },
  {
    name: "Cook Islands",
    dial_code: "+682",
    code: "CK",
  },
  {
    name: "Costa Rica",
    dial_code: "+506",
    code: "CR",
  },
  {
    name: "Cote d'Ivoire",
    dial_code: "+225",
    code: "CI",
  },
  {
    name: "Croatia",
    dial_code: "+385",
    code: "HR",
  },
  {
    name: "Cuba",
    dial_code: "+53",
    code: "CU",
  },
  {
    name: "Cyprus",
    dial_code: "+357",
    code: "CY",
  },
  {
    name: "Czech Republic",
    dial_code: "+420",
    code: "CZ",
  },
  {
    name: "Denmark",
    dial_code: "+45",
    code: "DK",
  },
  {
    name: "Djibouti",
    dial_code: "+253",
    code: "DJ",
  },
  {
    name: "Dominica",
    dial_code: "+1767",
    code: "DM",
  },
  {
    name: "Dominican Republic",
    dial_code: "+1849",
    code: "DO",
  },
  {
    name: "Ecuador",
    dial_code: "+593",
    code: "EC",
  },
  {
    name: "Egypt",
    dial_code: "+20",
    code: "EG",
  },
  {
    name: "El Salvador",
    dial_code: "+503",
    code: "SV",
  },
  {
    name: "Equatorial Guinea",
    dial_code: "+240",
    code: "GQ",
  },
  {
    name: "Eritrea",
    dial_code: "+291",
    code: "ER",
  },
  {
    name: "Estonia",
    dial_code: "+372",
    code: "EE",
  },
  {
    name: "Ethiopia",
    dial_code: "+251",
    code: "ET",
  },
  {
    name: "Falkland Islands (Malvinas)",
    dial_code: "+500",
    code: "FK",
  },
  {
    name: "Faroe Islands",
    dial_code: "+298",
    code: "FO",
  },
  {
    name: "Fiji",
    dial_code: "+679",
    code: "FJ",
  },
  {
    name: "Finland",
    dial_code: "+358",
    code: "FI",
  },
  {
    name: "France",
    dial_code: "+33",
    code: "FR",
  },
  {
    name: "French Guiana",
    dial_code: "+594",
    code: "GF",
  },
  {
    name: "French Polynesia",
    dial_code: "+689",
    code: "PF",
  },
  {
    name: "Gabon",
    dial_code: "+241",
    code: "GA",
  },
  {
    name: "Gambia",
    dial_code: "+220",
    code: "GM",
  },
  {
    name: "Georgia",
    dial_code: "+995",
    code: "GE",
  },
  {
    name: "Germany",
    dial_code: "+49",
    code: "DE",
  },
  {
    name: "Ghana",
    dial_code: "+233",
    code: "GH",
  },
  {
    name: "Gibraltar",
    dial_code: "+350",
    code: "GI",
  },
  {
    name: "Greece",
    dial_code: "+30",
    code: "GR",
  },
  {
    name: "Greenland",
    dial_code: "+299",
    code: "GL",
  },
  {
    name: "Grenada",
    dial_code: "+1473",
    code: "GD",
  },
  {
    name: "Guadeloupe",
    dial_code: "+590",
    code: "GP",
  },
  {
    name: "Guam",
    dial_code: "+1671",
    code: "GU",
  },
  {
    name: "Guatemala",
    dial_code: "+502",
    code: "GT",
  },
  {
    name: "Guernsey",
    dial_code: "+44",
    code: "GG",
  },
  {
    name: "Guinea",
    dial_code: "+224",
    code: "GN",
  },
  {
    name: "Guinea-Bissau",
    dial_code: "+245",
    code: "GW",
  },
  {
    name: "Guyana",
    dial_code: "+595",
    code: "GY",
  },
  {
    name: "Haiti",
    dial_code: "+509",
    code: "HT",
  },
  {
    name: "Holy See (Vatican City State)",
    dial_code: "+379",
    code: "VA",
  },
  {
    name: "Honduras",
    dial_code: "+504",
    code: "HN",
  },
  {
    name: "Hong Kong",
    dial_code: "+852",
    code: "HK",
  },
  {
    name: "Hungary",
    dial_code: "+36",
    code: "HU",
  },
  {
    name: "Iceland",
    dial_code: "+354",
    code: "IS",
  },
  {
    name: "India",
    dial_code: "+91",
    code: "IN",
  },
  {
    name: "Indonesia",
    dial_code: "+62",
    code: "ID",
  },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    dial_code: "+98",
    code: "IR",
  },
  {
    name: "Iraq",
    dial_code: "+964",
    code: "IQ",
  },
  {
    name: "Ireland",
    dial_code: "+353",
    code: "IE",
  },
  {
    name: "Isle of Man",
    dial_code: "+44",
    code: "IM",
  },
  {
    name: "Israel",
    dial_code: "+972",
    code: "IL",
  },
  {
    name: "Italy",
    dial_code: "+39",
    code: "IT",
  },
  {
    name: "Jamaica",
    dial_code: "+1876",
    code: "JM",
  },
  {
    name: "Japan",
    dial_code: "+81",
    code: "JP",
  },
  {
    name: "Jersey",
    dial_code: "+44",
    code: "JE",
  },
  {
    name: "Jordan",
    dial_code: "+962",
    code: "JO",
  },
  {
    name: "Kazakhstan",
    dial_code: "+77",
    code: "KZ",
  },
  {
    name: "Kenya",
    dial_code: "+254",
    code: "KE",
  },
  {
    name: "Kiribati",
    dial_code: "+686",
    code: "KI",
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dial_code: "+850",
    code: "KP",
  },
  {
    name: "Korea, Republic of South Korea",
    dial_code: "+82",
    code: "KR",
  },
  {
    name: "Kuwait",
    dial_code: "+965",
    code: "KW",
  },
  {
    name: "Kyrgyzstan",
    dial_code: "+996",
    code: "KG",
  },
  {
    name: "Laos",
    dial_code: "+856",
    code: "LA",
  },
  {
    name: "Latvia",
    dial_code: "+371",
    code: "LV",
  },
  {
    name: "Lebanon",
    dial_code: "+961",
    code: "LB",
  },
  {
    name: "Lesotho",
    dial_code: "+266",
    code: "LS",
  },
  {
    name: "Liberia",
    dial_code: "+231",
    code: "LR",
  },
  {
    name: "Libyan Arab Jamahiriya",
    dial_code: "+218",
    code: "LY",
  },
  {
    name: "Liechtenstein",
    dial_code: "+423",
    code: "LI",
  },
  {
    name: "Lithuania",
    dial_code: "+370",
    code: "LT",
  },
  {
    name: "Luxembourg",
    dial_code: "+352",
    code: "LU",
  },
  {
    name: "Macao",
    dial_code: "+853",
    code: "MO",
  },
  {
    name: "Macedonia",
    dial_code: "+389",
    code: "MK",
  },
  {
    name: "Madagascar",
    dial_code: "+261",
    code: "MG",
  },
  {
    name: "Malawi",
    dial_code: "+265",
    code: "MW",
  },
  {
    name: "Malaysia",
    dial_code: "+60",
    code: "MY",
  },
  {
    name: "Maldives",
    dial_code: "+960",
    code: "MV",
  },
  {
    name: "Mali",
    dial_code: "+223",
    code: "ML",
  },
  {
    name: "Malta",
    dial_code: "+356",
    code: "MT",
  },
  {
    name: "Marshall Islands",
    dial_code: "+692",
    code: "MH",
  },
  {
    name: "Martinique",
    dial_code: "+596",
    code: "MQ",
  },
  {
    name: "Mauritania",
    dial_code: "+222",
    code: "MR",
  },
  {
    name: "Mauritius",
    dial_code: "+230",
    code: "MU",
  },
  {
    name: "Mayotte",
    dial_code: "+262",
    code: "YT",
  },
  {
    name: "Mexico",
    dial_code: "+52",
    code: "MX",
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    dial_code: "+691",
    code: "FM",
  },
  {
    name: "Moldova",
    dial_code: "+373",
    code: "MD",
  },
  {
    name: "Monaco",
    dial_code: "+377",
    code: "MC",
  },
  {
    name: "Mongolia",
    dial_code: "+976",
    code: "MN",
  },
  {
    name: "Montenegro",
    dial_code: "+382",
    code: "ME",
  },
  {
    name: "Montserrat",
    dial_code: "+1664",
    code: "MS",
  },
  {
    name: "Morocco",
    dial_code: "+212",
    code: "MA",
  },
  {
    name: "Mozambique",
    dial_code: "+258",
    code: "MZ",
  },
  {
    name: "Myanmar",
    dial_code: "+95",
    code: "MM",
  },
  {
    name: "Namibia",
    dial_code: "+264",
    code: "NA",
  },
  {
    name: "Nauru",
    dial_code: "+674",
    code: "NR",
  },
  {
    name: "Nepal",
    dial_code: "+977",
    code: "NP",
  },
  {
    name: "Netherlands",
    dial_code: "+31",
    code: "NL",
  },
  {
    name: "Netherlands Antilles",
    dial_code: "+599",
    code: "AN",
  },
  {
    name: "New Caledonia",
    dial_code: "+687",
    code: "NC",
  },
  {
    name: "New Zealand",
    dial_code: "+64",
    code: "NZ",
  },
  {
    name: "Nicaragua",
    dial_code: "+505",
    code: "NI",
  },
  {
    name: "Niger",
    dial_code: "+227",
    code: "NE",
  },
  {
    name: "Nigeria",
    dial_code: "+234",
    code: "NG",
  },
  {
    name: "Niue",
    dial_code: "+683",
    code: "NU",
  },
  {
    name: "Norfolk Island",
    dial_code: "+672",
    code: "NF",
  },
  {
    name: "Northern Mariana Islands",
    dial_code: "+1670",
    code: "MP",
  },
  {
    name: "Norway",
    dial_code: "+47",
    code: "NO",
  },
  {
    name: "Oman",
    dial_code: "+968",
    code: "OM",
  },
  {
    name: "Pakistan",
    dial_code: "+92",
    code: "PK",
  },
  {
    name: "Palau",
    dial_code: "+680",
    code: "PW",
  },
  {
    name: "Palestinian Territory, Occupied",
    dial_code: "+970",
    code: "PS",
  },
  {
    name: "Panama",
    dial_code: "+507",
    code: "PA",
  },
  {
    name: "Papua New Guinea",
    dial_code: "+675",
    code: "PG",
  },
  {
    name: "Paraguay",
    dial_code: "+595",
    code: "PY",
  },
  {
    name: "Peru",
    dial_code: "+51",
    code: "PE",
  },
  {
    name: "Philippines",
    dial_code: "+63",
    code: "PH",
  },
  {
    name: "Pitcairn",
    dial_code: "+872",
    code: "PN",
  },
  {
    name: "Poland",
    dial_code: "+48",
    code: "PL",
  },
  {
    name: "Portugal",
    dial_code: "+351",
    code: "PT",
  },
  {
    name: "Puerto Rico",
    dial_code: "+1939",
    code: "PR",
  },
  {
    name: "Qatar",
    dial_code: "+974",
    code: "QA",
  },
  {
    name: "Romania",
    dial_code: "+40",
    code: "RO",
  },
  {
    name: "Russia",
    dial_code: "+7",
    code: "RU",
  },
  {
    name: "Rwanda",
    dial_code: "+250",
    code: "RW",
  },
  {
    name: "Reunion",
    dial_code: "+262",
    code: "RE",
  },
  {
    name: "Saint Barthelemy",
    dial_code: "+590",
    code: "BL",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: "+290",
    code: "SH",
  },
  {
    name: "Saint Kitts and Nevis",
    dial_code: "+1869",
    code: "KN",
  },
  {
    name: "Saint Lucia",
    dial_code: "+1758",
    code: "LC",
  },
  {
    name: "Saint Martin",
    dial_code: "+590",
    code: "MF",
  },
  {
    name: "Saint Pierre and Miquelon",
    dial_code: "+508",
    code: "PM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    dial_code: "+1784",
    code: "VC",
  },
  {
    name: "Samoa",
    dial_code: "+685",
    code: "WS",
  },
  {
    name: "San Marino",
    dial_code: "+378",
    code: "SM",
  },
  {
    name: "Sao Tome and Principe",
    dial_code: "+239",
    code: "ST",
  },
  {
    name: "Saudi Arabia",
    dial_code: "+966",
    code: "SA",
  },
  {
    name: "Senegal",
    dial_code: "+221",
    code: "SN",
  },
  {
    name: "Serbia",
    dial_code: "+381",
    code: "RS",
  },
  {
    name: "Seychelles",
    dial_code: "+248",
    code: "SC",
  },
  {
    name: "Sierra Leone",
    dial_code: "+232",
    code: "SL",
  },
  {
    name: "Singapore",
    dial_code: "+65",
    code: "SG",
  },
  {
    name: "Slovakia",
    dial_code: "+421",
    code: "SK",
  },
  {
    name: "Slovenia",
    dial_code: "+386",
    code: "SI",
  },
  {
    name: "Solomon Islands",
    dial_code: "+677",
    code: "SB",
  },
  {
    name: "Somalia",
    dial_code: "+252",
    code: "SO",
  },
  {
    name: "South Africa",
    dial_code: "+27",
    code: "ZA",
  },
  {
    name: "South Sudan",
    dial_code: "+211",
    code: "SS",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    dial_code: "+500",
    code: "GS",
  },
  {
    name: "Spain",
    dial_code: "+34",
    code: "ES",
  },
  {
    name: "Sri Lanka",
    dial_code: "+94",
    code: "LK",
  },
  {
    name: "Sudan",
    dial_code: "+249",
    code: "SD",
  },
  {
    name: "Suriname",
    dial_code: "+597",
    code: "SR",
  },
  {
    name: "Svalbard and Jan Mayen",
    dial_code: "+47",
    code: "SJ",
  },
  {
    name: "Swaziland",
    dial_code: "+268",
    code: "SZ",
  },
  {
    name: "Sweden",
    dial_code: "+46",
    code: "SE",
  },
  {
    name: "Switzerland",
    dial_code: "+41",
    code: "CH",
  },
  {
    name: "Syrian Arab Republic",
    dial_code: "+963",
    code: "SY",
  },
  {
    name: "Taiwan",
    dial_code: "+886",
    code: "TW",
  },
  {
    name: "Tajikistan",
    dial_code: "+992",
    code: "TJ",
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    dial_code: "+255",
    code: "TZ",
  },
  {
    name: "Thailand",
    dial_code: "+66",
    code: "TH",
  },
  {
    name: "Timor-Leste",
    dial_code: "+670",
    code: "TL",
  },
  {
    name: "Togo",
    dial_code: "+228",
    code: "TG",
  },
  {
    name: "Tokelau",
    dial_code: "+690",
    code: "TK",
  },
  {
    name: "Tonga",
    dial_code: "+676",
    code: "TO",
  },
  {
    name: "Trinidad and Tobago",
    dial_code: "+1868",
    code: "TT",
  },
  {
    name: "Tunisia",
    dial_code: "+216",
    code: "TN",
  },
  {
    name: "Turkey",
    dial_code: "+90",
    code: "TR",
  },
  {
    name: "Turkmenistan",
    dial_code: "+993",
    code: "TM",
  },
  {
    name: "Turks and Caicos Islands",
    dial_code: "+1649",
    code: "TC",
  },
  {
    name: "Tuvalu",
    dial_code: "+688",
    code: "TV",
  },
  {
    name: "Uganda",
    dial_code: "+256",
    code: "UG",
  },
  {
    name: "Ukraine",
    dial_code: "+380",
    code: "UA",
  },
  {
    name: "United Arab Emirates",
    dial_code: "+971",
    code: "AE",
  },
  {
    name: "United Kingdom",
    dial_code: "+44",
    code: "GB",
  },
  {
    name: "United States",
    dial_code: "+1",
    code: "US",
  },
  {
    name: "Uruguay",
    dial_code: "+598",
    code: "UY",
  },
  {
    name: "Uzbekistan",
    dial_code: "+998",
    code: "UZ",
  },
  {
    name: "Vanuatu",
    dial_code: "+678",
    code: "VU",
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    dial_code: "+58",
    code: "VE",
  },
  {
    name: "Vietnam",
    dial_code: "+84",
    code: "VN",
  },
  {
    name: "Virgin Islands, British",
    dial_code: "+1284",
    code: "VG",
  },
  {
    name: "Virgin Islands, U.S.",
    dial_code: "+1340",
    code: "VI",
  },
  {
    name: "Wallis and Futuna",
    dial_code: "+681",
    code: "WF",
  },
  {
    name: "Yemen",
    dial_code: "+967",
    code: "YE",
  },
  {
    name: "Zambia",
    dial_code: "+260",
    code: "ZM",
  },
  {
    name: "Zimbabwe",
    dial_code: "+263",
    code: "ZW",
  },
];

export const SELECT_BOX_THEME = (theme) => ({
  ...theme,
  borderRadius: "6px",
  borderColor: "#979797",
  colors: {
    ...theme.colors,
    primary50: 'var(--third-color)',
    primary25: 'var(--third-color)',
    primary: 'var(--primary-color)',
  },
});

export const CUSTOM_SELECT_STYLES = {
  dropdownIndicator: (base, state) => ({
    ...base,
    color: state.isFocused ? 'var(--primary-color)' : 'var(--primary-color)', // Change arrow color based on focus state
    '&:hover': {
      color: 'var(--primary-color)', // Change arrow color on hover
    },
  }),
  clearIndicator: (base, state) => ({
    ...base,
    color: state.isFocused ? 'var(--primary-color)' : 'var(--primary-color)', // Change cross icon color based on focus state
    '&:hover': {
      color: 'var(--primary-color)', // Change cross icon color on hover
    },
  }),
};

const dot = (color = "transparent") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 16,
    width: 16,
  },
});

export const colourStyles = {
  singleValue: (provided, state) => ({
    ...provided,
    ...dot(state.data.value),
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    color: state.data.value,
    padding: "7px",
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    letterSpacing: "-0.331915px",
    fontWeight: "500",
  }),
  control: (provided) => ({
    ...provided,
    height: "36px",
    whiteSpace: "nowrap",
    fontSize: "14px",
  }),
  option: (provided, state) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    color: state.data.value,
    padding: "7px",
    fontSize: "12px",
    lineHeight: "14px",
    textAlign: "center",
    letterSpacing: "-0.331915px",
    width: "auto",
    margin: "10px",
    background: "transparent",
  }),
};

export const statusStyles = {
  singleValue: (provided, state) => ({
    ...provided,
    // height: "41px",
    display: "flex",
    alignItems: "center",
    background:
      state.data.value === "notPublished"
        ? "#d2d6da"
        : state.data.value === "pending"
          ? "#ffcece"
          : "var(--primary-color)",
    border: `1px solid ${state.data.value === "notPublished"
      ? "#7e7e7e"
      : state.data.value === "pending"
        ? "#e96c6c"
        : "var(--primary-color)"
      }`,
    borderRadius: "8px",
    color: state.data.value === "pending" ? "#e96c6c" : "#7e7e7e",
    padding: "7px",
    fontSize: "12px",
    lineHeight: "14px",
    textAlign: "center",
    letterSpacing: "-0.331915px",
    width: "fit-content",
  }),
  control: (provided) => ({
    ...provided,
    height: "36px",
    whiteSpace: "nowrap",
    fontSize: "14px",
  }),
  option: (provided, state) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    background:
      state.data.value === "notPublished"
        ? "#d2d6da"
        : state.data.value === "pending"
          ? "#ffcece"
          : "var(--primary-color)",
    border: `1px solid ${state.data.value === "notPublished"
      ? "#7e7e7e"
      : state.data.value === "pending"
        ? "#e96c6c"
        : "var(--primary-color)"
      }`,
    borderRadius: "8px",
    color: state.data.value === "pending" ? "#e96c6c" : "#7e7e7e",
    padding: "7px",
    fontSize: "12px",
    lineHeight: "14px",
    textAlign: "center",
    letterSpacing: "-0.331915px",
    width: "fit-content",
    margin: "10px",
  }),
};

export const styleSelect = {
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  option: (provided) => ({
    ...provided,
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    whiteSpace: "nowrap",
  }),
  control: (provided) => ({
    ...provided,
    padding: "2px 0 2px 25px",
    width: "325px",
    overflow: "hide",
    textOverflow: "ellipsis",
  }),
};

export const statsuOption = [
  { title: "Published", sendValue: true, value: "published" },
  { title: "Not Published", sendValue: false, value: "notPublished" },
];

export const coloroptions = [
  { title: "Solid Yellow", sendValue: true, value: "var(--primary-color)" },
  { title: "Solid Red", sendValue: false, value: "red" },
];

export const CAPITAL = {
  shared: 1,
  paidUp: 2,
};

export const POSITIONS = [
  {
    value: 1,
    name: "Promoter",
  },
  {
    value: 2,
    name: "Shareholder",
  },
  {
    value: 3,
    name: "Director",
  },
  {
    value: 4,
    name: "Secretary",
  },
  {
    value: 5,
    name: "Officer",
  },
];

export const POTENTIAL_PURPOSE_PROFILES = [
  {
    value: 1,
    name: "Supplier",
  },
  {
    value: 2,
    name: "Customer",
  },
  {
    value: 3,
    name: "Investor",
  },
  {
    value: 4,
    name: "Partner",
  },
];

export const POTENTIAL_PURPOSE_PRODUCT_SERVICES = [
  {
    value: 1,
    name: "For sale",
  },
  {
    value: 2,
    name: "For licensing",
  },
];

export const POTENTIAL_PURPOSE_COMPANIES = [
  {
    value: 1,
    name: "For collaboration",
  },
  {
    value: 2,
    name: "For investment",
  },
];

export const HOLDERS = {
  promoters: 1,
  shareHolders: 2,
  directors: 3,
  Secretary: 4,
  Officer: 5,
};

export const TASK_STATUS = [
  { value: 1, label: "Backlog" },
  { value: 2, label: "In Progress" },
  { value: 3, label: "Complete" },
];

export const TASK_STATUS_ENUM = {
  BACKLOG: 1,
  INPROGRESS: 2,
  COMPLETE: 3,
};

export const SCORE_LIST = {
  isEmailVerified: 15,
  isPhonVerified: 15,
  isAddressVerified: 35,
  isIdentityVerified: 35,
};

export const SUBCRIPTION_PLAN_NAME = {
  MONTHLY: "MONTHLY",
  ANNUALLY: "ANNUALLY",
};

export const SUBCRIPTION_PLAN = [
  {
    name: "MONTHLY",
    amount: 329,
  },
  {
    name: "ANNUALLY",
    amount: 3330,
  },
];

export const LOGIN_TYPE = {
  ADMIN: 1,
  NORMAL: 2,
};

export const MODAL_BUSINESS_IDENTIFY = {
  LOGO: "logo",
  COMPANY_NAME: "companyName",
  COMPANY_WEBSITE: "companyWebsite",
  PRIMARY_ACTIVITY: "primaryActivity",
  PRIMARY_ADDRESS: "primaryAddress",
};

export const secretPass = "SecretPass";

export const notificationAction = {
  NOTI_MYINFO_VERIFICATION: { label: "Myinfo verification", value: 1 },
  NOTI_VERIFY_BUSINESS: { label: "Verify business", value: 2 },
  NOTI_COMPLETE_DUE_DILIGENCE: { label: "Complete due diligence", value: 3 },
  NOTI_LEARN_MORE: { label: "Learn more", value: 4, isDisabled: true },
  NOTI_INVITE_POTENTIAL_SHAREHOLDERS: {
    label: "Invite potential shareholders",
    value: 5,
  },
  NOTI_INVITE_POTENTIAL_DIRECTORS: {
    label: "Invite potential directors",
    value: 6,
  },
  NOTI_INVITE_POTENTIAL_PROMOTERS: {
    label: "Invite potential promoters",
    value: 7,
  },
  NOTI_CHAT_NOW: { label: "Chat now", value: 8, isDisabled: true },
  NOTI_UPGRADE_NOW: { label: "Upgrade now", value: 9 },
  NOTI_VIEW_FILES: { label: "View files", value: 10 },
  NOTI_SCHEDULE_SHAREHOLDERS_MEETINGS: {
    label: "Schedule shareholders meeting",
    value: 11,
    isDisabled: true,
  },
  NOTI_SCHEDULE_DIRECTORS_MEETINGS: {
    label: "Schedule directors meeting",
    value: 12,
    isDisabled: true,
  },
  NOTI_SCHEDULE_PROMOTERS_MEETINGS: {
    label: "Schedule promoters meeting",
    value: 13,
    isDisabled: true,
  },
  NOTI_APPOINT_SHAREHOLDER: {
    label: "Appoint shareholder",
    value: 14,
    isDisabled: true,
  },
  NOTI_APPOINT_OFFICER: {
    label: "Appoint officer",
    value: 15,
    isDisabled: true,
  },
};

export const I_POP_OVER = {
  BUSINESS_DETAIL: 1,
  POTENTIAL_ROLE: 2,
  BOOK_OF_BUSINESS_SUGGESTED_POTENTIAL_PURPOSE: 13
};

export const objectiveCards = [
  {
    name: "Set Up a Family Office",
    type: "A",
  },
  {
    name: "Set Up a Licensed Fund",
    type: "B",
  },
  {
    name: "Sell More Products or Services",
    type: "C",
  },
  {
    name: "Appoint Franchisees, Distributors or Licensees",
    type: "D",
  },
  {
    name: "Improve Profit Margins",
    type: "E",
  },
  {
    name: "Be More Tax-Efficient",
    type: "F",
  },
  {
    name: "Gain Exposure to Other Global Markets",
    type: "G",
  },
  {
    name: "Appoint Shareholders to Realize a Business Plan",
    type: "H",
  },
  {
    name: "Other Objectives",
    type: "I",
  },
];

export const companyType = [
  { label: "Public Company Limited by Shares", value: 1 },
  { label: "Public Company Limited by Guarantee", value: 2 },
  { label: "Unlimited Public Company", value: 3 },
  { label: "Private Company Limited by Shares", value: 4 },
  { label: "Exempt Private Company Limited by Shares", value: 5 },
  { label: "Unlimited Private Company", value: 6 },
  { label: "Unlimited Exempt Private Company", value: 7 },
  { label: "Branch of a company registered in a foreign country ", value: 8 },
];

export const entityType = [
  { label: "Audit Firm", code: "AF" },
  { label: "Business", code: "BN" },
  { label: "Charities and Institution of a Public Character", code: "CC" },
  { label: "Only Dental Clinics", code: "CD" },
  { label: "Commercial Home", code: "CH" },
  { label: "Clinical Laboratories", code: "CL" },
  { label: "Only Medical Clinic", code: "CM" },
  { label: "Consulate-General or Consulate", code: "CP" },
  { label: "Cooperative Societies", code: "CS" },
  { label: "Clinical Xray Laboratories", code: "CX" },
  { label: "High Commissions or Embassies", code: "DP" },
  { label: "International Education Language Centres", code: "EC" },
  { label: "Bank Representative Offices", code: "FB" },
  { label: "Foreign Company", code: "FC" },
  { label: "Foreign Military Units", code: "FM" },
  { label: "Insurance Representative Offices", code: "FN" },
  { label: "Other Financial Representative Offices", code: "FS" },
  { label: "Organs of State, Ministries and Departments", code: "GA" },
  { label: "Statutory Boards", code: "GB" },
  { label: "Government and Government-aided Schools", code: "GS" },
  { label: "Hospitals", code: "HS" },
  { label: "Local Company", code: "LC" },
  { label: "Limited Liability Partnerships", code: "LL" },
  { label: "Limited Partnerships", code: "LP" },
  { label: "Mutual Benefit Organisation", code: "MB" },
  { label: "Management Corporations", code: "MC" },
  { label: "Both Medical and Dental Clinic", code: "MD" },
  { label: "Maternity Homes", code: "MH" },
  { label: "Madrasahs", code: "MM" },
  { label: "Mosques", code: "MQ" },
  { label: "News Bureaus", code: "NB" },
  { label: "International Organisation", code: "NR" },
  { label: "PA Services", code: "PA" },
  { label: "Grassroot Units", code: "PB" },
  { label: "Public Accounting Firms", code: "PF" },
  { label: "Representative Office", code: "RF" },
  { label: "Foreign Law Practice Representative Office", code: "RP" },
  { label: "Subsidiary Management Corporations", code: "SM" },
  { label: "Societies", code: "SS" },
  { label: "Town Councils", code: "TC" },
  { label: "Other Organisation", code: "TR" },
  { label: "Trade Unions", code: "TU" },
  { label: "Unregistered Foreign Entities", code: "UF" },
  { label: "Unregistered Local Entities", code: "UL" },
  { label: "Voluntary Welfare Home", code: "VH" },
  { label: "Xray Laboratories", code: "XL" },
];

export const entityStatus = [
  { label: "LIVE", value: "LIVE" },
  { label: "NON-LIVE", value: "NON-LIVE" },
];

export const MESSAGING_LIMIT = 30;

export const PROFILE_STATUS = {
  SUGGESTED_PROFILES: 1,
  WARM_PROFILES: 2,
  MEETING_REQUESTS: 3,
};

export const PROFILE_ACTION = {
  REJECTED: -1,
  CONNECT: 1,
  REQUESTED: 2,
  SCHEDULED: 3,
  CONFIRM: 4,
  RESCHEDULED: 5,
  RESPOND: 6,
  ACCEPT: 7,
};


export const BOB_CTA = {
  VIEW_ALL: 1,
  NOTIFICATION: 2,
  SUBSCRIPTION: 3,
};

export const BOB_SERVICES = {
  PROFILES: 1,
  PRODUCT_AND_SERVICES: 2,
  COMPANIES: 3,
};

export const BOB_SERVICES_NAME = {
  1: "Profiles",
  2: "Product and services",
  3: "Companies",
};

export const BOB_SERVICES_TEMPLATE_NAME = {
  1: "BOB Profiles.csv",
  2: "BOB Product Services.csv",
  3: "BOB Companies.csv",
};

export const SUBSCRIPTION_MODULE_TYPE = {
  PLAN: 1,
  PRODUCT: 2,
  BOB: 3,
  PAIDUPCAPITAL: 4,
  FUNDS: 5
};

export const SUBSCRIPTION_MODULE_TYPE_NAME = {
  1: "Plan",
  2: "Product",
  3: "Book of business",
  4: "Paid-up Capital",
  5: "Funds"
};

export const SUBSCRIPTION_PLAN_TYPE = [{
  selected: true,
  value: '',
  name: 'Select plan type'
}, {
  value: 1,
  name: "Plan"
}, {
  value: 2,
  name: "Product"
}, {
  value: 5,
  name: "Funds"
}]

export const PRODUCT_GOLD_TYPE = [{
  selected: true,
  value: -1,
  name: 'Select type'
}, {
  value: 1,
  name: "Kilogram"
}, {
  value: 2,
  name: "Gram"
}, {
  value: 3,
  name: "Hedge Kilogram"
}]

export const BANNER_TYPE = {
  PLAN: 1,
  EXTERNAL: 2
}

export const BANNER_IMAGE_TYPE = {
  WEB: 1,
  MOBILE: 2,
};

export const BANNER_TYPE_OPTIONS = [
  {
    value: 1,
    name: "Plan",
  },
  {
    value: 2,
    name: "External",
  },
];

export const CURRENCY = {
  USD: 'usd',
  SGD: 'sgd',
  INR: 'inr',
  PKR: 'pkr',
  LKR: 'lkr',
  BDT: 'bdt',
  CNY: 'cny',
}

export const CURRENCY_NAME = [
  { value: '', label: 'Select currency' },
  { value: 'usd', label: 'USD' },
  { value: 'sgd', label: 'SGD' },
  { value: 'inr', label: 'INR' },
  { value: 'cny', label: 'CNY' },
]

export const XTRA_CURRENCY_NAME = [
  { value: 'pkr', 'label': 'Pakistani Rupee (PKR)' },
  { value: 'lkr', 'label': 'Sri Lankan Rupee (LKR)' },
  { value: 'bdt', 'label': 'Bangladeshi Taka (BDT)' },
]

export const CURRENCY_CRYPTO_NAME = [
  { value: 'btc', label: 'BTC' },
  { value: 'eth', label: 'ETH' },
  { value: 'usdt', label: 'USDT' },
  { value: 'usdc', label: 'USDC' },
]

export const XTRA_CURRENT_CRYPTO_NAME = [
  { value: 'xrp', label: 'XRP' },
  { value: 'aave', label: 'AAVE' },
  { value: 'doge', label: 'DOGE' },
  { value: 'sol', label: 'SOL' },
  { value: 'ltc', label: 'LTC' }
]

export const SUPPORTED_PAYMENT_METHOD_LIST = [
  { value: 1, name: 'Bank transfer' },
  { value: 2, name: 'Pay with card' },
  { value: 3, name: 'Pay with crypto' },
]

export const SUPPORTED_PAYMENT_METHOD = {
  BANK_TRANSFER: 1,
  PAY_WITH_CARD: 2,
  PAY_WITH_CRYPTO: 3,
}

export const SUPPORTED_PAYMENT_METHOD_NAME = {
  1: "Bank transfer",
  2: "Pay with card",
  3: "Pay with crypto",
}

export const GOLD_BID_PRICE_CONST = {
  GOLD1KG: "gold1kg",
  GOLD1G: "gold1g",
  GOLD1KGHEDGE: "gold1kghedge",
}

export const GOLD_BID_PRICE = [
  { value: 'gold1kg', name: 'Gold 1kg' },
  { value: 'gold1g', name: 'Gold 1g' },
  { value: 'gold1kghedge', name: 'Gold 1kg Hedge' },
]

export const CURRENCY_LOCALE_STRING = {
  usd: 'en-US',
  sgd: 'en-US',
  inr: 'en-IN',
  pkr: 'en-IN',
  lkr: 'en-IN',
  bdt: 'en-IN',
}

export const POTENTIAL_PURPOSES = [
  {
    value: "Potential customer",
    name: "Potential customer",
  },
  {
    value: "Potential investor",
    name: "Potential investor",
  },
  {
    value: "Potential director",
    name: "Potential director",
  },
  {
    value: "Potential employee",
    name: "Potential employee",
  },
  {
    value: "Potential vendor or supplier",
    name: "Potential vendor or supplier",
  },
];

export const ADMIN_USER = ['support@inproved.com']