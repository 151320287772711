export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE";
export const SET_FAILED_MESSAGE = "SET_FAILED_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const GET_COMPANY_LIST = "GET_COMPANY_LIST";
export const SELECTED_COMPANY = "SELECTED_COMPANY";
export const ADD_COMPANY_LIST = "ADD_COMPANY_LIST";
export const EDIT_COMPANY_LIST = "EDIT_COMPANY_LIST";
export const GET_CAPITAL_LIST = "GET_CAPITAL_LIST";
export const ADD_CAPITAL = "ADD_CAPITAL";
export const EDIT_FAIL = "EDIT_FAIL";
export const GET_FILES = "GET_FILES";
export const ADD_FILES = "ADD_FILES";
export const GET_SHAREHOLDER_LIST = "GET_SHAREHOLDER_LIST";
export const ADD_SHAREHOLDER = "ADD_SHAREHOLDER";
export const ADD_SHAREHOLDER_FAIL = "ADD_SHAREHOLDER_FAIL";
export const SET_CURRENT_FILE_NAME = "SET_CURRENT_FILE_NAME";
export const GET_OFFICER_LIST = "GET_OFFICER_LIST";
export const ADD_OFFICER = "ADD_OFFICER";
export const ADD_OFFICER_FAIL = "ADD_OFFICER_FAIL";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const USER_PROFILE = "USER_PROFILE";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const GET_TASK_LIST = "GET_TASK_LIST";
export const ADD_NEW_TASK = "ADD_NEW_TASK";
export const EDIT_TASK = "EDIT_TASK";
export const GET_COMPANY_ACTIVITY_LIST = "GET_COMPANY_ACTIVITY_LIST";
export const GET_USER_ACTIVITY = "GET_USER_ACTIVITY";
export const GET_PROMOTER_LIST = "GET_PROMOTER_LIST";
export const CHANGE_PERMISSION_UPDATES = "CHANGE_PERMISSION_UPDATES";
export const TWO_FACTOR_LOGIN_SUCCESS = "TWO_FACTOR_LOGIN_SUCCESS";
export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";
export const SET_AUTH_USER_INFO = "SET_AUTH_USER_INFO";
export const SET_STRIPE_MODAL = "SET_STRIPE_MODAL";
export const SET_STRIPE_SECRET_TOKEN = "SET_STRIPE_SECRET_TOKEN";
export const SET_STRIPE_SUBSCRIPTION = "SET_STRIPE_SUBSCRIPTION";
export const SET_STRIPE_PAYMENT = "SET_STRIPE_PAYMENT";
export const TOGGLE_MOBILE_MENU = "TOGGLE_MOBILE_MENU";
export const GET_SUBSCRIPTION_LIST = "GET_SUBSCRIPTION_LIST"
export const GET_OBJECTIVE = "GET_OBJECTIVE"
export const GET_OBJECTIVE_BY_ID = "GET_OBJECTIVE_BY_ID"


// ADMIN
export const GET_DASHBOARD = "GET_DASHBOARD";
export const GET_ACTIVITY_LOGS = "GET_ACTIVITY_LOGS";
export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_PAYMENTS = "GET_PAYMENTS";
export const GET_BANNERS = "GET_BANNERS";
export const GET_BANNER_BY_ID = "GET_BANNER_BY_ID";
export const ACTIVITY_FETCH_RESET = "ACTIVITY_FETCH_RESET"
export const GET_NOTIFICATION_BANNERS = "GET_NOTIFICATION_BANNERS"
export const GET_NOTIFICATION_BY_ID = "GET_NOTIFICATION_BY_ID"
export const GET_SUBSCRIPTION_BY_ID = "GET_SUBSCRIPTION_BY_ID"
export const GET_BUSINESS_LIST = "GET_BUSINESS_LIST"
export const SET_ADMIN_AS_USER = "SET_ADMIN_AS_USER"
export const SET_ALL_COMPANY_APPOINTED_LIST = "SET_ALL_COMPANY_APPOINTED_LIST"
export const GET_SETTINGS = "GET_SETTINGS"


// CHAT
export const CHAT_RESPONSE ="CHAT_RESPONSE"
export const TWILIO_TOKEN ="TWILIO_TOKEN"
export const CLEAR_TYPING_INDICATOR = "CLEAR_TYPING_INDICATOR"
export const GET_CHANNEL_LIST = "GET_CHANNEL_LIST"
export const REMOVE_TYPING_INDICATOR = "REMOVE_TYPING_INDICATOR"
export const RESET_CHANNEL_MESSAGE = "RESET_CHANNEL_MESSAGE"
export const SET_CHANNEL_LAST_MESSAGE = "SET_CHANNEL_LAST_MESSAGE"
export const SET_CHANNEL_MESSAGE = "SET_CHANNEL_MESSAGE"
export const SET_CHANNEL_NEW_MESSAGE = "SET_CHANNEL_NEW_MESSAGE"
export const SET_TYPING_INDICATOR = "SET_TYPING_INDICATOR"
export const SET_CLIENT = "SET_CLIENT"
export const SET_CURRENT_CONVERSATION = "SET_CURRENT_CONVERSATION"
export const SET_CHAT_CONVERSATION_MEDIA = "SET_CHAT_CONVERSATION_MEDIA"
export const SET_BULK_CHANNEL_MESSAGE = "SET_BULK_CHANNEL_MESSAGE"
export const TOGGLE_MENU_SIDEBAR = "TOGGLE_MENU_SIDEBAR"
export const SET_ALL_CONVERSATION = "SET_ALL_CONVERSATION"



// Actions
export const UPSERT_CONVERSATION = "UPSERT_CONVERSATION"
export const REMOVE_CONVERSATION = "REMOVE_CONVERSATION"
export const UPDATE_CURRENT_CONVERSATION = "UPDATE_CURRENT_CONVERSATION"
export const CONVERSATION_LAST_READ_INDEX = "CONVERSATION_LAST_READ_INDEX"
export const ADD_MESSAGES = "ADD_MESSAGES"
export const PUSH_MESSAGES = "PUSH_MESSAGES"
export const REMOVE_MESSAGES = "REMOVE_MESSAGES"
export const UPDATE_PARTICIPANTS = "UPDATE_PARTICIPANTS"
export const UPDATE_UNREAD_MESSAGES = "UPDATE_UNREAD_MESSAGES"
export const UPDATE_CONVERSATION = "UPDATE_CONVERSATION"
export const ADD_ATTACHMENT = "ADD_ATTACHMENT"
export const CLEAR_ATTACHMENTS = "CLEAR_ATTACHMENTS"
export const TYPING_STARTED = "TYPING_STARTED"
export const TYPING_ENDED = "TYPING_ENDED"
export const UPDATE_LOADING_STATE = "UPDATE_LOADING_STATE"
export const SET_DEFAULT_CURRENT_MESSAGE_LENGTH = "SET_DEFAULT_CURRENT_MESSAGE_LENGTH"
export const SET_CHAT_SEARCH_RESULT = "SET_CHAT_SEARCH_RESULT"
export const SET_CHAT_SEARCH_MESSAGE_ID = "SET_CHAT_SEARCH_MESSAGE_ID"

// Book of Business
export const GET_SUGGESTED_PROFILE = "GET_SUGGESTED_PROFILE"
export const GET_BUSINESS_GROUP = "GET_BUSINESS_GROUP"
export const GET_IMPORT_LIST = "GET_IMPORT_LIST"
export const RESET_BOB_PAGINATION = "RESET_BOB_PAGINATION"

export const SET_LOAD_CLIENT = "SET_LOAD_CLIENT"
export const GET_REFERRAL_LIST = "GET_REFERRAL_LIST"
export const LOAD_REFERRAL_SKELETON = "LOAD_REFERRAL_SKELETON"
export const SET_ALL_USER_LIST = "SET_ALL_USER_LIST"
export const SET_USER_LISTING = "SET_USER_LISTING"
export const SET_NEW_CHANNEL_REDIRECTION_FORCE = "SET_NEW_CHANNEL_REDIRECTION_FORCE"
export const HOLD_SID_CONVERSATION = "HOLD_SID_CONVERSATION"
export const OPEN_LATEST_CONVERSATION = "OPEN_LATEST_CONVERSATION"
export const CHAT_MEDIA = "CHAT_MEDIA"
export const LOADING_CHAT_MEDIA = "LOADING_CHAT_MEDIA"
export const CHAT_PREVIEWER_MEDIA = "CHAT_PREVIEWER_MEDIA"