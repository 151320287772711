import moment from "moment";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { RoutesPaths } from "../../Router/constant";
import { MESSAGING_LIMIT, backendUrl } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getChatUserName,
  getChatUserImage,
} from "./userList";
import { OverlayTrigger, Popover } from "react-bootstrap";
import EmojiPicker from "emoji-picker-react";
import { toast } from "react-hot-toast";
import ContentEditable from "react-contenteditable";
import { chatMediaAddAction, chatMediaPreviewerAction, getMessages, getTypingMessage } from "../../actions/chat";
import { getSdkConversationObject } from "../../conversations-objects";
import { downloadMedia, getName } from "./userMessages";
import TextMessageBox from "./TextMessageBox";
import InfiniteScroll from "react-infinite-scroll-component";
import { PuffLoader } from 'react-spinners';
import { uploadMultipleFileAction } from "../../actions/files";
import { clearAdminAsUserAction, loginAdminAsUserAction, loginAdminAsUserActionByCompanyId } from "../../actions/user";


var holdCurrentDate = null;

export default function MessageBox(props) {
  const { conversation, showList } = props;
  const dispatch = useDispatch();
  const bottomRef = useRef(null);
  const sendMessageButton = useRef(null);
  const mentionBtnRef = useRef(null);
  const overlayRef = useRef();
  const textAreaRef = useRef(null);
  const setScrollLastMessageId = useRef(null);
  const [value, setValue] = useState("");
  const { user, adminAsuser } = useSelector((state) => state.user);
  const { searchMessageId, searchQuery, chatMedia } = useSelector((state) => state.chat);
  const [fileArray, setFileArray] = useState([]);
  const [base64Files, setBase64Files] = useState([]);
  const [paginator, setPaginator] = useState(null);
  const [height, setHeight] = useState(0);
  const [loading, setLoading] = useState(false);
  const [mentionPopupToggle, setMentionPopupToggle] = useState(false);
  const [emojiPopupToggle, setEmojiPopupToggle] = useState(false);

  const emojiRef = useRef();
  const mentionRef = useRef();

  // New States
  const sid = useSelector((state) => state.sid);

  const participants =
    useSelector((state) => state.participants)[sid] ?? [];
  const messages = useSelector((state) => state.messages[sid] ?? []);
  const typingData =
    useSelector((state) => state.typingData)[sid] ?? [];
  const [hasMore, setHasMore] = useState(messages?.length === MESSAGING_LIMIT);
  const sdkConvo = useMemo(() => getSdkConversationObject(conversation), [conversation.sid]);
  const typingInfo = getTypingMessage(typingData);

  // useEffect(() => {
  //   setValue("");
  //   setFileArray([]);
  // }, [conversation]);

  useEffect(() => {
    const fetchData = async () => {
      getMessages(sdkConvo).then((paginator) => {
        setHasMore(paginator.hasPrevPage)
        setPaginator(paginator);
        setHeight(0);
      });
    }
    fetchData().catch(error => console.log(error));
  }, []);

  useEffect(() => {
    if (setScrollLastMessageId?.current) {
      const element = document.getElementById(setScrollLastMessageId.current);
      if (element) {
        setScrollLastMessageId.current = null
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth', block: 'end' });
          element.classList.add("tempFocus");
        }, 100);

        // Remove the class after another 5 seconds
        setTimeout(() => {
          element.classList.remove("tempFocus");
        }, 2000); // 10000 milliseconds = 10 seconds
      }
    }
  }, [messages])

  const scrollSectionRef = useCallback((value) => {
    bottomRef.current?.scrollIntoView({
      behavior: "smooth",
      block: value ? "start" : "end",
    });
  });

  const getSeperatedDate = (messageDate) => {
    var showDate = false;
    var currentMessageDate = moment(messageDate).format("YYYYMMDD");
    showDate = holdCurrentDate !== currentMessageDate ? true : false;
    holdCurrentDate = currentMessageDate;
    if (showDate) {
      return (
        <div className="seperate-line">
          <span>
            {moment(messageDate).format("ddd, MMM D")}{" "}
            {moment(messageDate).format("Y") !== moment().format("Y")
              ? "," + moment(messageDate).format("Y")
              : ""}
          </span>
        </div>
      );
    } else {
      return null;
    }
  };


  const fetchMore = async () => {
    setLoading(true);
    if (!paginator) {
      setLoading(false);
      return;
    }
    const result = await paginator?.prevPage();
    if (!result) {
      setLoading(false);
      return;
    }

    const moreMessages = result.items;
    setPaginator(result);
    setHasMore(result.hasPrevPage);
    setScrollLastMessageId.current = `message-${moreMessages[moreMessages.length - 1]?.sid}`
    props.upsertMessage(conversation.sid, moreMessages);
  };

  useLayoutEffect(() => {
    const currentHeight = bottomRef.current?.clientHeight;
    if (currentHeight && currentHeight > height && loading) {
      // for preventing immediate downloading of the next messages page
      setTimeout(() => {
        setHeight(currentHeight ?? 0);
        setLoading(false);
      }, 2000);
    }
  }, [bottomRef.current?.clientHeight]);

  const addToDiv = (data, value) => {
    const chatBox = textAreaRef.current;
    chatBox.focus();
    chatBox.innerHTML.split("@").join("");
    pasteHtmlAtCaret(
      value
        ? ` ${data.emoji} `
        : `<span class="tagName" contentEditable="false">${data?.attributes?.name || data?.identity}</span>`
    );
    overlayRef.current.click();
  };

  const pasteHtmlAtCaret = (html) => {
    var sel, range;
    if (window.getSelection) {
      // IE9 and non-IE
      sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);
        range.deleteContents();

        // Range.createContextualFragment() would be useful here but is
        // non-standard and not supported in all browsers (IE9, for one)
        var el = document.createElement("div");
        el.innerHTML = html;
        var frag = document.createDocumentFragment(), node, lastNode;
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node);
        }
        range.insertNode(frag);

        // Preserve the selection
        if (lastNode) {
          range = range.cloneRange();
          range.setStartAfter(lastNode);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
        }
      }
    } else if (document.selection && document.selection.type != "Control") {
      // IE < 9
      document.selection.createRange().pasteHTML(html);
    }

    setValue(textAreaRef.current.innerHTML)
  }

  const uploadFiles = (event) => {
    const maxfileUploadSize = 1024 * 1024 * 20;
    const files = Array.from(event.target.files);

    const readers = [];
    const filesUpload = [];

    for (const file of files) {
      if (file.size > maxfileUploadSize) {
        event.target.value = null;
        toast.error("Maximum file upload size is 20mb.");
        return;
      }

      // Push the file to be uploaded to backend
      filesUpload.push(file);

      // Create a promise for reading file as base64
      if (file.type.startsWith('image/')) {
        readers.push(
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
          })
        );
      } else {
        readers.push(new Promise((resolve, reject) => { resolve(null) }))
      }
    }

    // Update state with uploaded files
    setFileArray((oldArray) => [...oldArray, ...filesUpload]);

    // Read all files asynchronously and update base64Files state
    Promise.all(readers).then((results) => {
      setBase64Files((oldBase64Array) => [...oldBase64Array, ...results]);
    });

    event.target.value = null; // Reset input field
  };

  const removeMedia = (index) => {
    fileArray.splice(index, 1);
    base64Files.splice(index, 1);
    setFileArray([...fileArray]);
    setBase64Files([...base64Files]);
  };

  const handleKeyUpTrigger = (event) => {
    if (event.keyCode === 50) {
      const { current } = mentionBtnRef;
      current.click();
    }
  };

  const handleKeyPress = (event) => {
    if (event.charCode === 13 && !event.ctrlKey && !event.shiftKey && event.key === "Enter") {
      event.preventDefault(); // Prevent adding a newline
      sendMessageButton.current.click()
    }
  };

  const keepFocus = (event) => {
    if (event && event.relatedTarget && event.relatedTarget.type === 'search') { } else {
      const chatBox = textAreaRef.current;
      chatBox.focus();
    }
  };

  const dateFormatShow = (dateFilter) => {
    var date = new Date(dateFilter).toLocaleTimeString([], {
      hourCycle: 'h12',
      hour: '2-digit',
      minute: '2-digit',
    })
    return date
  }

  const handleMessageKeyDownEventLocal = () => {
    setValue(textAreaRef.current.innerHTML)
    if (sdkConvo) {
      sdkConvo.typing();
    }
    // handleMessageKeyDownEvent()
  }

  const setOpenImageViewer = (item) => {
    const indexValueChatMedia = chatMedia.findIndex(x => x.filename === item.filename)
    openImageViewer(indexValueChatMedia)
  }


  const openImageViewer = useCallback((index) => {
    dispatch(chatMediaPreviewerAction(index, true));
  }, []);

  const printMessage = (message) => {
    return (
      <>
        {message.attachedMedia.length > 0 ? (
          <>
            {message.body && (
              <TextMessageBox
                searchMessageId={searchMessageId}
                message={message}
                searchQuery={searchQuery}
              />
            )}
            <div className="message-box-hold-outer">
              {message.attachedMedia.map((item, index) => (
                <div className="message-box-hold" key={index}>
                  <span key={index} className="addMedia" onClick={() => item?.mimetype.startsWith('image/') ? setOpenImageViewer(item) : downloadMedia(dispatch, item)}>
                    {item?.mimetype.startsWith('image/') ? (
                      <span className="imagePreviewChat" style={{ backgroundImage: `url(${backendUrl + item.filename})` }}></span>
                    ) : (
                      <>
                        <span className="addMediaPadding">
                          <img className="imageView" src="assets/img/imageView.svg" alt="" />
                          {/* <img className="addMediatextImage" src="assets/img/addmediaBack.svg" alt="" /> */}
                          &nbsp;<span className="fileName">{item.originalName}</span>
                        </span>
                      </>
                    )}
                  </span>
                  {/* <span
                    className="addMedia"
                    onClick={() => downloadMedia(dispatch, msg)}
                  >
                    <img
                      className="imageView"
                      src="assets/img/imageView.svg"
                      alt=""
                    />
                    <img
                      className="addMediatextImage"
                      src="assets/img/addmediaBack.svg"
                      alt=""
                    />
                    <span className="extensionName">
                      {getName(msg.filename)}
                    </span>
                    <span className="fileName">{msg.filename}</span>
                  </span> */}
                </div>
              ))}
            </div>
          </>
        ) : (
          (message?.attributes?.module === 'ESIGN_DOCUMENT_TEMPLATE' || message?.attributes?.module === 'ESIGN') ? (
            <div className="e-sign-pdf-message">

              <div className="e-sign-pdf-img-hold">
                <div className="e-sign-pdf-img"></div>
              </div>
              <div className="e-sign-pdf-name">
                {message?.attributes?.type === 1 ? (
                  <h6>{message?.attributes?.documentName}.pdf has been initiated by {message?.attributes?.senderFullName} to be e-signed</h6>
                ) : message?.attributes?.type === 3 ? (
                  <h6>Everyone has signed {message?.attributes?.documentName}.pdf</h6>
                ) : (
                  <h6>{message?.attributes?.signerFullName} has received {message?.attributes?.documentName}.pdf to be e-signed.</h6>
                )}
                <div className="titleDesc showLess">
                  <h3>{message?.attributes?.title}</h3>
                  <p>{message?.attributes?.message}</p>
                </div>
                <div className="e-sign-pdf-button">
                  <div className="e-sign-pdf-button-with-size">
                    {/* <p className="e-sign-pdf-size">$100</p> */}
                    {(typeof (message?.attributes?.signers) === 'object' && message?.attributes?.signers.length > 0) && (
                      <div className="e-sign-pdf-signer-section">
                        {message?.attributes?.signers.map((fruit, index) => (
                          <p key={index} className="e-sign-pdf-signer">
                            <strong>Signer {index + 1}:</strong>&nbsp;
                            {fruit.first_name + ' ' + fruit.last_name}
                            {message?.attributes?.type === 1 && (
                              `, ${fruit.email}`
                            )}
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <TextMessageBox
              searchMessageId={searchMessageId}
              message={message}
              searchQuery={searchQuery}
            />
          )
        )}
      </>
    );
  };

  const onMessageSend = async () => {
    const innerValue = value
    setValue("");
    if (innerValue.length === 0 && fileArray.length === 0) {
      return;
    }
    const { conversation } = props;
    const sdkConvo = getSdkConversationObject(conversation);

    const newMessageBuilder = sdkConvo.prepareMessage().setBody(innerValue);

    const fileData = new FormData();
    for (const file of fileArray) {
      fileData.append("files", file);
    }
    if (fileArray.length > 0) {
      setFileArray([]);
      const filesResp = await uploadMultipleFileAction(fileData)
      if (filesResp && filesResp.length > 0) {
        dispatch(chatMediaAddAction({ channelId: conversation?.sid, media: filesResp }))
        newMessageBuilder.setAttributes({ files: filesResp });
      }
    }

    const messageIndex = await newMessageBuilder.build().send();
    try {
      await sdkConvo.advanceLastReadMessageIndex(messageIndex ?? 0);
      setTimeout(() => {
        scrollSectionRef()
      }, 1000);
    } catch (e) {
      throw e;
    }
  };

  useEffect(() => {
    if (adminAsuser) {
      window.open(adminAsuser, "_blank");
      dispatch(clearAdminAsUserAction());
    }
  }, [dispatch, adminAsuser]);

  const handleSpanClick = (event) => {
    if (event.target.classList.contains('messageExternalLinkCompanyChange')) {
      const companyId = event.target.getAttribute('companyid')
      dispatch(loginAdminAsUserAction({ company: companyId, user: null }));
    }
  };

  const handleClickOutside = (event) => {
    if (emojiRef.current && !emojiRef.current.contains(event.target)) {
      setEmojiPopupToggle(false);
    }
    if (mentionRef.current && !mentionRef.current.contains(event.target)) {
      setMentionPopupToggle(false);
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const downloadMediaOnFly = (item) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const fileUrl = event.target.result;
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = item.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    reader.readAsDataURL(item);
  }

  return (
    <>
      <div className="messageHeader">
        <div className="userName">
          <div
            className="userlistToggle"
            onClick={() => props?.setShowList(!showList)}
          >
            <i className="sidenav-toggler-line"></i>
            <i className="sidenav-toggler-line"></i>
            <i className="sidenav-toggler-line"></i>
          </div>
          <h4>
            <span dangerouslySetInnerHTML={{
              __html: sdkConvo?.friendlyName || getChatUserName(participants, user),
            }}></span>
          </h4>
          <p>
            {moment().format("h:mm") + " GMT" + moment().format("Z")}&nbsp;
            <span>&nbsp;</span>
          </p>
        </div>
        <div className="openDashboard">
          <Link
            className="d-flex align-items-center"
            to={RoutesPaths.DASHBOARD}
          >
            <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
              <img
                src="assets/img/dashboard-icon-active.svg"
                className="normal-icon"
                alt=""
              />
            </div>
            <span className="nav-link-text ms-1">Dashboard</span>
          </Link>
          <div className="arrowIcon" onClick={props?.openProfile}>
            <img
              className={props?.showProfile ? "showProfileSec" : ""}
              src="assets/img/openDashboard.svg"
              alt=""
            />
          </div>
        </div>
      </div>
      <div
        id="scrollable"
        className={
          fileArray.length > 0
            ? "addedMedia messageHolder customScroll"
            : "messageHolder customScroll"
        }
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          width: "100%",
        }}
      >
        <InfiniteScroll
          dataLength={messages?.length ?? 0}
          next={fetchMore}
          hasMore={!loading && hasMore}
          loader={
            <div className="loader-chatSpinner">
              <PuffLoader color="#F3C258" />
            </div>
          }
          scrollableTarget="scrollable"
          style={{
            display: "flex",
            overflow: "hidden",
            flexDirection: "column-reverse",
          }}
          inverse={true}
          scrollThreshold="20px"
        >
          <div className="messageList" ref={bottomRef}>
            {messages?.map((msg, index) => (
              <div key={index}>
                {getSeperatedDate(msg.dateCreated)}
                {msg.author === 'system' ? (
                  <div className="joinedLeaveChat">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: msg.body,
                      }}
                    >
                    </span>
                  </div>
                ) : (
                  <div id={"message-" + msg.sid} className={user.email === msg.author ? "messageListOuter ownProperty" : "messageListOuter"}>
                    <div className="messageListInner" onClick={handleSpanClick}>
                      {user.email !== msg.author && (
                        <div
                          style={{
                            backgroundImage: `url(${getChatUserImage(
                              participants,
                              user,
                              msg?.author
                            )})`,
                          }}
                          className="profile_photo"
                        ></div>
                      )}
                      <div className="messagebyUser">
                        <div className="userName">
                          <div className="userMessage">
                            {user.email !== msg.author && (
                              <h4>
                                <span dangerouslySetInnerHTML={{
                                  __html: getChatUserName(participants, user, msg?.author),
                                }}></span>
                                &nbsp;
                              </h4>
                            )}
                            <div className="messagingService">
                              {printMessage(msg)}
                              {/* {user.email === msg.author && ( */}
                              <span className="currentMessageTime">{dateFormatShow(msg.dateCreated)}</span>
                              {/* )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </InfiniteScroll>
      </div>

      <div className="row message-input">
        <div className="col-lg-12">
          {typingData.length > 0 && (
            <div className="typing" >
              {typingInfo}
            </div>
          )}
          <div className="inputHolder">
            {fileArray.length > 0 && (
              <div className="mediaInner">
                {fileArray.map((item, index) => (
                  <span key={index} className="removeImageHolder">
                    <img
                      src="assets/img/removeMedia.svg"
                      alt=""
                      className="removeMedia"
                      onClick={() => removeMedia(index)}
                    />
                    <span key={index}>
                      <span className="addMedia" onClick={() => downloadMediaOnFly(item)}>
                        {item?.type.startsWith('image/') ? (
                          <span className="imagePreviewChat" style={{ backgroundImage: `url(${base64Files[index]})` }}></span>
                        ) : (
                          <>
                            <span className="addMediaPadding">
                              <img className="imageView" src="assets/img/imageView.svg" alt="" />
                              &nbsp;<span className="fileName">{item.name}</span>
                            </span>
                          </>
                        )}
                      </span>
                    </span>
                  </span>
                ))}
              </div>
            )}
            <div
              className={
                fileArray.length > 0
                  ? "addedMedia userListHolder"
                  : "userListHolder"
              }
            >
              <ContentEditable
                id="text"
                innerRef={textAreaRef}
                html={value} // innerHTML of the editable div
                disabled={false} // use true to disable edition
                // onChange={(event) => setValue(event.target.value)} // handle innerHTML change
                onKeyUp={handleKeyUpTrigger}
                onBlur={keepFocus}
                onChange={handleMessageKeyDownEventLocal}
                onKeyPress={handleKeyPress}
                className="textarea customScroll"
                tagName="div"
                placeholder="Write your message here..."
              />
            </div>
          </div>
        </div>

        <div className="customButtonHolder">
          <div className="col-lg-4 customButtonsEditor">
            <div className="col-lg-12">
              <div className="styleMessage">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    document.execCommand("bold", false, null);
                  }}
                  className="editText"
                >
                  <img src="assets/img/boldMessage.svg" alt="" />
                </button>

                {/* <button
                  onClick={(e) => {
                    e.preventDefault();
                    document.execCommand("italic", false, null);
                  }}
                  className="editText"
                >
                  <img src="assets/img/ItalicMessage.svg" alt="" />
                </button>

                <button
                  onClick={(e) => {
                    document.execCommand("strikethrough", false, null);
                    e.preventDefault();
                  }}
                  className="editText"
                >
                  <img src="assets/img/subMessage.svg" alt="" />
                </button> */}
              </div>
            </div>
          </div>
          <div className="col-lg-4 customButtons">
            <div className="col-lg-12" ref={overlayRef}>
              <div
                ref={mentionRef}
                id="popover-basic"
                className={`emoji-popover webkitScroll ${!mentionPopupToggle ? "collapse" : "expand"
                  }`}
              >
                <div className="">
                  <div
                    className={
                      fileArray.length > 0
                        ? "addedMedia mentionHolder customScroll"
                        : "mentionHolder customScroll"
                    }
                  >
                    <div className="mentionInner">
                      <ul>
                        {participants.map(
                          (item, index) =>
                            user.email !== item.identity && (
                              <li key={index} onClick={() => addToDiv(item, false)}>
                                <div
                                  className="image"
                                  style={{
                                    backgroundImage: `url(${getChatUserImage(
                                      participants,
                                      user,
                                      item.identity
                                    )})`,
                                  }}
                                ></div>
                                <p>{item?.attributes?.name || item?.identity}</p>
                              </li>
                            )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <button ref={mentionBtnRef} onClick={() => setMentionPopupToggle(!mentionPopupToggle)}>
                <img src="assets/img/mentionMessage.svg" alt="" />
              </button>
              {/* </OverlayTrigger> */}

              <label>
                <input type="file" hidden onChange={uploadFiles} multiple />
                <img src="assets/img/mediaShare.svg" alt="" />
              </label>
              {/* <OverlayTrigger
                placement="top"
                trigger="click"
                rootClose
                overlay={popover}
              > */}
              <div
                ref={emojiRef}
                id="popover-basic"
                className={`emoji-popover webkitScroll ${!emojiPopupToggle ? "collapse" : "expand"
                  }`}
                position="right"
              >
                <div className="">
                  <EmojiPicker onEmojiClick={(data) => addToDiv(data, true)} />
                </div>
              </div>
              <button onClick={() => setEmojiPopupToggle(!emojiPopupToggle)}>
                <img
                  src="assets/img/emojiIcon.svg"
                  className="emojiIcon"
                  alt=""
                />
              </button>
              {/* </OverlayTrigger> */}
              <button type="submit" ref={sendMessageButton} onClick={onMessageSend}>
                <img src="assets/img/sendBtn.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
