var CryptoJS = require("crypto-js");
const secretKey = '8fb8fd059514a127f3cb682e49a5e9b6a9b240c67d60570b903ba1cf72e907c0'

// Encrypt response data
export const encryptData = (data) => {
  var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  return ciphertext
}

// Decrypt request data
export const decryptData = (encryptedData) => {
  var bytes  = CryptoJS.AES.decrypt(encryptedData, secretKey);
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData
}
