import React, { useEffect } from "react";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import ProfileActivity from "./profileActivity";
import ProfileBanner from "./profileBanner";
import ProfileInfo from "./profileInfo";
import { useDispatch, useSelector } from "react-redux";
import { getUserActivityAction } from "../../actions/user";
import EditPassword from "./editPassword";

export default function Profile() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  useEffect(() =>{
    if(user){
      dispatch(getUserActivityAction())
    }
  }, [user])

  return (
    <>
      <Header title="Profile" />
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-lg-3 col-xl-3 col-md-12 col-sm-12 mb-2"></div>
          <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 mb-2">
            <div className="card h-100">
              <EditPassword/>
            </div>
          </div>
          <div className="col-lg-3 col-xl-3 col-md-12 col-sm-12 mb-2"></div>
        </div>
      </div>
    </>
  );
}
