import React from "react";
import {PuffLoader} from 'react-spinners';

export default function Loader() {
  return (
    <div className="loader-spinner">
      <PuffLoader color="#f3c258" />
    </div>
  );
}
