import React, { useState } from "react";
import { Link } from "react-router-dom";
import CardModal from "./cardModal";
import moment from "moment";
import { TASK_STATUS_ENUM } from "../../constants";

export default function TaskCards({ item, editItem }) {
  const [taskCardShow, setTaskCardShow] = useState(false);
  const taskCardClose = () => {
    setTaskCardShow(false);
  };

  const handleTaskCardShow = () => setTaskCardShow(true);
  return (
    <>
      <div className="card-body cursor-pointer" onClick={handleTaskCardShow}>
        <h5 className="mb-0 ">{item?.title}</h5>
        <Link to="" className="cursor-pointer">
          <i
            className="fa fa-ellipsis-v text-secondary cursor-point"
            aria-hidden="true"
          ></i>
        </Link>

        <div className="text-status">
          <p>Status</p>
          <span className={item.status === TASK_STATUS_ENUM.BACKLOG ? 'active' : item.status === TASK_STATUS_ENUM.INPROGRESS ? 'inprogress' : 'complete'}>{item.status === TASK_STATUS_ENUM.BACKLOG ? 'BACKLOG' : item.status === TASK_STATUS_ENUM.INPROGRESS ? 'In Progress' : 'Complete'}</span>
        </div>
        <div className="text-status">
          <p>Progress</p>
          <span className="">{item.progress}%</span>
          <div
            className="progress-bar progress-bar-text task-progress-color"
            role="progressbar"
            aria-valuenow={item.progress}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: item.progress + "%" }}
          ></div>
        </div>
        <div className="text-status">
          <p>Due Date</p>
          <span className="font-weight-bolder payment-text">{moment(item?.dueDate).format('MM/DD/YYYY')}</span>
        </div>
        <div className="text-status">
          <p>{item.attachments && item.attachments.length > 0 ? item.attachments.length : 'No'} Attachments</p>
        </div>
      </div>
      <CardModal item={item} editItem={editItem} taskCardShow={taskCardShow} taskCardClose={taskCardClose} />
    </>
  );
}
