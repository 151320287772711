import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { updateBusinessMeetingAction } from "../../actions/bookOfBusiness";
import { useEffect } from "react";
import { TZ } from "../../constants";
import { useCallback } from "react";

export default function ConfirmMeeting({
  meetingOpen,
  meetingClose,
  handleConnect,
  item
}) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const [rescheduleMeeting, setRescheduleMeeting] = useState(false);

  const setFieldValues = useCallback(() => {
    setValue('location', item?.eventDetails?.location || "")
    setValue('description', item?.eventDetails?.description || "")
    setValue('eventDate', item?.eventDetails?.date ? new Date(item?.eventDetails?.date) : "")
    if (item?.eventDetails?.location && item?.eventDetails?.date && !rescheduleMeeting) {
      setRescheduleMeeting(true)
    }
  }, [item, rescheduleMeeting, setValue]);

  useEffect(() => {
    const callEvent = () => {
      if (meetingOpen) {
        setFieldValues();
      }
    }
    callEvent()
  }, [meetingOpen, setFieldValues])


  


  const onSubmit = async (values) => {
    const payload = {
      "date": values.eventDate,
      "location": values.location,
      "description": values.description
    }

    if (item?.company?._id && item?.id) {
      await dispatch(updateBusinessMeetingAction(item?.company?._id, item?.id, payload))
      reset();
      meetingClose();
    }
  }

  return (
    <Modal
      show={meetingOpen}
      onHide={() => { meetingClose(); setRescheduleMeeting(false) }}
      className="moddal-primary importModal confirmMeeting ps-0"
    >
      <Modal.Body>
        <div className="header">
          <div>
            <h4>{rescheduleMeeting ? "Reschedule meeting" : "Schedule meeting"}</h4>
          </div>
          <i
            onClick={() => { meetingClose(); setRescheduleMeeting(false) }}
            className="fa-solid fa-xmark"
          ></i>
        </div>

        <div className="modalBody importSampleFile">
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="note">
              <p>&nbsp;</p>
              <div className="row">
                <div className="col-md-12">
                  <label className="input-text heading">Date/Time</label>

                  <Controller
                    control={control}
                    className="form-control"
                    name="eventDate"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        className="form-control"
                        {...field}
                        selected={field.value}
                        placeholderText="dd/mm/yyyy hh:mm"
                        onChange={(date) => field.onChange(date)}
                        showTimeSelect
                        timeFormat="hh:mm aa"
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="dd/MM/yyyy hh:mm aa"
                        timeZone={TZ}
                      />)}
                  />

                  {/* <Controller
                    control={control}
                    className="form-control"
                    name="eventDate"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        className="form-control"
                        {...field}
                        placeholderText="dd/mm/yyyy"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                        dateFormat="dd/MM/yyyy"
                      //   minDate={new Date()}
                      />
                    )}
                  /> */}
                  {errors.eventDate?.type === "required" && (
                    <span className="errorMessage">Date is required</span>
                  )}
                </div>
                {/* <div className="col-md-5">
                  <label className="input-text heading">Time</label>
                  <Controller
                    control={control}
                    className="form-control"
                    name="eventTime"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TimePicker {...field} />
                    )}
                  />
                  {errors.eventTime?.type === "required" && (
                    <span className="errorMessage">Time is required</span>
                  )}
                </div> */}

                <div className="col-lg-12 mt-3">
                  <div className="form-group">
                    <label className="input-text heading">Location</label>
                    <input
                      maxLength={500}
                      autoComplete="off"
                      type="text"
                      className="form-control"
                      placeholder="Location"
                      id="recipient-name"
                      {...register("location", {
                        required: true,
                        maxLength: 500,
                        minLength: 3,
                      })}
                    />
                    {errors.location?.type === "required" && (
                      <span className="errorMessage">
                        Location is required
                      </span>
                    )}
                    {errors.location?.type === "maxLength" && (
                      <span className="errorMessage">
                        Location may not be longer than 20 characters
                      </span>
                    )}
                    {errors.location?.type === "minLength" && (
                      <span className="errorMessage">
                        Minimum 3 characters required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-12 mt-3">
                  <div className="form-group">
                    <label className="input-text heading">Description</label>
                    <textarea
                      rows={4}
                      cols={50}
                      maxLength={500}
                      autoComplete="off"
                      type="text"
                      className="form-control"
                      placeholder="Description"
                      {...register("description", { required: true })}
                    />
                    {errors.description?.type === "required" && (
                      <span className="errorMessage">
                        Description is required
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="importBtn">
              <button
                type="submit"
                className={`customAddButton removePadding ${rescheduleMeeting ? 'highlightBorder' : ''}`}
              >
                {rescheduleMeeting ? "Reschedule meeting" : "Schedule meeting"}
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
