import axios from "axios";
import { IS_LOCAL, backendUrl } from "./constants";
import { decryptData, encryptData } from "./crypto";

const axionIns = axios.create({
  baseURL: backendUrl+"api/",
  headers: { 
    "Content-Type": "application/json",
  },
});
// Set the AUTH token for any request
axionIns.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token') ? localStorage.getItem('token') : null;
  config.headers['access-token'] = token;
  if (!IS_LOCAL && !(config.data instanceof FormData)) {
    if(config.data && Object.keys(config.data).length > 0){
      config.data = { eData: encryptData(config?.data) }
    }
  }
  return config;
});

axionIns.interceptors.response.use(
  (response) => {
    if('eData' in response?.data){
      const decryptDataResp = decryptData(response?.data?.eData)
      response.data = decryptDataResp
    }
    return response; // Return the response unchanged
  },
  (error) => {
    return Promise.reject(error); // Reject the promise with the error
  }
);

export default axionIns;
