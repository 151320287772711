import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_SUCCESS_MESSAGE,
  SET_FAILED_MESSAGE,
  CHANGE_PERMISSION_UPDATES,
  TWO_FACTOR_LOGIN_SUCCESS,
  TOGGLE_MOBILE_MENU,
  TOGGLE_MENU_SIDEBAR
} from "./types";
import AuthService from "../services/auth.service";
import { startLoaderAction, stopLoaderAction } from "./updateReducerValue";
import { secretPass } from "../constants";
import CryptoJS from "crypto-js";

export const signup = (payload) => (dispatch) => {
  return AuthService.signup(payload).then(
    (resp) => {
      if (resp.status) {
        dispatch(setLogin(resp.data))
      } else {
        dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data, });
      }
      return Promise.resolve();
    },
    // (error) => {
    //   const message =
    //     (error.response &&
    //       error.response.data &&
    //       error.response.data.message) ||
    //     error.message ||
    //     error.toString();
    //   dispatch({
    //     type: REGISTER_FAIL,
    //   });
    //   dispatch({
    //     type: SET_FAILED_MESSAGE,
    //     payload: message,
    //   });
    //   return Promise.reject();
    // }
  );
};

export const login = (payload) => (dispatch) => {
  dispatch(startLoaderAction());
  return AuthService.login(payload).then(
    (resp) => {
      if (resp.status) {
        if (resp.data.twoFactor) {
          dispatch({ type: TWO_FACTOR_LOGIN_SUCCESS, payload: resp.data })
        } else {
          dispatch(setLogin(resp.data))
        }
        setLocalStorageRememberMe(payload);
      } else {
        dispatch({ type: LOGIN_FAIL, });
        dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data, });
      }
      dispatch(stopLoaderAction());
      return Promise.resolve();
    },
    // (error) => {
    //   const message =
    //     (error.response &&
    //       error.response.data &&
    //       error.response.data.message) ||
    //     error.message ||
    //     error.toString();
    //   dispatch({
    //     type: LOGIN_FAIL,
    //   });
    //   dispatch({
    //     type: SET_FAILED_MESSAGE,
    //     payload: message,
    //   });
    //   return Promise.reject();
    // }
  );
};

export const socialLogin = (payload) => (dispatch) => {
  return AuthService.socialLogin(payload).then(
    (resp) => {
      if (resp.status) {
        if (resp.data.twoFactor) {
          dispatch({ type: TWO_FACTOR_LOGIN_SUCCESS, payload: resp.data })
        } else {
          dispatch(setLogin(resp.data))
        }
      } else {
        dispatch({ type: LOGIN_FAIL, });
        dispatch({ type: SET_FAILED_MESSAGE, payload: "Something wen't wrong, please try again later.", });
      }
      return Promise.resolve();
    },
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout().then((resp) => {
    dispatch({ type: LOGOUT, });
    dispatch({ type: CHANGE_PERMISSION_UPDATES, payload: false })
    dispatch({
      type: SET_SUCCESS_MESSAGE,
      payload: "Logout successfully",
    });
  });
};

export const verifiedOTP = (resp) => (dispatch) => {
  dispatch(setLogin(resp))
}

const setLocalStorage = (data) => {
  if (data && data.token) {
    localStorage.setItem("kimbocorp", JSON.stringify(data));
    localStorage.setItem("token", data.token);
  }
}

const setLocalStorageRememberMe = (payload) => {
  if (payload.rememberme) {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(payload.password),
      secretPass
    ).toString();
    payload.password = data;
    localStorage.setItem("credential", JSON.stringify({ payload }));
  } else
    localStorage.removeItem("credential");
}

const setLogin = (data) => (dispatch) => {
  if (data && data.token) {
    setLocalStorage(data)
    dispatch({ type: LOGIN_SUCCESS, payload: { user: data }, });
    dispatch({ type: SET_SUCCESS_MESSAGE, payload: "Login successfully", });
  }
}

export const toggleMenuSidebar = (value) => async (dispatch) => {
  dispatch({ type: TOGGLE_MENU_SIDEBAR, payload: value });
};

export const toggleMobileMenu = () => async (dispatch) => {
  dispatch({ type: TOGGLE_MOBILE_MENU });
};
