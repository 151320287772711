import React from "react";
import { Modal } from "react-bootstrap";
import { I_POP_OVER } from "../../constants";

export default function InfoModalStatic({
  infoModalStaticShow,
  infoModalStaticClose,
  infoModalStaicShowType,
}) {
  return (
    <>
      <Modal
        show={infoModalStaticShow}
        onHide={infoModalStaticClose}
        className="custom-modal info-modal ps-0"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
      {infoModalStaicShowType === I_POP_OVER.BUSINESS_DETAIL ? (
        <>
          <div className="modal-header px-0 pt-0">
            <h5 className="edit-title">Business Details</h5>
            <button
              type="button"
              className="btn-close close-button text-dark"
              onClick={infoModalStaticClose}
            ></button>
          </div>
          <div className="modal-body">
            <p className="edit-description">
              Details shown here are extracted from myinfo business or provided to you after your business is formalised.
            </p>
          </div>
        </>
      ) :  infoModalStaicShowType === I_POP_OVER.BOOK_OF_BUSINESS_SUGGESTED_POTENTIAL_PURPOSE ? (
        <>
          <div className="modal-header px-0 pt-0">
            <h5 className="edit-title"></h5>
            <button
              type="button"
              className="btn-close close-button text-dark"
              onClick={infoModalStaticClose}
            ></button>
          </div>
          <div className="modal-body">
            <p className="edit-description">
              You can suggest this profile for any of the below functions
            </p>
            <ol className="edit-description">
              <li>Potential customer - someone whom you believe can be customer for your business</li>
              <li>Potential investor - someone whom you believe can be an investor for your business</li>
              <li>Potential director - someone whom you believe can be a director for your business</li>
              <li>Potential employee - someone whom you believe can be an employee for your business</li>
              <li>Potential vendor or supplier - someone whom you believe can supply products or services to your business</li>
            </ol>
          </div>
        </>
      ) : ""}

        <div className="modal-footer modal-bottom-action align-text-left">
          <button
            onClick={infoModalStaticClose}
            type="button"
            className="btn agree-button"
          >
            Got it
          </button>
        </div>
      </Modal>
    </>
  );
}
