import { GET_PAYMENTS } from "../actions/types";
const initialState = {
  payments: [],
};
export default function (state = initialState, action) {
  const { type, payload, page, count } = action;
  switch (type) {
    case GET_PAYMENTS:
      // var data = [];
      // if(page===1){
      //   data = [...payload]
      // }else{
      //   if(payload.length>0){
      //     data = [...state.payments, ...payload]
      //   }else{
      //     data = [...state.payments]
      //   }
      // }
      return {
        ...state,
        payments: payload,
        count: count
      };
    default:
      return state;
  }
}
