import { GET_BUSINESS_LIST, SET_ALL_COMPANY_APPOINTED_LIST } from "../actions/types";
const initialState = {
  businessInfo: [],
  count: 0,
  allAppointedList: []
};
export default function (state = initialState, action) {
  const { type, payload, count } = action;
  switch (type) {
    case GET_BUSINESS_LIST:
      return {
        ...state,
        businessInfo: payload,
        count: count,
      };

    case SET_ALL_COMPANY_APPOINTED_LIST:
      return {
        ...state,
        allAppointedList: payload
      };

    default:
      return state;
  }
}
