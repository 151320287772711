import businessServices from "../services/businessInfo.service";
import {
  GET_BUSINESS_LIST,
  SET_ALL_COMPANY_APPOINTED_LIST,
  SET_FAILED_MESSAGE,
  SET_SUCCESS_MESSAGE,
  START_LOADER,
  STOP_LOADER,
} from "./types";
export const getBusinessAction =
  (
    page,
    perPage,
    search,
    planFilter,
    callToAction,
    isRegister,
    startDate,
    endDate,
    tangibleAsset,
    financialObjective,
    verificationMethod
  ) =>
  (dispatch) => {
    dispatch({ type: START_LOADER });
    return businessServices
      .getBusinessInfo(
        page,
        perPage,
        search,
        planFilter,
        callToAction,
        isRegister,
        startDate,
        endDate,
        tangibleAsset,
        financialObjective,
        verificationMethod
      )
      .then((resp) => {
        if (resp.status) {
          dispatch({
            type: GET_BUSINESS_LIST,
            payload: resp.data,
            count: resp.count,
          });
        } else {
          dispatch({ type: GET_BUSINESS_LIST, payload: [] });
        }
        dispatch({ type: STOP_LOADER });
      });
  };

export const resetBusinessList = () => (dispatch) => {
  dispatch({ type: GET_BUSINESS_LIST, payload: [] });
}

export const editCompanyAction = (companyId, id, value, filterBody = null) => async (dispatch) => {
  dispatch({ type: START_LOADER });
  return businessServices
    .editCompanyNotification(companyId, id, value)
    .then((resp) => {
      if (resp.status) {
        dispatch({
          type: SET_SUCCESS_MESSAGE,
          payload: resp.data,
        });
        dispatch(filterGetBusinessAction(filterBody));
      } else {
        dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
      }
      dispatch({ type: STOP_LOADER });
    });
};

export const editCompanyPosition = (id, payloadId, filterBody = null) => async (dispatch) => {
  dispatch({ type: START_LOADER });
  return businessServices.editCompanyPosition(id, payloadId).then((resp) => {
    if (resp.status) {
      dispatch({
        type: SET_SUCCESS_MESSAGE,
        payload: resp.data,
      });
      dispatch(filterGetBusinessAction(filterBody));
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
    dispatch({ type: STOP_LOADER });
  });
};

export const updateBusinessInfo = (payload, filterBody) => async (dispatch) => {
  dispatch({ type: START_LOADER });
  return businessServices.updatebusinessInfo(payload).then((resp) => {
    if (resp.status) {
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
      dispatch(filterGetBusinessAction(filterBody));
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
    dispatch({ type: STOP_LOADER });
  });
};


export const getAllAppointedListAction = () => async (dispatch) => {
  return businessServices.getAllCompanyAppointedList().then(async (resp) => {
    dispatch({ type: SET_ALL_COMPANY_APPOINTED_LIST, payload: resp.data });
  })
}


export const updateBobCtaAction = (companyId, payload, filterBody = null) => (dispatch) => {
  dispatch({ type: START_LOADER });
  return businessServices.updateBobCta(companyId, payload).then((resp) => {
    if (resp.status) {
      dispatch({
        type: SET_SUCCESS_MESSAGE,
        payload: resp.data,
      });
      dispatch(filterGetBusinessAction(filterBody));
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
    dispatch({ type: STOP_LOADER });
  });
};


export const filterGetBusinessAction = (filterBody) => (dispatch) => {
  if(filterBody){
    dispatch(getBusinessAction(filterBody.page, filterBody.limit, filterBody.search, filterBody.planFilter, filterBody.callToAction, filterBody.isRegister, filterBody.startDate, filterBody.endDate, filterBody.tangibleAsset, filterBody.financialObjective, filterBody.verificationMethod));
  }
}