import axionIns from "../axios";

const getNotification = (page, perPage, isActive, color, search) => {
  var url = "admin/notification?page=" + page + "&perPage=" + perPage + "&isActive=" + isActive + "&color=" + color + "&search=" + search;
  return axionIns.get(url).then((response) => {
    return response.data;
  });
};
const addNotification = (payload) => {
  return axionIns
    .post("admin/notification", JSON.stringify(payload))
    .then((response) => {
      return response.data;
    });
};
const editNotification = (payload, id) => {
  return axionIns
    .put(`admin/editnotification/${id}`, JSON.stringify(payload))
    .then((response) => {
      return response.data;
    });
};
const getNotificationById = (id) => {
  return axionIns.get(`admin/notification/${id}`).then((response) => {
    return response.data;
  });
};
let exportObj = {
  addNotification,
  getNotification,
  editNotification,
  getNotificationById,
};

export default exportObj;
