import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { signup, socialLogin } from "../../actions/auth";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  getIdToken,
} from "firebase/auth";
import { Link } from "react-router-dom";

export default function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(signup(data))
      .then(() => {
        navigate("/");
      })
      .catch(() => {});
  };
  const googleLogin = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        getIdToken(result.user).then((token) => {
          dispatch(socialLogin(token))
            .then(() => {
              navigate("/dashboard");
            })
            .catch(() => {});
        });
      })
      .catch((error) => {});
  };
  return (
    <>
      <section className="min-vh-100 mb-8">
        <div
          className="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
          style={{ backgroundImage: "url(../assets/img/Mask.png)" }}
        >
          <span className="mask  opacity-6"></span>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 text-center mx-auto">
                <h1 className="text-white mb-2 mt-5">Sign Up !</h1>
                <p className="text-lead text-white"></p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mt-lg-n10 mt-md-n11 mt-n10">
            <div className="col-xl-5 col-lg-5 col-md-7 mx-auto">
              <div className="card z-index-0">
                <div className="card-header text-center pt-4">
                  <h5>Register with</h5>
                </div>
                <div className="row text-center mx-auto">
                  <div className="col-lg-12">
                    <div className="text-center">
                      <button
                        type="button"
                        onClick={googleLogin}
                        className="btn w-100 mt-4 mb-0 google-login"
                      >
                        Continue with Google{" "}
                        <img src="assets/img/google-logo.svg" alt="" />
                      </button>
                    </div>
                  </div>
                  <div className="mt-2 position-relative text-center">
                    <p className="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3">
                      or
                    </p>
                  </div>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <input
                            maxLength={50}
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            {...register("first_name", {
                              required: true,
                              maxLength: 20,
                              minLength: 3,
                            })}
                          />
                          {errors.first_name?.type === "required" && (
                            <span className="errorMessage">
                              First Name is required
                            </span>
                          )}
                          {errors.first_name?.type === "maxLength" && (
                            <span className="errorMessage">
                              First Name may not be longer than 20 characters
                            </span>
                          )}
                          {errors.first_name?.type === "minLength" && (
                            <span className="errorMessage">
                              Minimum 3 characters required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <input
                            maxLength={50}
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            {...register("last_name", { required: true })}
                          />
                          {errors.last_name?.type === "required" && (
                            <span className="errorMessage">
                              Last Name is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        {...register("email", {
                          required: true,
                          pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                        })}
                      />
                      {errors.email?.type === "required" && (
                        <span className="errorMessage">Email is required</span>
                      )}
                      {errors.email?.type === "pattern" && (
                        <span className="errorMessage">Email is Invalid</span>
                      )}
                    </div>
                    <div className="mb-3">
                      <input
                        maxLength={50}
                        autoComplete="off"
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        {...register("password", {
                          required: true,
                          minLength: 8,
                        })}
                      />
                      {errors.password?.type === "required" && (
                        <span className="errorMessage">
                          Password is required
                        </span>
                      )}
                      {errors.password?.type === "minLength" && (
                        <span className="errorMessage">
                          Password must be atleast 8 characters long{" "}
                        </span>
                      )}
                    </div>
                    <div className="form-check form-check-info text-left">
                      <input
                        maxLength={50}
                        autoComplete="off"
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        {...register("check", {
                          required: true,
                        })}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        I agree the{" "}
                        <span className="text-dark font-weight-bolder">
                          Terms and Conditions
                        </span>
                      </label>
                    </div>
                    {errors.check?.type === "required" && (
                      <span className="errorMessage">
                        Accept the terms and conditions
                      </span>
                    )}
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn bg-gradient-dark w-100 my-4 mb-2"
                      >
                        Sign up
                      </button>
                    </div>
                    <p className="text-sm mt-3 mb-0">
                      Already have an account?{" "}
                      <Link to="/" className="text-dark font-weight-bolder">
                        Sign In
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
