import axionIns from "../axios";

const addBusinessProfile = async (payload, id) => {
  return axionIns
    .post(`admin/bob/${id}`, JSON.stringify(payload))
    .then((response) => {
      return response.data;
    });
};

const editBusinessProfile = async (payload, id, businessId) => {
  return axionIns
    .put(`admin/bob/${id}/${businessId}`, JSON.stringify(payload))
    .then((response) => {
      return response.data;
    });
};

const getBusinessProfile = async (page, limit, company, status, searchBookOfBusinessGroup, searchBookOfBusinessService) => {
  return axionIns
    .get(
      `admin/bob/?page=${page}&perPage=${limit ? limit : 25}&company=${
        company ? company._id : ""
      }&status=${status}&searchBookOfBusinessGroup=${searchBookOfBusinessGroup ? searchBookOfBusinessGroup : ""}&searchBookOfBusinessService=${searchBookOfBusinessService ? searchBookOfBusinessService : ""}`
    )
    .then((response) => {
      return response.data;
    });
};

// const importBusinessProfile = async (payload) => {
//   return axionIns
//     .post(`file/admin/bob/importcsv`, JSON.stringify(payload))
//     .then((response) => {
//       return response.data;
//     });
// };

const importBusinessProfile = async (payload, id, onProgress, bookOfBusinessGroupId) => {
  let progress = 0;
  const config = {
    onUploadProgress: (progressEvent) => {
      progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      onProgress(progress); // Call the callback function with progress
    },
  };

  return new Promise((resolve, reject) => {
    axionIns
      .post(`admin/file/bob/importcsv/${id}/${bookOfBusinessGroupId}`, payload, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateBusinessProfile = async (companyId, id, payload) => {
  return axionIns
    .put(`admin/bob/status/${companyId}/${id}`, JSON.stringify(payload))
    .then((response) => {
      return response.data;
    });
};


const updateMeetingDetails = async (companyId, id, payload) => {
  return axionIns
    .put(`admin/bob/updateMeetingDetails/${companyId}/${id}`, JSON.stringify(payload))
    .then((response) => {
      return response.data;
    });
};



// Group
const addBusinessGroup = async (payload) => {
  return axionIns
    .post(`admin/bob_group`, JSON.stringify(payload))
    .then((response) => {
      return response.data;
    });
};

const editBusinessGroup = async (payload, id) => {
  return axionIns
    .put(`admin/bob_group/${id}`, JSON.stringify(payload))
    .then((response) => {
      return response.data;
    });
};

const getBusinessGroup = async (search, type = null, searchBookOfBusinessService) => {
  let url = `admin/bob_group/?search=${search}&searchBookOfBusinessService=${searchBookOfBusinessService ? searchBookOfBusinessService : ""}`
  if(type){
    url = `${url}&type=${type}`; 
  }
  return axionIns
    .get(url)
    .then((response) => {
      return response.data;
    });
};


const exportOject = {
  addBusinessProfile,
  editBusinessProfile,
  getBusinessProfile,
  importBusinessProfile,
  updateBusinessProfile,
  updateMeetingDetails,
  addBusinessGroup,
  editBusinessGroup,
  getBusinessGroup,
};

export default exportOject;
