import React, { useEffect } from "react";
import Header from "../../components/header/header";
import { useDispatch, useSelector } from "react-redux";
import ProfileActivity from "../profile/profileActivity";
import { getDashboardAction } from "../../actions/dashboard";
import { getActivityLogsAction } from "../../actions/activityLogs";
import { useRef } from "react";
import { RoutesPaths } from "../../Router/constant";
import { Link } from "react-router-dom";


export default function Dashboard() {
  const dispatch = useDispatch();
  const { dashboard } = useSelector((state) => state.dashboard);
  const { activityLogs } = useSelector((state) => state.activityLogs);
  const loadRef = useRef(true);

  useEffect(() => {
    dispatch(getDashboardAction());
    if (loadRef.current) {
      dispatch(getActivityLogsAction(1, 5));
    }
    return () => {
      loadRef.current = false
    }
  }, [dispatch]);

  return (
    <>
      <Header title="Dashboard" />
      <div className="container-fluid py-4 container-top">
        {/* <div className="row">
          <div className="col-md-12 mx-auto">
            <DashboardCarousel />
          </div>
        </div> */}
        <div className="row my-0 my-lg-4">
          <div className="col-lg-12 col-xl-12 col-md-12 mb-md-0 mb-4">
            <div className="row">
              <div className="col-xl-4 col-sm-6 mb-xl-0 mb-4">
                <div className="card">
                  <div className="card-body p-3">
                    <Link to={RoutesPaths.BUSINESSDETAIL}>
                      <div className="row">
                        <div className="col-12">
                          <div className="numbers">
                            <p className="text-sm mb-0 font-weight-bold">
                              Company account
                            </p>
                            <h5 className="mb-0">
                              {dashboard && dashboard.company
                                ? dashboard.company
                                : 0}
                            </h5>
                          </div>
                        </div>
                        <div className="col-12">
                          <p className="card-arrow-right">
                            &nbsp;
                            <img
                              src="assets/img/Arrow-right.svg"
                              alt="Arrow-right.svg"
                            />
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6 mb-xl-0 mb-4">
                <div className="card">
                  <div className="card-body p-3">
                    <Link to={RoutesPaths.INVITE}>
                      <div className="row">
                        <div className="col-12">
                          <div className="numbers">
                            <p className="text-sm mb-0 font-weight-bold">
                              Pending requests
                            </p>
                            <h5 className="mb-0">
                              {dashboard && dashboard.requestedLogs
                                ? dashboard.requestedLogs
                                : 0}
                            </h5>
                          </div>
                        </div>
                        <div className="col-12">
                          <p className="card-arrow-right">
                            <img
                              src="assets/img/Arrow-right.svg"
                              alt="Arrow-right.svg"
                            />
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6 mb-xl-0 mb-4">
                <div className="card">
                  <div className="card-body p-3">
                    <Link to={RoutesPaths.PAYMENT}>
                      <div className="row">
                        <div className="col-12">
                          <div className="numbers">
                            <p className="text-sm mb-0 font-weight-bold">
                              Upgraded account
                            </p>
                            <h5 className="mb-0">
                              {dashboard && dashboard.paidAccount
                                ? dashboard.paidAccount
                                : 0}
                            </h5>
                          </div>
                        </div>
                        <div className="col-12">
                          <p className="card-arrow-right">
                            <img
                              src="assets/img/Arrow-right.svg"
                              alt="Arrow-right.svg"
                            />
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 py-4">
                <div className="card h-100">
                  <ProfileActivity activity={activityLogs} />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-xl-4 mt-lg-4 mt-md-4 mt-xl-0 mt-4">
            {/* <Banner /> */}

            {/* <Notification /> */}

          </div>
        </div>
      </div>
    </>
  );
}
