import moment from "moment";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Editcompany from "../../components/addBussiness/Editcompanymodel";
import EditShareholder from "../../components/addBussiness/Editshareholdermodel";
import Editofficermodel from "../../components/addBussiness/Editofficermodel";
import EditProfile from "../../components/addBussiness/Editprofilemodel";
import { RoutesPaths } from "../../Router/constant";
import Sharecapital from "../../components/addBussiness/Sharecapitalmodel";
import EditsubscriptionCancellationmodel from "../../components/addBussiness/EditsubscriptionCancellationmodel";
import Editpromotermodel from "../../components/addBussiness/Editpromotermodel";
import Promoterappointmodel from "../../components/addBussiness/Promoterappointmodel";
import { Dropdown, DropdownButton } from "react-bootstrap";
import TimeFormat from "../../components/timeFormat/timeFormat";

export default function ProfileActivity({ activity, fullView, pageStatus, sortByStatus, pageStatusOption }) {
  const [show, setShow] = useState(false);
  const [pageItem, setPageItem] = useState(null);

  const handleModal = (item) => {
    setPageItem(item);
    setShow(true);
  };
  return (
    <>
      <div className="card-header pb-0 px-3">
        <div className="row">
          <div className={` ${fullView ? 'requestHeader col-md-12 col-12' : "col-md-6 col-6"}`}>
            <h6 className="font-weight-bolder payment-text mb-0">
              Latest requests
            </h6>
            {fullView &&
              <div className="filter">
                <div className="dropdownButton">
                  <img src="assets/img/downArrow.svg" alt="" />
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={
                      pageStatus?.title === "" ? "Search by type" : pageStatus?.title
                    }
                  >
                    {pageStatusOption?.map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => sortByStatus(item)}
                      >
                        {item.title}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </div>
              </div>
            }
          </div>

          <div className="col-md-6 col-6 text-end">
            {!fullView && activity.length > 0 && (
              <NavLink to={RoutesPaths.INVITE}>
                <button className="btn btn-sm view-all-button mb-4 mt-3">
                  View all
                </button>
              </NavLink>
            )}
          </div>
          {/* <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div className="btn-group dropup">
              <Dropdown>
                <Dropdown.Toggle className="btn payment-text-invoice">
                  <h6 className="payment-text-invoice mb-1  text-sm padding-left-lastest ">
                    Latest
                  </h6>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    Another action
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    Something else
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div> */}
        </div>
      </div >

      <div className="table-responsive">
        <div
          className={`card-body p-3 table-store ${fullView ? "fullView" : "notfullView"
            }`}
        >
          {fullView && (
            <div className="row mt-3 font-weight-bold text-dark">
              <div className="col-lg-3 col-xl-3 col-3">Request type</div>
              <div className="col-lg-3 col-xl-3 col-3">Company name</div>
              <div className="col-lg-2 col-xl-2 col-2">Requester</div>
              {/* <div className="col-lg-2 col-xl-2 col-2">Requester Position</div> */}
              <div className="col-lg-2 col-xl-2 col-2">Status</div>
              <div className="col-lg-2 col-xl-2 col-2">Action</div>
            </div>
          )}
          {!fullView && activity && activity.length > 0 ? (
            activity.map(
              (item, index) =>
                item.pageType && (
                  <div key={index} className="row mt-3">
                    <div className="col-lg-5 col-xl-5  col-5">
                      <div className="d-flex flex-column">
                        <h6 className="profile-text text-sm ">
                          {item.description}
                        </h6>
                        <span className="text-xs">
                          <TimeFormat time={item.createdAt} />
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-xl-3  col-3">
                      <div className=" align-items-center">
                        <h6 className="profile-text text-sm">{item.page}</h6>
                      </div>
                    </div>
                    <div className="col-lg-4 col-xl-4  col-4">
                      <div className="align-items-center">
                        {(item.status === 1 || item.status === 2) && (
                          <h6
                            className={`profile-text profile-status text-sm ${item.status === 1
                              ? "pending-status"
                              : item.status === 2
                                ? "verified-status"
                                : "denied-status"
                              }`}
                          >
                            <NavLink to={RoutesPaths.INVITE}>
                              <button className="btn btn-sm view-button mb-4 mt-3">
                                {item.status === 1
                                  ? "Pending Verification"
                                  : item.status === 2
                                    ? "Completed"
                                    : "Rejected"}
                              </button>
                            </NavLink>
                          </h6>
                        )}
                      </div>
                    </div>
                  </div>
                )
            )
          ) : fullView && activity && activity.length > 0 ? (
            activity.map(
              (item, index) =>
                item.pageType && (
                  <div key={index} className="row mt-3 align-items-center">
                    <div className="col-lg-3 col-xl-3  col-3">
                      <div className="d-flex flex-column">
                        <h6 className="profile-text text-sm">
                          {item.description}
                        </h6>
                        <span className="text-xs">
                          <TimeFormat time={item.createdAt} />
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-xl-3  col-3">
                      <div className=" align-items-center">
                        <h6 className="profile-text text-sm">
                          {item.company && item.company?.companyName
                            ? item.company?.companyName
                            : ""}
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-2 col-xl-2  col-2">
                      <div className="d-flex align-items-center">
                        <h6 className="profile-text text-sm Overflow">
                          {item.user
                            ? item.user.first_name + " " + item.user.last_name
                            : "-"}
                        </h6>
                      </div>
                    </div>
                    {/* <div className="col-lg-2 col-xl-2  col-2">
                <div className="d-flex align-items-center">
                  <h6 className="profile-text text-sm Overflow">
                    {item.userId}
                  </h6>
                </div>
              </div> */}
                    <div className="col-lg-2 col-xl-2  col-2">
                      <div className="d-flex align-items-center">
                        <h6
                          className={`profile-text text-sm ${item.status === 1
                            ? "pending-status"
                            : item.status === 2
                              ? "verified-status"
                              : "denied-status"
                            }`}
                        >
                          {item.status === 1
                            ? "Pending Verification"
                            : item.status === 2
                              ? "Verified"
                              : "Denied"}
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-2 col-xl-2  col-2">
                      <div className="d-flex align-items-center">
                        {(item.status === 1 || item.status === 2) && (
                          <h6
                            className={`profile-text text-sm ${item.status === 1
                              ? "pending-status"
                              : item.status === 2
                                ? "verified-status"
                                : "denied-status"
                              }`}
                          >
                            <button
                              className="btn btn-sm view-button mb-4 mt-3"
                              onClick={() => handleModal(item)}
                            >
                              View details
                            </button>
                          </h6>
                        )}
                      </div>
                    </div>
                  </div>
                )
            )
          ) : (
            <div className="d-flex pt-3">
              <div className="d-flex flex-column justify-content-center ">
                <h6 className="mb-0 text-sm table-heading text-center">
                  No Requests Log
                </h6>
              </div>
            </div>
          )}
        </div>
      </div>
      {
        pageItem &&
          pageItem.pageType === "promoter" &&
          pageItem.type === "request_appoint" ? (
          <Promoterappointmodel
            addModalShow={show}
            addModalClose={() => setShow(false)}
            item={pageItem}
          />
        ) : pageItem && pageItem.pageType === "company" ? (
          <Editcompany
            addModalShow={show}
            addModalClose={() => setShow(false)}
            item={pageItem}
          />
        ) : pageItem &&
          pageItem.pageType === "shareholder" &&
          pageItem.type !== "remove_invite" ? (
          <EditShareholder
            addModalShow={show}
            addModalClose={() => setShow(false)}
            item={pageItem}
          />
        ) : pageItem &&
          pageItem.pageType === "officer" &&
          pageItem.type !== "remove_invite" ? (
          <Editofficermodel
            addModalShow={show}
            addModalClose={() => setShow(false)}
            item={pageItem}
          />
        ) : pageItem && pageItem.pageType === "capital" ? (
          <Sharecapital
            addModalShow={show}
            addModalClose={() => setShow(false)}
            item={pageItem}
          />
        ) : pageItem && pageItem.pageType === "profile" ? (
          <EditProfile
            addModalShow={show}
            addModalClose={() => setShow(false)}
            item={pageItem}
          />
        ) : pageItem &&
          pageItem.pageType === "subscription" &&
          pageItem.type === "cancel_subscription" ? (
          <EditsubscriptionCancellationmodel
            addModalShow={show}
            addModalClose={() => setShow(false)}
            item={pageItem}
          />
        ) : (pageItem &&
          pageItem.pageType === "promoter" &&
          pageItem.type === "remove_promoter") ||
          (pageItem &&
            pageItem.pageType === "promoter" &&
            pageItem.type === "remove_invite") ||
          (pageItem &&
            pageItem.pageType === "shareholder" &&
            pageItem.type === "remove_invite") ||
          (pageItem &&
            pageItem.pageType === "officer" &&
            pageItem.type === "remove_invite") ? (
          <Editpromotermodel
            addModalShow={show}
            addModalClose={() => setShow(false)}
            item={pageItem}
          />
        ) : (
          ""
        )
      }
    </>
  );
}
