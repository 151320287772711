import axionIns from "../axios";

const getDocuments = (page, perPage, id) => {
  let url = `admin/documents/${id ? id : ""}?page=` + page + "&perPage=" + perPage
  return axionIns.get(url).then((response) => {
    return response.data;
  });
};

const uploadFile = async (payload) => {
  return await axionIns.post("/admin/no-entry", payload).then((response) => {
    return response.data;
  });
};

const addDocFiles = async (payload, id) => {
  return await axionIns.post(`/admin/files/${id}`, payload).then((response) => {
    return response.data;
  });
};

const uploadMultipleFiles = async (payload) => {
  return await axionIns.post("/admin/filesadmin/no-entry-multiple", payload).then((response) => {return response.data});
};

const exportObject = {
  getDocuments,
  uploadFile,
  addDocFiles,
  uploadMultipleFiles
};
export default exportObject;
