import React from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addIndividualReferralAction } from "../../actions/referralInvites";

export default function AddIndividualInviteModal({
  addModalShow,
  addModalClose,
}) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    dispatch(addIndividualReferralAction(data));
    addModalClose();
    reset();
  };
  return (
    <>
      <Modal
        show={addModalShow}
        onHide={addModalClose}
        className="custom-modal edit-information-width ps-0"
      >
        <div className="modal-header p-0">
          <button
            type="button"
            className="btn-close text-dark close-button close-button"
            onClick={addModalClose}
          ></button>
        </div>
        <div className="modal-body">
          <div className="row right-side-row">
            <div className="col-lg-5 col-md-6 col-sm-6 col-xs-6 col-xl-5">
              <h5 className="edit-title">Add a new individual referral</h5>
              {/* <p className="edit-description">
                Every business can appoint as many promoters as required.
              </p>
              <p className="edit-description">
                A promoter is any person who plays a part in forming a company
                or establishing its business (usually potential shareholders or
                directors of the business).
              </p>
              <p className="edit-description">
                A promoter can act for or as an agent of a business, or provide
                services or its own skillset to the business.
              </p>
              <p className="edit-description">
                A promoter who acts for or as an agent of the business before it
                has been legally incorporated will be personally liable for any
                contract they make on behalf or with the business.
              </p> */}
            </div>
            <div className="col-lg-7 col-md-6 col-sm-6 col-xs-6 col-xl-7">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-6 mb-2">
                    <div className="form-group">
                      <p className="input-text heading">First name *</p>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First name"
                        {...register("first_name", {
                          required: true,
                        })}
                      />
                      {errors.first_name?.type === "required" && (
                        <span className="errorMessage">
                          First name is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 mb-2">
                    <div className="form-group">
                      <p className="input-text heading">Last name *</p>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last name"
                        {...register("last_name", {
                          required: true,
                        })}
                      />
                      {errors.last_name?.type === "required" && (
                        <span className="errorMessage">
                          Last name is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <div className="form-group">
                      <p className="input-text heading">Email</p>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email address"
                        {...register("referral_email", {
                          required: true,
                          pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                        })}
                      />
                      {errors.referral_email?.type === "required" && (
                        <span className="errorMessage">Email is required</span>
                      )}
                      {errors.referral_email?.type === "pattern" && (
                        <span className="errorMessage">Email is invalid</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="modal-footer modal-bottom-action">
                  <button type="submit" className="btn agree-button">
                    Agree & add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
