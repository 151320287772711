import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { backendUrl } from "../../constants";
import { getSdkConversationObject } from "../../conversations-objects";

import {
  updateCurrentConversation,
  updateParticipants,
  updateUnreadMessages,
  setLastReadIndex,
  sliceCurrentConvoChatToDefault,
  getTypingMessage,
  searchAction,
  gotoMessageSearchMessageAction,
  resetRedirectionForce,
} from "../../actions/chat";
import ConversationView, { getLastMsgTime } from "./ConversationView";
import { useNavigate } from "react-router";
import { RoutesPaths } from "../../Router/constant";
import { useEffect } from "react";
import { formatFileSize, getName } from "./userMessages";
import TextMessageBox from "./TextMessageBox";


export const getChatUserImage = (members, user, filterByEmail = null) => {
  var returnVal = "assets/img/teamChat.svg";
  if (members?.length === 2 || filterByEmail) {
    var response = [];
    if (filterByEmail) {
      returnVal = "assets/img/userChat.svg";
      response = members && members.length > 0 && members.filter(function (element) {
        if (element.identity === filterByEmail) {
          return element;
        }
      });
    } else {
      response = members && members.length > 0 && members.filter(function (element) {
        if (element.identity !== user.email) {
          return element;
        }
      });
    }

    if (response && response.length > 0) {
      if (response[0]?.attributes?.image) {
        returnVal =
          backendUrl + response[0].attributes?.image;
      } else {
        returnVal = "assets/img/userChat.svg";
      }
    }
  }
  return returnVal;
};

export const getChatUserName = (members, user, filterByEmail = null) => {
  var returnVal = "";
  var response = [];
  if (filterByEmail) {
    response =
      members &&
      members.length > 0 &&
      members.filter(function (element) {
        if (element.identity === filterByEmail) {
          return element + (element.isAppointed ? (' <span class="appointed"></span>') : "");
        }
      });
    // members.filter((x) => x.user.email === filterByEmail));
  } else {
    response =
      members &&
      members.length > 0 &&
      members.filter(function (element) {
        if (element.identity !== user.email) {
          return element + (element.isAppointed ? (' <span class="appointed"></span>') : "");
        }
      });
    // members.filter((x) => x.user._id !== user.id));
  }
  if (response && response.length > 0) {
    returnVal = response
      .map((item) => {
        if (item.attributes.name) return item.attributes.name  + (item.isAppointed ? (' <span class="appointed"></span>') : "");
        else return item.identity + (item.isAppointed ? (' <span class="appointed"></span>') : "");
      })
      .join(", ");
  }

  if (returnVal === "" && filterByEmail) {
    // get the attributes
    returnVal = filterByEmail

  }
  return returnVal;
};

export const getChatUserCount = (members, user, filterByEmail = null) => {
  var returnVal = 0;
  var response = [];
  response =
    members &&
    members.length > 0 &&
    members.filter((x) => x.user._id === user.id);
  if (response && response.length > 0) {
    returnVal = response[0].unreadMessageCount;
  }
  return returnVal;
};

export const checkVerifiedTag = (selectedRoomId, email) => {
  var returnVal = false;
  var list = [];
  if (list.length === 0 && selectedRoomId?.shareholders.length > 0) {
    list = selectedRoomId?.shareholders;
  }
  if (list.length === 0 && selectedRoomId?.officers.length > 0) {
    list = selectedRoomId?.officers;
  }
  if (list.length === 0 && selectedRoomId?.promoters.length > 0) {
    list = selectedRoomId?.shareholders;
  }

  var filterList = list.filter((x) => x.email === email);

  if (filterList.length > 0) {
    returnVal = filterList[0].isVerified;
  }
  return returnVal;
};


function getLastMessage(messages, typingData) {
  if (messages === undefined || messages === null) {
    return "Loading...";
  }
  if (typingData.length) {
    return getTypingMessage(typingData);
  }
  if (messages.length === 0) {
    return "No messages";
  }
  return messages[messages.length - 1].body || "Media message";
}

function isMyMessage(messages) {
  if (messages === undefined || messages === null || messages.length === 0) {
    return false;
  }
  return messages[messages.length - 1].author === localStorage.getItem("username") ? messages[messages.length - 1] : false;
}


export default function UserList(props) {
  const { loadFirstChannel, setShowList, showList } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState([]);

  const sid = useSelector((state) => state.sid);
  const conversations = useSelector((state) => state.convos);
  const messages = useSelector((state) => state.messages);
  const unreadMessages = useSelector((state) => state.unreadMessages);
  const participants = useSelector((state) => state.participants);
  const typingData = useSelector((state) => state.typingData);
  const { searchQuery, setNewChannelRedirectionForce } = useSelector((state) => state.chat);

  const updateCurrentConvo = async (convo) => {
    if (sid) {
      dispatch(sliceCurrentConvoChatToDefault(sid));
    }
    dispatch(updateCurrentConversation(convo.sid));
    const participants = await getSdkConversationObject(convo).getParticipants();
    dispatch(updateParticipants(participants, convo.sid));
  }

  const setUnreadMessagesCount = (currentconvoSid, convoSid, unreadMessages, updateUnreadMessages) => {
    if (currentconvoSid === convoSid && unreadMessages[convoSid] !== 0) {
      dispatch(updateUnreadMessages(convoSid, 0));
      return 0;
    }
    if (currentconvoSid === convoSid) {
      return 0;
    }
    return unreadMessages[convoSid];
  }

  const changeRoomAction = async (convo) => {
    try {
      dispatch(setLastReadIndex(convo.lastReadMessageIndex ?? -1));
      await updateCurrentConvo(convo);
      //update unread messages
      dispatch(updateUnreadMessages(convo.sid, 0));
      //set messages to be read
      const lastMessage =
        messages && messages[convo.sid] && messages[convo.sid].length &&
        messages[convo.sid][messages[convo.sid].length - 1];
      if (lastMessage && lastMessage.index !== -1) {
        await getSdkConversationObject(convo).advanceLastReadMessageIndex(lastMessage.index);
      }
      setShowList(!showList)
      navigate(RoutesPaths.MESSAGE_DETAIL + "?room=" + convo.sid);
    } catch (e) {
      throw new Error(e.message);
    }
  }

  useEffect(() => {
    if (loadFirstChannel && loadFirstChannel.sid) {
      changeRoomAction(loadFirstChannel)
    }
  }, [loadFirstChannel])


  useEffect(() => {
    const runFunc = () => {
      if(setNewChannelRedirectionForce){
        changeRoomAction(setNewChannelRedirectionForce)
        dispatch(resetRedirectionForce(null))
      }
    }
    runFunc()
  }, [dispatch, setNewChannelRedirectionForce])

  const handleSearch = async (query) => {
    try {
      if (query.length > 0) {
        var concatMessage = []
        for (var message in messages) {
          if (messages[message].length > 0) {
            var filterRecord = messages[message].filter(function (data) {
              data.conversation = conversations.find(x => x.sid === message);
              const filterAttached = data.attachedMedia.filter(y => y.filename.toLowerCase().indexOf(query.toLowerCase()) > -1)
              const filterText = data.body.toLowerCase().indexOf(query.toLowerCase()) > -1
              return (filterAttached.length > 0 || filterText)
            })
            concatMessage = [...concatMessage, ...filterRecord]
          }
        }
        setSearchResults(concatMessage);
      } else {
        const elements = document.querySelectorAll('.messageListOuter');
        elements.forEach((element) => {
          element.classList.remove('highlight');
        });
        dispatch(gotoMessageSearchMessageAction(null))
        setSearchResults([]);
      }
    } catch (error) {
      console.error('Error searching for messages:', error);
    }
  };

  const gotoMessage = (convo, messageSid) => {
    const elements = document.querySelectorAll('.messageListOuter');
    elements.forEach((element) => {
      element.classList.remove('highlight');
    });
    changeRoomAction(convo);
    dispatch(gotoMessageSearchMessageAction(messageSid))
    setTimeout(() => {
      const element = document.getElementById('message-' + messageSid)
      element.classList.add("highlight");
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 1000);
  }

  return (
    <>
      <div className="userListHeader">
        <input
          type="search"
          placeholder="Search..."
          name=""
          id=""
          onChange={(e) => { handleSearch(e.target.value); dispatch(searchAction(e.target.value)) }}
        />
        {/* <i
          onClick={() => setShowList(false)}
          className={showProfile ? "fa fa-ellipsis-v" : "fa fa-ellipsis-v hideSearch"}
          aria-hidden="true"
        ></i> */}
      </div>
      <div className="userList chat">
        {searchQuery.length === 0 && searchResults.length === 0 ? (
          <ul>
            {conversations.map((convo) => (
              <ConversationView
                key={convo.sid}
                convoId={convo.sid}
                setSid={updateCurrentConversation}
                currentConvoSid={sid}
                lastMessage={
                  getLastMessage(messages[convo.sid], typingData[convo.sid] ?? []) ??
                  ""
                }
                messages={messages[convo.sid]}
                typingInfo={typingData[convo.sid] ?? []}
                myMessage={isMyMessage(messages[convo.sid])}
                unreadMessagesCount={setUnreadMessagesCount(
                  sid,
                  convo.sid,
                  unreadMessages,
                  updateUnreadMessages
                )}
                updateUnreadMessages={updateUnreadMessages}
                participants={participants[convo.sid] ?? []}
                convo={convo}
                changeRoom={() => changeRoomAction(convo)}
              />
            ))}
          </ul>
        ) : (
          <>
            <h6>SEARCH RESULTS: {searchResults.length}</h6>
            <ul>
              {searchResults.map((message) => (
                <li key={message.sid} onClick={() => gotoMessage(message.conversation, message.sid)}>
                  {message.attachedMedia.length === 0 && (
                    <div
                      style={{
                        backgroundImage: `url(${getChatUserImage(
                          participants[message.conversation.sid], message.author, message.author
                        )})`,
                      }}
                      className="profile_photo"
                    ></div>
                  )}
                  <div className="messageDetail">
                    <span className="lastSeen">
                      {getLastMsgTime(message?.dateCreated)}
                    </span>
                    <p className="userName">
                      <span dangerouslySetInnerHTML={{
                        __html: message.conversation.friendlyName || getChatUserName(participants[message.conversation.sid], message.author, message.author),
                      }}></span>
                      &nbsp;
                    </p>
                    <p className="userDetail">{message.conversation?.attributes?.companyName || ""}&nbsp;</p>
                    <>
                      {message.attachedMedia.length > 0 ? (
                        message.attachedMedia.map((msg, index) => (
                          <div className="message-box-hold" key={index}>
                            {message.body && (
                              <TextMessageBox
                                message={message}
                                searchQuery={searchQuery}
                                textFrom={getChatUserName(participants[message.conversation.sid], message.author, message.author)} />
                            )}
                            <div className="mediaFullWidth">
                              <span className="addMedia">
                                <span className="addMediaPadding">
                                  {/* <img className="imageView" src="assets/img/imageView.svg" alt="" /> */}
                                  <img className="addMediatextImage" src="assets/img/addmediaBack.svg" alt="" />
                                  <span className="extensionName">{getName(msg.filename)}</span>
                                </span>
                                {/* <img className="imageView" src="assets/img/imageView.svg" alt="" />
                                <img className="addMediatextImage" src="assets/img/addmediaBack.svg" alt="" />
                                <span className="extensionName">{getName(msg.filename)}</span> */}
                              </span>
                              <div className="fileNameSection">
                                <h4 className="fileName">{msg.filename}</h4>
                                <p className="fileSize">{formatFileSize(msg.size)} | {getName(msg.filename)}</p>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        message.body && (
                          <TextMessageBox
                            message={message}
                            searchQuery={searchQuery}
                            textFrom={getChatUserName(participants[message.conversation.sid], message.author, message.author)} />
                        )
                      )}
                    </>

                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
      </div >
    </>
  );
}
