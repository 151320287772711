import { filterHtml, highlightedSearchText } from "./ConversationView";


const TextMessageBox = ({ searchMessageId, message, searchQuery, textFrom }) => {
  return (
    searchMessageId ? (
      <div
        className="message-dangerous"
        dangerouslySetInnerHTML={{ __html: searchMessageId === message.sid ? highlightedSearchText(message.body, searchQuery) : message.body }}
      ></div>
    ) : (
      <p
        className="message" dangerouslySetInnerHTML={{ __html: filterHtml(message.body, searchQuery, textFrom)}}
      ></p>
    )
  )
};

export default TextMessageBox;
