import React from "react";
import { Modal } from "react-bootstrap";
import { SUBSCRIPTION_MODULE_TYPE_NAME } from "../../constants";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { updatePaymentApprovalStatusAction } from "../../actions/payment";

export default function PaymentApprovedActionDetails({ item, addActionModalShow, addActionModalClose }) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {

    const payload = {
      approvedStatus: 1
    }
    await dispatch(updatePaymentApprovalStatusAction(item?.id, payload))
    addActionModalClose()
  }

  const handleBadgeClick = (value) => {
    setValue("paymentStatus", value);
  };

  return (
    <>
      <Modal
        show={addActionModalShow}
        onHide={addActionModalClose}
        className="actionmodal-width companyEdit-modal ps-0 add-modal"
      >
        <div className="modal-header actionModalHeader">
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={addActionModalClose}
          >
            &nbsp;
          </button>
        </div>
        <div className="modal-body">

          <div className="row">
            <div className="co-12 edit-title headerTitle">
              <h6 className="m-0">Payment approval action</h6>
            </div>
            <div className="col-12 paymentPaddingTopbottom PaymentflexSection">
              <h6 className="m-0 paymentTitle">Payment by :&nbsp;</h6>
              <div className="paymentdetails">{item?.user?.first_name} {item?.user?.last_name} ( {item?.user?.email} )</div>
            </div>
            <div className="col-12 paymentPaddingTopbottom PaymentflexSection">
              <h6 className="m-0 paymentTitle">Item :&nbsp;</h6>
              <div className="paymentdetails">
                {SUBSCRIPTION_MODULE_TYPE_NAME[item?.subscriptionModuleType]} {item?.subscriptionPlan?.name ? `|| ${item?.subscriptionPlan?.name}` : ''}
              </div>
            </div>
          </div>

          <div className="row">
            <form onSubmit={handleSubmit(onSubmit)}>

              <div className="col-md-12">
                <h6 className="m-0 paymentTitle">Payment status : <strong>APPROVED</strong></h6>
              </div>

              <div className="col-12 saveActionBtn">
                <button type="submit" className="btn agree-button">Save</button>
              </div>
            </form>

          </div>

        </div>
      </Modal >
    </>
  );
}
