import React from "react";
import { Modal } from "react-bootstrap";
import { BOB_SERVICES, POSITIONS, PROFILE_STATUS } from "../../constants";
import { getPotentialPurpose } from "./ProfileForm";

export default function SuccessModal({
  successOpen,
  successClose,
  isEdit,
  reset,
  status
}) {
  const handleclose = () => {
    successClose();
    reset();
  };

  return (
    <Modal
      show={successOpen}
      onHide={handleclose}
      className="moddal-primary importModal ps-0"
    >
      <Modal.Body>
        <div className="header">
          <div>
            <h4>
              {isEdit ? (
                "Profile updated successfully"
              ) : (
                status === PROFILE_STATUS.SUGGESTED_PROFILES ? `Suggested ${(successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PROFILES) ? (
                  "profile"
                ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.COMPANIES) ? (
                  "company"
                ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PRODUCT_AND_SERVICES) ? (
                  "Service or products"
                ) : ("")} Added` : status === PROFILE_STATUS.WARM_PROFILES ? `Warm ${(successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PROFILES) ? (
                  "profile"
                ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.COMPANIES) ? (
                  "company"
                ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PRODUCT_AND_SERVICES) ? (
                  "service or products"
                ) : ("")} added` : status === PROFILE_STATUS.MEETING_REQUESTS ? "Meeting request added" : ""
              )}
            </h4>
          </div>
          <i onClick={handleclose} className="fa-solid fa-xmark"></i>
        </div>
        <div className="modalBody">
          <div className="successBody profileCardBody">
            <p className="descrip">
              {!isEdit
                ? `You have suggested the following 
                ${(successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PROFILES) ? (
                  "profile/s"
                ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.COMPANIES) ? (
                  "company/s"
                ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PRODUCT_AND_SERVICES) ? (
                  "Service or products/s"
                ) : ("")} to`
                : `You have save changes to the following 
                 
                ${(successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PROFILES) ? (
                  "profile/s"
                ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.COMPANIES) ? (
                  "company/s"
                ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PRODUCT_AND_SERVICES) ? (
                  "Service or products/s"
                ) : ("")}
                
                to Exclude Co Ltd:`}{" "}
              <span>{successOpen?.company?.companyName || "-"}</span>:
            </p>

            <div className="tableResponsive">
              <table className="profileTable succesTable">
                <thead>
                  <tr>
                    <th>
                      {(successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PROFILES) ? (
                        "Full name"
                      ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.COMPANIES) ? (
                        "Company"
                      ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PRODUCT_AND_SERVICES) ? (
                        "Service or product name"
                      ) : ("")}
                    </th>
                    {(successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PROFILES || successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.COMPANIES) && (
                      <th>Company {successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.COMPANIES ? "yype" : ""}</th>
                    )}
                    <th>Industry</th>
                    <th>Potential purpose</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        {(successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PROFILES) ? (
                          successOpen?.first_name + ' ' + successOpen?.last_name
                        ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.COMPANIES) ? (
                          successOpen?.companyProfile
                        ) : (successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PRODUCT_AND_SERVICES) ? (
                          successOpen?.serviceProductName
                        ) : ("")}
                      </p>
                    </td>
                    {(successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.PROFILES || successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.COMPANIES) && (
                      <td>
                        <p>{(successOpen?.bookOfBusinessGroupId?.service === BOB_SERVICES.COMPANIES ? successOpen?.companyType : successOpen?.companyProfile) || "-"}</p>
                        <p className="detail">
                          {successOpen?.positionProfile}
                        </p>
                      </td>
                    )}
                    <td>
                      <p>{successOpen?.industry}</p>
                    </td>
                    <td>
                      <p>
                        {successOpen?.bookOfBusinessGroupId?.isDefault ? (
                          successOpen?.customPotentialPurposeInput
                        ) : (
                          getPotentialPurpose(successOpen)
                        )}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="note">
            <p className="desc">
              <span>IMPORTANT NOTE:</span> Lorem ipsum velit officia consequat
              duis enim velit mollit.
            </p>
          </div>

          <div className="importBtn">
            <button
              onClick={handleclose}
              type="submit"
              className="customAddButton removePadding"
            >
              GOT IT
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
