import moment from "moment";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { approveActivityAction } from "../../actions/activityLogs";
import { backendUrl, MODAL_BUSINESS_IDENTIFY } from "../../constants";
import Deletemodel from "../../pages/dashboard/Deletemodel";
import Confirmmodel from "./Confirmmodel";
import TimeFormat from "../timeFormat/timeFormat";

export default function Editcompany({ addModalShow, addModalClose, item }) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [confirmshow, setConfirmShow] = useState(false);

  const handleSubmit = async (flag) => {
    setConfirmShow(false);
    setShow(false)
    var payload = {
      type: item.pageType,
      id: item.id,
      companyId: item.company._id,
      companyHistoryId: item.company_history._id,
      flag: flag,
    };
    await dispatch(approveActivityAction(payload));
    addModalClose();
  };

  return (
    <>
      {item && item.company && item.company._id && (
        <Modal
          show={addModalShow}
          onHide={addModalClose}
          className="moddal-width companyEdit-modal ps-0 add-modal"
        >
          <div className="modal-header pt-2">
            <button
              type="button"
              className="btn-close text-dark close-button"
              onClick={addModalClose}
            >
              &nbsp;
              {/* <span aria-hidden="true">×</span> */}
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xl-3">
                <h4 className="edit-title">Request</h4>
                <span className="text-xs">
                  <TimeFormat time={item.createdAt} />
                </span>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xl-4">
                <h6>Request Type</h6>
                <h4>{item.description}</h4>
              </div>
              {item.type !== MODAL_BUSINESS_IDENTIFY.COMPANY_NAME && (
                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 col-xl-5">
                  <h6>Company name</h6>
                  <h4>{item.company?.companyName}</h4>
                </div>
              )}
            </div>
            <div className="row paddingtopbtm">
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xl-4">
                <h6>Requested by</h6>
                <div>
                  {item.user
                    ? item.user.first_name + " " + item.user.last_name
                    : "-"}
                </div>
              </div>
              {/* <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xl-4">
                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-9">
                    <h6>Requester Position</h6>
                    <div>CEO</div>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xl-4">
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-8">
                    <h6>Company head email</h6>
                    <div>{item.user ? item.user.email : "-"}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row paddingtopbtm">
              <div className="col-md-12">
                <h6>Request Details</h6>
              </div>
            </div>

            <div className="row paddingtopbtm">
              <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 col-xl-5">
                <h6>
                  Current{" "}
                  {item.type === MODAL_BUSINESS_IDENTIFY.COMPANY_NAME
                    ? "Business Name"
                    : item.type === MODAL_BUSINESS_IDENTIFY.LOGO
                    ? "Logo"
                    : item.type === MODAL_BUSINESS_IDENTIFY.COMPANY_WEBSITE
                    ? "Company Website"
                    : item.type === MODAL_BUSINESS_IDENTIFY.PRIMARY_ACTIVITY
                    ? "Primary Activity"
                    : item.type === MODAL_BUSINESS_IDENTIFY.PRIMARY_ADDRESS
                    ? "Registered Business Address"
                    : ""}{" "}
                </h6>

                <h3>
                  {item.type === MODAL_BUSINESS_IDENTIFY.COMPANY_NAME ? (
                    item.company_history.companyName
                  ) : item.type === MODAL_BUSINESS_IDENTIFY.LOGO ? (
                    item.company_history.logo ? (
                      <img
                        className="companyLogo"
                        src={backendUrl + item.company_history.logo}
                        alt="companyLogo"
                      />
                    ) : (
                      "-"
                    )
                  ) : item.type === MODAL_BUSINESS_IDENTIFY.COMPANY_WEBSITE ? (
                    item.company_history.companyWebsite
                  ) : item.type === MODAL_BUSINESS_IDENTIFY.PRIMARY_ACTIVITY ? (
                    item.company_history.primaryActivity
                  ) : item.type === MODAL_BUSINESS_IDENTIFY.PRIMARY_ADDRESS ? (
                    <span>
                      {item.company_history.primaryAddress},{" "}
                      {item.company_history.secondaryAddress},{" "}
                      {item.company_history.city}, {item.company_history.city}{" "}
                      {item.company_history.country}{" "}
                      {item.company_history.postalCode}
                    </span>
                  ) : item.type === MODAL_BUSINESS_IDENTIFY.COMPANY_WEBSITE ? (
                    item.company_history.companyWebsite
                  ) : (
                    "-"
                  )}
                </h3>

                {item.type === MODAL_BUSINESS_IDENTIFY.PRIMARY_ACTIVITY && (
                  <>
                    <br />
                    <h6>
                      Current{" "}
                      {item.type === MODAL_BUSINESS_IDENTIFY.PRIMARY_ACTIVITY
                        ? "Secondary Activity"
                        : ""}{" "}
                    </h6>
                    <h3>
                      {item.type === MODAL_BUSINESS_IDENTIFY.PRIMARY_ACTIVITY
                        ? item.company_history.secondaryActivity
                        : ""}
                    </h3>
                  </>
                )}
              </div>
              <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7 col-xl-7 addressbox">
                <h6>
                  {item.type === MODAL_BUSINESS_IDENTIFY.COMPANY_NAME
                    ? "Business Name"
                    : item.type === MODAL_BUSINESS_IDENTIFY.LOGO
                    ? "Logo"
                    : item.type === MODAL_BUSINESS_IDENTIFY.COMPANY_WEBSITE
                    ? "Company Website"
                    : ""}
                </h6>
                {item.type === MODAL_BUSINESS_IDENTIFY.LOGO ? (
                  <img
                    className="companyLogo"
                    src={backendUrl + item.company.logo}
                    alt="companyLogo"
                  />
                ) : item.type === MODAL_BUSINESS_IDENTIFY.COMPANY_NAME ? (
                  <div className="textclr Overflow">
                    {item.company?.companyName}
                  </div>
                ) : item.type === MODAL_BUSINESS_IDENTIFY.COMPANY_WEBSITE ? (
                  <div className="textclr Overflow">
                    {item.company.companyWebsite}
                  </div>
                ) : item.type === MODAL_BUSINESS_IDENTIFY.PRIMARY_ACTIVITY ? (
                  <div className="row">
                    <div className="col-md-6">
                      <h6>Primary Activity</h6>
                      <div className="textclr Overflow">
                        {item.company.primaryActivity}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h6>Secondary Activity</h6>
                      <div className="textclr Overflow">
                        {item.company.secondaryActivity}
                      </div>
                    </div>
                  </div>
                ) : item.type === MODAL_BUSINESS_IDENTIFY.PRIMARY_ADDRESS ? (
                  <>
                    <div className="row">
                      <div className="col-md-6">
                        <h6>Address Line 1</h6>
                        <div className="textclr Overflow">
                          {item.company.primaryAddress}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-3"></div>
                          <div className="col-md-9">
                            <h6>Address Line 2</h6>
                            <div className="textclr Overflow">
                              {item.company.secondaryAddress}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row paddingtopbtm">
                      <div className="col-md-4">
                        <h6>City</h6>
                        <div className="textclr">{item.company.city}</div>
                      </div>
                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-md-2"></div>
                          <div className="col-md-10">
                            <h6>Country</h6>
                            <div className="textclr">
                              {item.company.country}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-md-3"></div>
                          <div className="col-md-9">
                            <h6>Post Code</h6>
                            <div className="textclr">
                              {item.company.postalCode}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="row paddingtopbtm">
              <div className="col-md-5">
                <button
                  type="submit"
                  className="btn save-btn savebtn"
                  onClick={addModalClose}
                >
                  BACK
                </button>
              </div>
              {item.status === 1 ? (
                <>
                  <div className="col-md-3">
                    <button
                      type="submit"
                      className="btn save-btn deny"
                      onClick={() => setShow(true)}
                    >
                      DENY
                    </button>
                  </div>
                  <Deletemodel
                    message="Are you sure you want to deny?"
                    addModalShow={show}
                    addModalClose={() => setShow(false)}
                    confirmModal={handleSubmit}
                  />

                  <div className="col-md-4">
                    <button
                      type="submit"
                      className="btn save-btn allow"
                      onClick={() => setConfirmShow(true)}
                    >
                      ALLOW AMMENDMENT
                    </button>
                  </div>
                  <Confirmmodel
                    message="Are you sure you want to Allow this ammendment?"
                    addModalShow={confirmshow}
                    addModalClose={() => setConfirmShow(false)}
                    confirmModal={handleSubmit}
                  />
                </>
              ) : (
                <div className="col-md-7 save-btn ">
                  <h6 className="alignright">
                    {item.status === 2
                      ? "Verified"
                      : item.status === 3
                      ? "Denied"
                      : "-"}
                  </h6>
                  <div className="text-xs alignright">
                    <TimeFormat time={item.updatedAt}/>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
