import React, { useEffect } from "react";
import Header from "../../components/header/header";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Controller, useForm } from "react-hook-form";
import {
  backendUrl,
  BANNER_IMAGE_TYPE,
  BANNER_TYPE,
  BANNER_TYPE_OPTIONS,
  CUSTOM_SELECT_STYLES,
  SELECT_BOX_THEME,
  statsuOption,
  statusStyles,
} from "../../constants";
import Select from "react-select";
import { Link } from "react-router-dom";
import { getSubscriptionPlanList } from "../../actions/subscription";
import { simpleUploadAction } from "../../actions/files";
import toast from "react-hot-toast";
import {
  addBannerAction,
  editBannerAction,
  getBannerActionById,
} from "../../actions/banner";

export default function AddBannerManager() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { subscription } = useSelector((state) => state.subscription);
  const { bannerDetail } = useSelector((state) => state.banner);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    getValues,
    trigger,
    formState: { errors },
  } = useForm();
  const bannerTypeWatch = watch("bannerType");
  const webImageWatch = watch("webImage");
  const mobileImageWatch = watch("mobileImage");
  const id = state?.id;

  useEffect(() => {
    dispatch(getSubscriptionPlanList(1, 10000, true, ""));
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getBannerActionById(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id) {
      setValue("webImage", bannerDetail?.webImage);
      setValue("mobileImage", bannerDetail?.mobileImage);
      setValue("bannerType", {
        name: BANNER_TYPE_OPTIONS.find(
          (option) => option.value === bannerDetail?.bannerType
        )?.name,
        value: bannerDetail?.bannerType,
      });
      if (subscription.length > 0 && bannerDetail) {
        const selectedOption =
          bannerDetail?.bannerType === BANNER_TYPE.PLAN
            ? subscription?.find((x) => x.id === bannerDetail.cta)
            : bannerDetail.cta;
        setValue("cta", selectedOption);
      }
      setValue("isActive", {
        title: bannerDetail.isActive ? "Published" : "Not Published",
        value: bannerDetail.isActive ? "published" : "notPublished",
      });
    }
  }, [setValue, bannerDetail, id]);

  const uploadFiles = (event, type) => {
    const file = event.target.files[0];
    uploadDispatchFile(file, type);
    event.target.value = null;
  };

  const isImageFileValid = (fileExten) => {
    switch (fileExten) {
      case "jpg":
      case "jpeg":
      case "png":
        return true;
      default:
        return false;
    }
  };

  const uploadDispatchFile = (file, type) => {
    var maxfileUploadSize = 1024 * 1024 * 100;
    if (file.size < maxfileUploadSize) {
      const formData = new FormData();
      var fileExten = file.name.split(".").slice(-1)[0];
      if (isImageFileValid(fileExten)) {
        formData.append("file", file);
        dispatch(simpleUploadAction(formData)).then((resp) => {
          switch (type) {
            case BANNER_IMAGE_TYPE.WEB:
              setValue("webImage", resp);
              break;
            case BANNER_IMAGE_TYPE.MOBILE:
              setValue("mobileImage", resp);
              break;
            default:
              break;
          }
        });
      } else {
        toast.error("Only *.png, *.jpg and *.jpeg image files are accepted");
      }
    } else {
      toast.error("Maximum file upload size is 50mb.");
    }
  };

  const onSubmit = (data) => {
    if (!webImageWatch?.filename) {
      toast.error("Please upload banner for web");
      return;
    }
    if (!mobileImageWatch?.filename) {
      toast.error("Please upload banner for mobile");
      return;
    }
    var allData = {
      webImage: webImageWatch,
      mobileImage: mobileImageWatch,
      bannerType: data?.bannerType?.value,
      cta:
        data?.bannerType?.value === BANNER_TYPE.PLAN
          ? data?.cta?.id
          : data?.cta,
      isActive: data.isActive.sendValue,
    };
    if (id) {
      dispatch(editBannerAction(id, allData));
    } else {
      dispatch(addBannerAction(allData));
    }
    navigate(-1);
  };

  return (
    <>
      <Header title="Banner Manager" />
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 mb-2">
            <div className="card notification addbanner h-100">
              <div className={`card-body pt-3`}>
                <div className="addBannerDetails addPlanPage">
                  <h5>{id ? "Edit banner" : "Create new banner"}</h5>

                  <form>
                    <div className="form-group bannerInputs">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="addPhotoCard">
                            <div className="imageHolderFlex">
                              <div
                                className={`imageHolder ${
                                  webImageWatch && webImageWatch?.filename
                                    ? "imagePlaceWithoutflex"
                                    : ""
                                }`}>
                                <p>Web banner</p>
                                <div className="imageBack">
                                  <label className="uploadBtn">
                                    <input
                                      onChange={(event) =>
                                        uploadFiles(event, 1)
                                      }
                                      type="file"
                                      hidden
                                    />
                                    <img
                                      src="/assets/img/uploadImage.svg"
                                      alt=""
                                    />
                                  </label>
                                  <label className="imagePlace">
                                    <input
                                      onChange={(event) =>
                                        uploadFiles(event, 1)
                                      }
                                      type="file"
                                      hidden
                                    />
                                    <div
                                      className={`image ${
                                        webImageWatch && webImageWatch.filename
                                          ? ""
                                          : "withPlaceholder"
                                      }`}
                                      style={{
                                        backgroundImage:
                                          webImageWatch &&
                                          webImageWatch.filename
                                            ? `url(${
                                                backendUrl +
                                                webImageWatch.filename
                                              })`
                                            : "url(/assets/img/planPlaceholder.png)",
                                      }}></div>
                                  </label>
                                </div>
                                <div className="imageDesc">
                                  <span>
                                    Note: Please upload an web banner with the
                                    following dimensions
                                  </span>
                                  <ul>
                                    <li>3030 x 528</li>
                                    <li>
                                      Only files in .png, .jpg, or .jpeg formats
                                      are accepted.
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div
                                className={`imageHolder ${
                                  mobileImageWatch && mobileImageWatch.filename
                                    ? "imagePlaceWithoutflex"
                                    : ""
                                }`}>
                                <p>Mobile banner</p>

                                <div className="imageBack">
                                  <label className="uploadBtn">
                                    <input
                                      onChange={(event) =>
                                        uploadFiles(event, 2)
                                      }
                                      type="file"
                                      hidden
                                    />
                                    <img
                                      src="/assets/img/uploadImage.svg"
                                      alt=""
                                    />
                                  </label>
                                  <label className="imagePlace">
                                    <input
                                      onChange={(event) =>
                                        uploadFiles(event, 2)
                                      }
                                      type="file"
                                      hidden
                                    />
                                    <div
                                      className={`image ${
                                        mobileImageWatch &&
                                        mobileImageWatch.filename
                                          ? ""
                                          : "withPlaceholder"
                                      }`}
                                      style={{
                                        backgroundImage:
                                          mobileImageWatch &&
                                          mobileImageWatch.filename
                                            ? `url(${
                                                backendUrl +
                                                mobileImageWatch.filename
                                              })`
                                            : "url(/assets/img/planPlaceholder.png)",
                                      }}></div>
                                  </label>
                                </div>
                                <div className="imageDesc">
                                  <span>
                                    Note: Please upload an mobile banner with
                                    the following dimensions
                                  </span>
                                  <ul>
                                    <li>984 x 360</li>
                                    <li>
                                      Only files in .png, .jpg, or .jpeg formats
                                      are accepted.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row bannerInputsRow">
                        <div className="col-lg-4 fieldPadding">
                          <p className="input-text heading">Banner type</p>
                          <div className="selectStatus">
                            <Controller
                              control={control}
                              name="bannerType"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Select banner type",
                                },
                              }}
                              defaultValue={null}
                              render={({ field }) => (
                                <Select
                                  className="select-border"
                                  {...field}
                                  theme={SELECT_BOX_THEME}
                                  styles={CUSTOM_SELECT_STYLES}
                                  options={BANNER_TYPE_OPTIONS}
                                  onChange={(event) => {
                                    setValue("bannerType", event);
                                    setValue("cta", "");
                                  }}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.value}
                                  placeholder="Select banner type"
                                  components={{
                                    IndicatorSeparator: () => null,
                                  }}
                                />
                              )}
                            />
                            {errors?.bannerType && (
                              <span className="errorMessage">
                                {errors?.bannerType?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        {bannerTypeWatch && (
                          <div className="col-lg-4 fieldPadding">
                            <p className="input-text heading">
                              {bannerTypeWatch?.value === BANNER_TYPE.PLAN
                                ? "Select Plan"
                                : "External link"}
                            </p>
                            <div className="selectStatus">
                              {bannerTypeWatch?.value === BANNER_TYPE.PLAN ? (
                                <Controller
                                  name="cta"
                                  control={control}
                                  rules={{
                                    required: {
                                      value: true,
                                      message: "Select a product",
                                    },
                                  }}
                                  // defaultValue={null}
                                  render={({ field }) => (
                                    <Select
                                      theme={SELECT_BOX_THEME}
                                      styles={CUSTOM_SELECT_STYLES}
                                      className="select-border"
                                      {...field}
                                      options={subscription}
                                      isClearable={true}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.id}
                                      placeholder="Choose subscription plan"
                                      components={{
                                        IndicatorSeparator: () => null,
                                      }}
                                    />
                                  )}
                                />
                              ) : (
                                <input
                                  autoComplete="off"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter external link"
                                  {...register("cta", {
                                    required: {
                                      value: true,
                                      message: "External link is required",
                                    },
                                  })}
                                />
                              )}
                              {errors?.cta && (
                                <span className="errorMessage">
                                  {errors?.cta?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                        <div className="col-md-3 fieldPadding">
                          <p className="input-text heading">Banner status</p>
                          <div className="selectStatus">
                            <Controller
                              control={control}
                              name="isActive"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Select status",
                                },
                              }}
                              defaultValue={null}
                              render={({ field }) => (
                                <Select
                                  className="select-border"
                                  {...field}
                                  options={statsuOption}
                                  styles={statusStyles}
                                  getOptionLabel={(option) => option.title}
                                  getOptionValue={(option) => option.sendValue}
                                  placeholder="Choose a Status"
                                  components={{
                                    IndicatorSeparator: () => null,
                                  }}
                                />
                              )}
                            />
                            {errors?.isActive && (
                              <span className="errorMessage">
                                {errors?.isActive?.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="addPlanPage previewFooter">
                  <div className="buttonSection">
                    <Link to={-1} type="button" className="btn save-btn cancel">
                      Cancel
                    </Link>
                    <button
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                      className="btn agree-button save-btn">
                      Publish banner
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
