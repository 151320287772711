import React, { useEffect } from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Header from "../../components/header/header";
import AssuredFeature from "./assuredFeature";
import GuaranteFeature from "./guaranteFeature";
import PlanProfile from "./planProfile";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router";
import { RoutesPaths } from "../../Router/constant";
import { PRODUCT_GOLD_TYPE, statsuOption, statusStyles } from "../../constants";
import { simpleUploadAction } from "../../actions/files";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  addSubscriptionAction,
  editSubscriptionAction,
  getPlanById,
} from "../../actions/subscription";

export default function AddPlan() {
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    resetField,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const id = state?.id;
  const { subscriptionById } = useSelector((state) => state.subscription);
  const [tab, setTab] = useState(0);
  const [fileName, setFileName] = useState();
  const planTypeWatch = watch("planType");

  useEffect(() => {
    if (id) {
      dispatch(getPlanById(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id) {
      setFileName(subscriptionById.image);
      setValue("planType", subscriptionById.planType);
      setValue("planName", subscriptionById.name);
      setValue("shortDescription", subscriptionById.shortDescription);
      setValue("audience", subscriptionById.targetAudience);
      setValue("price", subscriptionById.price);
      setValue("currency", subscriptionById.currency);
      setValue("stripePriceId", subscriptionById.stripePriceId);
      setValue("stockKey", subscriptionById.stockKey);
      setValue(
        "supportedPaymentMethod",
        subscriptionById.supportedPaymentMethod
      );
      setValue("goldProductType", subscriptionById.goldProductType);
      setValue("smallDescription", subscriptionById.smallDescription);
      setValue("status", {
        title: subscriptionById.isActive ? "Published" : "Not Published",
        value: subscriptionById.isActive ? "published" : "notPublished",
        sendValue: subscriptionById.isActive,
      });
    }
  }, [setValue, subscriptionById, id]);

  const uploadFiles = (event) => {
    const file = event.target.files[0];
    uploadDispatchFile(file);
    event.target.value = null;
  };

  const isImageFileValid = (fileExten) => {
    switch (fileExten) {
      case "jpg":
      case "jpeg":
      case "png":
        return true;
      default:
        return false;
    }
  };

  const uploadDispatchFile = (file) => {
    var maxfileUploadSize = 1024 * 1024 * 100;
    if (file.size < maxfileUploadSize) {
      const formData = new FormData();
      var fileExten = file.name.split(".").slice(-1)[0];
      if (isImageFileValid(fileExten)) {
        formData.append("file", file);
        dispatch(simpleUploadAction(formData)).then((resp) => {
          setFileName(resp);
        });
      } else {
        toast.error("Only *.png, *.jpg and *.jpeg image files are accepted");
      }
    } else {
      toast.error("Maximum file upload size is 50mb.");
    }
  };

  const onSubmit = async (data) => {
    const allData = {
      planType: data.planType,
      name: data.planName,
      image: fileName,
      isActive: id ? data.status.sendValue : false,
      currency: data.currency,
      price: data.price,
      stripePriceId: data.stripePriceId,
      shortDescription: data.shortDescription,
      targetAudience: data.audience,
      assuredFeature: data.assuredFeature,
      guarentedFeature: data.guarentedFeature,
      supportedPaymentMethod: data.supportedPaymentMethod,
      smallDescription: data.smallDescription,
      stockKey: data.stockKey,
      goldProductType:
        parseInt(data.planType?.value) === 2 &&
        data.goldProductType &&
        data.goldProductType?.value > 0
          ? data.goldProductType
          : PRODUCT_GOLD_TYPE[0],
    };

    if (
      "supportedPaymentMethod" in data &&
      data?.supportedPaymentMethod.length === 0
    ) {
      toast.error("Supported payment method is required");
    }

    if (
      "assuredFeature" in data &&
      data?.assuredFeature.length === 0 &&
      "guarentedFeature" in data &&
      data?.guarentedFeature.length === 0
    ) {
      setTab(1);
      return;
    }

    const result = await (id
      ? dispatch(editSubscriptionAction(allData, id))
      : dispatch(addSubscriptionAction(allData)));

    if (result?.status) {
      navigate(RoutesPaths.REVIEWDETAIL, {
        state: {
          id: id ? id : result.data,
        },
      });
    }
  };

  const onCancel = () => {
    reset();
    setFileName({});
    if (id) {
      navigate(RoutesPaths.PLANLIST);
    }
  };

  return (
    <>
      <Header title="Subscription Plans" />
      <div className="container-fluid py-4 addPlanPage">
        <div className="row">
          <div
            className={`col-lg-${id ? 8 : 12} col-xl-${id ? 8 : 12} col-md-${
              id ? 8 : 12
            } col-sm-12 mb-2`}>
            <div className="planSegments">
              <div
                onClick={() => setTab(0)}
                className={tab === 0 ? "segment active" : "segment"}>
                <p>Plan profile</p>
              </div>
              <div
                onClick={() => setTab(1)}
                className={tab === 1 ? "segment active" : "segment"}>
                <p>Benefits</p>
              </div>
            </div>
            {tab === 0 ? (
              <>
                <PlanProfile
                  register={register}
                  errors={errors}
                  control={control}
                  Controller={Controller}
                  planTypeWatch={planTypeWatch}
                  fileName={fileName}
                  uploadFiles={uploadFiles}
                />
                {/* <PlanPrice register={register} errors={errors} /> */}
              </>
            ) : (
              <>
                <AssuredFeature
                  register={register}
                  errors={errors}
                  control={control}
                  resetField={resetField}
                  assuredFeature={
                    id && subscriptionById?.assuredFeature
                      ? subscriptionById?.assuredFeature
                      : [""]
                  }
                />
                <GuaranteFeature
                  register={register}
                  errors={errors}
                  control={control}
                  resetField={resetField}
                  guarentedFeature={
                    id && subscriptionById?.guarentedFeature
                      ? subscriptionById?.guarentedFeature
                      : [""]
                  }
                />
              </>
            )}
            <div className="buttonSection">
              <button
                onClick={onCancel}
                type="button"
                className="btn save-btn cancel">
                Cancel
              </button>
              <button
                type="submit"
                onClick={handleSubmit(onSubmit)}
                className="btn agree-button save-btn">
                Save changes
              </button>
            </div>
          </div>
          <div
            className={`col-lg-${id ? 3 : 12} col-xl-${id ? 3 : 12}3 col-md-${
              id ? 3 : 12
            } col-sm-12 mb-2`}>
            {id && (
              <div className="addPhotoCard priceid">
                <p>Status</p>
                <div className="selectStatus">
                  <Controller
                    name="status"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <Select
                        className="select-border"
                        {...field}
                        options={statsuOption}
                        styles={statusStyles}
                        getOptionLabel={(option) => option.title}
                        getOptionValue={(option) => option.value}
                        placeholder="Choose a Status"
                        components={{ IndicatorSeparator: () => null }}
                      />
                    )}
                  />
                  {errors.status?.type === "required" && (
                    <span className="errorMessage">Status is required</span>
                  )}
                </div>

                <div className="imageDesc">
                  <p>Set the product status.</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
