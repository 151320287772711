import React, { useEffect } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/header/header";
import { RoutesPaths } from "../../Router/constant";
import { useState } from "react";
import { coloroptions, notificationAction, statsuOption } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationAction } from "../../actions/notificationBanner";
import ReactPaginate from "react-paginate";
import { useRef } from "react";

export default function NotificationManager() {
  const [status, setStatus] = useState("");
  const [search, setSearch] = useState("");
  const [color, setColor] = useState("");
  const [page, setPage] = useState(1);
  const [activePage, setActivePage] = useState(0);
  const dispatch = useDispatch();
  const timeoutRef = useRef(null);
  const navigate = useNavigate();
  const { notificationBanner, count } = useSelector(
    (state) => state.notificationBanner
  );

  useEffect(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    if (search !== "") {
      timeoutRef.current = setTimeout(() => {
        dispatch(getNotificationAction(page, 20, status, color, search));
      }, 500);
    } else {
      dispatch(getNotificationAction(page, 20, status, color, search));
    }
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [search, page, color, status, dispatch]);

  const sortByStatus = (data, value) => {
    if (value === "color") {
      setColor(data.sendValue);
    } else {
      setStatus(data.sendValue);
    }
    setPage(1);
    setActivePage(0);
  };

  const onEdit = (value) => {
    navigate(RoutesPaths.ADDNOTIFICATIONBANNER, {
      state: {
        id: value.id,
      },
    });
  };
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setActivePage(selectedPage);
    setPage(selectedPage + 1);
  };

  return (
    <>
      <Header title="Notification Manager" />
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 mb-2">
            <div className="card planList notification h-100">
              <div className="card-header">
                <div className="searchbar">
                  <input
                    type="search"
                    placeholder="Search banner"
                    name="search"
                    id=""
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value.trimLeft());
                    }}
                  />
                  <img src="assets/img/searchIcon.svg" alt="" />
                </div>
                <div className="filter">
                  <div className="dropdownButton">
                    <img src="assets/img/downArrow.svg" alt="" />
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={
                        color === ""
                          ? "Search by color"
                          : color
                            ? "Solid yellow"
                            : "Solid red"
                      }
                    >
                      {coloroptions.map((item, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() => sortByStatus(item, "color")}
                        >
                          {item.title}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div>
                  <div className="dropdownButton">
                    <img src="assets/img/downArrow.svg" alt="" />
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={
                        status === ""
                          ? "Search by status"
                          : status
                            ? "Published"
                            : "Not published"
                      }
                    >
                      {statsuOption.map((item, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() => sortByStatus(item, "status")}
                        >
                          {item.title}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div>
                  <div className="addButton">
                    <img src="assets/img/addIcon.svg" alt="" />
                    <Link
                      to={RoutesPaths.ADDNOTIFICATIONBANNER}
                      className="customAddButton"
                    >
                      Add new banner
                    </Link>
                  </div>
                </div>
              </div>
              <div className="notedisclaimer">
                <p>Note: For dynamic reflection please use the below reference</p>
                <ul>
                  <li>Company name = ${'{companyName}'}</li>
                  <li>Subscription plan name = ${'{subscriptionPlan}'}</li>
                </ul>
              </div>
              <div
                className={
                  notificationBanner?.length > 0
                    ? "card-body"
                    : "card-body px-0"
                }
              >
                {notificationBanner.length > 0 ? (
                  notificationBanner.map((item, index) => (
                    <div key={index} className="bannerHolder row">
                      <div className="bannerDetail col-md-10 ps-md-0">
                        <div className="bannerName">
                          <p>
                            Label:{" "}
                            <span className="bannerHeading">{item.label}</span>
                          </p>
                          <p>
                            Status:{" "}
                            <span
                              className={
                                item.isActive ? "publish" : "notPublished"
                              }
                            >
                              {item.isActive ? "Published" : "Not Published"}
                            </span>
                          </p>
                        </div>
                        <div
                          className={`bannerPreview ${!item.color ? "" : "successBannrer"
                            }`}
                        >
                          <p>{item.message}</p>
                          <button
                            className={`btn save-btn bannerButton ${!item.color ? "" : "successButton"
                              }`}
                          >
                            {item?.callToAction?.value ===
                              notificationAction?.NOTI_MYINFO_VERIFICATION?.value ? (
                              <img src="assets/img/myInfoRetry.svg" alt="myInfo" />
                            ) : (
                              item?.callToAction?.label
                            )}
                          </button>
                          <button type="button" className="bannerClose">
                            <img src="assets/img/close-banner.svg" alt="" />
                          </button>
                        </div>
                      </div>
                      <div className="editBanner col-md-2 pe-md-0">
                        <button
                          onClick={() => onEdit(item)}
                          className="btn save-btn editButton"
                        >
                          Edit banner
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="d-flex pt-3">
                    <div className="d-flex flex-column justify-content-center">
                      <h6 className="mb-0 text-sm table-heading text-center">
                        No notification found
                      </h6>
                    </div>
                  </div>
                )}

                {/* <div className="viewAll">
                  <button
                    type="button"
                    className="btn save-btn editButton"
                  >
                    View All Banners
                  </button>
                </div> */}
              </div>
              {count > 1 && (
                <div className="pagination-section">
                  <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={count}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    forcePage={activePage}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
