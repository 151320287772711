import axionIns from "../axios";

const getBusinessInfo = async (
  page,
  perPage,
  search,
  planFilter,
  callToAction,
  isRegister,
  startDate,
  endDate,
  tangibleAsset,
  financialObjective,
  verificationMethod
) => {
  const url = "admin/getinfocompanies";
  const params = {
    page,
    perPage,
    search,
    suggestedPlan: planFilter,
    callToAction,
    singaporRegistered:
      isRegister === "yes" ? true : isRegister === "no" ? false : "",
    startDate,
    endDate,
    tangibleAsset,
    financialObjective,
    verificationMethod,
  };
  const response = await axionIns.get(url, { params });
  return response.data;
};

const editCompanyNotification = (companyId, id, value) => {
  return axionIns
    .put(
      `admin/${value === "cta"
        ? "updatenotificationbar"
        : value === "plan"
          ? "updatesubscriptionplan"
          : "updateCompanyDetails"
      }/${companyId}/${id}`
    )
    .then((response) => {
      return response.data;
    });
};

const editCompanyPosition = (id, payloadId) => {
  return axionIns
    .put(`admin/updateCompanyDetails/${id}`, JSON.stringify(payloadId))
    .then((response) => {
      return response.data;
    });
};
const updatebusinessInfo = (payload) => {
  return axionIns
    .put(`admin/updatebusinessinfo`, JSON.stringify(payload))
    .then((response) => {
      return response.data;
    });
};

const getAllCompanyAppointedList = () => {
  return axionIns.get("admin/company/list/appointed").then((response) => { return response.data });
};


const updateBobCta = (companyId, payload) => {
  return axionIns
    .put(`admin/company/updateBobCta/${companyId}`, JSON.stringify(payload))
    .then((response) => {
      return response.data;
    });
};

let exportObj = {
  getBusinessInfo,
  editCompanyNotification,
  updatebusinessInfo,
  getAllCompanyAppointedList,
  editCompanyPosition,
  updateBobCta
};

export default exportObj;
