import React from "react";
import { Modal } from "react-bootstrap";
import { BOB_SERVICES } from "../../constants";

export default function ContactInfo({ contactOpen, contactClose, bookOfBusinessResponseService }) {
  const handleclose = () => {
    contactClose();
  };
  return (
    <Modal
      show={contactOpen}
      onHide={handleclose}
      className="moddal-primary contactModal ps-0"
    >
      <Modal.Body>
        <div className="header">
          <i onClick={handleclose} className="fa-solid fa-xmark"></i>
        </div>
        <div className="contactBody">
          <h5>Contact Information</h5>
          <div className="contactProfile">
            {/* <div
              className="imageHolder"
              style={{ backgroundImage: "url(assets/img/ivancik.jpg)" }}
            ></div> */}
            <div className="contactName">
              <p>
                {BOB_SERVICES.PROFILES === bookOfBusinessResponseService ? contactOpen?.first_name + " " + contactOpen?.last_name : [BOB_SERVICES.COMPANIES, BOB_SERVICES.PRODUCT_AND_SERVICES].includes(bookOfBusinessResponseService) ? (BOB_SERVICES.COMPANIES === bookOfBusinessResponseService ? contactOpen.companyProfile : contactOpen.serviceProductName) + " " + (BOB_SERVICES.COMPANIES === bookOfBusinessResponseService ? contactOpen.companyType : "") : ""}
              </p>
            </div>
            <div className="contactNumber">
              <div className="textField">
                Phone:{" "}
                <ul>
                  {contactOpen?.mobileNumber?.map((item, index) => (
                    <li key={index}>
                      {item.areaCode?.dial_code}
                      {item?.number || "-"}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="textField">
                Email:{" "}
                <ul>
                  {contactOpen?.email?.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="importBtn">
          <button
            onClick={handleclose}
            type="submit"
            className="customAddButton removePadding"
          >
            GOT IT
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
