import React from "react";
import Header from "../../components/header/header";
import UploadFile from "./uploadFile";

export default function Files() {
  return (
    <>
      <Header title="Files" />
      <div className="container-fluid py-4">
        <div className="row">
          <div className={`col-lg-12 col-xl-12 col-md-12 mb-lg-0 mb-4`}>
            <div className="card planList filesList">
              <UploadFile />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
