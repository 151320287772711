import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/header/header";
import { getActivityLogsAction } from "../../actions/activityLogs";
import ProfileActivity from "../profile/profileActivity";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router";

export default function RequestedLogs() {
  const [page, setPage] = useState(1);
  const [activePage, setActivePage] = useState(0);
  const [pageStatus, setPageStatus] = useState({ title: "", value: "" });

  const location = useLocation()
  const dispatch = useDispatch();
  const { count, activityLogs, resetLogs } = useSelector((state) => state.activityLogs);
  useEffect(() => {
    dispatch(getActivityLogsAction(page, 20, pageStatus))
  }, [page, dispatch, location, resetLogs, pageStatus])

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setActivePage(selectedPage);
    setPage(selectedPage + 1);
  };


  const pageStatusOption = [
    { title: "All", value: "" },
    { title: "Company", value: "company" },
    { title: "Shareholder", value: "shareholder" },
    { title: "Director", value: "officer" },
    { title: "Capital", value: "capital" },
    { title: "Profile", value: "profile" },
    { title: "Subscription", value: "subscription" },
    { title: "Promoter", value: "promoter" },
    { title: "Invite", value: "invite" },
  ];

  const sortByStatus = (data) => {
    setPageStatus(data);
    setPage(1);
    setActivePage(0);
  };

  return (
    <>
      <Header title="Requests Logs" />
      <div className="container-fluid py-4">
        <div className="row">
          <div className={`col-lg-12 col-xl-12 col-md-12 mb-lg-0 mb-4`}>
            <div className="card">
              <ProfileActivity activity={activityLogs} fullView={true} pageStatus={pageStatus} sortByStatus={sortByStatus} pageStatusOption={pageStatusOption} />
            </div>
            {/* {activityLogs.length < count && (
              <div className="viewMore">
                <button type="button" className="btn agree-button save-btn" onClick={setPaginate}>View More</button>
              </div>
            )} */}

            {count > 1 && (
              <div className="pagination-section">
                <ReactPaginate
                  previousLabel={"Prev"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={count}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  forcePage={activePage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
