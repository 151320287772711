import notificationService from "../services/notification.service";
import {
  GET_NOTIFICATION_BANNERS,
  GET_NOTIFICATION_BY_ID,
  SET_FAILED_MESSAGE,
  SET_SUCCESS_MESSAGE,
  START_LOADER,
  STOP_LOADER,
} from "./types";

export const getNotificationAction =
  (page, perPage, isActive, color, search) => (dispatch) => {
    dispatch({ type: START_LOADER });
    return notificationService
      .getNotification(page, perPage, isActive, color, search)
      .then((resp) => {
        if (resp.status) {
          dispatch({
            type: GET_NOTIFICATION_BANNERS,
            payload: resp.data,
            count: resp.count,
          });
        } else {
          dispatch({ type: GET_NOTIFICATION_BANNERS, payload: [] });
        }
        dispatch({ type: STOP_LOADER });
      });
  };

export const getNotificationByIdAction = (id) => (dispatch) => {
  return notificationService.getNotificationById(id).then(async (resp) => {
    if (resp.status) {
      await dispatch({ type: GET_NOTIFICATION_BY_ID, payload: resp.data });
    } else {
      dispatch({ type: GET_NOTIFICATION_BY_ID, payload: {} });
    }
  });
};

export const addNotificationAction = (payload) => (dispatch) => {
  dispatch({ type: START_LOADER });
  return notificationService.addNotification(payload).then(async (resp) => {
    if (resp.status) {
      dispatch({
        type: SET_SUCCESS_MESSAGE,
        payload: resp.data,
      });
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
    dispatch({ type: STOP_LOADER });
  });
};

export const editNotificationAction = (payload, id) => (dispatch) => {
  dispatch({ type: START_LOADER });
  return notificationService.editNotification(payload, id).then((resp) => {
    if (resp.status) {
      dispatch({
        type: SET_SUCCESS_MESSAGE,
        payload: resp.data,
      });
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
    dispatch({ type: STOP_LOADER });
  });
};
