import taskService from "../services/task.service";
import { GET_TASK_LIST, SET_FAILED_MESSAGE, SET_SUCCESS_MESSAGE } from "./types";


export const getTaskList = () => async (dispatch) => {
  return taskService.getTask().then((resp) => {
    if(resp.status){
      dispatch({type: GET_TASK_LIST ,payload: resp.data,});
    }else{
      dispatch({type: GET_TASK_LIST ,payload: [],});
    }
  });
};