import axionIns from "../axios";
const signup = (payload) => {
  return axionIns.post("auth/signup", JSON.stringify(payload)).then((response) => {return response.data});
};
const login = (payload) => {
  return axionIns
    .post("auth/signin", JSON.stringify(payload)).then((response) => {return response.data;});
};

const socialLogin = (idToken) => {
  return axionIns
    .post("auth/social", {token: idToken}).then((response) => {return response.data;});
};
const logout = () => {
  return axionIns.post("admin/signout", {}).then((response) => {
    localStorage.removeItem("kimbocorp");
    localStorage.removeItem("token");
    return response.data;
  });
};

export default {
  signup,
  login,
  logout,
  socialLogin,
};
