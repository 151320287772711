import moment from "moment";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { approveActivityAction } from "../../actions/activityLogs";
import Deletemodel from "../../pages/dashboard/Deletemodel";
import Confirmmodel from "./Confirmmodel";
import TimeFormat from "../timeFormat/timeFormat";

export default function EditShareholder({ addModalShow, addModalClose, item }) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [confirmshow, setConfirmShow] = useState(false);
  const handleSubmit = async (flag) => {
    setConfirmShow(false);
    setShow(false)
    var payload = {
      type: item.pageType,
      id: item.id,
      itemType: item.type,
      shareholderId: item.shareholders?._id,
      flag: flag,
    };
    await dispatch(approveActivityAction(payload));
    addModalClose();
  };
  return (
    <>
      {item &&
        item.company &&
        item.company._id &&
        item.type != "remove_invite" && (
          <Modal
            show={addModalShow}
            onHide={addModalClose}
            className="moddal-width companyEdit-modal ps-0 add-modal"
          >
            <div className="modal-header pt-2">
              <button
                type="button"
                className="btn-close text-dark close-button"
                onClick={addModalClose}
              >
                &nbsp;
                {/* <span aria-hidden="true">×</span> */}
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xl-3">
                  <h4 className="edit-title">Request</h4>
                  <span className="text-xs">
                    <TimeFormat time={item.createdAt} />
                  </span>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xl-4">
                  <h6>Request Type</h6>
                  <h4>{item.description}</h4>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 col-xl-5">
                  <h6>Company name</h6>
                  <h4>{item.company?.companyName}</h4>
                </div>
              </div>
              <div className="row paddingtopbtm">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xl-4">
                  <h6>Requested by</h6>
                  <div>
                    {item.user
                      ? item.user.first_name + " " + item.user.last_name
                      : "-"}
                  </div>
                </div>
                {/* <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xl-4">
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-8">
                    <h6>Requester Position</h6>
                    <div>CEO</div>
                  </div>
                </div>
              </div> */}
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xl-4 ">
                  <div className="row">
                    <div className="col-md-5"></div>
                    <div className="col-md-7">
                      <h6>Company head email</h6>
                      <div>{item.user ? item.user.email : "-"}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row paddingtopbtm">
                <div className="col-md-12">
                  <h6>Request Details</h6>
                </div>
              </div>

              <div className="row paddingtopbtm">
                {item.description !== "Add New Shareholder" &&
                  item.description !== "Remove Shareholder Invite" &&
                  "shareholders_history" in item && (
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xl-6 curencybox">
                      <div className="row paddingtopbtm">
                        <div className="col-md-6">
                          <h6>Shareholder Full Name</h6>
                          <div className="text-wraper">
                            {item.shareholders_history &&
                            item.shareholders_history.name
                              ? item.shareholders_history.name
                              : "-"}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <h6>Shareholder ID Number</h6>
                          <div className="text-wraper">
                            {item.shareholders_history &&
                            item.shareholders_history._id
                              ? item.shareholders_history._id
                              : "-"}
                          </div>
                        </div>
                      </div>

                      <div className="row paddingtopbtm">
                        <div className="col-md-6">
                          <h6>Shareholder Email</h6>
                          <div className="text-wraper">
                            {item.shareholders_history &&
                            item.shareholders_history.email
                              ? item.shareholders_history.email
                              : "-"}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <h6>Mobile Number</h6>
                          <div className="text-wraper">
                            {item.shareholders_history &&
                            item.shareholders_history.phoneNumber
                              ? item.shareholders_history.phoneNumber
                              : "-"}
                          </div>
                        </div>
                      </div>

                      <div className="row paddingtopbtm">
                        <div className="col-md-6">
                          <h6>Nationality</h6>
                          <div className="text-wraper">
                            {item.shareholders_history &&
                            item.shareholders_history.nationality
                              ? item.shareholders_history.nationality
                              : "-"}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <h6>Position</h6>
                          <div className="text-wraper">
                            {item.shareholders_history &&
                              item.shareholders_history.position &&
                              (item.shareholders_history.position === 2
                                ? "Director of sales"
                                : item.shareholders_history.position === 3
                                ? "Secretary of sales"
                                : "-")}
                          </div>
                        </div>
                      </div>
                      <div className="row paddingtopbtm">
                        <div className="col-md-6">
                          <h6>Passport Copy</h6>
                          <div className="text-wraper">
                            {item.shareholders_history &&
                            item.shareholders_history.passportCopy?.fileName
                              ? item.shareholders_history.passportCopy
                                  ?.originalName
                              : "-"}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <h6>POA</h6>
                          <div className="text-wraper">
                            {item.shareholders_history &&
                            item.shareholders_history.poa?.fileName
                              ? item.shareholders_history.poa?.originalName
                              : "-"}
                          </div>
                        </div>
                      </div>
                      <div className="row paddingtopbtm">
                        <div className="col-md-6">
                          <h6>Address Line 1</h6>
                          <div>
                            {item.shareholders_history &&
                            item.shareholders_history.address1
                              ? item.shareholders_history.address1
                              : "-"}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <h6>Address Line 2</h6>
                          <div className="text-wraper">
                            {item.shareholders_history &&
                            item.shareholders_history.address2
                              ? item.shareholders_history.address2
                              : "-"}
                          </div>
                        </div>
                      </div>
                      <div className="row paddingtopbtm">
                        <div className="col-md-4">
                          <h6>City</h6>
                          <div className="text-wraper">
                            {item.shareholders_history &&
                            item.shareholders_history.city
                              ? item.shareholders_history.city
                              : "-"}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <h6>Country</h6>
                          <div className="text-wraper">
                            {item.shareholders_history &&
                            item.shareholders_history.country
                              ? item.shareholders_history.country
                              : "-"}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <h6>Post Code</h6>
                          <div className="text-wraper">
                            {item.shareholders_history &&
                            item.shareholders_history.postalCode
                              ? item.shareholders_history.postalCode
                              : "-"}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                <div
                  className={`col-lg-${
                    item.description !== "Add New Shareholder" &&
                    "shareholders_history" in item
                      ? "6"
                      : "8"
                  } col-md-${
                    item.description !== "Add New Shareholder" &&
                    "shareholders_history" in item
                      ? "6"
                      : "8"
                  } col-sm-${
                    item.description !== "Add New Shareholder" &&
                    "shareholders_history" in item
                      ? "6"
                      : "8"
                  } col-xs-${
                    item.description !== "Add New Shareholder" &&
                    "shareholders_history" in item
                      ? "6"
                      : "12"
                  } col-xl-${
                    item.description !== "Add New Shareholder" &&
                    "shareholders_history" in item
                      ? "6"
                      : "8"
                  } addressbox`}
                >
                  <div className="row paddingtopbtm">
                    <div className="col-md-6">
                      <h6>Shareholder Full Name</h6>
                      <div className="textclr">
                        {item.shareholders ? item.shareholders.name : "-"}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h6>Shareholder ID Number</h6>
                      <div className="textclr">
                        {item.shareholders && item.shareholders._id
                          ? item.shareholders._id
                          : "-"}
                      </div>
                    </div>
                  </div>

                  <div className="row paddingtopbtm">
                    <div className="col-md-6">
                      <h6>Shareholder Email</h6>
                      <div className="textclr">
                        {item.shareholders && item.shareholders.email
                          ? item.shareholders.email
                          : "-"}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h6>Mobile Number</h6>
                      <div className="textclr">
                        {item.shareholders && item.shareholders.phoneNumber
                          ? item.shareholders.phoneNumber
                          : "-"}
                      </div>
                    </div>
                  </div>

                  {item.description !== "Add New Shareholder" &&
                    item.description !== "Remove Shareholder Invite" && (
                      <>
                        <div className="row paddingtopbtm">
                          <div className="col-md-6">
                            <h6>Nationality</h6>
                            <div className="textclr">
                              {item.shareholders &&
                              item.shareholders.nationality
                                ? item.shareholders.nationality
                                : "-"}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <h6>Position</h6>
                            <div className="textclr">
                              {item.shareholders &&
                                item.shareholders.position &&
                                (item.shareholders.position === 2
                                  ? "Director of sales"
                                  : item.shareholders.position === 3
                                  ? "Secretary of sales"
                                  : "-")}
                            </div>
                          </div>
                        </div>
                        <div className="row paddingtopbtm">
                          <div className="col-md-6">
                            <h6>Passport Copy</h6>
                            <div className="textclr">
                              {item.shareholders &&
                              item.shareholders.passportCopy?.filename
                                ? item.shareholders.passportCopy?.originalName
                                : "-"}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <h6>POA</h6>
                            <div className="textclr">
                              {item.shareholders &&
                              item.shareholders.poa?.filename
                                ? item.shareholders.poa?.originalName
                                : "-"}
                            </div>
                          </div>
                        </div>
                        <div className="row paddingtopbtm">
                          <div className="col-md-6">
                            <h6>Address Line 1</h6>
                            <div className="textclr">
                              {item.shareholders && item.shareholders.address1
                                ? item.shareholders.address1
                                : "-"}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <h6>Address Line 2</h6>
                            <div className="textclr">
                              {item.shareholders && item.shareholders.address2
                                ? item.shareholders.address2
                                : "-"}
                            </div>
                          </div>
                        </div>
                        <div className="row paddingtopbtm">
                          <div className="col-md-4">
                            <h6>City</h6>
                            <div className="textclr">
                              {item.shareholders && item.shareholders.city
                                ? item.shareholders.city
                                : "-"}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <h6>Country</h6>
                            <div className="textclr">
                              {item.shareholders && item.shareholders.country
                                ? item.shareholders.country
                                : "-"}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <h6>Post Code</h6>
                            <div className="textclr">
                              {item.shareholders && item.shareholders.postalCode
                                ? item.shareholders.postalCode
                                : "-"}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                </div>

                <div className="row paddingtopbtm">
                  <div className="col-md-5">
                    <button
                      type="submit"
                      className="btn save-btn savebtn"
                      onClick={addModalClose}
                    >
                      BACK
                    </button>
                  </div>
                  {item.status === 1 ? (
                    <>
                      <div className="col-md-3">
                        <button
                          type="submit"
                          className="btn save-btn deny"
                          onClick={() => setShow(true)}
                        >
                          DENY
                        </button>
                      </div>
                      <Deletemodel
                        message="Are you sure you want to deny?"
                        addModalShow={show}
                        addModalClose={() => setShow(false)}
                        confirmModal={handleSubmit}
                      />

                      <div className="col-md-4">
                        <button
                          type="submit"
                          className="btn save-btn allow"
                          onClick={() => setConfirmShow(true)}
                        >
                          ALLOW AMMENDMENT
                        </button>
                      </div>
                      <Confirmmodel
                        message="Are you sure you want to allow this Ammendment?"
                        addModalShow={confirmshow}
                        addModalClose={() => setConfirmShow(false)}
                        confirmModal={handleSubmit}
                      />
                    </>
                  ) : (
                    <div className="col-md-7 save-btn ">
                      <h6 className="alignright">
                        {item.status === 2
                          ? "Verified"
                          : item.status === 3
                          ? "Denied"
                          : "-"}
                      </h6>
                      <div className="text-xs alignright">
                        <TimeFormat item={item.createdAt}/>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal>
        )}

      {item &&
        item.company &&
        item.company._id &&
        item.type === "remove_invite" && (
          <Modal
            show={addModalShow}
            onHide={addModalClose}
            className="moddal-width companyEdit-modal ps-0 add-modal"
          >
            <div className="modal-header pt-2">
              <button
                type="button"
                className="btn-close text-dark close-button"
                onClick={addModalClose}
              >
                &nbsp;
                {/* <span aria-hidden="true">×</span> */}
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xl-3">
                  <h4 className="edit-title">Request</h4>
                  <span className="text-xs">
                    <TimeFormat item={item.createdAt}/>
                  </span>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xl-4">
                  <h6>Request Type</h6>
                  <h4>{item.description}</h4>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 col-xl-5">
                  <h6>Company name</h6>
                  <h4>{item.company?.companyName}</h4>
                </div>
              </div>
              <div className="row paddingtopbtm">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xl-4">
                  <h6>Requested by</h6>
                  <div>
                    {item.user
                      ? item.user.first_name + " " + item.user.last_name
                      : "-"}
                  </div>
                </div>
                {/* <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xl-4">
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-8">
                    <h6>Requester Position</h6>
                    <div>CEO</div>
                  </div>
                </div>
              </div> */}
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xl-4 ">
                  <div className="row">
                    <div className="col-md-5"></div>
                    <div className="col-md-7">
                      <h6>Company head email</h6>
                      <div>{item.user ? item.user.email : "-"}</div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="row paddingtopbtm">
                <div className="col-md-12">
                  <h6>Request Details</h6>
                </div>
              </div> */}

              <div className="row paddingtopbtm">
                <div className="row paddingtopbtm">
                  <div className="col-md-5">
                    <button
                      type="submit"
                      className="btn save-btn savebtn"
                      onClick={addModalClose}
                    >
                      BACK
                    </button>
                  </div>
                  {item.status === 1 ? (
                    <>
                      <div className="col-md-3">
                        <button
                          type="submit"
                          className="btn save-btn deny"
                          onClick={() => setShow(true)}
                        >
                          DENY
                        </button>
                      </div>
                      <Deletemodel
                        message="Are you sure you want to deny?"
                        addModalShow={show}
                        addModalClose={() => setShow(false)}
                        confirmModal={handleSubmit}
                      />

                      <div className="col-md-4">
                        <button
                          type="submit"
                          className="btn save-btn allow"
                          onClick={() => setConfirmShow(true)}
                        >
                          ALLOW AMMENDMENT
                        </button>
                      </div>
                      <Confirmmodel
                        message="Are you sure you want to allow this Ammendment?"
                        addModalShow={confirmshow}
                        addModalClose={() => setConfirmShow(false)}
                        confirmModal={handleSubmit}
                      />
                    </>
                  ) : (
                    <div className="col-md-7 save-btn ">
                      <h6 className="alignright">
                        {item.status === 2
                          ? "Verified"
                          : item.status === 3
                          ? "Denied"
                          : "-"}
                      </h6>
                      <div className="text-xs alignright">
                        <TimeFormat item={item.updatedAt}/>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal>
        )}
    </>
  );
}
