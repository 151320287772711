import React, { useState } from "react";
// import Footer from "../../components/footer";
import Header from "../../components/header/header";
import { useSelector } from "react-redux";
import IndividualInviteTable from "../InvitesIndividual/IndividualInviteTable";

export default function Invites() {
  const [modalShow, setModalShow] = useState(false);

  const modalClose = () => {
    setModalShow(false);
  };
  const handleShow = () => setModalShow(true);


  return (
    <>
      <Header title="Invites" subtitle="Invites" />
      <div className="container-fluid inner-container-fluid py-4 invite-page paddingBottomMD">
        <div className="row">
          <div className="col-md-3 mb-lg-0 mb-4">
              <>
                <h5 className="main-page-title">Referral invitations</h5>
              </>
          </div>
          <div className="col-md-9 mb-lg-0 mb-4">
            <div className="mb-4">
              <IndividualInviteTable />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
