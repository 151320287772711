import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

export default function FileDragDrop({ onUpload, multiple }) {
  const onDrop = useCallback((acceptedFiles) => {
    if (multiple) {
      onUpload(acceptedFiles);
    } else {
      acceptedFiles.forEach((element) => {
        onUpload(element);
      });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <>
      <div className="upload-img-holder" {...getRootProps()}>
        <input {...getInputProps()} />
        <div>
          <img src="assets/img/upload-cloud.png" alt="" />
          <p className="drag-drop-text">
            click to browse or drag and drop files here
          </p>
        </div>
      </div>
    </>
  );
}
