import axionIns from "../axios";

const getSubscription = () => {
  return axionIns.get("admin/subscription").then((response) => {
    return response.data;
  });
};

const getSubscriptionPlan = (page, perPage, isActive, search) => {
  var url =
    "admin/subscriptionplan?page=" +
    page +
    "&perPage=" +
    perPage +
    "&isActive=" +
    isActive +
    "&search=" +
    search;
  return axionIns.get(url).then((response) => {
    return response.data;
  });
};
const getPlanById = (id) => {
  var url = `admin/subscriptionplan/${id}`;
  return axionIns.get(url).then((response) => {
    return response.data;
  });
};

const updateSubscription = (payload) => {
  return axionIns.put("admin/subscription", payload).then((response) => {
    return response.data;
  });
};

const addSubscription = (payload) => {
  return axionIns.post("admin/subscriptionplan", payload).then((response) => {
    return response.data;
  });
};

const editSubscription = (payload,id) => {
  return axionIns.put(`admin/subscriptionplan/${id}`, payload).then((response) => {
    return response.data;
  });
};

const exportObject = {
  getSubscription,
  updateSubscription,
  getSubscriptionPlan,
  getPlanById,
  addSubscription,
  editSubscription,
};
export default exportObject;
