import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";
import { backendUrl, TASK_STATUS_ENUM } from "../../constants";

export default function CardModal({ item, taskCardShow, taskCardClose, editItem }) {
  return (
    <>
      <Modal show={taskCardShow} onHide={taskCardClose}>
        <div className="modal-header task-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {item.title ? item.title : '-'}
          </h5>
        </div>
        <div className="modal-body task-img-padding">
          <div className="task-holder">
            <h6 className="mb-0 text-sm table-heading">Holding company name:</h6>
            <p className="business-font activity_wrapper">{item?.company?.companyName ? item?.company?.companyName : '-'}</p>
          </div>
          <div className="task-holder ">
            <h6 className="mb-0 text-sm table-heading">Login ID:</h6>
            <p className="business-font activity_wrapper">{item?.user?.email ? item?.user?.email : '-'}</p>
          </div>
          <div className="task-holder ">
            <h6 className="mb-0 text-sm table-heading">Username:</h6>
            <p className="business-font activity_wrapper">{item?.user?.first_name ? item?.user?.first_name + " " + item?.user?.last_name : '-'}</p>
          </div>
          <div className="task-holder">
            <h6 className="mb-0 text-sm table-heading">Task Description:</h6>
            <p className="business-font description-busi">
              {item.description ? item.description : '-'}
            </p>
          </div>
          {/* <span className="business-activities">Task Description</span>
          <p className="business-activities">
            {item.description ? item.description : '-'}
          </p> */}
          {/* <hr /> */}
          <div className="card-status">
            <div className="text-status">
              <p>Status</p>
              <span className={item.status === TASK_STATUS_ENUM.BACKLOG ? 'active' : item.status === TASK_STATUS_ENUM.INPROGRESS ? 'inprogress' : 'complete'}>{item.status === TASK_STATUS_ENUM.BACKLOG ? 'BACKLOG' : item.status === TASK_STATUS_ENUM.INPROGRESS ? 'In Progress' : 'Complete'}</span>
            </div>
            <div className="text-status">
              <p>Progress</p>
              <span className="">{item.progress ? item.progress : 0}%</span>
              <div className="progress task-progress">
                <div
                  className="progress-bar task-progress-color"
                  role="progressbar"
                  aria-valuenow={item.progress ? item.progress : 0}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: (item.progress ? item.progress : 0) + "%" }}
                ></div>
              </div>
            </div>
            <div className="text-status">
              <p>Due Date</p>
              <span className="font-weight-bolder payment-text">
                {item?.dueDate ? moment(item?.dueDate).format('MM/DD/yyyy') : '-'}
              </span>
            </div>
            <div className="text-status">
              <p>{item.attachments && item.attachments.length > 0 ? item.attachments.length : 'No'} Attachments</p>
            </div>
            {item.attachments.length > 0 && (
              <div className="attachmentsItems">
                {item.attachments.map((attachItem, index) => (
                  <p key={index}>
                    <a href={backendUrl + attachItem.filename} target="_blank" rel="noreferrer">{attachItem.originalName}</a></p>
                ))}
              </div>
            )}
          </div>
          {/* <div className="upload-here-img-task">
            <div className="img-width-task">
              <img
                src="../assets/img/upload-cloud.png"
                className="upload-task"
                alt=""
              />
              <label className="drop-text">
                click to browse or drag and drop files here
                <input type="file" hidden name="" id="img-dropp" />
              </label>
            </div>
          </div> */}
        </div>
      </Modal>
    </>
  );
}
