import axionIns from "../axios";

const getPayment = async (page, perPage) => {
  return axionIns.get(`admin/payments?page=${page}&perPage=${perPage ? perPage : 10}`).then((response) => { return response.data });
};

const updatePaymentStatus = async (id, payload) => {
  return axionIns.put("admin/payments/" + id, JSON.stringify(payload)).then((response) => { return response.data });
};

const updatePaymentApprovalStatus = async (id, payload) => {
  return axionIns.put("admin/paymentsapproval/" + id, JSON.stringify(payload)).then((response) => { return response.data });
};

const exportOject = {
  getPayment,
  updatePaymentStatus,
  updatePaymentApprovalStatus
};

export default exportOject;
