import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addBusinessGroupAction, editBusinessGroupAction } from "../../actions/bookOfBusiness";
import { BOB_SERVICES, BOB_SERVICES_NAME, CUSTOM_SELECT_STYLES, POTENTIAL_PURPOSE_COMPANIES, POTENTIAL_PURPOSE_PRODUCT_SERVICES, POTENTIAL_PURPOSE_PROFILES, SELECT_BOX_THEME } from "../../constants";
import Select from "react-select";

export default function BusinessGroup({ businessGroupPopup, isEdit, closePopup, bookOfBusiness }) {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    resetField,
    formState: { errors },
    reset,
  } = useForm({ mode: "onChange" });
  const watchServiceFields = watch("service")
  const watchPositionFields = watch("position")
  const dispatch = useDispatch();
  const handleclose = () => {
    reset();
    closePopup();
  };

  useEffect(() => {
    if (isEdit && bookOfBusiness?.id) {
      setValue('service', bookOfBusiness?.service ? bookOfBusiness?.service.toString() : null || null)
      setValue('name', bookOfBusiness?.name || null)
      setValue('industry', bookOfBusiness?.industry || null)

      const position = bookOfBusiness?.position || null
      const service = bookOfBusiness?.service || null
      if (position && service) {
        var positionList = BOB_SERVICES.PROFILES===service ? POTENTIAL_PURPOSE_PROFILES : BOB_SERVICES.PRODUCT_AND_SERVICES===service ? POTENTIAL_PURPOSE_PRODUCT_SERVICES : BOB_SERVICES.COMPANIES===service ? POTENTIAL_PURPOSE_COMPANIES : []
        setValue('position', positionList.filter(x=>x.value === position)[0] || null)
      }
    }
  }, [isEdit, bookOfBusiness, setValue])

  const onSubmit = async (data) => {
    if (data) {
      if (isEdit) {
        if (bookOfBusiness?.id) {
          await dispatch(editBusinessGroupAction(data, bookOfBusiness?.id))
        }
      } else {
        await dispatch(addBusinessGroupAction(data))
      }
      handleclose()
    }
  };


  useEffect(() => {
    if(!isEdit){
      setValue("position", null)
    }
    showPotentialPurpose()
  }, [watchServiceFields])

  const showPotentialPurpose = () => {
    const service = parseInt(getValues('service'))
    var potentialList = []
    switch (service) {
      case BOB_SERVICES.PROFILES:
        potentialList =  POTENTIAL_PURPOSE_PROFILES
        break;
      case BOB_SERVICES.PRODUCT_AND_SERVICES:
        potentialList =  POTENTIAL_PURPOSE_PRODUCT_SERVICES
        break;
      case BOB_SERVICES.COMPANIES:
        potentialList =  POTENTIAL_PURPOSE_COMPANIES
        break;
      default:
        break;
    }
    return potentialList
  }

  return (
    <>
      <Modal
        show={businessGroupPopup}
        onHide={handleclose}
        className="moddal-primary importModal ps-0"
      >
        <Modal.Body>
          <div className="header">
            <div>
              <h4>{isEdit ? "Edit" : "Create"} book of business</h4>
            </div>
            <i onClick={handleclose} className="fa-solid fa-xmark"></i>
          </div>
          <div className="modalBody">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row mb-3">
                <div className="col-md-12">
                  <ul className="product-services">
                    {Object.keys(BOB_SERVICES_NAME).map(key => (
                      <li key={key}>
                        <input
                          id={key}
                          value={key}
                          type="radio"
                          disabled={isEdit ? true : false}
                          className=""
                          {...register("service", { required: isEdit ? false : true })}
                        />
                        <label htmlFor={key}>{BOB_SERVICES_NAME[key]}</label>

                      </li>
                    ))}
                    <div>
                      {errors.service?.type === "required" && (
                        <span className="errorMessage">Service is required</span>
                      )}
                    </div>
                  </ul>
                </div>
                <div className="col-md-12">
                  <label className="labelForm" htmlFor="">
                    Book of business name *
                  </label>
                  <input
                    maxLength={50}
                    autoComplete="off"
                    type="text"
                    className="form-control"
                    placeholder="Business name"
                    {...register("name", { required: true })}
                  />
                  <p className="disclaimerInfo">A product name is required and recommended to be unique.</p>
                  {errors.name?.type === "required" && (
                    <span className="errorMessage">Business name is required</span>
                  )}
                </div>
                <div className="col-md-12">
                  <label className="labelForm" htmlFor="">
                    Industry *
                  </label>
                  <input
                    maxLength={50}
                    autoComplete="off"
                    type="text"
                    className="form-control"
                    placeholder="industry"
                    {...register("industry", { required: true })}
                  />
                  <p className="disclaimerInfo">Brief description of your target demographic (e.g. For individuals seeking wealth accumulation...).</p>
                  {errors.industry?.type === "required" && (
                    <span className="errorMessage">Industry is required</span>
                  )}
                </div>
                <div className="col-md-12">
                  <label className="labelForm" htmlFor="">
                    Potential purpose *
                  </label>
                  <Controller
                    name="position"
                    control={control}
                    rules={{ required: true }}
                    // defaultValue={null}
                    render={({ field }) => (
                      <Select
                        theme={SELECT_BOX_THEME}
                        styles={CUSTOM_SELECT_STYLES}
                        className="select-border"
                        {...field}
                        isDisabled={bookOfBusiness?.isDefault ? true : false}
                        options={showPotentialPurpose()}
                        isClearable={true}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.value}
                        placeholder="Choose potential purpose..."
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    )}
                  />
                  {errors.position?.type === "required" && (
                    <span className="errorMessage">Potential purpose is required</span>
                  )}
                </div>
              </div>

              <div className="importBtn">
                <button
                  type="submit"
                  // disabled={!isValid}
                  className="customAddButton removePadding"
                >
                  {isEdit ? "Edit" : "Add"} book of business
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
