export const RoutesPaths = {
  DASHBOARD: "/dashboard",
  SUBSCRIPTION: "/subscription",
  PAYMENT: "/payments",
  FILE: "/documents",
  INVITE: "/requested-logs",
  TASK: "/task",
  SIGNIN: "/",
  SIGNUP: "/sign-up",
  PROFILE: "/profile",
  SECURITY: "/security",
  ACCOUNTSETTING: "/accountsetting",
  PLANLIST: "/planlist",
  ADDPLAN: "/plan",
  REVIEWDETAIL: "/reviewdetail",
  BUSINESSDETAIL: "/businessdetail",
  NOTIFICATIONMANAGER: "/notificationmanager",
  ADDNOTIFICATIONBANNER: "/addnotification",
  MESSAGE_DETAIL: "/chat",
  OBJECTIVE: "/objective",
  ADDOBJECTIVE: "/manage-objective",
  BOOK_OF_BUSINESS: "/book-of-business",
  SETTINGS: "/settings",
  REFERRAL_INVITES: "/invites",
  USER_MANAGER: "/user",
  BANNER: "/banner",
  ADDBANNER: "/banner/addbanner"
};
