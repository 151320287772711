import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { SUBSCRIPTION_MODULE_TYPE_NAME, backendUrl, country } from "../../constants";
import TimeFormat from "../timeFormat/timeFormat";
import Currency from "../currency/currency";
import CurrencyFormat from "../currencyFormat/currencyFormat";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { updatePaymentStatusAction } from "../../actions/payment";

export default function PaymentActionDetails({ item, addActionModalShow, addActionModalClose }) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {

    const payload = {
      status: data.paymentStatus
    }
    await dispatch(updatePaymentStatusAction(item?.id, payload))
    addActionModalClose()
    reset()
  }

  const handleBadgeClick = (value) => {
    setValue("paymentStatus", value);
  };

  const statusArray = [
    { name: "ATTEMPTED TO PAY" },
    { name: "PAID" },
    { name: "CANCELED" },
    { name: " IN-TRANSIT" },
    { name: "HOLD" },
    { name: "TRANSACTION APPROVED" },
    { name: "BULLION PROCURED" },
    { name: "BULLION IN-VAULT" }
  ]

  return (
    <>
      <Modal
        show={addActionModalShow}
        onHide={addActionModalClose}
        className="actionmodal-width companyEdit-modal ps-0 add-modal"
      >
        <div className="modal-header actionModalHeader">
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={addActionModalClose}
          >
            &nbsp;
          </button>
        </div>
        <div className="modal-body">

          <div className="row">
            <div className="co-12 edit-title headerTitle">
              <h6 className="m-0">Payment action</h6>
            </div>
            <div className="col-12 paymentPaddingTopbottom PaymentflexSection">
              <h6 className="m-0 paymentTitle">Payment by :&nbsp;</h6>
              <div className="paymentdetails">{item?.user?.first_name} {item?.user?.last_name} ( {item?.user?.email} )</div>
            </div>
            <div className="col-12 paymentPaddingTopbottom PaymentflexSection">
              <h6 className="m-0 paymentTitle">Item :&nbsp;</h6>
              <div className="paymentdetails">
                {SUBSCRIPTION_MODULE_TYPE_NAME[item?.subscriptionModuleType]} {item?.subscriptionPlan?.name ? `|| ${item?.subscriptionPlan?.name}` : ''}
              </div>
            </div>
          </div>

          <div className="row">
            <form onSubmit={handleSubmit(onSubmit)}>

              <div className="col-md-12">
                <h6 className="m-0 paymentTitle">Payment status :</h6>
              </div>

              <div className="col-md-12">
                <input
                  type="text"
                  maxLength={50}
                  autoComplete="off"
                  className="form-control"
                  placeholder="Payment status"
                  {...register("paymentStatus", { required: true })}
                ></input>
                {errors.paymentStatus?.type === "required" && (
                  <span className="errorMessage">Payment Status is required</span>
                )}
              </div>

              <div className="col-12 pt-1">
                {statusArray?.map((info, index) => (
                  <button type="button" disabled={item?.payment_status?.toUpperCase() === info?.name} className="badge" key={index} onClick={() => handleBadgeClick(info?.name)}>
                    {info?.name}
                  </button>
                ))}
                {/* <div className="badge" onClick={() => handleBadgeClick("PAID")}>
                  PAID
                </div>
                <div className="badge" onClick={() => handleBadgeClick("CANCELED")}>
                  CANCELED
                </div>
                <div className="badge" onClick={() => handleBadgeClick("IN-TRANSIT")}>
                  IN-TRANSIT
                </div>
                <div className="badge" onClick={() => handleBadgeClick("HOLD")}>
                  HOLD
                </div>
                <div className="badge" onClick={() => handleBadgeClick("TRANSACTION APPROVED")}>
                  TRANSACTION APPROVED
                </div>
                <div className="badge" onClick={() => handleBadgeClick("BULLION PROCURED")}>
                  BULLION PROCURED
                </div>
                <div className="badge" onClick={() => handleBadgeClick("BULLION IN-VAULT")}>
                  BULLION IN-VAULT
                </div> */}

              </div>

              <div className="col-12 saveActionBtn">
                <button type="submit" className="btn agree-button">Save</button>
              </div>
            </form>

          </div>

        </div>
      </Modal >
    </>
  );
}
