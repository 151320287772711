import documentsService from "../services/files.service";
import { GET_DOCUMENTS, START_LOADER, STOP_LOADER } from "./types";

export const getDocumentAction = (page, perPage,id) => (dispatch) => {
  dispatch({type: START_LOADER});
  return documentsService.getDocuments(page, perPage, id).then((resp) => {
    if(resp.status){
      dispatch({type: GET_DOCUMENTS ,payload: resp.data, page: page, count: resp.count});
      dispatch({type: STOP_LOADER});
    }else{
      dispatch({type: GET_DOCUMENTS ,payload: [], page: page, count: 0});
      dispatch({type: STOP_LOADER});
    }
  });
};