import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import Currency from "../../components/currency/currency";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  editSubscriptionAction,
  getPlanById,
} from "../../actions/subscription";
import { backendUrl } from "../../constants";
import { RoutesPaths } from "../../Router/constant";
import CurrencyFormat from "../../components/currencyFormat/currencyFormat";

export default function ReviewPlanDetail() {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = state?.id;

  const { subscriptionById } = useSelector((state) => state.subscription);
  useEffect(() => {
    if (id) {
      dispatch(getPlanById(id));
    }
  }, [id, dispatch]);
  const [price, setPrice] = useState("Annual");
  const onSubmit = async () => {
    const allData = {
      name: subscriptionById.planName,
      file: subscriptionById.image,
      isActive: true,
      targetAudience: subscriptionById.targetAudience,
      monthlyPriceId: subscriptionById.monthlyPriceId,
      annualPriceId: subscriptionById.annualPriceId,
      assuredFeature: subscriptionById.assuredFeature,
      guarentedFeature: subscriptionById.guarentedFeature,
    };
    dispatch(editSubscriptionAction(allData, id));
    navigate(RoutesPaths.PLANLIST, { replace: true });
  };

  const handlePlanChange = (value) => {
    setPrice(value ? "Month" : "Annual");
  };

  return (
    <>
      <Header title="Review Plan Details" />
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 mb-2">
            <div className="card planList reviewPlan h-100">
              <h5 className="heading">Review Plan Details</h5>
              <div className="preview">
                <div className="previewHeader">
                  <span>Here is the final look of your subscription plan:</span>
                </div>

                <div className="previewBody">
                  <div className="row content-text-payment">
                    <div className="col-md-6 col-12 planCol">
                      <div className="leftHolder">
                        <div
                          className="planBack"
                          style={{
                            backgroundImage: subscriptionById.image
                              ? `url(${backendUrl + subscriptionById.image.filename
                              })`
                              : "url(/assets/img/planBack.png)",
                          }}
                        >
                          <div className="overlay">
                            <h4>{subscriptionById.name}</h4>
                          </div>
                        </div>
                        <div className="plandesc" dangerouslySetInnerHTML={{__html: subscriptionById.targetAudience,}}>
                        </div>
                        <div className="planPrice">
                          <h1 className="payment-text number-text">
                            <Currency currency={subscriptionById?.currency?.value} />
                            <CurrencyFormat currency={subscriptionById?.currency?.value} value={(subscriptionById?.priceResponse && parseFloat(subscriptionById?.priceResponse?.amount) > 0) ? parseFloat(subscriptionById?.priceResponse?.amount) : parseFloat(subscriptionById?.price)}
                            decimal={2}/>
                          </h1>
                          {/* <div className="annually-toggal-row">
                            <div className="annually-toggal">
                              <label
                                className="form-check-label annually-lable active"
                                htmlFor="rememberMe"
                              >
                                Annually
                              </label>
                              {subscriptionById?.stripePriceId && (
                                <>
                                  <div className="form-check form-switch annually-btn">
                                    <input
                                      maxLength={50}
                                      autoComplete="off"
                                      className="form-check-input"
                                      type="checkbox"
                                      id="rememberMe"
                                      // checked={
                                      //   stripeSubscription &&
                                      //   stripeSubscription.interval ===
                                      //     SUBCRIPTION_PLAN_NAME.MONTHLY
                                      //     ? true
                                      //     : false
                                      // }
                                      onChange={(event) =>
                                        handlePlanChange(event.target.checked)
                                      }
                                    />
                                  </div>
                                  <label
                                    className={`form-check-label annually-lable active`}
                                    htmlFor="rememberMe"
                                  >
                                    Monthly
                                  </label>
                                </>
                              )}
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="listHolder custom-scroll">
                        <ul className="planDetail">
                          <h5>You are assured of:</h5>
                          {subscriptionById.assuredFeature?.map(
                            (item, index) => (
                              <li key={index}>
                                <img src="assets/img/listCheck.svg" alt="" />
                                <p>{item}</p>
                              </li>
                            )
                          )}
                        </ul>
                        <ul className="planDetail">
                          <h5>You are guaranteed to receive:</h5>
                          {subscriptionById.guarentedFeature?.map(
                            (item, index) => (
                              <li key={index}>
                                <img src="assets/img/listCheck.svg" alt="" />
                                <p>{item}</p>
                              </li>
                            )
                          )}
                        </ul>
                        <button
                          type="button"
                          //   onClick={handleOrderShow}
                          className="btn card-btn payment-bottom-btn mb-0"
                        >
                          UPGRADE PLAN
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="previewFooter">
                <div className="buttonSection">
                  <Link to={-1} type="button" className="btn save-btn cancel">
                    GO BACK
                  </Link>
                  <button
                    onClick={onSubmit}
                    className="btn agree-button save-btn"
                  >
                    PUBLISH PLAN
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
