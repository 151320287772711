import axionIns from "../axios";

const getSettings = async () => {
  return axionIns.get("admin/settings").then((response) => {return response.data});
};

const updateSettings = async (payload) => {
  return axionIns.put("admin/settings", JSON.stringify(payload)).then((response) => {return response.data});
};

const exportOject = {
  getSettings,
  updateSettings
};

export default exportOject;
