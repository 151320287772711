import React, { useState } from "react";
import Header from "../../components/header/header";
import FilterModal from "./FilterModal";
import { Dropdown, DropdownButton } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useEffect } from "react";
import {
  editCompanyAction,
  editCompanyPosition,
  getBusinessAction,
  updateBobCtaAction,
} from "../../actions/businessInfo";
import { useDispatch, useSelector } from "react-redux";
import { BOB_CTA, I_POP_OVER, POSITIONS, backendUrl } from "../../constants";
import { getSubscriptionPlanList } from "../../actions/subscription";
import { getNotificationAction } from "../../actions/notificationBanner";
import EditBusinessDetail from "./EditBusinessDetail";
import InfoModalStatic from "../../components/infoModal/infoModalStatic";
import { clearAdminAsUserAction, loginAdminAsUserAction } from "../../actions/user";
import TimeFormat from "../../components/timeFormat/timeFormat";
import { getBusinessGroupAction } from "../../actions/bookOfBusiness";


export default function BusinessDetail() {
  const { businessInfo, count } = useSelector((state) => state.businessInfo);
  const { subscription } = useSelector((state) => state.subscription);
  const { adminAsuser } = useSelector((state) => state.user);
  const { businessGroup } = useSelector((state) => state.bookOfBusiness);
  const { notificationBanner } = useSelector(
    (state) => state.notificationBanner
  );
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filterModalShow, setFilterModalShow] = useState(false);
  const [activePage, setActivePage] = useState(0);
  const [selectedOption, setSelectedOption] = useState({});
  const [planFilter, setPlanFilter] = useState("");
  const [callToAction, setCallToAction] = useState("");
  const [isRegister, setIsRegister] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tangibleAsset, setTangibleAsset] = useState("");
  const [financialObjective, setFinancialObjective] = useState("");
  const [verificationMethod, setVerificationMethod] = useState("");
  const [editModalShow, setEditModalShow] = useState(false);
  const [company, setCompany] = useState();
  const [infoModalStaicShow, setInfoModalStaticShow] = useState(false);
  const [infoModalStaicShowType, setInfoModalStaticShowType] = useState(null);
  const limit = 20;
  useEffect(() => {
    let timeoutId;

    const fetchData = () => {
      dispatch(
        getBusinessAction(
          page,
          limit,
          search,
          planFilter,
          callToAction,
          isRegister,
          startDate,
          endDate,
          tangibleAsset,
          financialObjective,
          verificationMethod
        )
      );
    };

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    if (search) {
      timeoutId = setTimeout(() => {
        fetchData();
      }, 500);
    } else {
      fetchData();
    }
    return () => clearTimeout(timeoutId);
  }, [
    search,
    page,
    dispatch,
    planFilter,
    callToAction,
    isRegister,
    startDate,
    endDate,
    tangibleAsset,
    financialObjective,
    verificationMethod,
  ]);

  useEffect(() => {
    dispatch(getSubscriptionPlanList(1, 10000, true, ""));
    dispatch(getNotificationAction(1, 10000, "", "", ""));
    dispatch(getBusinessGroupAction('', 'getall'))
  }, [dispatch]);

  useEffect(() => {
    if (adminAsuser) {
      window.open(adminAsuser, "_blank");
      dispatch(clearAdminAsUserAction());
    }
  }, [dispatch, adminAsuser]);

  const getUserTemporaryLink = (item) => {
    if (item.user.length > 0 && item.user && item.user[0]._id) {
      const payload = {
        user: item.user[0]._id,
        company: item._id
      };
      dispatch(loginAdminAsUserAction(payload));
    }
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setActivePage(selectedPage);
    setPage(selectedPage + 1);
  };
  const updateSelectedOption = (id, field, value) => {
    setSelectedOption((prevSelectedOption) => ({
      ...prevSelectedOption,
      [id]: {
        ...prevSelectedOption[id],
        [field]: value,
      },
    }));
  };

  const onSubmit = (item, field, id) => {
    const value = field === "cta" ? item.label : item.name;
    updateSelectedOption(id, field, value);
    dispatch(editCompanyAction(id, item.id, field, filterBodyListAgain()));
    setActivePage(0);
    setPage(1);
  };
  const onSubmitRole = (item, id) => {
    // updateSelectedOption(id, item.name);
    dispatch(editCompanyPosition(id, { defaultDesignationRole: item.value }, filterBodyListAgain()));
    setActivePage(0);
    setPage(1);
  };

  const getName = (ele, value) => {
    const source = value === "notification" ? notificationBanner : value === 'bookOfBusinessGroup' ? businessGroup : subscription;
    var match = {};
    if (value === 'bookOfBusinessGroup') {
      match = ele?.bookOfBusinessGroup[0]
    } else {
      match = source.find((x) => x.id === ele[value]);
    }
    return match ? match.name || match.label : "";
  };

  const getRoleName = (ele, value) => {
    const match = POSITIONS.find((x) => x.value === ele.defaultDesignationRole);
    return match ? match.name || match.label : "";
  };

  const handleEdit = (item) => {
    setCompany(item);
    setEditModalShow(true);
  };

  const handleClickInfo = (type) => {
    setInfoModalStaticShow(true);
    setInfoModalStaticShowType(type);
  };

  const onSubmitBob = (field, companyId, id) => {
    // updateSelectedBobOption(id, field, value);
    const payload = {
      type: field,
      cta_id: id,
    }

    dispatch(updateBobCtaAction(companyId, payload, filterBodyListAgain()));
    setActivePage(0);
    setPage(1);
  };

  const filterBodyListAgain = () => {
    return { page, limit, search, planFilter, callToAction, isRegister, startDate, endDate, tangibleAsset, financialObjective, verificationMethod }
  }


  const getBobOptionTitle = (item) => {
    if (item.bobCta) {
      let title = "Choose CTA..."
      switch (item.bobCta) {
        case BOB_CTA.NOTIFICATION:
          title = item.bobNotification[0]?.label
          break;
        case BOB_CTA.SUBSCRIPTION:
          title = item.bobSubscriptionPlan[0]?.name
          break;
        default:
          title = 'View all'
          break;
      }
      return title;
    }
    else {
      return "Choose CTA..."
    }
  };

  return (
    <>
      <Header title="Subscription Plans" />
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 mb-2">
            <div className="card planList">
              <div className="card-headings">
                <h6 className="font-weight-bolder payment-text mb-0">
                  Business verification details
                </h6>
                <img
                  src="assets/img/info-icon.svg"
                  alt=""
                  onClick={() => handleClickInfo(I_POP_OVER.BUSINESS_DETAIL)}
                />
              </div>
              <div className="card-header">
                <div className="searchbar">
                  <input
                    type="search"
                    placeholder="Search business name"
                    name="search"
                    id=""
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value.trimLeft());
                    }}
                  />
                  <img src="assets/img/searchIcon.svg" alt="" />
                </div>
                <div className="filter">
                  <div className="addButton">
                    <button
                      type="button"
                      onClick={() => setFilterModalShow(true)}
                      className="customAddButton filterButton"
                    >
                      Search filters
                      <i className="fa-solid fa-sliders"></i>
                      {/* <img src="assets/img/filterIcon.svg" alt="" /> */}
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body detail">
                <table className="businessTable">
                  <thead>
                    <tr>
                      <td>Logo</td>
                      <td>Holding company name</td>
                      <td>Date added</td>
                      <td>Created by</td>
                      <td>Preferred objectives</td>
                      <td>Singapore registered</td>
                      <td>Verification method</td>
                      {/* <td>Bob Designated Role</td> */}
                      <td>Bob</td>
                      <td>Bob CTA</td>
                      <td>Call to action</td>
                      <td>Plan to suggest</td>
                    </tr>
                  </thead>
                  <tbody>
                    {businessInfo.length > 0 ? (
                      businessInfo?.map((ele, eleIndex) => (
                        <tr key={eleIndex}>
                          <td className="pointer" onClick={() => getUserTemporaryLink(ele)}>
                            <div
                              className={`business-image ${ele.logo ? "hasLogo" : "hasText"
                                }`}
                              style={{
                                backgroundImage:
                                  ele.logo && `url(${backendUrl + ele.logo})`,
                              }}
                            >
                              {!ele.logo
                                ? ele.companyName
                                  ? ele.companyName?.substring(0, 1)
                                  : "-"
                                : ""}
                            </div>
                          </td>
                          <td className="pointer" onClick={() => getUserTemporaryLink(ele)}>
                            <h5>{ele.companyName || "-"}</h5>
                          </td>
                          <td><TimeFormat time={ele.createdAt} /></td>
                          <td>
                            {ele?.user[0]?.first_name
                              ? ele?.user[0]?.first_name +
                              " " +
                              ele?.user[0]?.last_name
                              : "-"}
                          </td>
                          <td className="objective">
                            {ele?.objective?.length > 0
                              ? ele?.objective.map(e => e.shortName)
                              : "-"}
                          </td>
                          <td>
                            <span>
                              {'isSingapore' in ele ? ele?.isSingapore ? "Yes" : "No" : "-"}
                            </span>
                          </td>
                          <td
                            onClick={
                              ele?.verificationMethod === "standard"
                                ? () => handleEdit(ele)
                                : null
                            }
                            className={`${ele?.verificationMethod === "standard" ? "cursor-pointer" : ""}`}
                          >
                            {ele?.verificationMethod
                              ? ele?.verificationMethod
                              : "-"}
                          </td>

                          {/* <td>
                            {ele.isActive && (
                              <div className="filter">
                                <div className="dropdownButton bussinessDropdown">
                                  <img src="assets/img/downArrow.svg" alt="" />
                                  <DropdownButton
                                    drop="down"
                                    id="dropdown-basic-button"
                                    title={
                                      selectedOption[ele._id] &&
                                        selectedOption[ele._id].role
                                        ? selectedOption[ele._id].role
                                        : getRoleName(ele, "role")
                                          ? getRoleName(ele, "role")
                                          : "Choose Designated Role"
                                    }
                                  >
                                    {POSITIONS?.length > 0 ? (
                                      POSITIONS &&
                                      POSITIONS?.slice(0, 3)?.map((item, index) => (
                                        <Dropdown.Item
                                          key={index}
                                          onClick={() =>
                                            onSubmitRole(item, ele._id)
                                          }
                                        >
                                          {item.name}
                                        </Dropdown.Item>
                                      ))
                                    ) : (
                                      <div className="text-center">
                                        No List Found
                                      </div>
                                    )}
                                  </DropdownButton>
                                </div>
                              </div>
                            )}
                          </td> */}
                          <td>
                            {ele.isActive && (
                              <div className="filter">
                                <div className="dropdownButton bussinessDropdown">
                                  <img src="assets/img/downArrow.svg" alt="" />
                                  <DropdownButton
                                    drop="down"
                                    id="dropdown-basic-button"
                                    title={
                                      selectedOption[ele._id] &&
                                        selectedOption[ele._id].bookOfBusinessGroup
                                        ? selectedOption[ele._id].bookOfBusinessGroup
                                        : getName(ele, "bookOfBusinessGroup")
                                          ? getName(ele, "bookOfBusinessGroup")
                                          : "Choose Book of Business"
                                    }
                                  >
                                    {businessGroup && businessGroup?.length > 0 ? (
                                      businessGroup?.map((item, index) => (
                                        <Dropdown.Item
                                          key={index}
                                          onClick={() =>
                                            onSubmitBob('bookOfBusinessGroup', ele._id, item.id)
                                          }
                                        >
                                          {item.name}
                                        </Dropdown.Item>
                                      ))
                                    ) : (
                                      <div className="text-center">
                                        No List Found
                                      </div>
                                    )}
                                  </DropdownButton>
                                </div>
                              </div>
                            )}
                          </td>
                          <td>
                            {ele.isActive && (
                              <div className="filter">
                                <div className="dropdownButton bussinessDropdown">
                                  <img src="assets/img/downArrow.svg" alt="" />
                                  <DropdownButton
                                    drop="down"
                                    id="dropdown-basic-button"
                                    title={getBobOptionTitle(ele)}
                                  >

                                    <Dropdown.Item onClick={() => onSubmitBob(BOB_CTA.VIEW_ALL, ele._id, -1)}>View all</Dropdown.Item>
                                    {notificationBanner?.length > 0 && (<Dropdown.Item disabled>Notification</Dropdown.Item>)}
                                    {notificationBanner?.length > 0 && (
                                      notificationBanner &&
                                      notificationBanner?.map((item, index) => (
                                        <Dropdown.Item
                                          className="innerItem"
                                          key={index}
                                          onClick={() =>
                                            onSubmitBob(BOB_CTA.NOTIFICATION, ele._id, item.id)
                                          }
                                        >
                                          -- {item.label}
                                        </Dropdown.Item>
                                      ))
                                    )}


                                    {/* Subscription Plan */}

                                    {subscription?.length > 0 && (<Dropdown.Item disabled>Subscription Plan</Dropdown.Item>)}
                                    {subscription?.length > 0 && (
                                      subscription &&
                                      subscription?.map((item, index) => (
                                        <Dropdown.Item
                                          className="innerItem"
                                          key={index}
                                          onClick={() =>
                                            onSubmitBob(BOB_CTA.SUBSCRIPTION, ele._id, item.id)
                                          }
                                        >
                                          -- {item.name}
                                        </Dropdown.Item>
                                      ))
                                    )}
                                  </DropdownButton>
                                </div>
                              </div>
                            )}
                          </td>
                          <td>
                            {ele.isActive && (
                              <div className="filter">
                                <div className="dropdownButton bussinessDropdown">
                                  <img src="assets/img/downArrow.svg" alt="" />
                                  <DropdownButton
                                    drop="down"
                                    id="dropdown-basic-button"
                                    title={
                                      selectedOption[ele._id] &&
                                        selectedOption[ele._id].cta
                                        ? selectedOption[ele._id].cta
                                        : getName(ele, "notification")
                                          ? getName(ele, "notification")
                                          : "Choose CTA..."
                                    }
                                  >
                                    {notificationBanner?.length > 0 ? (
                                      notificationBanner &&
                                      notificationBanner?.map((item, index) => (
                                        <Dropdown.Item
                                          key={index}
                                          onClick={() =>
                                            onSubmit(item, "cta", ele._id)
                                          }
                                        >
                                          {item.label}
                                        </Dropdown.Item>
                                      ))
                                    ) : (
                                      <div className="text-center">
                                        No Notification Found
                                      </div>
                                    )}
                                  </DropdownButton>
                                </div>
                              </div>
                            )}
                          </td>
                          <td>
                            {ele.isActive && (
                              <div className="filter">
                                <div className="dropdownButton bussinessDropdown">
                                  <img src="assets/img/downArrow.svg" alt="" />
                                  <DropdownButton
                                    drop="down"
                                    id="dropdown-basic-button"
                                    title={
                                      selectedOption[ele._id] &&
                                        selectedOption[ele._id].plan
                                        ? selectedOption[ele._id].plan
                                        : getName(ele, "subscriptionPlan")
                                          ? getName(ele, "subscriptionPlan")
                                          : "Suggest Plan..."
                                    }
                                  >
                                    {subscription?.length > 0 ? (
                                      subscription &&
                                      subscription?.map((item, index) => (
                                        <Dropdown.Item
                                          key={index}
                                          onClick={() =>
                                            onSubmit(item, "plan", ele._id)
                                          }
                                        >
                                          {item.name}
                                        </Dropdown.Item>
                                      ))
                                    ) : (
                                      <div className="text-center">
                                        No plan found
                                      </div>
                                    )}
                                  </DropdownButton>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="d-flex pt-3">
                        <td className="d-flex flex-column justify-content-center">
                          <h6 className="mb-0 text-sm table-heading text-center">
                            No Business Details Found
                          </h6>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {count > 1 && (
              <div className="pagination-section">
                <ReactPaginate
                  previousLabel={"Prev"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={count}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  forcePage={activePage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <FilterModal
        filterModalShow={filterModalShow}
        filterModalClose={() => setFilterModalShow(false)}
        subscriptionList={subscription}
        notificationList={notificationBanner}
        setPlanFilter={setPlanFilter}
        setCallToAction={setCallToAction}
        setIsRegister={setIsRegister}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setTangibleAsset={setTangibleAsset}
        setFinancialObjective={setFinancialObjective}
        setVerificationMethod={setVerificationMethod}
      />

      <EditBusinessDetail
        editModalShow={editModalShow}
        editModalClose={() => setEditModalShow(false)}
        company={company}
      />
      <InfoModalStatic
        infoModalStaticShow={infoModalStaicShow}
        infoModalStaticClose={() => setInfoModalStaticShow(false)}
        infoModalStaicShowType={infoModalStaicShowType}
      />
    </>
  );
}
