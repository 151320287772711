import { GET_DASHBOARD } from "../actions/types";
const initialState = {
  dashboard: {},
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_DASHBOARD:
      return {
        ...state,
        dashboard: payload,
      };
    default:
      return state;
  }
}
