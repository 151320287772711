import React, { useEffect, useState } from "react";
import { useRef } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  addBannerAction,
  deleteBannerAction,
  editBannerAction,
  getBannerAction,
} from "../../actions/banner";
import { backendUrl, statsuOption } from "../../constants";
import Deletemodel from "../dashboard/Deletemodel";
import ReactPaginate from "react-paginate";
import Header from "../../components/header/header";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { RoutesPaths } from "../../Router/constant";
import { isMobile } from "react-device-detect";

export default function Banner() {
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  const { banner, count } = useSelector((state) => state.banner);
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getBannerAction(page, 20, status));
  }, [dispatch, page, status]);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setActivePage(selectedPage);
    setPage(selectedPage + 1);
  };

  const getImage = (info) => {
    return isMobile ? info?.mobileImage?.filename : info?.webImage?.filename;
  };

  const onEdit = (value) => {
    navigate(RoutesPaths.ADDBANNER, {
      state: {
        id: value.id,
      },
    });
  };

  const sortByStatus = (data) => {
    setStatus(data.sendValue);
    setPage(1);
    setActivePage(0);
  };
  return (
    <>
      <Header title="Banner Manager" />
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 mb-2">
            <div className="card planList notification h-100">
              <div className="card-header">
                <div className="filter p-0">
                  <div className="dropdownButton">
                    <img src="assets/img/downArrow.svg" alt="" />
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={
                        status === ""
                          ? "Search by status"
                          : status
                          ? "Published"
                          : "Not published"
                      }>
                      {statsuOption.map((item, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() => sortByStatus(item)}>
                          {item.title}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div>
                </div>
                <div className="filter">
                  <div className="addButton">
                    <img src="assets/img/addIcon.svg" alt="" />
                    <Link
                      to={RoutesPaths.ADDBANNER}
                      className="customAddButton">
                      Add new banner
                    </Link>
                  </div>
                </div>
              </div>

              <div
                className={banner?.length > 0 ? "card-body" : "card-body px-0"}>
                {banner.length > 0 ? (
                  banner.map((item, index) => (
                    <div key={index} className="bannerHolder row">
                      <div className="bannerDetail col-md-10 ps-md-0">
                        <div className="bannerName">
                          <p>
                            Label:{" "}
                            <span className="bannerHeading">
                              {item.cta.name}
                            </span>
                          </p>
                          <p>
                            Status:{" "}
                            <span
                              className={
                                item.isActive ? "publish" : "notPublished"
                              }>
                              {item.isActive ? "Published" : "Not Published"}
                            </span>
                          </p>
                        </div>
                        <div>
                          <img
                            className="bannerImage"
                            src={backendUrl + getImage(item)}
                          />
                        </div>
                      </div>
                      <div className="editBanner col-md-2 pe-md-0">
                        <button
                          onClick={() => onEdit(item)}
                          className="btn save-btn editButton">
                          Edit banner
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="d-flex pt-3">
                    <div className="d-flex flex-column justify-content-center">
                      <h6 className="mb-0 text-sm table-heading text-center">
                        No Banner found
                      </h6>
                    </div>
                  </div>
                )}
              </div>
              {count > 1 && (
                <div className="pagination-section">
                  <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={count}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    forcePage={activePage}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
