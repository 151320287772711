import React from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { updatePasswordAction } from "../../actions/user";

export default function EditPassword() {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    if (data.password === data.confirmPassword) {
      var payload = {
        currentPassword: data.currentPassword,
        password: data.confirmPassword,
      };
      dispatch(updatePasswordAction(payload));
      reset();
    } else {
      toast.error("You new pasword is not matched.");
    }
  };
  return (
    <>
      <div className="card-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group form-margin">
            <div className="row">
              <label className="col-form-label edit-info">Edit password</label>
              <div className="col-lg-12">
                <span className="input-text">Enter current password</span>
                <input
                  maxLength={50}
                  autoComplete="off"
                  type="password"
                  className="form-control"
                  placeholder="Enter password"
                  {...register("currentPassword", { required: true })}
                />
                {errors.currentPassword?.type === "required" && (
                  <span className="errorMessage">Password is required</span>
                )}
              </div>
            </div>
            <div className="form-group ">
              <div className="row">
                <div className="col-lg-6 mt-3">
                  <span className="input-text">Enter new password</span>
                  <input
                    maxLength={50}
                    autoComplete="off"
                    type="password"
                    className="form-control"
                    placeholder="Enter password"
                    {...register("password", { required: true })}
                  />
                  {errors.password?.type === "required" && (
                    <span className="errorMessage">
                      New Password is required
                    </span>
                  )}
                </div>
                <div className="col-lg-6 mt-3">
                  <span className="input-text">Confirm new password</span>
                  <input
                    maxLength={50}
                    autoComplete="off"
                    type="password"
                    className="form-control"
                    placeholder="Enter password"
                    {...register("confirmPassword", { required: true })}
                  />
                  {errors.confirmPassword?.type === "required" && (
                    <span className="errorMessage">
                      Confirm Password is required
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <button type="submit" className="btn agree-button save-btn mt-3">
            Save changes
          </button>
        </form>
      </div>
    </>
  );
}
