import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import ProfileDetail from "./ProfileDetail";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessGroupAction, getBusinessProfile, resetBobPaginationAction } from "../../actions/bookOfBusiness";
import { Controller, useForm, useWatch } from "react-hook-form";
import Select from "react-select";
import { BOB_SERVICES, BOB_SERVICES_NAME, SELECT_BOX_THEME, styleSelect } from "../../constants";
import BusinessGroup from "./BusinessGroup";
import debounce from 'lodash/debounce';


export default function SuggestedProfiles() {
  const dispatch = useDispatch();
  const { businessProfiles, count, businessGroup, resetBobPagination, bookOfBusinessResponseService } = useSelector((state) => state.bookOfBusiness);

  const { control, getValues, setValue, reset } = useForm();
  const [profileTab, setProfileTab] = useState(1);
  const [businessGroupPopup, setBusinessGroupPopup] = useState(false);
  const [businessGroupPopupEdit, setBusinessGroupPopupEdit] = useState(false);
  const [serviceTypeList, setBobServiceType] = useState(false);
  const [page, setPage] = useState(1);
  const [activePage, setActivePage] = useState(0);
  const [status, setStatus] = useState(1);
  const companyName = useWatch({ control, name: "companyName" });
  const bookOfBusiness = useWatch({ control, name: "businessName" });
  const serviceType = useWatch({ control, name: "serviceType" });
  const limit = useWatch({ control, name: "limit" });
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    dispatch(getBusinessProfile(page, limit, companyName, status, bookOfBusiness && bookOfBusiness?.id ? bookOfBusiness?.id : "", serviceType && serviceType.value ? serviceType.value : ""));
  }, [page, limit, companyName, bookOfBusiness, status, dispatch, serviceType]);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setActivePage(selectedPage);
    setPage(selectedPage + 1);
  };

  const handleSlider = (index) => {
    setProfileTab(index);
    setStatus(index);
  };


  useEffect(() => {
    const callFunction = () => {
      if (resetBobPagination) {
        handleResetPage()
        dispatch(getBusinessProfile(page, limit, companyName, status, bookOfBusiness && bookOfBusiness?.id ? bookOfBusiness?.id : "", serviceType && serviceType.value ? serviceType.value : ""));
        dispatch(resetBobPaginationAction(false))
      }
    }

    callFunction();
  }, [resetBobPagination, page, limit, companyName, status, bookOfBusiness, dispatch, serviceType])

  useEffect(() => {
    const callFunction = () => {
      handleResetPage()
    }
    callFunction();
  }, [limit, status, companyName]);

  useEffect(() => {
    var serviceTypeList = []
    Object.keys(BOB_SERVICES_NAME).map(key => {
      serviceTypeList.push({
        value: key,
        label: BOB_SERVICES_NAME[key]
      })
    })
    setBobServiceType(serviceTypeList)
  }, [])

  const handleResetPage = () => {
    setActivePage(0);
    setPage(1);
  }

  const handleBusinessInputChange = (inputValue) => {
    setSearchValue(inputValue);
  };

  useEffect(() => {
    const debouncedSearch = debounce((value) => {
      dispatch(getBusinessGroupAction(value, null, serviceType && serviceType.value ? serviceType.value : ""))
    }, 1000);
    debouncedSearch(searchValue);
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchValue, dispatch]);

  const handleClosePopup = (flag) => {
    setBusinessGroupPopup(flag)
    setValue('businessName', null);
    setValue('serviceType', null);
  }

  return (
    <>
      <Header title="Book of business" />
      <div className="container-fluid py-4 mt-4">
        <div className="row">
          <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 mb-4">
            <h3 className="suggestHeader">Book of business</h3>
          </div>
          <div className="col-lg-8 col-xl-8 col-md-8 col-sm-12 mb-2">
            <div className="searchbar">
              <Controller
                name="serviceType"
                control={control}
                render={({ field }) => (
                  <Select
                    className="select-border"
                    theme={SELECT_BOX_THEME}
                    {...field}
                    styles={styleSelect}
                    isClearable={true}
                    isSearchable={true}
                    options={serviceTypeList}
                    getOptionLabel={(option) => option?.label}
                    // formatOptionLabel={formatOptionLabel}
                    // onInputChange={handleChange}
                    getOptionValue={(option) => option?.value}
                    placeholder="Search services"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                  />
                )}
              />
              <img src="assets/img/searchIcon.svg" alt="" />
            </div>
            <div className="searchbar">
              <Controller
                name="businessName"
                control={control}
                render={({ field }) => (
                  <Select
                    className="select-border"
                    theme={SELECT_BOX_THEME}
                    {...field}
                    styles={styleSelect}
                    isClearable={true}
                    isSearchable={true}
                    options={businessGroup}
                    getOptionLabel={(option) => option?.name}
                    // formatOptionLabel={formatOptionLabel}
                    // onInputChange={handleChange}
                    getOptionValue={(option) => option?._id}
                    placeholder="Search book of business"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    onInputChange={handleBusinessInputChange}
                  />
                )}
              />
              <img src="assets/img/searchIcon.svg" alt="" />
            </div>
            {bookOfBusiness && (
              <div className="gearIconEdit" onClick={() => { setBusinessGroupPopup(true); setBusinessGroupPopupEdit(true) }}>
                <i className="fa-solid fa-gear"></i>
              </div>
            )}
          </div>
          <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12 mb-2 align-right">
            <button
              type="button"
              className="customDottedButton"
              onClick={() => { setBusinessGroupPopup(true); setBusinessGroupPopupEdit(false) }}
            >
              <i className="fa-regular fa-circle-xmark"></i>&nbsp;&nbsp;Create book of business
            </button>
          </div>
          <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 mb-4">
            <div className="profileHolder">
              <span
                className={`sliderText ${profileTab === 1 ? "active" : ""}`}
                onClick={() => handleSlider(1)}
              >
                Suggested
                <span
                  className="profiles">
                  {businessProfiles?.profileCount?.suggested || 0}
                </span>
              </span>
              <span
                className={`sliderText ${profileTab === 2 ? "active" : ""}`}
                onClick={() => handleSlider(2)}
              >
                Warm profiles
                <span
                  className="profiles">
                  {" "}
                  {businessProfiles?.profileCount?.warm || 0}
                </span>
              </span>
              <span
                className={`sliderText ${profileTab === 3 ? "active" : ""}`}
                onClick={() => handleSlider(3)}
              >
                Meeting Requests
                <span
                  className="profiles">
                  {businessProfiles?.profileCount?.meeting || 0}
                </span>
              </span>

              <div className="profileCard">
                <ProfileDetail
                  activePage={activePage}
                  count={count}
                  handlePageClick={handlePageClick}
                  businessProfiles={businessProfiles}
                  getValues={getValues}
                  control={control}
                  limit={limit}
                  setStatus={setProfileTab}
                  profileTab={profileTab}
                  setPage={setPage}
                  setActivePage={setActivePage}
                  bookOfBusiness={bookOfBusiness}
                  bookOfBusinessResponseService={bookOfBusinessResponseService}
                />
              </div>
            </div>
          </div>
        </div>

        <BusinessGroup
          bookOfBusiness={bookOfBusiness}
          isEdit={businessGroupPopupEdit}
          businessGroupPopup={businessGroupPopup}
          closePopup={() => handleClosePopup(false)}
        />

      </div>
    </>
  );
}
