import React, { useEffect, useRef } from "react";
import { Accordion, Dropdown, DropdownButton } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/header/header";
import { RoutesPaths } from "../../Router/constant";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { backendUrl, statsuOption } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptionPlanList } from "../../actions/subscription";
import Currency from "../../components/currency/currency";
import CurrencyFormat from "../../components/currencyFormat/currencyFormat";
import toast from "react-hot-toast";
import { CopyToClipboard } from 'react-copy-to-clipboard';

export const shortenDescription = (description) => {
  const plainText = description.replace(/<\/?[^>]+(>|$)/g, "");
  const shortenedDescription = plainText.length > 100 ? plainText.substring(0, 70) + "..." : plainText;
  return shortenedDescription;
}

export default function PlanList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subscription, count } = useSelector((state) => state.subscription);
  const [status, setStatus] = useState("");
  const [page, setPage] = useState(1);
  const [activePage, setActivePage] = useState(0);
  const [search, setSearch] = useState("");
  const timeoutRef = useRef(null);
  useEffect(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    if (search !== "") {
      timeoutRef.current = setTimeout(() => {
        dispatch(getSubscriptionPlanList(page, 20, status, search));
      }, 500);
    } else {
      dispatch(getSubscriptionPlanList(page, 20, status, search));
    }
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [search, page, status, dispatch]);

  const sortByStatus = (data) => {
    setStatus(data.sendValue);
    setPage(1);
    setActivePage(0);
  };

  const onEdit = (value) => {
    navigate(RoutesPaths.ADDPLAN, {
      state: {
        id: value.id,
      },
    });
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setActivePage(selectedPage);
    setPage(selectedPage + 1);
  };
  return (
    <>
      <Header title="Subscription Plans" />
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 mb-2">
            <div className="card planList">
              <div className="card-header">
                <div className="searchbar">
                  <input
                    type="search"
                    placeholder="Search plan"
                    name="search"
                    id=""
                    autoComplete="off"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value.trimLeft());
                    }}
                  />
                  <img src="assets/img/searchIcon.svg" alt="" />
                </div>
                <div className="filter">
                  <div className="dropdownButton">
                    <img src="assets/img/downArrow.svg" alt="" />
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={
                        status === ""
                          ? "Search by status"
                          : status
                            ? "Published"
                            : "Not published"
                      }
                    >
                      {statsuOption.map((item, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() => sortByStatus(item)}
                        >
                          {item.title}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div>
                  <div className="addButton">
                    <img src="assets/img/addIcon.svg" alt="" />
                    <Link to={RoutesPaths.ADDPLAN} className="customAddButton">
                      Add new plan
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className={
                  subscription?.length > 0 ? "card-body" : "card-body px-0"
                }
              >
                <div className="planListHolder">
                  <Accordion>
                    {subscription?.length > 0 ? (
                      subscription?.map((item, index) => (
                        <Accordion.Item
                          className={!item.isActive ? "notPublished " : ""}
                          key={index}
                          eventKey={index}
                        >
                          <Accordion.Header>
                            <div className="planListInner">
                              {/* <div
                                className="imgHolder"
                                style={{
                                  backgroundImage: item.image
                                    ? `url(${backendUrl + item.image.filename}`
                                    : "url(assets/img/planBack.png)",
                                }}
                              >
                                <img src="assets/img/planBack.png" alt="" />
                              </div> */}
                              <div className="planDesc">
                                <CopyToClipboard text={item?.id}>
                                  <div onClick={($event) => { toast.success("Copied!"); $event.stopPropagation(); $event.preventDefault(); }} className="descProductId"><h6>Product/Plan ID : {item?.id} <img src="assets/img/copyText.svg" alt="" /></h6></div>
                                </CopyToClipboard>
                                <div className="descHead">
                                  <h5>{item.planType?.name} || {item.name}</h5>
                                  <div className="planPrice">
                                    {/* {item?.monthlyPriceId && (
                                      <p>
                                        Monthly{" "}
                                        <span>
                                          <Currency
                                            currency={
                                              item?.monthPriceResponse?.currency
                                            }
                                          />
                                          <CurrencyFormat
                                            currency={
                                              item?.monthPriceResponse?.currency
                                            }
                                            value={
                                              item?.monthPriceResponse?.amount
                                                ? item?.monthPriceResponse?.amount
                                                : 0
                                            }
                                            decimal={2}
                                          />
                                        </span>
                                      </p>
                                    )} */}
                                    <p>
                                      Price/Amount
                                      <span>
                                        <Currency currency={(item?.price && parseFloat(item?.price) > 0) ? item?.currency?.value : "-"} />
                                        <CurrencyFormat currency={(item?.price && parseFloat(item?.price) > 0) ? item?.currency?.value : "-"} value={(item?.price && parseFloat(item?.price) > 0) ? parseFloat(item?.price) : "-"} decimal={2}/>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="descBottom">
                                  <p dangerouslySetInnerHTML={{__html: shortenDescription(item?.targetAudience)}}></p>
                                  <span
                                    className={
                                      !item.isActive
                                        ? "notPublished"
                                        : "publish"
                                    }
                                  >
                                    {item.isActive
                                      ? "Published"
                                      : "Not Published"}
                                  </span>
                                </div>
                              </div>
                              <div className="arrow">
                                <img src="assets/img/accArrow.svg" alt="" />
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="row planDetailRow">

                              <div className="col-12 col-md-6">
                                <ul>
                                  <h5>You are assured of:</h5>
                                  {item?.assuredFeature?.map((list, index) => (
                                    <li key={index}>
                                      <img
                                        src="assets/img/listCheck.svg"
                                        alt=""
                                      />
                                      {list}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              <div className="col-12 col-md-6">
                                <ul>
                                  <h5>You are guaranteed to receive:</h5>
                                  {item?.guarentedFeature?.map(
                                    (list, index) => (
                                      <li key={index}>
                                        <img
                                          src="assets/img/listCheck.svg"
                                          alt=""
                                        />
                                        {list}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                              <div className="col-12 text-center pt-4">
                                <button
                                  onClick={() => onEdit(item)}
                                  className="customAddButton paddingLeft"
                                >
                                  EDIT PLAN
                                </button>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))
                    ) : (
                      <div className="d-flex pt-3">
                        <div className="d-flex flex-column justify-content-center">
                          <h6 className="mb-0 text-sm table-heading text-center">
                            No plan found
                          </h6>
                        </div>
                      </div>
                    )}
                  </Accordion>
                </div>
              </div>
            </div>
            {count > 1 && (
              <div className="pagination-section">
                <ReactPaginate
                  previousLabel={"Prev"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={count}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  forcePage={activePage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
