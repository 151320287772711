import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

export default function Confirm(props) {
  return (

    <Modal
      {...props}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you wan't to logout?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onHide(false)}>
          Close
        </Button>
        <Button variant="primary" className="add-button confirmbtn" onClick={() => props.onHide(true)}>
          Logout
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
