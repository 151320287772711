import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RoutesPaths } from './constant';

function PrivateRoute({ children }) {
    const { isLoggedIn } = useSelector(state => state.auth);
    if (isLoggedIn) {
        // not logged in so redirect to login page with the return url
        return <Navigate to={RoutesPaths.DASHBOARD} />
    }else{}
    // authorized so return child components
    return children;
}
export default PrivateRoute;
