import axionIns from "../axios";

const getDashboard = () => {
  return axionIns.get("admin/dashboard").then((response) => {return response.data});
};

const exportOject = {
  getDashboard,
};

export default exportOject;
