import { UPDATE_CURRENT_CONVERSATION } from "../actions/types";

const initialState = "";

const reducer = (state = initialState, action)=> {
  switch (action.type) {
    case UPDATE_CURRENT_CONVERSATION:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
