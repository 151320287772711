import { GET_IMPORT_LIST, GET_SUGGESTED_PROFILE, GET_BUSINESS_GROUP, RESET_BOB_PAGINATION } from "../actions/types";

const initialState = {
  businessGroup: [],
  businessProfiles: {},
  importList: [],
  count:0,
  resetBobPagination: false,
  bookOfBusinessResponseService : null,
};
export default function (state = initialState, action) {
  const { type, payload, count, serviceTypeResponse } = action;
  switch (type) {
    case GET_SUGGESTED_PROFILE:
      return {
        ...state,
        businessProfiles: payload,
        count: count,
        bookOfBusinessResponseService: parseInt(serviceTypeResponse) || null,
      };
    case GET_IMPORT_LIST:
      return {
        ...state,
        importList: payload,
      };

    case GET_BUSINESS_GROUP:
      return {
        ...state,
        businessGroup: payload,
      };

    case RESET_BOB_PAGINATION:
      return {
        ...state,
        resetBobPagination: payload,
      };
    default:
      return state;
  }
}
