import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PrivateRoute({ children }) {
    const { isLoggedIn } = useSelector(state => state.auth);
    if (!isLoggedIn) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/" />
    }
    // authorized so return child components
    return children;
}
export default PrivateRoute;
