import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../actions/auth";
import { useEffect } from "react";
import { LOGIN_TYPE, secretPass } from "../../constants";
import CryptoJS from "crypto-js";
import { useState } from "react";

export default function SignIn() {
  const dispatch = useDispatch();
  const { twoFactorStorage, isLoggedIn } = useSelector((state) => state.auth)
  const navigate = useNavigate();
  const [passwordView, setPasswordView] = useState(false);
  const loadRef = useRef(true);
  const {
    register,
    handleSubmit,
    setValue,
    // reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    data.type = LOGIN_TYPE.ADMIN
    dispatch(login(data))
  };

  useEffect(() => {
    if (loadRef.current) {
      if (localStorage.getItem("credential")) {
        var credentials = JSON.parse(localStorage.getItem("credential"))
        const { email, password, rememberme } = credentials.payload;
        const bytes = CryptoJS.AES.decrypt(password, secretPass);
        const data = bytes.toString(CryptoJS.enc.Utf8) ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) : password;
        setValue('email', email)
        setValue('password', data)
        setValue('rememberme', rememberme)
      }
    }
    getLogin();
    return () => {
      loadRef.current = false
    }
  }, [isLoggedIn, twoFactorStorage]);

  const getLogin = () => {
    if (isLoggedIn) {
      navigate("/dashboard");
    }
  };

  return (
    <>
      <section className="signIn-back">
        <div className="page-header">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-5 col-md-6 d-flex flex-column mx-auto">
                <div className="card card-plain card-login">
                  <div className="card-header pb-0 text-left bg-transparent">
                    <h3 className="font-weight-bolder text-info text-gradient">
                      InProved admin sign in
                    </h3>

                    <p className="mb-0">
                      Enter your username and password to sign in
                    </p>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <label>Email</label>
                      <div className="mb-3">
                        <input
                          maxLength={50}
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          {...register("email", {
                            required: true,
                            pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                          })}
                        />
                        {errors.email?.type === "required" && (
                          <span className="errorMessage">
                            Email is required
                          </span>
                        )}
                        {errors.email?.type === "pattern" && (
                          <span className="errorMessage">Email is Invalid</span>
                        )}
                      </div>
                      <label>Password</label>
                      <div className="mb-3 handleEye">
                        <input
                          maxLength={50}
                          autoComplete="off"
                          type={passwordView ? "text" : "password"}
                          className="form-control"
                          placeholder="Password"
                          {...register("password", { required: true })}
                        />
                        <div className="viewEye" onClick={() => setPasswordView(!passwordView)}><i className={`fa fa-eye${passwordView ? "-slash" : ""}`} aria-hidden="true"></i></div>
                        {errors.password?.type === "required" && (
                          <span className="errorMessage">
                            Password is required
                          </span>
                        )}
                      </div>
                      <div className="form-check form-switch">
                        <input
                          maxLength={50}
                          autoComplete="off"
                          className="form-check-input"
                          type="checkbox"
                          id="rememberMe"
                          {...register("rememberme", { required: false })}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="rememberMe"
                        >
                          Remember me
                        </label>
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn bg-gradient-info w-100 mt-4 mb-0"
                        >
                          Sign In
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
