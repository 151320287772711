import {
  USER_PROFILE,
  SET_SUCCESS_MESSAGE,
  SET_FAILED_MESSAGE,
  GET_USER_ACTIVITY,
  STOP_LOADER,
  SET_ADMIN_AS_USER,
  SET_ALL_USER_LIST,
  SET_USER_LISTING
} from "./types";
import userService from "../services/user.service";
import { logout } from "./auth";
import { startLoaderAction, stopLoaderAction } from "./updateReducerValue";

export const updatePasswordAction = (payload) => (dispatch) => {
  return userService.updatePassword(payload).then(
    (resp) => {
      if(resp.status){
        dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });  
      }else{
        dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });  
      }
      return Promise.resolve();
    }
  );
};


export const getProfileAction = () => (dispatch) => {
  return userService.getProfile().then(
    (resp) => {
      if(resp.status){
        dispatch({ type: USER_PROFILE, payload: resp.data });  
      }else{
        dispatch(logout());
        dispatch({ type: USER_PROFILE, payload: []});  
      }
      dispatch({ type: STOP_LOADER})
      return Promise.resolve();
    }
  );
};

export const updateUserTwoFactorAction = (payload) => (dispatch) => {
  return userService.updateProfile(payload).then(
    (resp) => {
      if(resp.status){
        dispatch(getProfileAction());
        var message = "2-factor Authentication ";
        if(payload.twoFactor){
          message = message + 'Enabled.'
        }else{
          message = message + 'Disabled.'
        }
        dispatch({ type: SET_SUCCESS_MESSAGE, payload: message });  
      }else{
        dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });  
      }
      return Promise.resolve();
    }
  );
};

export const updateUserProfileAction = (payload) => (dispatch) => {
  return userService.updateProfile(payload).then(
    (resp) => {
      if(resp.status){
        dispatch(getProfileAction());
        dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });  
      }else{
        dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });  
      }
      return Promise.resolve();
    }
  );
};


export const getUserActivityAction = () => (dispatch) => {
  return userService.getActivity().then(
    (resp) => {
      if(resp.status){
        dispatch({ type: GET_USER_ACTIVITY, payload: resp.data });  
      }else{
        dispatch({ type: GET_USER_ACTIVITY, payload: [] });  
      }
      return Promise.resolve();
    }
  );
};

export const loginAdminAsUserAction = (data) => (dispatch) => {
  dispatch(startLoaderAction());  
  return userService.loginAdminAsuser(data).then(
    (resp) => {
      setTimeout(() => {
        if(resp.status){
          dispatch({ type: SET_ADMIN_AS_USER, payload: resp.data });  
        }else{
          dispatch({ type: SET_ADMIN_AS_USER, payload: null });  
        }
        dispatch(stopLoaderAction());  
        return Promise.resolve();  
      }, 1000);
      
    }
  );
}

export const clearAdminAsUserAction = () => (dispatch) => {
  dispatch({ type: SET_ADMIN_AS_USER, payload: null });  
}


export const getAllUserListAction = () => async (dispatch) => {
  return userService.getAllUserList().then(
    (resp) => {
      dispatch({ type: SET_ALL_USER_LIST, payload: resp.status ? resp.data : [] });  
    }
  );
}


export const getUserListingAction = (page, perPage) => async (dispatch) => {
  return userService.getUserListing(page, perPage).then(
    (resp) => {
      dispatch({ type: SET_USER_LISTING, payload: resp });  
    }
  );
}