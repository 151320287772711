import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { backendUrl } from "../../constants";
import { useNavigate } from "react-router";
import ContentLoader from "react-content-loader";
import TimeFormat from "../../components/timeFormat/timeFormat";
import AddIndividualInviteModal from "./AddIndividualInviteModal";
import {
  getReferralListAction,
  removeIndividualInviteAction,
} from "../../actions/referralInvites";
import DeleteIndividualInvite from "./DeleteIndividualInvite";

export default function IndividualInviteTable(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    referralInvites,
    loadReferral,
  } = useSelector((state) => state.referralInvites);

  const [addModalShow, setAddModalShow] = useState(false);
  const [record, setRecord] = useState(2);
  const [removeModalShow, setRemoveModalShow] = useState(false);
  const [actionData, setActionData] = useState(null);

  const addModalClose = () => {
    setAddModalShow(false);
    navigate({ state: "" });
  };
  const handleAdd = () => setAddModalShow(true);

  const handleRemove = (item) => {
    setRemoveModalShow(true);
    setActionData(item);
  };

  const onLoad = () => {
    setRecord(record + 2);
  };

  useEffect(() => {
    dispatch(getReferralListAction());
  }, [dispatch]);

  const removeModalCloseConfirm = async () => {
    if (actionData.id) {
      await dispatch(removeIndividualInviteAction(actionData.id));
    }
    setRemoveModalShow(false);
  };

  return (
    <>
      <div className="share-officer individual-holder-invites">
        <div className="header-promoters">Individual invites</div>
        <div className="card">
          <div className="card-header pb-0">
            <div className="row">
              <div className="col-lg-6 col-6">
                <h3 className="business-font capital-head high-cap px-2">
                  Individual invites
                </h3>
              </div>
              <div className="col-lg-6 col-6 my-auto text-end">
                {true && (
                  <div className="dropdown float-lg-end">
                    <button
                      className="btn add-button add-button-shareholder m-0"
                      onClick={handleAdd}
                    >
                      Invite your friends
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card-body px-0 pb-2 pt-2">
            {!loadReferral ? (
              referralInvites?.length > 0 ? (
                <div className="table-responsive">
                  <table className="table align-items-center mb-0 table-sharholder">
                    <thead>
                      <tr className="tableHeadings">
                        <th className="name-heading">Name</th>
                        <th className="position-heading">Email address</th>
                        <th className="date-heading">Date added</th>
                        <th className="action-heading">Actions</th>
                        <th className="status-heading">Status</th>
                      </tr>
                    </thead>
                    <tbody className="text-row-color">
                      {referralInvites?.length > 0 &&
                        referralInvites
                          ?.slice(0, record)
                          ?.map((item, index) => (
                            <tr className="" key={index}>
                              <td>
                                <div className="d-flex">
                                  <div className="d-flex flex-column justify-content-center">
                                    <h6 className="mb-0 shareHolderName">
                                      <div
                                        className="shareholder-img"
                                        style={{
                                          backgroundImage: `url(${item &&
                                              item.profilePic &&
                                              item.profilePic.filename
                                              ? backendUrl +
                                              item.profilePic.filename
                                              : "assets/img/Frame1.svg"
                                            })`,
                                        }}
                                      />
                                      {(item.first_name
                                        ? item.first_name
                                        : "") +
                                        " " +
                                        (item.first_name && item.last_name
                                          ? item.last_name
                                          : "")}
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td className="text-sm table-heading date-padding">
                                <div
                                  className="shareCreateDate shareCreateEmail activity_wrapper limit-text cursor-pointer"
                                  title={item.email}
                                >
                                  {item.email}
                                </div>
                              </td>
                              <td className="text-sm table-heading date-padding">
                                <span className="shareCreateDate">
                                  <TimeFormat time={item.createdAt} type={1} />
                                </span>
                              </td>
                              <td className="text-sm table-heading date-padding">
                                {item.isActive ? (<>&nbsp;</>) : (
                                  <span className="btn btn-link px-0 mb-0 mx-1 right-delete-icon">
                                    <i
                                      className="fas fa-trash-alt"
                                      aria-hidden="true"
                                      onClick={() => handleRemove(item)}
                                    ></i>
                                  </span>
                                )}
                              </td>

                              <td className="text-sm table-heading date-padding status-type">
                                <button className={`btn ${item.isActive ? "active-button-second" : ""} pending-button-second m-0`}>
                                  {item.isActive ? "Invite accepted" : "Pending"}
                                </button>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                  {referralInvites.length > record && (
                    <button
                      onClick={onLoad}
                      className="btn btn-sm  view-button mb-4 mt-3"
                    >
                      View all
                    </button>
                  )}
                </div>
              ) : (
                <div className="no-record d-flex pt-2 pb-2">
                  <div className="d-flex flex-column justify-content-center ">
                    <h6 className="mb-0 text-sm table-heading ">
                      No new invites
                    </h6>
                  </div>
                </div>
              )
            ) : (
              <div className="table-responsive p-3">
                {[80, 60].map((item, index) => (
                  <ContentLoader key={index} width={item + "%"} height={15}>
                    <rect rx="5" ry="5" width={item + "%"} height="15" />
                  </ContentLoader>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <AddIndividualInviteModal
        addModalShow={addModalShow}
        addModalClose={addModalClose}
      />

      <DeleteIndividualInvite
        removeModalShow={removeModalShow}
        removeModalClose={() => setRemoveModalShow(false)}
        removeModalCloseConfirm={removeModalCloseConfirm}
        actionData={actionData}
      />
    </>
  );
}
