import { GET_BANNER_BY_ID, GET_BANNERS } from "../actions/types";
const initialState = {
  banner: [],
  count: 0,
  bannerDetail: {},
};
export default function (state = initialState, action) {
  const { type, payload, count } = action;
  switch (type) {
    case GET_BANNERS:
      return {
        ...state,
        banner: payload,
        count: count,
      };
    case GET_BANNER_BY_ID:
      return {
        ...state,
        bannerDetail: payload,
      };
    default:
      return state;
  }
}
