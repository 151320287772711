import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { updateSettingsAction } from "../../actions/settings";


export default function AppVersion({ data }) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset(data)
  }, [data])


  const onSubmit = (data) => {
    const payload = { appVersion: data }
    dispatch(updateSettingsAction(payload));
  };
  return (
    <>
      {/* <div className="card-body"> */}
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="form-group form-margin">
          <div className="row">
            <div className="col-lg-5">
              <label className="col-form-label edit-info">Android</label>
              <div className="row">
                <div className="col-lg-12">
                  <span className="input-text">Android app version *</span>
                  <input
                    maxLength={500}
                    autoComplete="off"
                    type="text"
                    className="form-control"
                    placeholder="Android app version"
                    {...register("android", { required: true })}
                  />
                  {errors.android?.type === "required" && (
                    <span className="errorMessage">This field is required</span>
                  )}
                </div>
                <div className="col-lg-12 mt-2">
                  <span className="input-text">Android force update</span>
                  <div className="form-check form-switch ps-0">
                    <input className="form-check-input mt-1 ms-auto" type="checkbox" id="navbarFixed" {...register("androidForceUpdate", { required: false })} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-5">
              <label className="col-form-label edit-info">IOS</label>
              <div className="row">
                <div className="col-lg-12">
                  <span className="input-text">IOS app version *</span>
                  <input
                    maxLength={500}
                    autoComplete="off"
                    type="text"
                    className="form-control"
                    placeholder="IOS app version"
                    {...register("ios", { required: true })}
                  />
                  {errors.ios?.type === "required" && (
                    <span className="errorMessage">This field is required</span>
                  )}
                </div>
                <div className="col-lg-12 mt-2">
                  <span className="input-text">IOS force update</span>
                  <div className="form-check form-switch ps-0">
                    <input className="form-check-input mt-1 ms-auto" type="checkbox" id="navbarFixed" {...register("iosForceUpdate", { required: false })} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button type="submit" className="btn agree-button save-btn mt-3">
          Save changes
        </button>
      </form >
      {/* </div> */}
    </>
  );
}
