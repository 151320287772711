import React, { useEffect } from "react";
import Header from "../../components/header/header";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import TimeFormat from "../../components/timeFormat/timeFormat";
import { getUserListingAction } from "../../actions/user";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";

export default function UserManager() {
  const [page, setPage] = useState(1);
  const [activePage, setActivePage] = useState(0);
  const dispatch = useDispatch();
  const { userList, userListCount, userListLink } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    dispatch(getUserListingAction(page, 20));
  }, [page, dispatch]);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setActivePage(selectedPage);
    setPage(selectedPage + 1);
  };

  return (
    <>
      <Header title="Notification Manager" />
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 mb-2">
            <div className="card planList notification h-100">
              <div className="card-header">
                <div className="searchbar">
                  <h6 className="font-weight-bolder payment-text mb-0">
                    Users
                  </h6>
                </div>
              </div>
              <div className="card-body detail">
                <table className="businessTable userTable">
                  <thead>
                    <tr>
                      <td>Name</td>
                      <td>Email</td>
                      <td>Verification status</td>
                      <td>From user</td>
                      <td>Email verification link</td>
                      <td>Forgot Password link</td>
                      <td>Registration date</td>
                    </tr>
                  </thead>
                  <tbody>
                    {userList.length > 0 ? (
                      userList?.map((ele, eleIndex) => (
                        <tr key={eleIndex}>
                          <td>
                            <h6 className="profile-text text-sm">
                              {ele.first_name + " " + ele.last_name || "-"}
                            </h6>
                          </td>
                          <td>
                            <h6 className="profile-text text-sm">
                              {ele.email || "-"}
                            </h6>
                          </td>
                          <td>
                            <h6 className="profile-text text-sm">
                              {ele.isEmailVerified ? "Active" : "Non-Active"}
                            </h6>
                          </td>
                          <td>
                            <h6 className="profile-text profile-text-fromuser text-sm">
                              {ele?.referredByUser?.first_name || "-"}{" "}
                              {ele?.referredByUser?.first_name &&
                                ele?.referredByUser?.last_name}
                            </h6>
                            <p className="profile-text profile-text-fromuser text-sm">
                              {ele?.referredByUser?.email}
                            </p>
                          </td>
                          <td>
                            <h6 className="profile-text text-sm">
                              {!ele.isEmailVerified
                                ? (
                                    <>
                                      <CopyToClipboard
                                        text={
                                          userListLink +
                                          "verify-email/" +
                                          ele.token
                                        }>
                                        <div
                                          className="pointer"
                                          onClick={($event) => {
                                            toast.success(
                                              "Copied to clipboard!"
                                            );
                                            $event.stopPropagation();
                                            $event.preventDefault();
                                          }}>
                                          Click to copy link{" "}
                                          <img
                                            src="assets/img/copyText.svg"
                                            alt=""
                                          />
                                        </div>
                                      </CopyToClipboard>
                                    </>
                                  ) || "-"
                                : ""}
                            </h6>
                          </td>

                          <td>
                            <h6 className="profile-text text-sm">
                              {ele.forgetToken
                                ? (
                                    <>
                                      <CopyToClipboard
                                        text={
                                          userListLink +
                                          "reset-password/" +
                                          ele.forgetToken
                                        }>
                                        <div
                                          className="pointer"
                                          onClick={($event) => {
                                            toast.success(
                                              "Copied to clipboard!"
                                            );
                                            $event.stopPropagation();
                                            $event.preventDefault();
                                          }}>
                                          Click to copy link{" "}
                                          <img
                                            src="assets/img/copyText.svg"
                                            alt=""
                                          />
                                        </div>
                                      </CopyToClipboard>
                                    </>
                                  ) || "-"
                                : ""}
                            </h6>
                          </td>
                          <td>
                            <h6 className="profile-text text-sm">
                              <TimeFormat time={ele.createdAt} />
                            </h6>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="d-flex pt-3">
                        <td className="d-flex flex-column justify-content-center">
                          <h6 className="mb-0 text-sm table-heading text-center">
                            No users found
                          </h6>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {userListCount > 1 && (
                <div className="pagination-section">
                  <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={userListCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    forcePage={activePage}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
