import paymentService from "../services/payment.service";
import { GET_PAYMENTS, SET_FAILED_MESSAGE, SET_SUCCESS_MESSAGE, START_LOADER, STOP_LOADER } from "./types";

export const getPaymentAction = (page, perPage) => (dispatch) => {
  if (page > 1) {
    dispatch({ type: START_LOADER });
  }
  return paymentService.getPayment(page, perPage).then((resp) => {
    dispatch({ type: GET_PAYMENTS, payload: resp.status ? resp.data : [], page: page, count: resp.status ? resp.count : 0 });
    dispatch({ type: STOP_LOADER });
  });
};


export const updatePaymentStatusAction = (id, payload, page, perPage) => (dispatch) => {
  dispatch({ type: START_LOADER });
  return paymentService.updatePaymentStatus(id, payload).then((resp) => {
    dispatch(getPaymentAction(page, perPage))
    dispatch({ type: STOP_LOADER });
    dispatch({ type: resp.status ? SET_SUCCESS_MESSAGE : SET_FAILED_MESSAGE, payload: resp.data });
    return Promise.resolve();
  });
};


export const updatePaymentApprovalStatusAction = (id, payload, page, perPage) => (dispatch) => {
  dispatch({ type: START_LOADER });
  return paymentService.updatePaymentApprovalStatus(id, payload).then((resp) => {
    dispatch({ type: STOP_LOADER });
    dispatch(getPaymentAction(page, perPage))
    dispatch({ type: resp.status ? SET_SUCCESS_MESSAGE : SET_FAILED_MESSAGE, payload: resp.data });
    return Promise.resolve();
  });
};