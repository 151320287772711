import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { CUSTOM_SELECT_STYLES, SELECT_BOX_THEME, objectiveCards } from "../../constants";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useEffect } from "react";
import { getObjectiveAction } from "../../actions/objective";
import { useDispatch, useSelector } from "react-redux";
export default function FilterModal({
  filterModalClose,
  filterModalShow,
  setPlanFilter,
  subscriptionList,
  setCallToAction,
  notificationList,
  setIsRegister,
  setStartDate,
  setEndDate,
  setTangibleAsset,
  setFinancialObjective,
  setVerificationMethod,
}) {
  const dispatch = useDispatch();
  const { control, reset, handleSubmit } = useForm();
  const [startDateValue, setStartDateValue] = useState(null);
  const { objectives } = useSelector((state) => state.objective);

  const onSubmit = (data) => {
    if (data) {
      setPlanFilter(data?.planFilter?.id || "");
      setCallToAction(data?.callToAction?.id || "");
      setIsRegister(data?.isRegisterd?.value || "");
      setStartDate(
        startDateValue ? moment(startDateValue).format("YYYY-MM-DD") : ""
      );
      setEndDate(
        data?.timeRange ? moment(data?.timeRange).format("YYYY-MM-DD") : ""
      );
      setTangibleAsset(data?.tangibleAsset?.value || "");
      setFinancialObjective(data?.financial?.id || "");
      setVerificationMethod(data?.verification?.value || "");
    }
    filterModalClose();
  };
  const onCancel = () => {
    filterModalClose();
    reset();
    setStartDateValue(null);
    setStartDate("");
    setPlanFilter("");
    setCallToAction("");
    setIsRegister("");
    setEndDate("");
    setTangibleAsset("");
    setFinancialObjective("");
    setVerificationMethod("");
  };

  useEffect(() => {
    if(filterModalShow){
      dispatch(getObjectiveAction(1, 10000));
    }
  },[filterModalShow])

  const handleOnChange = (date) => {
    setStartDateValue(date);
  };
  return (
    <Modal
      show={filterModalShow}
      onHide={onCancel}
      className="companyEdit-modal confim-modal filter-modal"
    >
      <div className="modal-header">
        <h5>Search filters</h5>
      </div>
      <div className="modal-body">
        <form>
          <div className="row">
            <div className="col-md-6">
              <label className="labelForm" htmlFor="">
                Start date
              </label>
              <Controller
                control={control}
                className="form-control"
                name="dateAdded"
                render={({ field }) => (
                  <DatePicker
                    className="form-control"
                    {...field}
                    placeholderText="Select date"
                    onChange={(date) => handleOnChange(date)}
                    selected={startDateValue}
                    dateFormat="yyyy-MM-dd"
                    maxDate={new Date()}
                  />
                )}
              />
            </div>
            {startDateValue && (
              <div className="col-md-6">
                <label className="labelForm" htmlFor="">
                  End date
                </label>
                <Controller
                  control={control}
                  className="form-control"
                  name="timeRange"
                  render={({ field }) => (
                    <DatePicker
                      className="form-control"
                      {...field}
                      placeholderText="Select date"
                      onChange={(date) => field.onChange(date)}
                      selected={field.value}
                      dateFormat="yyyy-MM-dd"
                      maxDate={new Date()}
                      minDate={startDateValue}
                    />
                  )}
                />
              </div>
            )}
            <div className="col-md-6">
              <label className="labelForm" htmlFor="">
                Main financial objective
              </label>
              <Controller
                name="financial"
                control={control}
                // defaultValue={null}
                render={({ field }) => (
                  <Select
                    theme={SELECT_BOX_THEME}
                    styles={CUSTOM_SELECT_STYLES}
                    className="select-border"
                    {...field}
                    options={objectives}
                    isClearable={true}
                    getOptionLabel={(option) => option.title}
                    getOptionValue={(option) => option.id}
                    placeholder="Choose CTA..."
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                )}
              />
            </div>
            <div className="col-md-6">
              <label className="labelForm" htmlFor="">
                Singapore registered
              </label>
              <Controller
                name="isRegisterd"
                control={control}
                // defaultValue={null}
                render={({ field }) => (
                  <Select
                    theme={SELECT_BOX_THEME}
                    styles={CUSTOM_SELECT_STYLES}
                    className="select-border"
                    {...field}
                    options={[
                      { label: "Yes", value: "yes" },
                      { label: "No", value: "no" },
                    ]}
                    isClearable={true}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    placeholder="Choose CTA..."
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                )}
              />
            </div>

            <div className="col-md-6">
              <label className="labelForm" htmlFor="">
                Verification method
              </label>
              <Controller
                name="verification"
                control={control}
                // defaultValue={null}
                render={({ field }) => (
                  <Select
                    theme={SELECT_BOX_THEME}
                    styles={CUSTOM_SELECT_STYLES}
                    className="select-border"
                    {...field}
                    options={[
                      { value: "myinfo", label: "MyInfo" },
                      { value: "standard", label: "Standard" },
                    ]}
                    isClearable={true}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    placeholder="Choose CTA..."
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                )}
              />
            </div>
            <div className="col-md-6">
              <label className="labelForm" htmlFor="">
                Call to action
              </label>
              <Controller
                name="callToAction"
                control={control}
                // defaultValue={null}
                render={({ field }) => (
                  <Select
                    theme={SELECT_BOX_THEME}
                    styles={CUSTOM_SELECT_STYLES}
                    className="select-border"
                    {...field}
                    options={notificationList}
                    isClearable={true}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.id}
                    placeholder="Choose CTA..."
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                )}
              />
            </div>

            <div className="col-md-6">
              <label className="labelForm" htmlFor="">
                Suggested plan
              </label>
              <Controller
                name="planFilter"
                control={control}
                // defaultValue={null}
                render={({ field }) => (
                  <Select
                    theme={SELECT_BOX_THEME}
                    styles={CUSTOM_SELECT_STYLES}
                    className="select-border"
                    {...field}
                    options={subscriptionList}
                    isClearable={true}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    placeholder="Choose CTA..."
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className="row bottomRow">
            <div className="col-md-6">
              <button
                onClick={onCancel}
                type="button"
                className="btn save-btn cancelButton"
              >
                Cancel
              </button>
            </div>
            {/* <div className="col-md-4 resultCol">
              <div className="totalResult">Results: 2 Entries</div>
            </div> */}
            <div className="col-md-6 mt-md-0 mt-2">
              <button
                type="submit"
                onClick={handleSubmit(onSubmit)}
                className="btn save-btn customConfirmButton"
              >
                Apply
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}
