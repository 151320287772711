import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { updateSettingsAction } from "../../actions/settings";
import { CUSTOM_SELECT_STYLES, GOLD_BID_PRICE, SELECT_BOX_THEME, SUPPORTED_PAYMENT_METHOD_LIST } from "../../constants";
import Select from "react-select";

export default function GoldBidMapping({ data }) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset(data)
  }, [data])


  const onSubmit = (data) => {
    const payload = {
      goldBidMapping: data,
      goldBidMappingSubscription: data
    }
    dispatch(updateSettingsAction(payload));
  };
  return (
    <>
      {/* <div className="card-body"> */}
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="form-group form-margin">
          <div className="row">
            <label className="col-form-label edit-info">Enter gold big price mapping</label>

            {GOLD_BID_PRICE.map((item, index) => (
              <div className="col-lg-4" key={index}>
                <span className="input-text">{item?.name} *</span>
                <input
                  maxLength={500}
                  autoComplete="off"
                  type="text"
                  className="form-control"
                  placeholder="Enter plan/product id"
                  {...register(item?.value, { required: true })}
                />
                {errors[item?.value]?.type === "required" && (
                  <span className="errorMessage">This field is required</span>
                )}
              </div>
            ))}
            
          </div>
        </div>
        <button type="submit" className="btn agree-button save-btn mt-3">
          Save changes
        </button>
      </form >
      {/* </div> */}
    </>
  );
}
