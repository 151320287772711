import dashboardService from "../services/dashboard.service";
import { GET_DASHBOARD } from "./types";

export const getDashboardAction = (payload) => (dispatch) => {
  return dashboardService.getDashboard(payload).then((resp) => {
    if(resp.status){
      dispatch({type: GET_DASHBOARD ,payload: resp.data,});
    }else{
      dispatch({type: GET_DASHBOARD ,payload: {},});
    }
  });
};