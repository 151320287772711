import objectiveService from "../services/objective.service";
import {
  GET_NOTIFICATION_BANNERS,
  GET_NOTIFICATION_BY_ID,
  GET_OBJECTIVE,
  GET_OBJECTIVE_BY_ID,
  SET_FAILED_MESSAGE,
  SET_SUCCESS_MESSAGE,
  START_LOADER,
  STOP_LOADER,
} from "./types";

export const getObjectiveAction =
  (page, perPage) => (dispatch) => {
    dispatch({ type: START_LOADER });
    return objectiveService
      .getObjective(page, perPage)
      .then((resp) => {
        if (resp.status) {
          dispatch({
            type: GET_OBJECTIVE,
            payload: resp.data,
            count: resp.count,
          });
        } else {
          dispatch({ type: GET_OBJECTIVE, payload: [], count: 0 });
        }
        dispatch({ type: STOP_LOADER });
      });
  };

export const getObjectiveByIdAction = (id) => (dispatch) => {
  return objectiveService.getObjectiveById(id).then(async (resp) => {
    if (resp.status) {
      await dispatch({ type: GET_OBJECTIVE_BY_ID, payload: resp.data });
    } else {
      dispatch({ type: GET_OBJECTIVE_BY_ID, payload: {} });
    }
  });
};

export const addObjectiveAction = (payload) => (dispatch) => {
  dispatch({ type: START_LOADER });
  return objectiveService.addObjective(payload).then(async (resp) => {
    if (resp.status) {
      dispatch({
        type: SET_SUCCESS_MESSAGE,
        payload: resp.data,
      });
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
    dispatch({ type: STOP_LOADER });
  });
};

export const editObjectiveAction = (payload, id) => (dispatch) => {
  dispatch({ type: START_LOADER });
  return objectiveService.editObjective(payload, id).then((resp) => {
    if (resp.status) {
      dispatch({
        type: SET_SUCCESS_MESSAGE,
        payload: resp.data,
      });
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
    dispatch({ type: STOP_LOADER });
  });
};
