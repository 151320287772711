import {
  CLEAR_TYPING_INDICATOR,
  GET_CHANNEL_LIST,
  REMOVE_TYPING_INDICATOR,
  RESET_CHANNEL_MESSAGE,
  SET_CHANNEL_LAST_MESSAGE,
  SET_CHANNEL_MESSAGE,
  SET_CHANNEL_NEW_MESSAGE,
  SET_TYPING_INDICATOR,
  SET_CLIENT,
  SET_CURRENT_CONVERSATION,
  SET_CHAT_CONVERSATION_MEDIA,
  SET_BULK_CHANNEL_MESSAGE,
  SET_ALL_CONVERSATION,



  UPSERT_CONVERSATION,
  REMOVE_CONVERSATION,
  UPDATE_CURRENT_CONVERSATION,
  CONVERSATION_LAST_READ_INDEX,
  ADD_MESSAGES,
  PUSH_MESSAGES,
  REMOVE_MESSAGES,
  UPDATE_PARTICIPANTS,
  UPDATE_UNREAD_MESSAGES,
  UPDATE_CONVERSATION,
  ADD_ATTACHMENT,
  CLEAR_ATTACHMENTS,
  TYPING_STARTED,
  TYPING_ENDED,
  UPDATE_LOADING_STATE,
  SET_DEFAULT_CURRENT_MESSAGE_LENGTH,
  SET_CHAT_SEARCH_RESULT,
  SET_CHAT_SEARCH_MESSAGE_ID,
  SET_LOAD_CLIENT,
  SET_SUCCESS_MESSAGE,
  SET_FAILED_MESSAGE,
  SET_NEW_CHANNEL_REDIRECTION_FORCE,
  HOLD_SID_CONVERSATION,
  OPEN_LATEST_CONVERSATION,
  CHAT_MEDIA,
  LOADING_CHAT_MEDIA,
  CHAT_PREVIEWER_MEDIA
} from "./types";
import chatService from "../services/chat.service";
import { MESSAGING_LIMIT } from "../constants";
import { startLoaderAction, stopLoaderAction } from "./updateReducerValue";

export const addChannel = async (payload) => {
  return await chatService.addChannel(payload).then((res) => {
    return res
  });
};

export const getChannelList = () => (dispatch) => {
  return chatService.getChannelList().then((res) => {
    if (res.status) {
      dispatch({ type: GET_CHANNEL_LIST, payload: res.data });
    } else {
      dispatch({ type: GET_CHANNEL_LIST, payload: [] });
    }
    return Promise.resolve();
  });
};


export const getTwilioToken = async (string, id) => {
  return chatService.getTwilioToken(string, id).then((response) => {
    return response
  })
};

export const updateChannel = (obj, companyId) => (dispatch) => {
  return chatService.updateChannel(obj).then((response) => {
    dispatch({ type: SET_CHANNEL_LAST_MESSAGE, payload: obj });
    return response
  })
}


export const refreshChatReadStatus = (obj, companyId) => (dispatch) => {
  return chatService.refreshChatReadStatus(companyId, obj).then((response) => {
    dispatch(getChannelList(companyId))
    return response
  })
}

export const setAllConversationAction = (conversations) => (dispatch) => {
  dispatch({ type: SET_ALL_CONVERSATION, payload: conversations });
}

export const setMessages = (conversationSid, message, participants, hasPrevious) => (dispatch) => {
  dispatch({ type: SET_CHANNEL_MESSAGE, payload: { message: message, sid: conversationSid, participants: participants, hasPrevious: hasPrevious } });
}

export const setMessage = (conversationSid, message) => (dispatch) => {
  dispatch({ type: SET_CHANNEL_NEW_MESSAGE, payload: { message: message, sid: conversationSid } });
}

export const resetMessage = () => (dispatch) => {
  dispatch({ type: RESET_CHANNEL_MESSAGE });
}

export const setBulkMessages = (messages) => (dispatch) => {
  dispatch({ type: SET_BULK_CHANNEL_MESSAGE, payload: messages });
}

export const setTypingIndicationAction = (channelId, identity) => (dispatch) => {
  dispatch({ type: SET_TYPING_INDICATOR, payload: { identity: identity, channelId: channelId } });
}

export const removeTypingIndicationAction = (channelId, identity) => (dispatch) => {
  dispatch({ type: REMOVE_TYPING_INDICATOR, payload: { identity: identity, channelId: channelId } });
}

export const clearTypingIndicationAction = () => (dispatch) => {
  dispatch({ type: CLEAR_TYPING_INDICATOR });
}

export const setClientAction = (client) => (dispatch) => {
  dispatch({ type: SET_CLIENT, payload: client });
}

export const setConversationAction = (conversation) => (dispatch) => {
  dispatch({ type: SET_CURRENT_CONVERSATION, payload: conversation });
}


export const createMembersAPI = (members) => (dispatch) => {
  return chatService.createNewMembers(members).then((resp) => {
    if (resp.status) {
      return Promise.resolve(true);
    } else {
      return Promise.resolve(false);
    }
  });
}

export const SetChatCurrentMedia = (payload) => (dispatch) => {
  dispatch({ type: SET_CHAT_CONVERSATION_MEDIA, payload: payload });
}







// ------------------------------------------------------------------------------------------------------------


export const upsertConversation = (convo) => (dispatch) => {
  dispatch({ type: UPSERT_CONVERSATION, payload: convo, });
};

export const removeConversation = (sid) => (dispatch) => {
  dispatch({ type: REMOVE_CONVERSATION, payload: sid, });
};

export const updateCurrentConversation = (sid) => (dispatch) => {
  dispatch({ type: UPDATE_CURRENT_CONVERSATION, payload: sid });
};

export const sliceCurrentConvoChatToDefault = (sid) => (dispatch) => {
  dispatch({ type: SET_DEFAULT_CURRENT_MESSAGE_LENGTH, payload: sid });
};

export const setLastReadIndex = (index) => (dispatch) => {
  dispatch({ type: CONVERSATION_LAST_READ_INDEX, payload: index, });
};

export const upsertMessages = (channelSid, messages) => (dispatch) => {
  dispatch({
    type: ADD_MESSAGES,
    payload: { channelSid, messages },
  });
};

export const pushMessages = (channelSid, messages) => (dispatch) => {
  dispatch({
    type: PUSH_MESSAGES,
    payload: { channelSid, messages },
  });
};

export const removeMessages = (channelSid, messages) => (dispatch) => {
  dispatch({
    type: REMOVE_MESSAGES,
    payload: { channelSid, messages },
  });
};

export const updateLoadingState = (loadingStatus) => (dispatch) => {
  dispatch({
    type: UPDATE_LOADING_STATE,
    payload: loadingStatus,
  });
};

export const updateParticipants = (participants, sid) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_PARTICIPANTS,
    payload: { participants, sid },
    stateValue: getState()
  });
};

export const updateUnreadMessages = (channelSid, unreadCount) => (dispatch) => {
  dispatch({
    type: UPDATE_UNREAD_MESSAGES,
    payload: { channelSid, unreadCount },
  });
};

export const updateConversation = (channelSid, parameters) => (dispatch) => {
  dispatch({
    type: UPDATE_CONVERSATION,
    payload: { channelSid, parameters },
  });
};

export const addAttachment = (
  channelSid,
  messageSid,
  mediaSid,
  attachment
) => (dispatch) => {
  dispatch({
    type: ADD_ATTACHMENT,
    payload: { channelSid, messageSid, mediaSid, attachment },
  });
};

export const clearAttachments = (channelSid, messageSid) => (dispatch) => {
  dispatch({
    type: CLEAR_ATTACHMENTS,
    payload: { channelSid, messageSid },
  });
};

export const startTyping = (channelSid, participant) => (dispatch) => {
  dispatch({
    type: TYPING_STARTED,
    payload: { channelSid, participant },
  });
};

export const endTyping = (channelSid, participant) => (dispatch) => {
  dispatch({
    type: TYPING_ENDED,
    payload: { channelSid, participant },
  });
};

export const getMessages = async (conversation) => {
  return await conversation.getMessages(MESSAGING_LIMIT)
}

export const getTypingMessage = (typingData) => {
  return typingData.length > 1 ? `${typingData.length + " participants are typing..."}` : `${typingData[0] + " is typing..."}`;
}

export const addParticipantAction = async (payload) => {
  return chatService.addChannelParticipant(payload).then((response) => {
    return response;
  });
};

export const removeParticipantAction = async (payload) => {
  return chatService.removeChannelParticipant(payload).then((response) => {
    return response;
  });
};


export const searchAction = (payload) => (dispatch) => {
  dispatch({ type: SET_CHAT_SEARCH_RESULT, payload: payload });
}

export const gotoMessageSearchMessageAction = (payload) => (dispatch) => {
  dispatch({ type: SET_CHAT_SEARCH_MESSAGE_ID, payload: payload });
}

export const changeChannelNameAction = (payload) => (dispatch) => {
  dispatch(startLoaderAction());
  return new Promise((resolve, reject) => {
    chatService.changeChannelName(payload).then((response) => {
      dispatch(stopLoaderAction());
      if (response.status) dispatch({ type: SET_SUCCESS_MESSAGE, payload: response.data });
      else dispatch({ type: SET_FAILED_MESSAGE, payload: response.data });
      resolve(response);
    });
  });
};


export const setChatLoadClientAction = () => (dispatch) => {
  dispatch({ type: SET_LOAD_CLIENT })
}

export const addNewChannelAction = (payload) => (dispatch) => {
  return chatService.addNewChannelConversation(payload).then((response) => {
    if(response?.status){
      dispatch(resetRedirectionForce(response?.sid));
    }
    return Promise.resolve();
  });
}

export const resetRedirectionForce = (payload) => (dispatch) => {
  dispatch({ type: SET_NEW_CHANNEL_REDIRECTION_FORCE, payload: payload });
}

export const setSidConversationAction= (payload) => (dispatch) => {
  dispatch({ type: HOLD_SID_CONVERSATION, payload: payload })
}

export const openLatestConversationAction= (payload) => (dispatch) => {
  dispatch({ type: OPEN_LATEST_CONVERSATION, payload: payload })
}


export const chatMediaGetAction = (channelId) => async (dispatch) => {
  dispatch({ type: LOADING_CHAT_MEDIA, payload: true})
  return chatService.chatMediaGet(channelId).then((response) => {
    dispatch({ type: CHAT_MEDIA, payload: response?.status ? (response?.data ?? []) : [] })
    dispatch({ type: LOADING_CHAT_MEDIA, payload: false})
  });
};


export const chatMediaAddAction = (payload) => async (dispatch) => {
  return chatService.chatMediaAdd(payload).then((response) => {
    // dispatch(chatMediaGetAction(payload?.channelId));
  });
};

export const emptyChatMediaAction = () => async (dispatch) => {
  dispatch({ type: CHAT_MEDIA, payload: [] })
};

export const chatMediaPreviewerAction = (currentImage, isViewerOpen) => async (dispatch) => {
  dispatch({ type: CHAT_PREVIEWER_MEDIA, payload: {currentImage, isViewerOpen} })
};