import moment from "moment";
import React, { useCallback } from "react";
import { downloadMedia } from "../../pages/chatPage/userMessages";
import { backendUrl } from "../../constants";
import { noData } from "../../pages/chatPage/userProfile";
import { chatMediaPreviewerAction } from "../../actions/chat";

var holdCurrentDate = null;
var currentPreviewFileTabChange = null;

export default function ChatImagePreviewer({ type, chatMedia, dispatch, previewFileTabChange }) {

  if (previewFileTabChange !== currentPreviewFileTabChange) {
    holdCurrentDate = null
  }
  const getSeperatedDate = (messageDate) => {
    var showDate = false;
    var currentMessageDate = moment(messageDate).format("YYYYMMDD");
    showDate = holdCurrentDate !== currentMessageDate ? true : false;
    holdCurrentDate = currentMessageDate;
    if (showDate) {
      return (
        <div className="seperate-line">
          <span>
            {moment(messageDate).format("ddd, MMM D")}{" "}
            {moment(messageDate).format("Y") !== moment().format("Y")
              ? "," + moment(messageDate).format("Y")
              : ""}
          </span>
        </div>
      );
    } else {
      return null
    }
  };

  const openImageViewer = useCallback((index) => {
    dispatch(chatMediaPreviewerAction(index, true));
  }, []);

  

  return (
    // success class for green banner else empty ""
    <>
      {chatMedia.length > 0 ? (
        chatMedia && chatMedia.map((item, index) => (
          <span key={index}>
            {getSeperatedDate(item.createdAt)}

            {/* // cvMedia.attachedMedia.map((item, index) => ( */}
            <span key={index} className="addMedia" onClick={() => type==='image' ? openImageViewer(index) : downloadMedia(dispatch, item)}>
              {item?.mimetype.startsWith('image/') ? (
                <span className="imagePreviewChat" style={{ backgroundImage: `url(${backendUrl + item.filename})` }}></span>
              ) : (
                <>
                  <span className="addMediaPadding">
                    <img className="imageView" src="assets/img/imageView.svg" alt="" />
                    &nbsp;<span className="fileName">{item.originalName}</span>
                  </span>
                </>
              )}
            </span>
            {/* // )) */}

          </span>

        )
        )) : (
        noData("No attached files...")
      )}
    </>
  );
}
