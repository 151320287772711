import React, { useEffect } from "react";
import { useFieldArray } from "react-hook-form";

export default function AssuredFeature({
  control,
  register,
  errors,
  resetField,
  assuredFeature
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "assuredFeature"
  });

  useEffect(() => {
    resetField('assuredFeature')
    assuredFeature.forEach(element => {
      append(element)  
    });
  }, [])
  return (
    <form>
      <div className="planProfileCard">
        <h5>You are assured of:</h5>
        <div className="form-group form-margin">
          <div className="row">

          {fields.map((field, index) => (
               <div key={field.id} className="col-lg-12 fieldPadding">
               <p className="input-text heading">Feature {index + 1}</p>
               {fields.length > 2 && (
                 <div
                   onClick={() => remove(index)}
                   className="removeBtn"
                 >
                   <i className="fa fa-times-circle" aria-hidden="true"></i>
                 </div>
               )}
               <input
                 autoComplete="off"
                 type="text"
                 className="form-control"
                 placeholder="Type feature here"
                 {...register(`assuredFeature.${index}`, {
                  required: true,
                 })}
               />
               {errors[`assuredFeature`]?.[index] && errors[`assuredFeature`]?.[index]?.type === "required" && (
                   <span className="errorMessage">
                     Feature {index + 1} is required
                   </span>
                 )}
             </div>
            ))}
            <div className="col-lg-4">
              <div
                onClick={() => append('')}
                className="addMoreFeature"
              >
                <img src="assets/img/plusBtn.svg" alt="" />
                Add another feature
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
