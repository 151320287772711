import React, { useEffect } from "react";
import Header from "../../components/header/header";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { getSettingsAction } from "../../actions/settings";
import AppVersion from "./AppVersion";
import GoldBidMapping from "./GoldBidMapping";

export default function Settings() {
  const dispatch = useDispatch();
  const { appVersion, goldBidMapping } = useSelector((state) => state.settings);
  useEffect(() => {
    const callActions = () => {
      dispatch(getSettingsAction())
    }
    callActions()
  }, [])

  return (
    <>
      <Header title="Profile" />
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-lg-1 col-xl-1 col-md-12 col-sm-12 mb-2"></div>
          <div className="col-lg-10 col-xl-10 col-md-12 col-sm-12 mb-2">
            <div className="planProfileCard card h-100">
              <div className="card-body">
                <Tabs>
                  <TabList>
                    {/* <Tab>Default StripeId</Tab> */}
                    <Tab>Gold price mapping </Tab>
                    <Tab>App version</Tab>
                  </TabList>
                  {/* <TabPanel>
                    <DefaultStripeId data={defaultStripePriceId}/>
                  </TabPanel> */}
                  <TabPanel>
                    <GoldBidMapping data={goldBidMapping}/>
                  </TabPanel>
                  <TabPanel>
                    <AppVersion data={appVersion}/>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
          <div className="col-lg-1 col-xl-1 col-md-12 col-sm-12 mb-2"></div>
        </div>
      </div>
    </>
  );
}
