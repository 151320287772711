import React from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import FileDragDrop from "./FileDragDrop";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { BOB_SERVICES_TEMPLATE_NAME, POSITIONS, POTENTIAL_PURPOSES, SELECT_BOX_THEME } from "../../constants";
import { importBusinessProfileAction } from "../../actions/bookOfBusiness";
import Select from "react-select";

export default function ImportFile({
  importOpen,
  importClose,
  companyDetail,
  status,
  limit,
  bookOfBusiness
}) {
  
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const [fileStep, setFileStep] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [progressWidth, setPrgressWidth] = useState(0);
  const [validate, setValidate] = useState(false);
  const [errorPotentialPurpose, setErrorPotentialPurpose] = useState(false);
  const [customPotentialPurposeInputValue, setCustomPotentialPurposeInputValue] = useState(null);
  const uploadFiles = async (event, value) => {
    if (companyDetail?.bookOfBusinessGroup.length > 0 && companyDetail?.bookOfBusinessGroup[0]?.isDefault && customPotentialPurposeInputValue === null) {
      setErrorPotentialPurpose(customPotentialPurposeInputValue === null ? true : false)
      return false
    }
    const files = value === "replace" ? event.target.files[0] : event[0];
    setFile(files);
    setIsError({ status: true });
    setFileStep(1);
  };

  const handlePotentialPurpose = (event) => {
    if (companyDetail?.bookOfBusinessGroup.length > 0 && companyDetail?.bookOfBusinessGroup[0]?.isDefault) {
      setErrorPotentialPurpose(event === null ? true : false)
      setCustomPotentialPurposeInputValue(event)
    }
  }

  const handleDisabledImport = () => {
    if (companyDetail?.bookOfBusinessGroup?.isDefault) {
      if (customPotentialPurposeInputValue === null) {
        return true
      } else {
        return fileStep === 0 ? file : fileStep === 2 ? progressWidth < 100 : false
      }
    } else {
      return fileStep === 0 ? file : fileStep === 2 ? progressWidth < 100 : false
    }
  }

  const handleImport = async () => {
    switch (fileStep) {
      case 0:
        setValidate(true);
        break;
      case 1:
        if (file) {
          const formData = new FormData();
          formData.append("bobfile", file);
          formData.append("status", status);
          if (companyDetail?.bookOfBusinessGroup.length > 0 && companyDetail?.bookOfBusinessGroup[0]?.isDefault) {
            formData.append("customPotentialPurposeInput", (customPotentialPurposeInputValue?.value || null));
          }
          const onProgress = async (percentage) => {
            setFileStep(2);
            setPrgressWidth(percentage);
            setIsError({ status: true });
          };
          var resp = await dispatch(
            importBusinessProfileAction(
              formData,
              companyDetail?._id,
              onProgress,
              bookOfBusiness?.id ? bookOfBusiness?.id : null
            )
          );
          if (resp?.status) {
            setIsSuccess(resp);
            setIsError({ status: true });
          } else {
            setIsError(resp);
            setFileStep(1);
          }
        }
        if (isSuccess.status) {
          setFileStep(2);
        }
        break;
      case 2:
        if (progressWidth === 100 && isSuccess.status) {
          setFileStep(3);
          setPrgressWidth(0);
        }
        break;
      case 3:
        handleclose();
        break;
      default:
        break;
    }
  };

  // useEffect(() => {
  //   if (fileStep === 2) {
  //     if (progressWidth < 100) {
  //       //Implementing the setInterval method
  //       const interval = setInterval(() => {
  //         setPrgressWidth(progressWidth + 10);
  //       }, 1000);

  //       //Clearing the interval
  //       return () => clearInterval(interval);
  //     }
  //   }
  // }, [progressWidth, fileStep]);

  const handleclose = () => {
    importClose();
    setFile([]);
    setFileStep(0);
    setIsError(false);
    setIsSuccess(false);
    setValidate(false);
    setPrgressWidth(0);
    setCustomPotentialPurposeInputValue(null);
  };

  const getRole = (position) => {
    var returnVal = "";
    var filter = POSITIONS.filter((x) => x.value === position);
    if (filter.length > 0) {
      returnVal = filter[0].name;
    }
    return returnVal;
  };

  const formatBytes = (bytes = 0, decimals) => {
    if (bytes === 0) return "0 Bytes";
    var k = 1024,
      dm = decimals || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  return (
    <>
      <Modal
        show={importOpen}
        onHide={handleclose}
        className="moddal-primary importModal ps-0"
      >
        <Modal.Body>
          <div className="header">
            <div>
              <h4>Import profiles by CSV</h4>
              <p>
                Account name:{" "}
                <span>{companyDetail?.companyName || "-"}</span> Potential role:{" "}
                <span>{getRole(companyDetail?.defaultDesignationRole)}</span> Book of business:{" "}
                <span>{companyDetail?.bookOfBusinessGroup.length > 0 ? (companyDetail?.bookOfBusinessGroup[0]?.name || "-") : "-"}</span>

                {companyDetail?.bookOfBusinessGroup.length > 0 && companyDetail?.bookOfBusinessGroup[0]?.isDefault && (
                  <><br />
                    {fileStep === 0 ? (
                      <div className="display-single-line">
                        <div>Potential purpose:{" "}&nbsp;</div>
                        <div>

                          <Select
                            className="select-border"
                            theme={SELECT_BOX_THEME}
                            value={customPotentialPurposeInputValue}
                            onChange={handlePotentialPurpose}
                            options={POTENTIAL_PURPOSES}
                            isClearable={true}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.value}
                            placeholder={`${companyDetail?.isDefault ? "Enter a potential reason why you like to introduce this profile to other users" : "Enter a potential reason why you would like to meet this profile"}`}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                          />


                          {/* <input
                            maxLength={50}
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            placeholder="Potential Purpose"
                            onChange={handlePotentialPurpose}
                          /> */}
                          {errorPotentialPurpose && (
                            <span className="errorMessage">Potential purpose is required</span>
                          )}
                        </div>
                      </div>
                    ) : (
                      <>
                        Potential Purpose:{" "}
                        <span>{customPotentialPurposeInputValue?.value || "-"}</span>
                      </>
                    )}
                  </>
                )}
              </p>
            </div>
            <i onClick={handleclose} className="fa-solid fa-xmark"></i>
          </div>
          <div className="modalBody importSampleFile">
            {fileStep !== 3 && companyDetail?.bookOfBusinessGroup[0]?.service && (
              <p className="sampleFile">
                <a href={`assets/file/${BOB_SERVICES_TEMPLATE_NAME[companyDetail?.bookOfBusinessGroup[0]?.service]}`} download>
                  Download a sample CSV
                </a>{" "}
                to see an example of the required format.
              </p>
            )}
            {fileStep === 0 && (
              <>
                <p className="desc">
                  Lorem ipsum velit officia consequat duis enim. Lorem ipsum
                  officia velit.
                </p>
                <FileDragDrop
                  onUpload={(event) => uploadFiles(event, "new")}
                  multiple="false"
                  validate={validate}
                />
              </>
            )}
            {(fileStep === 1 || fileStep === 2) && (
              <>
                {!isError.status && (
                  <>
                    <div className="messageHolder">
                      <img src="assets/img/errorIcon.svg" alt="" />

                      <div className="content">
                        <h5>{isError.type}</h5>
                        <ul>
                          <li
                            dangerouslySetInnerHTML={{ __html: isError?.data }}
                          ></li>
                        </ul>
                      </div>
                    </div>
                  </>
                )}
                <p className="desc">Added File:</p>
                <div className={isError.status ? "" : "fileHolder"}>
                  <div
                    className={`${fileStep === 2 ? "loaderHolder" : ""
                      } addedFiles`}
                  >
                    {fileStep === 1 ? (
                      <>
                        <div className="fileInfo">
                          <img src="assets/img/fileInput.svg" alt="" />
                          <div className="fileName">
                            <p>{file?.name || "No file selected"}</p>
                            <span>{formatBytes(file?.size)}</span>
                          </div>
                        </div>
                        <div className="replaceBtn">
                          <label htmlFor="file" className="customReplaceButton">
                            <input
                              onChange={(event) =>
                                uploadFiles(event, "replace")
                              }
                              type="file"
                              id="file"
                              hidden
                            />
                            Replace File
                          </label>
                        </div>
                      </>
                    ) : fileStep === 2 ? (
                      <>
                        <div className="loaderHead">
                          {/* <p>{file?.name}</p> */}
                          <p className="percent">{progressWidth}% uploaded</p>
                        </div>
                        <div className="progressHolder">
                          <ProgressBar now={progressWidth} />
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </>
            )}

            {fileStep === 3 && (
              <>
                {isSuccess.status && (
                  <>
                    <div className="fileHolder">
                      <div className="messageHolder successMessge">
                        <img src="assets/img/successIcon.svg" alt="" />

                        <div className="content">
                          <h5>Import Profiles Successful</h5>
                          <ul>
                            <li
                              dangerouslySetInnerHTML={{
                                __html: isSuccess.data,
                              }}
                            ></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            <div className="note">
              <p className="desc">
                <span>IMPORTANT NOTE:</span> Lorem ipsum velit officia consequat
                duis enim velit mollit.
              </p>
            </div>
            <div className="importBtn">
              {fileStep === 2 && (
                <button
                  onClick={handleclose}
                  disabled={true}
                  className="customImportButton"
                >
                  Cancel
                </button>
              )}
              <button
                onClick={handleImport}
                disabled={handleDisabledImport()}
                className="customAddButton removePadding"
              >
                {fileStep === 3 ? "Proceed" : "Import"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
