import {
  GET_SUGGESTED_PROFILE,
  SET_FAILED_MESSAGE,
  SET_SUCCESS_MESSAGE,
  START_LOADER,
  STOP_LOADER,
  GET_BUSINESS_GROUP,
  RESET_BOB_PAGINATION
} from "./types";
import bookOfBusinessService from "../services/bookOfBusiness.service";

export const addBusinessProfile = (payload, id, bookOfBusinessGroupId) => (dispatch) => {
  dispatch({ type: START_LOADER });
  return bookOfBusinessService
    .addBusinessProfile(payload, id, bookOfBusinessGroupId)
    .then(async (resp) => {
      if (resp.status) {
        dispatch({ type: STOP_LOADER });
        dispatch(resetBobPaginationAction(true))
        return resp;
      } else {
        await dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
        dispatch({ type: STOP_LOADER });
      }
    });
};

export const editBusinessProfile =
  (payload, id, businessId) => (dispatch) => {
    dispatch({ type: START_LOADER });
    return bookOfBusinessService
      .editBusinessProfile(payload, id, businessId)
      .then(async (resp) => {
        if (resp.status) {
          await dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
          dispatch({ type: STOP_LOADER });
          dispatch(resetBobPaginationAction(true))
          return resp;
        } else {
          await dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
          dispatch({ type: STOP_LOADER });
        }
        dispatch({ type: STOP_LOADER });
      });
  };

export const getBusinessProfile =
  (page, limit, company, status, searchBookOfBusinessGroup, searchBookOfBusinessService) => (dispatch) => {
    dispatch({ type: START_LOADER });
    return bookOfBusinessService
      .getBusinessProfile(page, limit, company, status, searchBookOfBusinessGroup, searchBookOfBusinessService)
      .then((resp) => {
        if (resp.status) {
          dispatch({
            type: GET_SUGGESTED_PROFILE,
            payload: resp,
            count: resp.count,
            serviceTypeResponse: resp.serviceTypeResponse
          });
        } else {
          dispatch({ type: GET_SUGGESTED_PROFILE, payload: [] });
          dispatch({ type: STOP_LOADER });
        }
        dispatch({ type: STOP_LOADER });
      });
  };

export const importBusinessProfileAction =
  (payload, id, onProgress, bookOfBusinessGroupId) => (dispatch) => {
    return bookOfBusinessService
      .importBusinessProfile(payload, id, onProgress, bookOfBusinessGroupId)
      .then(async (resp) => {
        if (resp.status) {
          dispatch(resetBobPaginationAction(true))
        }
        return resp;
      })
      .catch((err) => {
        return err;
      });
  };

export const updateBusinessProfileAction =
  (companyId, id, payload) => (dispatch) => {
    dispatch({ type: START_LOADER });
    return bookOfBusinessService
      .updateBusinessProfile(companyId, id, payload)
      .then(async (resp) => {
        if (resp.status) {
          await dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
          dispatch({ type: STOP_LOADER });
          dispatch(resetBobPaginationAction(true))
          return resp;
        } else {
          await dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
          dispatch({ type: STOP_LOADER });
        }
      });
  };


export const updateBusinessMeetingAction =
  (companyId, id, payload) => (dispatch) => {
    dispatch({ type: START_LOADER });
    return bookOfBusinessService
      .updateMeetingDetails(companyId, id, payload)
      .then(async (resp) => {
        if (resp.status) {
          await dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
          dispatch({ type: STOP_LOADER });
          dispatch(resetBobPaginationAction(true))
        } else {
          await dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
          dispatch({ type: STOP_LOADER });
        }
        return resp;
      });
  };

  export const resetBobPaginationAction = (payload) => (dispatch) => {
    dispatch({ type: RESET_BOB_PAGINATION, payload: payload });
  }


  // Group


  export const addBusinessGroupAction = (payload) => (dispatch) => {
    dispatch({ type: START_LOADER });
    return bookOfBusinessService
      .addBusinessGroup(payload)
      .then(async (resp) => {
        if (resp.status) {
          dispatch({ type: STOP_LOADER });
          await dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
          dispatch(getBusinessGroupAction());
          return resp;
        } else {
          await dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
          dispatch({ type: STOP_LOADER });
        }
      });
  };
  
  export const editBusinessGroupAction =(payload, id) => (dispatch) => {
      dispatch({ type: START_LOADER });
      return bookOfBusinessService
        .editBusinessGroup(payload, id)
        .then(async (resp) => {
          if (resp.status) {
            await dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
            dispatch({ type: STOP_LOADER });
            dispatch(getBusinessGroupAction());
            return resp;
          } else {
            await dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
            dispatch({ type: STOP_LOADER });
          }
          dispatch({ type: STOP_LOADER });
        });
    };
  
  export const getBusinessGroupAction = (search, type, searchBookOfBusinessService) => (dispatch) => {
      dispatch({ type: START_LOADER });
      return bookOfBusinessService
        .getBusinessGroup(search, type, searchBookOfBusinessService)
        .then((resp) => {
          if (resp.status) {
            dispatch({
              type: GET_BUSINESS_GROUP,
              payload: resp.data,
              count: resp.count,
            });
          } else {
            dispatch({ type: GET_BUSINESS_GROUP, payload: [] });
            dispatch({ type: STOP_LOADER });
          }
          dispatch({ type: STOP_LOADER });
        });
    };