import axionIns from "../axios";

const getActivityLogs = (page, perPage, pageStatus) => {
  var url = "admin/requestedlogs?page=" + page + "&perPage=" + perPage;
  if(pageStatus && pageStatus.value){
    url = url + "&pageType=" + pageStatus?.value
  }
    
  return axionIns.get(url).then((response) => { return response.data });
};

const approveActivity = (payload) => {
  let endpoint = null;
  if (payload.type === 'company') {
    endpoint = `admin/company/${payload.flag ? 'approve' : 'reject'}/${payload.id}/${payload.companyId}`
    if(!payload.flag){
      endpoint = `${endpoint}/${payload.companyHistoryId}`
    }
  }
  if (payload.type === 'shareholder' && !payload.itemType) {
    endpoint = `admin/shareholders/${payload.flag ? 'approve' : 'reject'}/${payload.id}/${payload.shareholderId}`
  }
  if (payload.type === 'shareholder' && payload.itemType === 'request_appoint') {
    endpoint = `admin/shareholders/${payload.flag ? 'approvedAppoint' : 'rejectAppoint'}/${payload.id}/${payload.shareholderId}`
  }
  if (payload.type === 'shareholder' && payload.itemType === 'remove_invite') {
    endpoint = `admin/shareholders/remove_invite/${payload.flag ? 'approve' : 'reject'}/${payload.id}`
  }
  if (payload.type === 'officer' && !payload.itemType) {
    endpoint = `admin/officers/${payload.flag ? 'approve' : 'reject'}/${payload.id}/${payload.officerId}`
  }
  if (payload.type === 'officer' && payload.itemType === 'request_appoint') {
    endpoint = `admin/officers/${payload.flag ? 'approvedAppoint' : 'rejectAppoint'}/${payload.id}/${payload.officerId}`
  }
  if (payload.type === 'officer' && payload.itemType === 'remove_invite') {
    endpoint = `admin/officers/remove_invite/${payload.flag ? 'approve' : 'reject'}/${payload.id}`
  }
  if (payload.type === 'capital') {
    endpoint = `admin/capital/${payload.flag ? 'approve' : 'reject'}/${payload.id}/${payload.capitalId}`
  }
  if (payload.type === 'profile') {
    endpoint = `admin/profile/${payload.flag ? 'approve' : 'reject'}/${payload.id}/${payload.itemType}`
  }
  if (payload.type === 'subscription' && payload.itemType === 'cancel_subscription') {
    endpoint = `admin/cancel_subscription/${payload.flag ? 'approve' : 'reject'}/${payload.id}`
  }
  if (payload.type === 'promoter' && payload.itemType === 'remove_promoter') {
    endpoint = `admin/company/remove_promoter/${payload.flag ? 'approve' : 'reject'}/${payload.id}`
  }
  if (payload.type === 'promoter' && payload.itemType === 'remove_invite') {
    endpoint = `admin/company/remove_invite/${payload.flag ? 'approve' : 'reject'}/${payload.id}`
  }
  if (payload.type === 'promoter' && payload.itemType === 'request_appoint') {
    endpoint = `admin/company/invite/${payload.flag ? 'approvedAppoint' : 'rejectAppoint'}/${payload.companyId}/${payload.promoterId}/${payload.activityId}`
  }
  if (endpoint) {
    return axionIns.put(endpoint).then((response) => {
      return response.data
    });
  } else {
    return null
  }
};


const exportOject = {
  getActivityLogs,
  approveActivity
};

export default exportOject;
