import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

export default function FileDragDrop({ onUpload, multiple, validate }) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (multiple) {
        onUpload(acceptedFiles);
      } else {
        acceptedFiles.forEach((element) => {
          onUpload(element);
        });
      }
    },
    [multiple, onUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <div className="uploadHolder" {...getRootProps()}>
        <img src="assets/img/uploadIcon.svg" alt="" />
        <input {...getInputProps()} />
        <p className="desc">Click to browse or drag and drop files here</p>
      </div>
      {validate && <span className="errorMessage">Please upload csv file</span>}
    </>
  );
}
