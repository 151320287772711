import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { CUSTOM_SELECT_STYLES, SELECT_BOX_THEME } from "../../constants";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import FileDragDrop from "./FileDragDrop";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { addDocAction } from "../../actions/files";

export default function AddFile({ openFile, closeFile }) {
  const dispatch = useDispatch();
  const { businessInfo } = useSelector((state) => state.businessInfo);
  const { control, getValues, reset } = useForm();
  const [file, setFile] = useState([]);
  const [error, setError] = useState({});

  const uploadFiles = (event) => {
    setError({});
    setFile((file) => [...file, ...event]);
  };

  const handleUpload = async () => {
    var maxfileUploadSize = 1024 * 1024 * 50;
    var currentFileSize = 0;
    setError({});
    if (getValues("companyName")) {
      if (file.length > 0) {
        const formData = new FormData();
        file.forEach((element) => {
          currentFileSize = currentFileSize += element.size;
          formData.append("files", element);
        });
        if (currentFileSize < maxfileUploadSize) {
          dispatch(addDocAction(formData, getValues("companyName")._id));
          handleClose();
        } else {
          toast.error("Maximum file upload size is 50mb.");
        }
      } else {
        setError({ file: "Please select at least one file" });
      }
    } else {
      setError({ companyName: "Please select a Company" });
    }
  };
  const removeFile = (index) => {
    file.splice(index, 1);
    setFile([...file]);
  };
  const handleClose = () => {
    closeFile();
    setFile([]);
    setError({});
    reset();
  };
  return (
    <Modal show={openFile} className="fileModal" onHide={handleClose}>
      <div className="modal-header">
        <h5>Add files</h5>
        <i onClick={handleClose} className="fa-solid fa-xmark"></i>
      </div>
      <div className="modal-body">
        <div className="search">
          <Controller
            name="companyName"
            control={control}
            // defaultValue={null}
            render={({ field }) => (
              <Select
                theme={SELECT_BOX_THEME}
                styles={CUSTOM_SELECT_STYLES}
                className="select-border"
                {...field}
                options={businessInfo}
                isClearable={true}
                getOptionLabel={(option) => option.companyName}
                getOptionValue={(option) => option._id}
                placeholder="Select company"
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            )}
          />
          {error?.companyName !== "" && (
            <span className="errorMessage">{error?.companyName}</span>
          )}
        </div>
        <div className="dragDrop">
          <FileDragDrop
            onUpload={(event) => uploadFiles(event)}
            multiple="false"
          />
        </div>
        <div className="fileName customScroll">
          {file?.length > 0 &&
            file?.map((item, index) => (
              <p key={index}>
                {index + 1}. {item.name}{" "}
                <i
                  onClick={() => removeFile(index)}
                  className="fa-solid fa-xmark"
                ></i>
              </p>
            ))}
        </div>
        {error?.file !== "" && (
          <span className="errorMessage">{error?.file}</span>
        )}
        <div className="footer">
          <button
            onClick={handleUpload}
            // disabled={file?.length === 0}
            className="customAddButton removePadding"
          >
            Upload now
          </button>
        </div>
      </div>
    </Modal>
  );
}
