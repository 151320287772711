import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { RoutesPaths } from "../../Router/constant";
import { logout, toggleMobileMenu } from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import Confirm from "../confirm/confrm";

export default function Sidebar() {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { sidebarMobile, showMenuSidebar } = useSelector((state) => state.auth);

  const [confirmModal, setConfirmModal] = useState(false);

  const dashboardPath = useLocation(RoutesPaths.DASHBOARD);
  const subscriptionPath = useLocation(RoutesPaths.SUBSCRIPTION);
  const suggestedProfilePath = useLocation(RoutesPaths.BOOK_OF_BUSINESS);
  const businessDetailPath = useLocation(RoutesPaths.BUSINESSDETAIL);
  const notificationPath = useLocation(RoutesPaths.NOTIFICATIONMANAGER);
  const paymentPath = useLocation(RoutesPaths.PAYMENT);
  const invitePath = useLocation(RoutesPaths.INVITE);
  const filePath = useLocation(RoutesPaths.FILE);
  const taskPath = useLocation(RoutesPaths.TASK);
  const MessagePath = useLocation(RoutesPaths.MESSAGE_DETAIL);
  const bannerPath = useLocation(RoutesPaths.BANNER);

  useEffect(() => {
    if (sidebarMobile) {
      document.body.classList.add("g-sidenav-pinned");
    } else {
      document.body.classList.remove("g-sidenav-pinned");
    }
  }, [sidebarMobile]);

  const logoutClick = () => {
    setConfirmModal(true);
  };

  const logoutHide = (value) => {
    if (value) {
      dispatch(logout());
    }
    setConfirmModal(false);
  };

  const mobileMenuOpen = () => {
    dispatch(toggleMobileMenu());
  };

  const logoActionHeader = () => {
    navigate("/dashboard");
  }

  return (
    <aside
      // bg-white
      className={`sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 custom-scroll ${sidebarMobile ? "bg-white" : ""
        }`}
      id="sidenav-main"
    >
      <div className="sidenav-header">
        <i
          className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-xl-none icon"
          aria-hidden="true"
          onClick={mobileMenuOpen}
        ></i>
        <div onClick={logoActionHeader} className="navbar-brand m-0">
          <div className="navbar-header-icon admin-nav active">
            <img src="assets/img/logo.png" className="normal-icon" alt="" />
          </div>
          <p className="navbar-title">Admin</p>
        </div>
      </div>
      <hr className="horizontal dark mt-0" />
      {showMenuSidebar && (
        <div
          className="collapse navbar-collapse w-auto display-account ps"
          id="sidenav-collapse-main"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink
                active={dashboardPath?.isExact}
                to={RoutesPaths.DASHBOARD}
                className="nav-link"
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <img
                    src="assets/img/dashboard-icon.svg"
                    className="active-icon"
                    alt=""
                  />
                  <img
                    src="assets/img/dashboard-icon-active.svg"
                    className="normal-icon"
                    alt=""
                  />
                </div>
                <span className="nav-link-text ms-1">Dashboard</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                active={suggestedProfilePath?.isExact}
                to={RoutesPaths.BOOK_OF_BUSINESS}
                className="nav-link"
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="fa-solid fa-address-card"></i>
                </div>
                <span className="nav-link-text ms-1">Book of business</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                active={subscriptionPath?.isExact}
                to={RoutesPaths.SUBSCRIPTION}
                className="nav-link"
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="fas fa-solid fa-coins"></i>
                </div>
                <span className="nav-link-text ms-1">Subscription plans</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                active={businessDetailPath?.isExact}
                to={RoutesPaths.BUSINESSDETAIL}
                className="nav-link"
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <img
                    src="assets/img/businessDetail.svg"
                    className="normal-icon"
                    alt=""
                  />
                  <img
                    src="assets/img/businessDetail-active.svg"
                    className="active-icon"
                    alt=""
                  />
                </div>
                <span className="nav-link-text ms-1">Business details</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                active={notificationPath?.isExact}
                to={RoutesPaths.NOTIFICATIONMANAGER}
                className="nav-link"
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <img
                    src="assets/img/notify-normal.svg"
                    className="normal-icon"
                    alt=""
                  />
                  <img
                    src="assets/img/notify-active.svg"
                    className="active-icon"
                    alt=""
                  />
                </div>
                <span className="nav-link-text ms-1">Notifications manager</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                active={notificationPath?.isExact}
                to={RoutesPaths.OBJECTIVE}
                className="nav-link"
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <img
                    src="assets/img/objective-normal.svg"
                    className="normal-icon"
                    alt=""
                  />
                  <img
                    src="assets/img/objective-active.svg"
                    className="active-icon"
                    alt=""
                  />
                </div>
                <span className="nav-link-text ms-1">Objectives manager</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                active={bannerPath?.isExact}
                to={RoutesPaths.BANNER}
                className="nav-link"
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <img
                    src="assets/img/banner-normal.svg"
                    className="normal-icon"
                    alt=""
                  />
                  <img
                    src="assets/img/banner-active.svg"
                    className="active-icon"
                    alt=""
                  />
                </div>
                <span className="nav-link-text ms-1">Banner</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                active={invitePath?.isExact}
                to={RoutesPaths.INVITE}
                className="nav-link"
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="fas fa-solid fa-list"></i>
                </div>
                <span className="nav-link-text ms-1">Request logs</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                active={paymentPath?.isExact}
                to={RoutesPaths.PAYMENT}
                className="nav-link"
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="fas fa-solid fa-wallet"></i>
                </div>
                <span className="nav-link-text ms-1">Payments</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                active={paymentPath?.isExact}
                to={RoutesPaths.REFERRAL_INVITES}
                className="nav-link"
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="fas fa-solid fa-wallet"></i>
                </div>
                <span className="nav-link-text ms-1">Invites</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                active={filePath?.isExact}
                to={RoutesPaths.FILE}
                className="nav-link"
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="fas fa-solid fa-file"></i>
                </div>
                <span className="nav-link-text ms-1">Documents</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                active={taskPath?.isExact}
                to={RoutesPaths.TASK}
                className="nav-link"
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="fas fa-check-circle"></i>
                </div>
                <span className="nav-link-text ms-1">Tasks</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                active={paymentPath?.isExact}
                to={RoutesPaths.USER_MANAGER}
                className="nav-link"
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="fas fa-solid fa-user"></i>
                </div>
                <span className="nav-link-text ms-1">Users</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                active={MessagePath?.isExact}
                to={RoutesPaths.MESSAGE_DETAIL}
                className="nav-link"
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="fas fa-solid fa-wallet"></i>
                </div>
                <span className="nav-link-text ms-1">Message</span>
              </NavLink>
            </li>
          </ul>
          <span className="sidebar-account mt-3 mb-2">SUPPORT </span>
          <ul className="navbar-nav">
            <li className="nav-item" onClick={logoutClick}>
              <a className="nav-link" href="#">
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="fas fa-power-off"></i>
                </div>

                <span className="nav-link-text ms-1 logout-icon">LOGOUT</span>
              </a>
            </li>
          </ul>
        </div>
      )}
      <Confirm show={confirmModal} onHide={logoutHide} />
    </aside>
  );
}
