import filesService from "../services/files.service";
import { getDocumentAction } from "./documents";
import { GET_FILES, SET_USER_PROFILE, SET_CURRENT_FILE_NAME, SET_FAILED_MESSAGE, SET_SUCCESS_MESSAGE, START_LOADER, STOP_LOADER } from "./types";

export const getAllFiles = (payload) => (dispatch) => {
  return filesService.getFiles(payload).then((resp) => {
    if(resp.status){
      dispatch({type: GET_FILES ,payload: resp.data,});
    }else{
      dispatch({type: GET_FILES ,payload: [],});
    }
  });
};


export const addFilesAction = (payload) => (dispatch) => {
  dispatch({type: START_LOADER});
  return filesService.addFiles(payload).then((resp) => {
    dispatch({type: STOP_LOADER});
    if (resp.status) {
      dispatch({
        type: SET_SUCCESS_MESSAGE,
        payload: resp.data,
      });
      if(payload.get('company')){
        dispatch(getAllFiles(payload.get('company')));
      }
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: "Something wen't wrong" });
    }
  });
};

export const addUploadAction = (payload) => (dispatch) => {
  return filesService.uploadFile(payload).then((resp) => {
    if (resp.status) {
      dispatch(setCurrentFile(resp.filename))
      dispatch({
        type: SET_SUCCESS_MESSAGE,
        payload: "Files added Successfully",
      });
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: "Something wen't wrong" });
    }
  });
};


export const setCurrentFile = (payload) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_FILE_NAME,
    payload: payload,
  })
};

// New
export const addUploadActionFile = (payload, reducerName, fieldName) => (dispatch) => {
  return filesService.uploadFile(payload).then((resp) => {
    if (resp.status) {
      if(reducerName==='user'){
        dispatch({ type: SET_USER_PROFILE, payload: {fieldName: fieldName, value: resp.filename  } });
      }
      dispatch({type: SET_SUCCESS_MESSAGE,payload: "Files Uploaded Successfully",});
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: "Something wen't wrong" });
    }
  });
};


// simple File upload action
export const simpleUploadAction = (payload) => (dispatch) => {
  return filesService.uploadFile(payload).then((resp) => {
    if (resp.status) {
      dispatch({type: SET_SUCCESS_MESSAGE,payload: "Files Uploaded Successfully",});
      return resp.filename
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: "Something wen't wrong" });
    }
  });
};


export const addDocAction = (payload, id) => (dispatch) => {
  return filesService.addDocFiles(payload, id).then((resp) => {
    if (resp.status) {
      dispatch({type: SET_SUCCESS_MESSAGE, payload: resp.data});
      dispatch(getDocumentAction(1, 20))
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
  });
};

export const uploadMultipleFileAction = (payload) => {
  return filesService.uploadMultipleFiles(payload).then((resp) => {
    if (resp.status) {
      return resp.filename
    } else {
      return null;
    }
  });
};