import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { approveActivityAction } from "../../actions/activityLogs";
import { useDispatch } from "react-redux";
import Deletemodel from "../../pages/dashboard/Deletemodel";
import Confirmmodel from "./Confirmmodel";
import moment from "moment";
import CurrencyFormat from "../currencyFormat/currencyFormat";
import TimeFormat from "../timeFormat/timeFormat";


export default function Sharecapital({ addModalShow, addModalClose, item }) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [confirmshow, setConfirmShow] = useState(false);

  const handleSubmit = async (flag) => {
    setConfirmShow(false)
    setShow(false)
    var payload = {
      type: item.pageType,
      id: item.id,
      capitalId: item.capital._id,
      flag: flag,
    }
    await dispatch(approveActivityAction(payload))
    addModalClose();
    setShow(false)
  }

  return (
    <>
      <Modal
        show={addModalShow}
        onHide={addModalClose}
        className="moddal-width companyEdit-modal ps-0 add-modal"
      >
        <div className="modal-header pt-2">
          <button
            type="button"
            className="btn-close text-dark close-button"
            onClick={addModalClose}
          >
            &nbsp;
            {/* <span aria-hidden="true">×</span> */}
          </button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xl-3">
              <h4 className="edit-title">Request</h4>
              <span className="text-xs">
                <TimeFormat time={item.createdAt} />
              </span>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xl-4">
              <h6>Request Type</h6>
              <h4>{item.description}</h4>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 col-xl-5">
              <h6>Request Type</h6>
              <h4>{item.company?.companyName}</h4>
            </div>
          </div>
          <div className="row paddingtopbtm">
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xl-4">
              <h6>Requested by</h6>
              <div>{item.user ? item.user.first_name + ' ' + item.user.last_name : '-'}</div>
            </div>
            {/* <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xl-4">
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-9">
                  <h6>Requester Position</h6>
                  <div>CEO</div>
                </div>
              </div>
            </div> */}
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xl-4">
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-8">
                  <h6>Company head email</h6>
                  <div>{item.user ? item.user.email : '-'}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="row paddingtopbtm">
            <div className="col-md-12">
              <h6>Request Details</h6>
            </div>
          </div>

          <div className="row paddingtopbtm">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xl-6 curencybox">
              <div className="row">
                <h6>Current Paid-Up Capital</h6>
                <h3>{item.capital_history && item.capital_history.shareCapital && "$ "} {item.capital_history && item.capital_history.shareCapital ? (<CurrencyFormat value={item.capital_history.shareCapital} />) : '-'} </h3>
              </div>
              <div className="row">
                <h6>Number of Shares</h6>
                <h3>{item.capital_history && item.capital_history.shares ? (<CurrencyFormat value={item.capital_history.shares} />) : '-'}</h3>
              </div>
              <div className="row">
                <h6>Currency</h6>
                <h3>{item.capital_history && item.capital_history.currency ? item.capital_history.currency : '-'}</h3>
              </div>
              <div className="row">
                <h6>Share Type</h6>
                <h3>{item.capital_history && item.capital_history.shareType ? item.capital_history.shareType === 1 ? "Ordinary" : item.capital_history.shareType === 2 ? "Preference" : "-" : "-"}</h3>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xl-6 addressbox">
              <div className="row">
                <div className="row">
                  <h6>Current Paid-Up Capital</h6>
                  <h3 className="textclr">{item.capital && item.capital.shareCapital && "$ "} {item.capital && item.capital.shareCapital ? (<CurrencyFormat value={item.capital.shareCapital} />) : '-'}</h3>
                </div>
                <div className="row">
                  <h6>Number of Shares</h6>
                  <h3 className="textclr">{item.capital && item.capital.shares ? (<CurrencyFormat value={item.capital.shares} />) : '-'}</h3>
                </div>
              </div>
              <div className="row">
                <h6>Currency</h6>
                <h3 className="textclr">{item.capital && item.capital.currency ? item.capital.currency : '-'}</h3>
                <div className="row">
                  <h6>Share Type</h6>
                  <h3 className="textclr">{item.capital && item.capital.shareType ? item.capital.shareType === 1 ? "Ordinary" : item.capital.shareType === 2 ? "Preference" : "-" : "-"}</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="row paddingtopbtm">
            <div className="col-md-5">
              <button type="submit" className="btn save-btn savebtn" onClick={addModalClose}>
                BACK
              </button>
            </div>
            {item.status === 1 ? (
              <>
                <div className="col-md-3">
                  <button type="submit" className="btn save-btn deny" onClick={() => setShow(true)}>
                    DENY
                  </button>
                </div>
                <Deletemodel message="Are you sure you want to deny?" addModalShow={show} addModalClose={() => setShow(false)} confirmModal={handleSubmit} />

                <div className="col-md-4">
                  <button type="submit" className="btn save-btn allow" onClick={() => setConfirmShow(true)}>
                    ALLOW AMMENDMENT
                  </button>
                </div>
                <Confirmmodel message="Are you sure you want to allow this Ammendment?" addModalShow={confirmshow} addModalClose={() => setConfirmShow(false)} confirmModal={handleSubmit} />
              </>
            ) : (
              <div className="col-md-7 save-btn ">
                <h6 className="alignright">{item.status === 2 ? "Verified" : item.status === 3 ? "Denied" : "-"}</h6>
                <div className="text-xs alignright">
                <TimeFormat item={item.updatedAt}/>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}
