import { GET_NOTIFICATION_BANNERS, GET_NOTIFICATION_BY_ID } from "../actions/types";
const initialState = {
  notificationBanner: [],
  count: 0,
  notification: {},
};
export default function (state = initialState, action) {
  const { type, payload, count } = action;
  switch (type) {
    case GET_NOTIFICATION_BANNERS:
      return {
        ...state,
        notificationBanner: payload,
        count: count,
      };
    case GET_NOTIFICATION_BY_ID:
      return {
        ...state,
        notification: payload,
      };
    default:
      return state;
  }
}
