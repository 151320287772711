import axionIns from "../axios";

const addChannel = async (payload) => {
  return axionIns
    .post("chat/initiatechat", JSON.stringify(payload))
    .then((response) => {
      return response.data;
    });
};

const getChannelList = async () => {
  return axionIns.get("admin/chat/channellist").then((response) => {
    return response.data;
  });
};


const getTwilioToken = async (string, id) => {
  let url = `user/${string}twiliotoken`;
  if(id) {
    url = `${url}?channel_admin_id=${id}`;
  }
  return axionIns.get(url).then((response) => {
    return response.data
  })
}

const updateChannel = async (obj) => {
  return axionIns.post("chat/updatechannel", JSON.stringify(obj)).then((response) => {
    return response.data
  })
}


const refreshChatReadStatus = async (channelId, obj) => {
  return axionIns.post("chat/refreshChatRoom/"+channelId, JSON.stringify(obj)).then((response) => {
    return response.data
  })
}

const createNewMembers = async (members) => {
  return axionIns.post("chat/createMembers", JSON.stringify(members)).then((response) => {
    return response.data
  })
}

const getChatMedia = async (payload) => {
  return axionIns.get("chat/getConversationMedia/" + payload).then((response) => {
    return response.data;
  });
}

const addChannelParticipant = async (payload) => {
  return axionIns.put("admin/chat/addparticipant", JSON.stringify(payload)).then((response) => {
    return response.data;
  });
}

const removeChannelParticipant = async (payload) => {
  return axionIns.put("admin/chat/removeparticipant", JSON.stringify(payload)).then((response) => {
    return response.data;
  });
}

const changeChannelName = async (payload) => {
  return axionIns.put("admin/chat/changegroupname", JSON.stringify(payload)).then((response) => {
    return response.data;
  });
}

const addNewChannelConversation = async (payload) => {
  return axionIns.post("admin/chat/addnewconversation", JSON.stringify(payload)).then((response) => {
    return response.data;
  });
}

const chatMediaGet = async (channelId) => {
  return axionIns.get(`admin/chat/chatmedia/${channelId}`).then((response) => {
    return response.data;
  });
}

const chatMediaAdd = async (payload) => {
  return axionIns.post("admin/chat/chatmedia", JSON.stringify(payload)).then((response) => {
    return response.data;
  });
}


const exportObject = {
  addChannel,
  getChannelList,
  getTwilioToken,
  updateChannel,
  refreshChatReadStatus,
  createNewMembers,
  getChatMedia,
  addChannelParticipant,
  removeChannelParticipant,
  changeChannelName,
  addNewChannelConversation,
  chatMediaGet,
  chatMediaAdd
};
export default exportObject;
