import {
  GET_SUBSCRIPTION_BY_ID,
  GET_SUBSCRIPTION_LIST,
} from "../actions/types";
const initialState = {
  subscription: [],
  count: 0,
  subscriptionById: {},
};
export default function (state = initialState, action) {
  const { type, payload, count } = action;
  switch (type) {
    case GET_SUBSCRIPTION_LIST:
      return {
        ...state,
        subscription: payload,
        count: count,
      };
    case GET_SUBSCRIPTION_BY_ID:
      return {
        ...state,
        subscriptionById: payload,
      };

    default:
      return state;
  }
}
