import axionIns from "../axios";

const getTask = () => {
  return axionIns.get("admin/tasks").then((response) => {return response.data});
};

const exportOject = {
  getTask,
};

export default exportOject;
