import React from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { SELECT_BOX_THEME, companyType, country, entityType, entityStatus, CUSTOM_SELECT_STYLES } from "../../constants";
import Select from "react-select";
import moment from "moment";
import { useDispatch } from "react-redux";
import { updateBusinessInfo } from "../../actions/businessInfo";
import { useEffect } from "react";
export default function EditBusinessDetail({
  editModalShow,
  editModalClose,
  company,
}) {
  
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if(company && company._id){
      const countryList = [{ name: "Not registered anywhere" }].concat(country) 
      setValue("countryOfIncorporation", countryList.filter((x) => x.name === company.countryOfIncorporation)[0] || "");
      setValue("companyType", companyType.filter((x) => x.label === company.companyType)[0] || "");
    }
  }, [company])

  const onSubmit = (data) => {
    let { _id } = company
    if(_id){
      let payload = {
        company: company?._id,
        uen: data?.uen,
        registrationDate: data?.registrationDate
          ? moment(data?.registrationDate).format("YYYY-MM-DD")
          : "",
        entityType: data?.entityType?.label,
        entityStatus: data?.entityStatus?.label,
        companyType: data?.companyType?.label,
        countryOfIncorporation: data?.countryOfIncorporation?.name,
        businessExpiry: data?.businessExpiry
          ? moment(data?.businessExpiry).format("YYYY-MM-DD")
          : "",
        businessConstitution: data?.businessConstitution,
      };
      dispatch(updateBusinessInfo(payload));
      reset();
      editModalClose();
    }
  };
  return (
    <>
      <Modal
        show={editModalShow}
        onHide={editModalClose}
        className="companyEdit-modal confim-modal filter-modal"
      >
        <div className="modal-header">
          <h5>Standard Verification</h5>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6">
                <label className="labelForm" htmlFor="">
                  UEN
                </label>
                <input
                  maxLength={50}
                  autoComplete="off"
                  type="text"
                  className="form-control"
                  placeholder="Insert UEN "
                  {...register("uen", { required: true })}
                />
                {errors.uen?.type === "required" && (
                  <span className="errorMessage">UEN is required</span>
                )}
              </div>
              <div className="col-md-6">
                <label className="labelForm" htmlFor="">
                  Company Type
                </label>
                <Controller
                  control={control}
                  className="form-control"
                  name="companyType"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      theme={SELECT_BOX_THEME}
                      styles={CUSTOM_SELECT_STYLES}
                      className="select-border"
                      {...field}
                      options={companyType}
                      isClearable={true}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      placeholder="Select Company Type"
                    />
                  )}
                />
                {errors.companyType?.type === "required" && (
                  <span className="errorMessage">Company Type is required</span>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-3">
                <label className="labelForm" htmlFor="">
                  Registration Date
                </label>
                <Controller
                  control={control}
                  className="form-control"
                  name="registrationDate"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <DatePicker
                      className="form-control"
                      {...field}
                      placeholderText="dd/mm/yyyy"
                      onChange={(date) => field.onChange(date)}
                      selected={field.value}
                      dateFormat="dd/MM/yyyy"
                      //   minDate={new Date()}
                    />
                  )}
                />
                {errors.registrationDate?.type === "required" && (
                  <span className="errorMessage">Date is required</span>
                )}
              </div>

              <div className="col-md-3">
                <label className="labelForm" htmlFor="">
                  Business Expiry
                </label>
                <Controller
                  control={control}
                  className="form-control"
                  name="businessExpiry"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <DatePicker
                      className="form-control"
                      {...field}
                      placeholderText="dd/mm/yyyy"
                      onChange={(date) => field.onChange(date)}
                      selected={field.value}
                      dateFormat="dd/MM/yyyy"
                      //   minDate={new Date()}
                    />
                  )}
                />
                {errors.businessExpiry?.type === "required" && (
                  <span className="errorMessage">Date is required</span>
                )}
              </div>

              <div className="col-md-6">
                <label className="labelForm" htmlFor="">
                  Country of Incorporation
                </label>
                <Controller
                  control={control}
                  className="form-control"
                  name="countryOfIncorporation"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      theme={SELECT_BOX_THEME}
                      styles={CUSTOM_SELECT_STYLES}
                      className="select-border"
                      {...field}
                      options={[
                        { name: "Not registered anywhere" },
                      ].concat(country)}
                      isClearable={true}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.name}
                      placeholder="Select Country"
                    />
                  )}
                />
                {errors.countryOfIncorporation?.type === "required" && (
                  <span className="errorMessage">
                    Country of Incorporation is required
                  </span>
                )}
              </div>

              <div className="col-md-6">
                <label className="labelForm" htmlFor="">
                  Entity Type
                </label>
                <Controller
                  control={control}
                  className="form-control"
                  name="entityType"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      theme={SELECT_BOX_THEME}
                      styles={CUSTOM_SELECT_STYLES}
                      className="select-border"
                      {...field}
                      options={entityType}
                      isClearable={true}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.code}
                      placeholder="Select  Entity Type"
                    />
                  )}
                />
                {errors.entityType?.type === "required" && (
                  <span className="errorMessage">Entity Type is required</span>
                )}
              </div>

              <div className="col-md-6">
                <label className="labelForm" htmlFor="">
                  Entity Status
                </label>
                <Controller
                  control={control}
                  className="form-control"
                  name="entityStatus"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      theme={SELECT_BOX_THEME}
                      styles={CUSTOM_SELECT_STYLES}
                      className="select-border"
                      {...field}
                      options={entityStatus}
                      isClearable={true}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      placeholder="Select Entity Status"
                    />
                  )}
                />
                {errors.entityStatus?.type === "required" && (
                  <span className="errorMessage">
                    Entity Status is required
                  </span>
                )}
              </div>

              <div className="col-md-12">
                <label className="labelForm" htmlFor="">
                  Business Constitution
                </label>
                <textarea
                  autoComplete="off"
                  type="text"
                  className="form-control customText"
                  rows="5"
                  cols="90"
                  placeholder="Enter Business Constitution"
                  {...register("businessConstitution", { required: true })}
                />
                {errors.businessConstitution?.type === "required" && (
                  <span className="errorMessage">
                    Business Constitution is required
                  </span>
                )}
              </div>
            </div>

            <div className="row bottomRow">
              <div className="col-md-12 mt-md-0 mt-2 text-center">
                <button
                  type="submit"
                  className="btn save-btn customConfirmButton float-none"
                >
                  Save Details
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}
